<template>
    <div>

    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';

const storeStore = useStoreStore();

const router = useRouter();
const route = useRoute();

onBeforeMount(() =>{
    storeStore.tryReferralAccept(route.params.code).then(function(success){
        if(success){
            useGlobalStore().successMessage('Convite aceito com sucesso');
        }

        router.push('/')
    })
})

</script>

<style scoped>
</style>