<template>
    <BaseTable :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="anticipations.length"  @changePage="changePage">
        <tr v-for="(anticipation, key) in withdrawalStore.anticipations" :key="key">
            <td>{{ anticipation.amount }}</td>
            <td>{{ anticipation.fee }}</td>
            <td>{{ anticipation.created_at }}</td>
            <td>{{ anticipation.date }}</td>
            <td><BaseBadget :text="anticipation.status.name" :color="anticipation.status.color" /></td>
            <td class="actions"></td>
        </tr>
    </BaseTable>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';

import { useWithdrawalStore } from '@/stores/withdrawal';
const withdrawalStore = useWithdrawalStore();
const { anticipations, paginate } = storeToRefs(withdrawalStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const tableColumns = ['Valor', 'Taxa', 'Data da solicitação', 'Data da efetivação', 'Status'];

const isLoading = ref(false);

import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('wallet')) {
        return router.push('/403');
    }

    if(withdrawalStore.anticipations.length == undefined){
        isLoading.value = true;
    }
    
    if(route.query.page) {
        withdrawalStore.filters.page = route.query.page;
    }

    loadAnticipations().then(() => {
        isLoading.value = false;
    });
});


async function loadAnticipations() {
    if(withdrawalStore.filters.page) {
        router.replace({query: withdrawalStore.filters});
    }
    await withdrawalStore.tryAnticipations()
    globalStore.loader('hide');
}

let isChangingPage = false;
async function changePage(p) {
    if(isChangingPage) return;
    withdrawalStore.filters.page = p;
    globalStore.loader('show');
    isChangingPage = true;
    loadAnticipations().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}
</script>

<style scoped>

</style>