<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
            <router-link v-if="globalStore.hasPermissionTo('new_user')" to="/users/create" class="item action btn-group">
                <BaseButton class="btn btn-primary w-100">Novo</BaseButton>
            </router-link>
        </BaseHeader>
        <BaseFilter :title="title" @search="search" :initialSearch="initialSearch"></BaseFilter>
        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="users.length"  @changePage="changePage" firstColumnLarge>
            <tr v-for="(user, key) in userStore.users" :key="key">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td class="actions">
                    <router-link :to="'/users/'+ user.id" v-if="globalStore.hasPermissionTo('edit_user')" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar"><font-awesome-icon icon="fas fa-edit" /></router-link>
                    <span v-if="user.is_primary === false && globalStore.hasPermissionTo('destroy_user')" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(user.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                </td>
            </tr>
        </BaseTable>
        <router-view></router-view>
        <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
            <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text" >Deseja remover este usuário?</p> 
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <BaseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</BaseButton>
                <BaseButton @click="deleteUser" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</BaseButton>
            </div>
        </div>
        </BasePopup>
    </div>
</template>

<script setup>
const title = {title: 'Usuários', icon: 'fa-user-tie'};
import { ref, onBeforeMount, shallowRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useUsersStore } from '@/stores/users';
import { useGlobalStore } from '@/stores/global';
import { useRouter, useRoute } from 'vue-router';
import BaseFilter from '@/components/ui/BaseFilter.vue';

const userStore = useUsersStore();
const { users, paginate } = storeToRefs(userStore);

const globalStore = useGlobalStore();

const tableColumns = ['Nome', 'E-mail'];

const isLoading = ref(false);
const route = useRoute();
const router = useRouter();

let initialSearch = null;
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null)

onBeforeMount(() => {
    if(userStore.users.length == undefined){
        isLoading.value = true;
    }

    if(route.query.search || route.query.page) {

        Object.keys(userStore.users_filters).forEach((key) => {
            if(key == 'search') {
                initialSearch = route.query.search;
            }
            let param = route.query[key];
            userStore.users_filters[key] = (param == undefined) ? null : param;
        });
    }

    if(userStore.users_filters){
        Object.keys(userStore.users_filters).forEach((key) => {{
            let param = userStore.users_filters[key];
            userStore.users_filters[key] = (param == undefined) ? null : param;

            if(key == 'search') {
                initialSearch = userStore.users_filters[key];

            }else if(key == 'page') {
                if((userStore.users_filters[key]) && userStore.users_filters[key].constructor !== Array) {
                    let currentThing = userStore.users_filters[key];
                    userStore.users_filters[key] = [];
                    userStore.users_filters[key].push(currentThing);
                }
            }
        }});
    }

    loadUsers().then(() => {
        isLoading.value = false;
    });
});

async function loadUsers() {
    let usedParams = {};
    Object.keys(userStore.users_filters).forEach((key) => {
        if(userStore.users_filters[key] != null && userStore.users_filters[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: userStore.users_filters[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    await userStore.tryUsers(userStore.users_filters);
    globalStore.loader('hide');
}

function search(r) {
    userStore.users_filters.search = r;
    userStore.users_filters.page = null;
    loadUsers();
}

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    userStore.users_filters.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadUsers().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let isDeleting = false;
const current_page = ref({
    'page': null
});

let userToDelete = null;
async function deleteUser() {
    if(!isDeleting && userToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        current_page.value.page = userStore.paginate.current_page
        await userStore.tryDeleteUser(userToDelete).then(() => {
            userStore.tryUsers(current_page.value).then(()=>{
                if(userStore.users.length > 1){
                    userStore.tryUsers(current_page.value);
                    isLoading.value = false;
                    globalStore.loader('hide');
                    isDeleting = false;
                    confirmAction.value = null;
                }else {
                    current_page.value.page = current_page.value.page - 1;
                    router.replace({query: current_page.value});
                    userStore.tryUsers(current_page.value).then(() =>{
                        globalStore.loader('hide');
                        isLoading.value = false;
                        isDeleting = false;
                        confirmAction.value = null;
                    })
                }    
            });
            excludeTextConfirm.value = null;  
        }); 
    }
}

function openPopup(user_id) {
    userToDelete = user_id
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}
</script>