<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>
                        Débitos
                        <span data-bs-toggle="tooltip" data-bs-placement="right" data-bs-animation="true" v-tooltip title="Débitos referentes a chargeback/pedidos devolvidos após serem sacados." class="ms-1">
                            <font-awesome-icon icon="fas fa-circle-info"/>
                        </span>
                    </th>
                    <th>Sacado em</th>
                    <th>
                        Total
                        <span data-bs-toggle="tooltip" data-bs-placement="right" data-bs-animation="true" v-tooltip title="Valor total do pedido debitado." class="ms-1">
                            <font-awesome-icon icon="fas fa-circle-info"/>
                        </span>
                    </th>
                    <th class="row_right">
                        Pago
                        <span data-bs-toggle="tooltip" data-bs-placement="right" data-bs-animation="true" v-tooltip title="Valor do débito pago por esse pedido." class="ms-1">
                            <font-awesome-icon icon="fas fa-circle-info"/>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>

                <tr v-for="(item, key) in props.comissions.debts_paid_by_order" :key="key">
                    <td><router-link :to="'/orders/' + item.id">{{ item.id }}</router-link></td>
                    <td><router-link v-if="item.withdrawal" :to="'/withdrawals/' + item.withdrawal.id">{{ item.withdrawal.code }}</router-link></td>
                    <td><span class="sensitive_information">{{ globalStore.formatMoney(item.total) }}</span></td>
                    <td class="row_right"><span class="sensitive_information">{{ globalStore.formatMoney(item.amount) }}</span></td>
                </tr>

            </tbody>
        </table>
    </div>
</template>

<script setup>
const props = defineProps({
    comissions: {
        required: true,
        default: null
    }
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
</script>