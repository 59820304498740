<template>
    <div v-if="methodsLoaded != false">
        <div v-if="route.params.id" class="container px-2 px-lg-4">
            <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
                <div class="item action btn-group">
                    <div class="item action btn-group">
                        <baseButton class="btn-primary w-100" @click="submit('completed')" style="border-top-right-radius: 0!important; border-bottom-right-radius: 0!important;"  :disabled="isLoading">
                            Salvar
                        </baseButton>
                        <button class="btn btn-primary dropdown-toggle save_draft" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="background-color: #215EDA;"></button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li class="dropdown-item"  @click="submit('draft')" :disabled="isLoading"><a>Salvar como rascunho</a></li>
                        </ul>
                    </div>
                </div>
            </BaseHeader>
            <div>
                <div class="row">
                    <div class="box-infos col-12 mb-4 mb-xl-0">
                        <div class="box_content">
                            <form class="row mb-2" @submit.prevent="">
                                <BaseInput
                                    class="col-6"
                                    label="Nome *"
                                    id="title"
                                    type="text"
                                    :page="route.name"
                                    v-model="product.title"
                                />
                                <BaseInput
                                    class="col-6"
                                    label="Descrição"
                                    id="description"
                                    type="text"
                                    :page="route.name"
                                    v-model="product.description"
                                />
                                <BaseInput
                                    class="col-md-6 col-12"
                                    label="Tag"
                                    id="tag"
                                    type="text"
                                    :page="route.name"
                                    v-model="product.tag"
                                />
                                <BaseInput
                                    class="col-md-6 col-12"
                                    label="Código(SKU)"
                                    id="sku"
                                    type="text"
                                    :page="route.name"
                                    v-model="product.sku"
                                />
                                <BaseSelect
                                    class="col-md-4 col-12"
                                    id="type"
                                    :items="productType"
                                    label="Tipo *"
                                    placeholder="Selecione"
                                    :page="route.name"
                                    v-model="product.type"
                                />
                                <BaseSelect
                                    class="col-md-4 col-12"
                                    id="category_slug"
                                    :items="productCategories"
                                    label="Categoria *"
                                    placeholder="Selecione"
                                    :page="route.name"
                                    v-model="product.category_slug"
                                />
                                <BaseInput
                                    v-if="
                                        product.type == 'digital' &&
                                        product.category_slug == 'course'
                                    "
                                    id="course_title"
                                    class="col-md-6 col-12"
                                    type="text"
                                    label="Nome do Curso *"
                                    :page="route.name"
                                    v-model="product.course_title"
                                />
                                <BaseInput
                                    v-if="
                                        product.type == 'digital' &&
                                        product.category_slug == 'course'
                                    "
                                    id="course_duration"
                                    class="col-md-6 col-12"
                                    type="number"
                                    label="Duração do curso *"
                                    tooltip="Duração do curso em minutos"
                                    :page="route.name"
                                    v-model="product.course_duration"
                                />
                                <BaseDatePicker
                                    v-if="product.category_slug == 'ticket'"
                                    id="meta.ticket_date"
                                    class="col-md-4 col-12 mb-3"
                                    label="Data do evento"
                                    :page="route.name"
                                    v-model="product.meta.ticket_date"
                                    range
                                    multi
                                    timepicker
                                    :close_autoapply="false"
                                />
                                <BaseInput
                                    v-if="product.category_slug == 'ticket'"
                                    id="meta.ticket_place"
                                    class="col-12"
                                    textarea
                                    label="Local do evento"
                                    :page="route.name"
                                    v-model="product.meta.ticket_place"
                                />
                                                      
                                <template v-if="product.type == 'physical'">
                                    <h6 class="mt-2">Volumes</h6>
                                    <BaseInput
                                        class="col-md-3 col-12"
                                        label="Quantidade *"
                                        id="qty_items"
                                        type="number"
                                        tooltip_text="Quantidade de itens que compõem esse produto"
                                        :page="route.name"
                                        v-model="product.qty_items"
                                    />
                                    <BaseInput
                                        class="col-md-2 col-12"
                                        id="weight"
                                        type="number"
                                        label="Peso"
                                        tag="kg"
                                        :page="route.name"
                                        v-model="product.weight"
                                    />
                                    <BaseInput
                                        class="col-md-2 col-12"
                                        id="width"
                                        type="number"
                                        label="Largura"
                                        tag="cm"
                                        :page="route.name"
                                        v-model="product.width"
                                    />
                                    <BaseInput
                                        class="col-md-2 col-12"
                                        id="height"
                                        type="number"
                                        label="Altura"
                                        tag="cm"
                                        :page="route.name"
                                        v-model="product.height"
                                    />
                                    <BaseInput
                                        class="col-md-3 col-12"
                                        id="length"
                                        type="number"
                                        label="Comprimento"
                                        tag="cm"
                                        :page="route.name"
                                        v-model="product.length"
                                    />
                                </template>
                                <BaseSwitch
                                    class="col-12 my-lg-2 mt-4 mb-5"
                                    label="Permitir que afiliados tenham acesso a esse produto"
                                    id="allow_referral"
                                    :page="route.name"
                                    v-model:checked="product.allow_referral"
                                />
                                <BaseSwitch
                                    v-if="product.type === 'digital'"
                                    class="mb-2"
                                    label="Solicitar endereço na compra"
                                    id="meta.request_address_on_digital"
                                    :page="route.name"
                                    v-model:checked="product.meta.request_address_on_digital"
                                />
                            </form>
                        </div>

                        <div class="box_content">
                            <form class="row">
                                <BaseInput
                                    class="col-md-4 col-12"
                                    type="text"
                                    label="Valor *"
                                    id="price"
                                    tag="R$"
                                    :page="route.name"
                                    v-model="product.price"
                                    mask="9.99#,##"
                                    :tokens="'9:[0-9]:repeated'"
                                    :reversed="true"
                                    maxlength="10"
                                />
                                <BaseInput
                                    class="col-md-4 col-12"
                                    type="text"
                                    label="Valor promocional"
                                    id="price_promotional"
                                    tag="R$"
                                    :page="route.name"
                                    v-model="product.price_promotional"
                                    mask="9.99#,##"
                                    :tokens="'9:[0-9]:repeated'"
                                    :reversed="true"
                                    maxlength="10"
                                />
                                <BaseSelect
                                    class="col-md-4 col-12"
                                    id="installments"
                                    :items="installmentsList"
                                    label="Parcelamento"
                                    type="text"
                                    placeholder="Selecione"
                                    :page="route.name"
                                    v-model="product.installments"
                                />   
                            </form>   
                        </div>

                        <div class="box_content">
                            <div class="row align-items-end">
                                <BaseSwitch
                                    class="my-lg-2 mt-3 mb-0"
                                    label="Gerenciar estoque"
                                    id="manage_qty"
                                    :page="route.name"
                                    v-model:checked="product.manage_qty"
                                />
                            </div>
                            <template v-if="product.manage_qty">
                                <hr class="m-0" />
                                <div class="row align-items-end">
                                    <BaseInput
                                        class="col-md-6 col-12"
                                        id="qty"
                                        label="Estoque"
                                        type="number"
                                        :page="route.name"
                                        v-model="product.qty"
                                    />
                                    <BaseSwitch
                                        class="my-lg-2 mt-3 mb-0"
                                        label="Vender mesmo com o estoque zerado"
                                        id="sell_on_zero_qty"
                                        :page="route.name"
                                        v-model:checked="product.sell_on_zero_qty"
                                    />
                                </div>
                            </template>
                        </div>

                        <template v-if="1 == 1">
                            <div class="box_content">
                                <div class="row align-items-end">
                                    <h6>Recorrência</h6>
                                    <BaseSwitch
                                        class="my-lg-2 mt-3 mb-0"
                                        label="Ativar recorrência"
                                        id="recurrent"
                                        :page="route.name"
                                        v-model:checked="productStore.product.recurrent"
                                    />
                                    <template v-if="product.recurrent">
                                        <BaseInput
                                            class="col-md-4 col-12 mb-0"
                                            id="recurrence_period.count"
                                            type="number"
                                            label="Período da recorrência*"
                                            :page="route.name"
                                            v-model="product.recurrence_period.count"
                                        />
                                        <BaseSelect
                                            class="col-md-4 col-12 mb-0"
                                            id="recurrence_period.interval"
                                            :items="recurrenceInterval"
                                            placeholder="Selecione"
                                            :page="route.name"
                                            v-model="product.recurrence_period.interval"
                                        />
                                        <small>*Com a recorrência ativa só serão realizadas vendas à vista com cartão de crédito.</small>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <div class="box_content">
                            <form class="my-4 px-4">
                                <div>
                                    <BaseFileUpload
                                        class="col-12"
                                        label="Imagem do produto *"
                                        type="file"
                                        id="image"
                                        tooltip_text="Tamanho recomendado: 200px por 200px"
                                        :page="route.name"
                                        v-model="product.image"
                                        preview
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BaseDefineStore
            v-else
            :create_view="'products'"
            @loadMethods="loadMethods"
        />
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from "@/stores/global";
import { useProductStore } from "@/stores/products";
import { useStoreStore } from "@/stores/store";

const title = { title: "Novo produto", icon: "fa-box" };

const route = useRoute();
const router = useRouter();

const productStore = useProductStore();
const { product } = storeToRefs(productStore);

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore)

const storeStore = useStoreStore();

const previousPage = ref(null);

var methodsLoaded = ref(false)

const productType = ref({
    digital: "Produto Digital",
    physical: "Produto Físico",
});

const installmentsList = ref({
    1: '1x',
    2: '2x',
    3: '3x',
    4: '4x',
    5: '5x',
    7: '7x',
    8: '8x',
    9: '9x',
    10: '10x',
    11: '11x',
    12: '12x',
})

const productsList = ref({});
const productCategories = ref ({});
const recurrenceInterval = ref({
    'day': 'Dia(s)',
    'week': 'Semana(s)',
    'month': 'Mês(es)',
    'year': 'Ano(s)'
});

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('new_product')) {
        return router.push('/403');
    }

    delete globalStore.errors[route.name];

    globalStore.cleanObject(productStore.product);
    previousPage.value = router.options.history.state.back;
    if(route.params.id){
        methodsLoaded.value = true
        loadMethods(route.params.id)
    } else if(defaultStore._object.defaultStore.id != undefined){
        let is_productor_store = false;
        storeStore.tryStoresProductor().then(() =>{
            for(let i = 0; i < storeStore.stores_productor.length; i++){
                if(storeStore.stores_productor[i].id == defaultStore._object.defaultStore.id){
                    is_productor_store = true;
                    i = storeStore.stores_productor.length;
                }
            }
            if(is_productor_store){
                methodsLoaded.value = true;
                loadMethods(defaultStore._object.defaultStore.id);
                router.push('/products/create/store=' + defaultStore._object.defaultStore.id);
            } else{
                methodsLoaded.value = true;
            }
        })
    } else{
        methodsLoaded.value = true
    }
    productStore.tryProductsCategories().then(() => {
      if(productStore.all_categories){
          for(let i = 0; i < productStore.all_categories.length; i++){
              productCategories.value[productStore.all_categories[i].slug] = productStore.all_categories[i].title;
          }
      }
    });
});

function loadMethods() {
    setTimeout(() => {
        productStore.tryProductsList("").then(() => {
            productsList.value = {};
            product.value.id_store = route.params.id;
            for (let i = 0; i < productStore.productslist.length; i++) {
                productsList.value[productStore.productslist[i].id] = productStore.productslist[i].title + [productStore.productslist[i].tag !== null ? ' ('+ productStore.productslist[i].tag +')' : ''];
            }
            methodsLoaded.value = true
        });
    }, 100);
}

const isLoading = ref(false);

function submit(value) {
    if(!isLoading.value){
        globalStore.loader("show");
        isLoading.value = true;

        const params = {
            productCreate: [
                'title',
                'tag',
                'description',
                'price_promotional',
                'price',
                'image',
                'manage_qty',
                'qty',
                'sell_on_zero_qty',
                'qty_items',
                'product_sell',
                'id_store',
                'type',
                'category_slug',
                'recurrent',
                'recurrence_period',
                'installments',
                'weight',
                'width',
                'height',
                'length',
                'allow_referral',
                'meta.request_address_on_digital',
                'sku',
                'course_title',
                'course_duration',
                'status',
            ]
        }

        if(productStore.product.category_slug === 'ticket'){
            params.productCreate.push('meta.ticket_date');
            params.productCreate.push('meta.ticket_place');
        }

        if(value === 'completed'){
            productStore.product.status = 'published';
        } else if(value === 'draft'){
            productStore.product.status = 'draft';
        }

        productStore.tryCreateProduct(params, route.name).then(function (success) {
            globalStore.loader("hide");
            isLoading.value = false;
            if (success) {
                router.push("/products");
            }
        });
    }
}
</script>

<style scoped>
.box_content .row {
    padding: 19px 24px 24px 24px !important;
}

.divisor_top {
    border-top: 1px #ddd solid !important;
}

.box-infos {
    padding-right: 10px;
}
.boxes-images {
    padding-left: 10px;
}

.save_draft{
    border: 0px;
    border-left: 1px #2750a3 solid;;
}

.save_draft:hover{
    background-color: #2750a3 !important;
}

.dropdown-toggle:empty::after{
    margin: auto!important;
    font-size: 11px!important;
}

@media screen and (max-width: 992px){
    .base-title .item, .base-title .btn-group {
        width: fit-content!important;
    }
}

@media screen and (max-width: 768px){
    .base-title .action {
        min-width: 100%;
    }
}
</style>