<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <template v-if="isLoaded != false">        
            <header>
                <font-awesome-icon icon="fa-solid fa-file" style="color: #215eda;"/>
                <span v-if="route.name == 'productFileEdit'"  class="ms-2 align-middle">Editar arquivo</span>
                <span v-else class="ms-2 align-middle">Novo arquivo</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseInput
                            class="col-12 mb-3"
                            label="Título *"
                            type="text"
                            id="title"
                            v-model="file.title"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-12 mb-3"
                            label="Descrição *"
                            textarea
                            id="description"
                            v-model="file.description"
                            :page="route.name"
                        />
                        <BaseFileUpload
                            class="col-12 mb-3"
                            label="Arquivo *"
                            type="file"
                            id="file"
                            v-model="file.file"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-12 mb-3"
                            label="Expiração do arquivo (dias) *"
                            type="number"
                            id="expiration"
                            v-model="file.expiration"
                            :page="route.name"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </template>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter} from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { file }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false)
const isLoading = ref(false);

const product_id = computed (function () {
    return route.params.id;
});

onBeforeMount(() =>{
    if(route.name == 'productFileEdit'){
        if(productStore.file.id == undefined || productStore.file.id == null){
            globalStore.loader('show')
            productStore.tryFile(route.params.slug).then(() =>{
                isLoaded.value = true
                globalStore.loader('hide')
            })
        } else {
            isLoaded.value = true
        }
    } else if(route.name == 'productFileCreate') {
        globalStore.cleanObject(productStore.file);
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(route.name == 'productFileEdit'){
            let params = ['title', 'description', 'file', 'expiration'];

            productStore.tryUpdateFile(params, route.name, productStore.file.id).then(function(success) {
                if(success){
                    useGlobalStore().successMessage('Arquivo atualizado com sucesso');

                    productStore.tryFiles().then(function(success){
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/files')
                        } else{
                            globalStore.loader('hide');
                        }
                    })
                }
                isLoading.value = false;
            });
        } else if(route.name == 'productFileCreate') {
            let params = ['title', 'description', 'file', 'expiration'];

            productStore.tryCreateFile(params, route.name).then(function(success){
                if(success){
                    useGlobalStore().successMessage('Arquivo criado com sucesso');

                    productStore.tryFiles().then(function(success) {
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/files')
                        } else {
                            globalStore.loader('hide');
                        }
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    }
}

</script>