<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <div>
            <header>
                <font-awesome-icon icon="fas fa-file-lines" style="color: #215eda;"/>
                <span v-if="route.name == 'storePageEdit'" class="ms-2 align-middle">Editar página</span>
                <span v-else class="ms-2 align-middle">Nova página</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseInput
                            class="col-12 mb-3"
                            label="Nome *"
                            type="text"
                            id="title"
                            v-model="page.title"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-12 mb-3"
                            label="URL *"
                            type="text"
                            id="url"
                            v-model="page.url"
                            :page="route.name"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </div>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';
import { useRoute, useRouter } from "vue-router"; 
import { storeToRefs } from 'pinia';

const globalStore = useGlobalStore();
const storeStore = useStoreStore();
const { page } = storeToRefs(storeStore);

const route = useRoute();
const router = useRouter();

const isLoading = ref(false);

const store_id = computed (function () {
    return route.params.id;
});

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(route.name == 'storePageEdit'){
        if(storeStore.page.id){
            globalStore.loader('hide')
        } else {
            storeStore.tryPage(route.params.slug, store_id.value).then(() =>{
                globalStore.loader('hide')
            })
        }
    } else if(route.name == 'storePageCreate'){
        globalStore.cleanObject(storeStore.page);
        globalStore.loader('hide')
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(route.name == 'storePageEdit'){

            let params = ['title', 'url'];

            storeStore.tryUpdatePage(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryPages().then(function() {
                        globalStore.loader('hide');
                        isLoading.value = false;
                        router.push('/stores/' + store_id.value + '/pages')
                    });
                }else {
                    globalStore.loader('hide');
                }
            })
        }
        else if(route.name == 'storePageCreate') {

            let params = ['title', 'url'];

            storeStore.tryCreatePage(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryPages().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/pages')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    }
}


</script>