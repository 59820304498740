<template>
  <div id="completion">
    <div class="container">
      <div class="pt-5 pb-5 text-center">
        <TheLogo width="150" id="logo" />
      </div>

      <router-view></router-view>

      <div v-if="route.name == 'accountCompletionDocuments'">
        <div class="space"></div>

        <div class="actions">
          <div class="container d-flex justify-content-between">
            <router-link to="/account/completion/bank">
              <BaseButton class="btn-white btn-lg"><font-awesome-icon class="mx-2" icon="fa-solid fa-arrow-left-long" />
                Voltar</BaseButton>
            </router-link>
            <BaseButton @click="submit" class="btn-primary btn-lg">Salvar <font-awesome-icon class="mx-2" icon="fa-solid fa-check" /></BaseButton>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { useAccountStore } from '@/stores/account'
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from '@/stores/global';
import { onBeforeMount } from 'vue';

const route = useRoute();
const router = useRouter();

const accountStore = useAccountStore();
const globalStore = useGlobalStore();

onBeforeMount(() => {
  if(accountStore.account.status.slug !== 'new'){
    router.push('/');
  }
});

async function submit() {
  globalStore.loader('show');

  let params = {
    accountCompletionInformations: ['social_reason', 'name', 'birth_date', 'document', 'state_registration', 'phone', 'cellphone', 'type', 'mothers_name', 'occupation'],
    accountCompletionAddress: ['postcode', 'address', 'address_number', 'neighborhood', 'complement', 'city', 'state'],
    accountCompletionBank: ['bank_account_code', 'bank_account_type', 'bank_account_agency', 'bank_account_number', 'bank_account_number_digit', 'pix_type', 'pix_key'],
    accountCompletionDocuments: ['file_cnpj_card', 'file_social_contract', 'file_proof_address', 'file_proof_bank', 'file_document_front', 'file_document_back']
  };

  accountStore.tryUpdateAccountCompletion(params, route.name).then(function (success) {
    globalStore.loader('hide');

    if (success) {
      router.push('/');
    }
  })
}
</script>

<style scoped>
</style>