<template>
    <div class="box mb-3">
        <nav>
            <ul class="tabs" id="nav-tab" role="tablist">
                <li class="active" id="nav-products-tab" data-bs-toggle="tab" data-bs-target="#nav-products">Produtos</li>
                <li id="nav-comission-tab" data-bs-toggle="tab" data-bs-target="#nav-comission">Taxas e comissões</li>
            </ul>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane show active" id="nav-products" role="tabpanel">
                <div class="row">
                    <div class="col-md">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Produto</th>
                                        <th>Qtd</th>
                                        <th>Valor</th>
                                        <th class="row_right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, key) in props.products" :key="key">
                                        <td>
                                            {{ product.title }}
                                            <BaseBadget small class="ms-1" v-if="product.type != 'primary'" icon="fa-arrow-up" iconType="fas" color="#28a745" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="globalStore.capitalizeText(product.type)" />
                                        </td>
                                        <td>{{ product.qty }}</td>

                                        <td v-if="product.price_promotional">
                                            <del class="d-block">{{ globalStore.formatMoney(product.price) }}</del> 
                                            {{ globalStore.formatMoney(product.price_promotional) }}
                                        </td>
                                        <td v-else>{{ globalStore.formatMoney(product.price) }}</td>

                                        <td v-if="product.price_promotional">{{ globalStore.formatMoney(product.price_promotional) }}</td>
                                        <td v-else>{{ globalStore.formatMoney(product.price) }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3">Subtotal</td>
                                        <td class="row_right">{{ globalStore.formatMoney(props.tables_values.subtotal) }}</td>
                                    </tr>
                                    <tr v-if="props.tables_values.shipping">
                                        <td colspan="3">Entrega ({{ props.tables_values.shipping.title }})</td>
                                        <td class="row_right">{{ globalStore.formatMoney(props.tables_values.shipping.cost) }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            Descontos
                                            <span v-if="props.tables_values.discounts.discount_payment" class="d-block fw-light">Método de pagamento: {{ globalStore.formatMoney(props.tables_values.discounts.discount_payment) }}</span>
                                            <span v-if="props.tables_values.discounts.coupon_code" class="d-block fw-light">Cupom: {{ globalStore.formatMoney(props.tables_values.discounts.coupon_code) }}</span>
                                        </td>
                                        <td class="row_right">{{ globalStore.formatMoney(props.tables_values.discounts.total) }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">Total
                                        </td><td class="row_right">{{ globalStore.formatMoney(props.tables_values.total) }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="nav-comission" role="tabpanel">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>
                                    Valor pago pelo comprador
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Este é o valor que foi pago pelo comprador, considerando tarifas de parcelamento e possíveis cupons de desconto." class="ms-1"><font-awesome-icon icon="fas fa-circle-info" /></span>
                                </td>
                                <td class="row_right">{{ globalStore.formatMoney(props.comissions.total) }}</td>
                            </tr>
                            <tr>
                                <td>
                                    Valor pago pelo comprador sem tarifas
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Este é o valor pago pelo comprador, descontando possíveis tarifas de parcelamento e possíveis cupons de desconto." class="ms-1"><font-awesome-icon icon="fas fa-circle-info" /></span>
                                </td>
                                <td class="row_right">{{ globalStore.formatMoney(props.comissions.total_without_fees) }}</td>
                            </tr>
                            <tr>
                                <td>
                                    Valor base para cálculo de tarifas e comissões
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="O valor base para o cálculo das tarifas da Fullsale e comissões, é valor pago pelo comprador, descontando tarifas de parcelamento e possíveis cupons de desconto." class="ms-1"><font-awesome-icon icon="fas fa-circle-info" /></span>
                                </td>
                                <td class="row_right">{{ globalStore.formatMoney(props.comissions.total_without_fees_comissions) }}</td>
                            </tr>
                            <tr>
                                <td>Tarifa Fullsale</td>
                                <td class="row_right">{{ globalStore.formatMoney(props.comissions.total_fullsale) }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style="color: #28a745!important;">Sua comissão</td>
                                <td style="color: #28a745!important;" class="row_right">{{ globalStore.formatMoney(props.comissions.your_comission) }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const props = defineProps({
    products: {
        required: true,
        default: null
    },

    tables_values: {
        required: true,
        default: null
    },

    comissions: {
        required: true,
        default: null
    }
});
</script>

<style scoped>
.tabs {
    display: flex;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
}

.tabs li {
    display: inline-block;
    padding: 20px 20px;
    font-weight: 600;
    cursor: pointer;
    color: #767676;
    border-bottom: 1px #ddd solid;
    width: 100%;
}

.tabs li.active {
    border-bottom: 2px #215eda solid;
}

div p a {
    text-decoration: underline !important;;
}
</style>