<template>
  <div class="box_content">
    <div class="tab-content">
      <div class="tab-pane show active p-4">
        <h6 class="mb-0" v-if="accountStore.account.status.slug === 'validated'" style="color: green!important;">Documentos verificados.</h6>

        <div class="row" v-if="accountStore.account.type == 'J' && accountStore.account.status.slug !== 'validated'">
          <BaseFileUpload :class="[accountStore.account.status.slug === 'validated' ? 'disabled' : ''] + ' col-12'" label="Cartão CNPJ *" id="file_cnpj_card" :page="route.name"
            v-model="accountStore.account.file_cnpj_card" :disabled="!accountStore.account.is_editable" />

          <BaseFileUpload :class="[accountStore.account.status.slug === 'validated' ? 'disabled' : ''] + ' col-12'" label="Última alteração do contrato social *" id="file_social_contract"
            :page="route.name" v-model="accountStore.account.file_social_contract" :disabled="!accountStore.account.is_editable" />

          <BaseFileUpload :class="[accountStore.account.status.slug === 'validated' ? 'disabled' : ''] + ' col-12'" label="Comprovante de endereço da empresa *" id="file_proof_address"
            :page="route.name" v-model="accountStore.account.file_proof_address" :disabled="!accountStore.account.is_editable" />

          <BaseFileUpload :class="[accountStore.account.status.slug === 'validated' ? 'disabled' : ''] + ' col-12'" label="Comprovante bancário *" id="file_proof_bank" :page="route.name"
            v-model="accountStore.account.file_proof_bank" :disabled="!accountStore.account.is_editable" />

        </div>

        <div class="row" v-else-if="accountStore.account.type == 'F' && accountStore.account.status.slug !== 'validated'">

          <BaseFileUpload :class="[accountStore.account.status.slug === 'validated' ? 'disabled' : ''] + ' col-12'" label="Foto da frente do RG ou CNH *" id="file_document_front" :page="route.name"
            v-model="accountStore.account.file_document_front" :disabled="!accountStore.account.is_editable" />

          <BaseFileUpload :class="[accountStore.account.status.slug === 'validated' ? 'disabled' : ''] + ' col-12'" label="Foto do verso do RG ou CNH *" id="file_document_back" :page="route.name"
            v-model="accountStore.account.file_document_back" :disabled="!accountStore.account.is_editable" />

          <BaseFileUpload :class="[accountStore.account.status.slug === 'validated' ? 'disabled' : ''] + ' col-12'" label="Comprovante de endereço *" id="file_proof_address" :page="route.name"
            v-model="accountStore.account.file_proof_address" :disabled="!accountStore.account.is_editable" />

          <BaseFileUpload :class="[accountStore.account.status.slug === 'validated' ? 'disabled' : ''] + ' col-12'" label="Comprovante bancário *" id="file_proof_bank" :page="route.name"
            v-model="accountStore.account.file_proof_bank" :disabled="!accountStore.account.is_editable" />

        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { useAccountStore } from '@/stores/account'
import { useRoute } from "vue-router";

const accountStore = useAccountStore();
const route = useRoute();

</script>

<style scoped>

.disabled :deep(.fake-input){
  cursor: default;
}

.disabled :deep(.fake-input:hover){
  background-color: #f8f8f8;
}

</style>