<template>
    <div class="box_content">
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>Transportadora</th>
                        <th></th>
                    </tr>
                </thead>
                <template v-if="shipping_companies">
                    <tbody>
                        <tr v-for="(shipping_company, key) in shipping_companies" :key="key">
                            <td>{{ shipping_company.title }}</td>
                            <td class="actions">
                                <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editCompany(shipping_company.id)"><font-awesome-icon icon="fas fa-edit" /></span>
                                <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(shipping_company.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </table>
            <h6 v-if="!shipping_companies.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>

        </div>
        <div style="text-align:center;" class="p-4 divisor_top">
            <router-link :to="'/stores/' + store_id + '/shipping/companies/new'"> 
                <BaseButton class="btn-primary btn-lg">
                    Adicionar
                </BaseButton>
            </router-link>
        </div>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover esta transportadora?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteCompany" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { computed, shallowRef, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from "vue-router";
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from "@/stores/global";

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { shipping_companies } = storeToRefs(storeStore);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

let companyToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null)
const isLoading = ref(false);

async function deleteCompany(){
    if(!isDeleting && !isLoading.value && companyToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isLoading.value = true;
        globalStore.loader('show');
        await storeStore.tryDeleteShippingCompany(companyToDelete).then(function(success) {
            isDeleting = false;
            if(success){
                storeStore.tryShippingCompanies().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    confirmAction.value = null;
                    isLoading.value = false;
                    router.push('/stores/' + store_id.value + '/shipping/companies')
                });
            }else {
                isLoading.value = false;
                globalStore.loader('hide');
                isDeleting = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function editCompany(id){
    globalStore.loader('show')
    storeStore.tryShippingCompany(id).then(() =>{
        router.push('/stores/' + store_id.value + '/shipping/companies/' + id).then(() =>{
            globalStore.loader('hide')
        })
    })
}

function openPopup(id) {
    companyToDelete = id
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

</script>