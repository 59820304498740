<template>
    <div class="box_content">
        <div class="divisor_bottom  p-4">
            <div class="row">
                <h6 class="mb-4">Motivo do reembolso</h6>
                <BaseInput
                    class="col-12"
                    type="text"
                    id="refund_text"
                    :required="true"
                    textarea
                    v-model="order.refund.refund_text"
                    :page="page"
                    :disabled="!order.is_cancelable"
                />
            </div>
        </div>
        <div v-if="order.payment.payment_method != 'credit-card'">
            <div class="divisor_bottom p-4">
                <div class="row">
                    <h6 class="mb-4">Dados bancários do cliente</h6>
                    <BaseSelect
                        class="col-md-6"
                        label="Banco"
                        :items="order.refund.banks_list"
                        id="bank_code"
                        v-model="order.refund.bank_code"
                        :page="page"
                        :disabled="!order.is_cancelable"
                    />
                    <BaseSelect
                        class="col-md-6"
                        label="Tipo de conta"
                        :items="accountType"
                        id="bank_type"
                        v-model="order.refund.bank_type"
                        :page="page"
                        :disabled="!order.is_cancelable"
                    />
                    <BaseInput
                        class="col-md-4"
                        label="Agência"
                        type="tel"
                        id="bank_agency"
                        v-model="order.refund.bank_agency"
                        :page="page"
                        mask="###############"
                        :disabled="!order.is_cancelable"
                    />
                    <BaseInput
                        class="col-md-4"
                        label="Conta"
                        type="tel"
                        id="bank_number"
                        v-model="order.refund.bank_number"
                        :page="page"
                        mask="###############"
                        :disabled="!order.is_cancelable"
                    />
                    <BaseInput
                        class="col-md-4"
                        label="Dígito da conta"
                        type="tel"
                        id="bank_number_digit"
                        v-model="order.refund.bank_number_digit"
                        :page="page"
                        mask="#"
                        :disabled="!order.is_cancelable"
                    />
                </div>
            </div>
            <div class="divisor_bottom p-4">
                <div class="row">
                        <BaseSelect
                            class="col-md-6"
                            label="Tipo de Chave Pix *"
                            :items="pixTypeList"
                            v-model="order.refund.pix_type"
                            id="pix_type"
                            :page="page"
                            placeholder="Selecione"
                            :disabled="!order.is_cancelable"
                        />
                        <BaseInput
                            class="col-md-6"
                            label="Chave Pix *"
                            :type="pixKeyField"
                            id="pix_key"
                            v-model="order.refund.pix_key"
                            :page="page"
                            :mask="pixKeyType"
                            :disabled="!order.is_cancelable"
                        />
                </div>
            </div>
        </div>
        <div v-if="order.is_cancelable" class="text-end px-4 pb-4">
            <BaseButton @click="refundSumbit" :disabled="formLoading" class="btn-danger mt-3">Solicitar reembolso</BaseButton>
        </div>

        <div v-if="order.refund.id" class="text-end px-4 pb-4">
            <router-link :to="'/refunds/' + order.refund.id">
                <BaseButton class="btn-primary mt-3">Acompanhar reembolso</BaseButton>
            </router-link>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useOrderStore } from '@/stores/order';
import { useRouter } from 'vue-router'; 
const router = useRouter();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const page = ref('orderCancelation');

import { useGlobalStore } from '@/stores/global';
const global = useGlobalStore();

onBeforeMount(() => {
    delete global.errors[page.value];
    if(!global.hasPermissionTo('refund_order')) {
        return router.push('/403');
    }
});

let pixTypeList = {
    'TELEFONE': 'Telefone',
    'EMAIL': 'E-mail',
    'CPF': 'CPF',
    'CNPJ': 'CNPJ',
    'CHAVE_ALEATORIA': 'Chave aleatória',
};

let accountType = {
    'C': 'Corrente',
    'P': 'Poupança'
}

const refund_method = ref('pix');

if(order.value.refund == undefined) {
    order.value.refund = {};
}

if(order.value.refund != undefined && order.value.refund.bank_number != undefined && order.value.refund.bank_number) {
    refund_method.value = 'ted';
}

const pixKeyType = ref('');
const pixKeyField = ref('text');

watch(() => orderStore.order.refund.pix_type, () => {

    if(order.value.refund != undefined && orderStore.order.refund.pix_type != undefined) {
        order.value.refund.pix_key = '';
        document.getElementById('pix_key').removeAttribute("data-mask")
        if(orderStore.order.refund.pix_type == 'CNPJ') {
            pixKeyType.value = '##.###.###/####-##';
            pixKeyField.value = 'tel';
        }else if(orderStore.order.refund.pix_type == 'CPF') {
            pixKeyType.value = '###.###.###-##';
            pixKeyField.value = 'tel';
        }else if(orderStore.order.refund.pix_type == 'TELEFONE') {
            pixKeyType.value = "['(##) ####-####', '(##) #####-####']";
            pixKeyField.value = 'tel';
        }else if(orderStore.order.refund.pix_type == 'EMAIL' || orderStore.order.refund.pix_type == 'CHAVE_ALEATORIA') {
            pixKeyType.value = '';
            pixKeyField.value = 'text';
        }
    }
});

const formLoading = ref(false);

async function refundSumbit() {

    if(formLoading.value === true) {
        return;
    }
    
    formLoading.value = true;
    global.loader('show');

    let params = ['refund_text', 'bank_code', 'bank_type', 'bank_agency', 'bank_number', 'bank_number_digit', 'pix_type', 'pix_key'];

    orderStore.tryRefundRequest(page.value, order.value.id, params).then(() =>{
        formLoading.value = false;
        global.loader('hide');
    });
}
</script>

<style scoped>
.inputs_spacing{
    margin-bottom: 17px!important;
}

.cancel_button{
    border-radius: 25px;
    padding: 8px 15px 9px 14px;
    font-size: 14px;
    border: 0;
    color: #fff;
    background-color: #dc3545;
    display: flex;
    margin: 11px 24px 20px auto;

}

.cancel_button:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.cancel_button:active{
    color: #fff!important;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.cancel_button.focus, .cancel_button:focus {
    box-shadow: 0 0 0 0.2rem rgb(225 83 97 / 50%);
}

:deep(.base-select){
    background-color: white!important
}


</style>