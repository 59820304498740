<template>
    <BaseTable :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="withdrawals.length"  @changePage="changePage">
        <tr v-for="(withdrawal, key) in withdrawalStore.withdrawals" :key="key">
            <td width="10">{{ withdrawal.code }}</td>
            <td>{{ globalStore.formatMoney(withdrawal.value) }}</td>
            <td>{{ withdrawal.payment_provider }}</td>
            <td><BaseBadget :text="withdrawal.status.name" :color="withdrawal.status.color" /></td>
            <td>{{ globalStore.formatDate(withdrawal.created_at) }}</td>
            <td class="actions">
                <a :href="'/withdrawals/' + withdrawal.id" v-if="globalStore.hasPermissionTo('show_withdrawal')" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Detalhes" @click.prevent="viewWithdrawal(withdrawal.id)"><font-awesome-icon icon="fas fa-eye" /></a>
            </td>
        </tr>
    </BaseTable>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';

import { useWithdrawalStore } from '@/stores/withdrawal';
const withdrawalStore = useWithdrawalStore();
const { withdrawals, paginate } = storeToRefs(withdrawalStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const tableColumns = ['ID', 'Valor', 'Tipo de saque', 'Status', 'Data'];

const isLoading = ref(false);

import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('wallet')) {
        return router.push('/403');
    }

    if(withdrawalStore.withdrawals.length == undefined){
        isLoading.value = true;
    }
    
    if(route.query.page) {
        withdrawalStore.filters.page = route.query.page;
    }

    loadWithdrawals().then(() => {
        isLoading.value = false;
    });
});


async function loadWithdrawals() {
    if(withdrawalStore.filters.page) {
        router.replace({query: withdrawalStore.filters});
    }
    await withdrawalStore.tryWithdrawals()
    globalStore.loader('hide');
}

let isChangingPage = false;
async function changePage(p) {
    if(isChangingPage) return;
    withdrawalStore.filters.page = p;
    globalStore.loader('show');
    isChangingPage = true;
    loadWithdrawals().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

function viewWithdrawal(id){
    globalStore.loader('show')
    withdrawalStore.tryWithdrawal(id).then(() =>{
        router.push('/withdrawals/' + id)
    })
}
</script>

<style scoped>

</style>