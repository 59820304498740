<template>
    <router-link v-if="link && !target" :to="to" class="btn px-3" >
        <slot></slot>
    </router-link>

    <a v-else-if="link && target" :href="to" class="btn px-3" target="_blank">
        <slot></slot>
    </a>

    <button v-else :disabled="disabled == 1 || loading" :class="[!!loading ? 'loading' : 'px-3']" class="btn">
        <slot></slot>
        <div v-if="!!loading" class="loader"><strong></strong></div>
    </button>
</template>

<script>
export default {
    props: {
        link: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        to: {
            type: String,
            required: false,
            default: "/",
        },

        loading: {
            type: Boolean,
            required: false,
        },

        target: {
            type: String,
            required: false,
            default: "",
        }
    },
};
</script>

<style scoped>
a {
    color: #fff !important;
    text-decoration: none !important;
}

a:hover {
    text-decoration: none !important;
}

.btn {
    border-radius: 6px !important;
    font-size: 14px;
    padding: 9px;
    border-width: 0px !important;
}

.btn.btn-primary, a.btn-primary {
    background-color: #215eda !important;
}

.btn.btn-primary:hover, a.btn-primary:hover {
    background-color: #2750a3 !important;
}

.btn.btn-white, a.btn-white{
    background-color: #DDDDDD;
    color: #595959;
}

.btn.btn-white:hover, a.btn-white:hover{
    background-color: #ccc;
    color: #595959;
}

.btn.btn-success, a.btn-success {
    background-color: #28a745 !important;
}

.btn.btn-success:hover, a.btn-success:hover {
    background-color: #24983e !important;
}

.btn.btn-white:active, a.btn-white:active,
.btn.btn-white:focus, a.btn-white:focus{
    box-shadow: inset 0 0 0 0px #dddddd, 0 0 0 4px rgb(170 170 170 / 16%), inset 0 0 0 1px #dddddd !important;
    border-color: #DDDDDD;
    background-color: #DDDDDD;
}

.btn.btn-primary:active, a.btn-primary:active,
.btn.btn-primary:focus, a.btn-primary:focus{
    box-shadow: 0 0 0 0.2rem rgb(33 94 218 / 50%);
}

.btn.btn-secondary:active, a.btn-secondary:active,
.btn.btn-secondary:focus, a.btn-secondary:focus{
    box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

.btn.btn-success:active, a.btn-success:active,
.btn.btn-success:focus, a.btn-success:focus{
    box-shadow: 0 0 0 0.2rem rgb(25 105 0 / 50%);
}

.btn.btn-white::after{
    content: none;
}

.btn .loader {
  z-index: 9999;
  display: none;
  width: 100%;
  border-radius: 50px;
  text-align: center;
}

.btn .loader strong {
  display: inline-block;
  z-index: 2000;
  width: 25px;
  height: 25px;
  border: solid 3px transparent;
  border-top-color: #fff;
  border-left-color: #fff;
  border-radius: 100%;
  animation: pace-spinner 500ms linear infinite;
}

.btn.loading {
  padding: 7px 5px 7px 5px;
  font-size: 0 !important;
  width: 45px !important;
  margin: 0 auto;
  transition: width 0.5s ease-in-out;
  opacity: 1;
}

.btn.loading, .btn.loading .loader {
  display: block;
}

.btn.loading::after {
    display: none !important;
}

.btn-lg {
    width: 207px;
}

@keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>