<template>
    <div class="filter_container">
        <div class="collapse_items">

            <div class="collapse_item">
                <div class="collapse_title">
                    <a>
                        <span>Período</span>
                    </a>
                </div>
                <div class="collapse show preset_picker">
                    <BaseDatePicker range multi class="mb-2" v-model="filter.date_range" :enablePreset="true"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, watch } from 'vue';
import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const filter = ref({
    date_range: null,
    date_start: null,
    date_end: null,
});

const props = defineProps({
    initialFilters: {
        required: false,
        default: null
    }
});

let ignoreOnloadEmit = false;

onBeforeMount(() => {
    if(props.initialFilters) {
        ignoreOnloadEmit = true;
        let filters = props.initialFilters;

        for(var i=0; i <= 1 ; i++) {
            let currentForFilter

            if((filters[currentForFilter]) && filters[currentForFilter].length != 0) {
                Object.keys(filter.value[currentForFilter]).forEach((key) => {

                    if((filters[currentForFilter]) && filters[currentForFilter].constructor === Array) {

                        if (!filters[currentForFilter].includes(key)) {
                            filter.value[currentForFilter][key] = false;
                        }
                    }else {
                        if(key != filters[currentForFilter])  {
                            filter.value[currentForFilter][key] = false;
                        }
                    }
                });
            }
        }

        if(filters['date_range']) {
            filter.value['date_range'] = filters['date_range'];
        }
        updateFiltersCount();
    }
});

const emit = defineEmits(["filters", "filtersQty"]);

watch(() => filter, (e) => {

    if(filter.value.date_range){
        filter.value.date_start = filter.value.date_range[0];
        filter.value.date_end = filter.value.date_range[1];
    } else {
        filter.value.date_start = null;
        filter.value.date_end = null;
    }
    debounceFilter(e.value.date_start, e.value.date_end);

},{ deep: true })

 
let debounce = null;
function debounceFilter(date_start, date_end) {
    if(ignoreOnloadEmit == false) {
        globalStore.loader('show');
    }
    updateFiltersCount()
    clearTimeout(debounce)
    debounce = setTimeout(() => {
        if(ignoreOnloadEmit == false) {
            emit("filters", {
                'date_start': date_start,
                'date_end': date_end,
            });
        }
        if(ignoreOnloadEmit == true) {
            ignoreOnloadEmit = false;
        }
    }, 700)
}

function updateFiltersCount() {

    let count = 0;

    Object.keys(filter.value).forEach((key) => {

        if(key == 'date_range') {
            if(filter.value[key] != null) {
                count = count + 1;
            }
    }});

    emit("filtersQty", count);
}

</script>