<template>
    <div v-if="!isLoading" class="container px-2 px-lg-4">
        <BaseHeader :title="title">
        </BaseHeader>
        <div v-if="tests_ab.length" class="row">
            <div class="col-12 mb-4" v-for="(test_ab, key) in tests_ab" :key="key"
                @click="toReport(test_ab.id_store, test_ab.id)"
                @click.middle="toReportBlank(test_ab.id_store, test_ab.id)">
                <div class="box">
                    <div class="p-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="mb-0"> {{ test_ab.title }}</h6>
                                <small class="mb-0">{{ test_ab.duration }} dias de teste</small>
                            </div>
                            <div class="icon">
                                <font-awesome-icon icon="fa-regular fa-arrow-right-long" />                            </div>
                        </div>
                    </div>

                    <div class="divisor_top" id="options">
                        <div class="option p-3" v-for="(option, key) in test_ab.options" :key="key">
                            <div class="row">
                                <div class="col">
                                    <strong class="d-block">Opção {{ key + 1 }}</strong>
                                    <span>{{ option.title }}</span>
                                </div>

                                <div class="col">
                                    <strong class="d-block">Conversão</strong>
                                    <span>{{ option.conversion }}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h5 class="text-center">Nenhum resultado encontrado</h5>
        </div>
    </div>
</template>
<script setup>
const title = { title: 'Testes A/B', icon: 'fa-flask' };
import { onBeforeMount, ref, watch } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { useReportStore } from '@/stores/report';
import { useStoreStore } from '@/stores/store';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const reportStore = useReportStore();
const { tests_ab } = storeToRefs(reportStore)

const storeStore = useStoreStore()

const router = useRouter();
const isLoading = ref(true);

onBeforeMount(() => {
    if (!reportStore.tests_ab.length) {
        globalStore.loader('show')
        reportStore.tryTestsAb().then(() => {
            isLoading.value = false;
            globalStore.loader('hide')
        });
    } else {
        isLoading.value = false;
    }
});

let loadDefaultStore = false;
watch(defaultStore, function () {
    if (!loadDefaultStore) {
        loadDefaultStore = true;
        globalStore.loader('show');
        reportStore.tryTestsAb().then(() => {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

function toReport(id_store, id) {
    globalStore.loader('show');
    storeStore.tryStore(id_store).then(function (success) {
        if (success) {
            globalStore.loader('hide');
            router.push('/stores/' + id_store + '/tests-ab/' + id + '/report');
        } else {
            globalStore.loader('hide');
        }
    });
}

function toReportBlank(id_store, id) {
    window.open('/stores/' + id_store + '/tests-ab/' + id + '/report')
}

</script>

<style scoped>
.box {
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
}

.box:hover {
    background-color: #fafafa;
}

h6 {
    font-size: 16px;
    margin-top: 5px;
    color: #414951;
}

.option {
    border-bottom: 1px #ddd solid;
}

.option:last-child {
    border-bottom: 0;
}

small {
    font-size: 14px;
    color: #959595;
    font-weight: 400;
}

.icon {
    font-size: 20px;
    vertical-align: middle;
    padding-right: 10px;
    color: #215eda;
}
</style>