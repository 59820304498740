<template>
   <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <template v-if="isLoaded != false">
            <header>
                <font-awesome-icon icon="fa-solid fa-location-arrow" style="color: #215eda;"/>
                <span v-if="route.params.slug != 'new'" class="ms-2 align-middle">Editar evento Mex</span>
                <span v-else class="ms-2 align-middle">Novo evento Mex</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseSelect
                            class="col-12 mb-3"
                            id="type"
                            label="Evento"
                            :items="eventsList"
                            :page="route.name"
                            v-model="event_mex.type_slug"
                        />
                        <div v-if="event_mex.type != 'boleto_generated' && event_mex.type != 'all' && event_mex.type != '' && event_mex.type != null" class="code table-responsive" style="border-radius: 0px;">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <code>{cliente:nome}</code
                                                ><span
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                                data-bs-animation="true"
                                                v-tooltip
                                                title="Copiar variável"
                                                data-copy="{cliente:nome}"
                                                @click="copy('{cliente:nome}')"
                                            ><font-awesome-icon icon="fas fa-copy"
                                            /></span>
                                        </td>
                                        <td>Exibe o primeiro nome do cliente.</td>
                                    </tr>
                                    <tr v-if="event_mex.type == 'abandoned_checkout'">
                                        <td>
                                            <code>{checkout:url}</code
                                                ><span
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                                data-bs-animation="true"
                                                v-tooltip
                                                title="Copiar variável"
                                                data-copy="{checkout:url}"
                                                @click="copy('{checkout:url}')"
                                            ><font-awesome-icon icon="fas fa-copy"
                                            /></span>
                                        </td>
                                        <td>URL do checkout</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <BaseInput
                            class="col-12 mb-3 mt-2"
                            id="content.message"
                            label="Mensagem"
                            textarea
                            v-model="event_mex.content.message"
                            :page="route.name"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </template>
   </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { event_mex }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false)
const isLoading = ref(false);

const product_id = computed (function () {
    return route.params.id;
});

let previousPage = router.options.history.state.back;

const eventsList = ref({
    'abandoned_checkout': 'Checkout abandonado',
    'pix_generated': 'Pix gerado',
    'boleto_generated': 'Boleto gerado',
    'ordered': 'Compra Realizada',
    'ordered_card': 'Compra Realizada com cartão',
    'ordered_pix': 'Compra Realizada com pix',
    'ordered_boleto': 'Compra Realizada com boleto',
    'refused_card': 'Cartão recusado',
    'refunded': 'Compra reembolsada',
    'empty_custom_fields': 'Campos personalizados não preenchidos',
    'all': 'Todos os eventos',
})

onBeforeMount(() =>{
    delete globalStore.errors[route.name];
    if(route.params.slug != 'new'){
        if(!productStore.event_mex.id){
            productStore.tryEventMex(route.params.slug).then(() =>{
                isLoaded.value = true
            })
        } else {
            isLoaded.value = true
        }
    }else {
        globalStore.cleanObject(productStore.event_mex);
        productStore.event_mex = {
            type: '',
            content: {},
        }
        isLoaded.value = true
    }
})



function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(route.params.slug != 'new'){
            let params = ['type', 'content'];
            productStore.event_mex.type = productStore.event_mex.type_slug
            productStore.tryUpdateEventMex(productStore.event_mex.id, params, route.name).then(function(success) {
                if(success){
                    productStore.tryEventsMex().then(function(success){
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/events/mex')
                        } else{
                            globalStore.loader('hide');
                        }
                    })
                    isLoading.value = false;
                } else {
                    isLoading.value = false;
                    globalStore.loader('hide');
                }
            });
        } else {
            let params = ['type', 'content'];
            productStore.event_mex.type = productStore.event_mex.type_slug
            productStore.tryCreateEventMex(params, route.name).then(function(success){
                if(success){
                    productStore.tryEventsMex().then(function(success) {
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/events/mex')
                        } else{
                            globalStore.loader('hide')
                        }
                    });
                    isLoading.value = false;
                }else {
                    isLoading.value = false;
                    globalStore.loader('hide');
                }
            })
        }
    }
}

function copy(copy){
    navigator.clipboard.writeText(copy);
}

</script>

<style scoped>

header{
    width: 100%;
    display: block!important;
    margin: 0;
    padding-top: 6px;
    font-size: 16px;
    color: #646464;
    font-weight: 600;
}


.code {
  background: #f7f7f7;
  box-sizing: border-box;
  margin-top: 10px;
}

.code h6 {
  margin: 0;
  padding-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-bottom: 0;
}

.code table {
  width: 100%;
}

.code td {
  border: 1px solid #d7d7d7;
  padding: 10px;
}

.code span {
  cursor: pointer;
  margin-left: 10px;
  padding-right: 5px;
  color: #215eda;
  vertical-align: middle;
}

.code span:hover {
  color: #767676;
}

tbody:last-child tr:last-child td {
  border-bottom: 1px solid #ddd !important;
}


</style>