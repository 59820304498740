<template>
    <div v-if="cart" class="box_content">
        <div class="p-4">
            <div>
                <div class="timeline">
                   <ul v-if="cart.timeline" id="cart_notes">
                        <li v-for="(hist, index) in cart.timeline" :key="index" :class="cart.timeline.length === 1 ? 'single_note': '' + ' timeline_item'">
                            <div v-if="hist.time <= 59" class="time">
                                {{ hist.time }}s
                            </div>
                            <div v-else-if="hist.time <= 3599" :on="showMinutesSeconds(hist.time, index)" class="time" >
                                {{ timerMinutes[index] }}
                            </div>
                            <div v-else :on="showHoursMinutes(hist.time, index)" class="time" >
                                {{ timerHours[index] }}
                            </div>
                            <div class="description">
                                <div class="desc">
                                    <h6 class="title">
                                        {{ hist.page_title }}
                                    </h6>
                                    <p class="url">
                                        {{ hist.url }}
                                    </p>
                                    <p v-if="hist.referrer" class="reference light mt-1">
                                        Referência: {{ hist.referrer }}
                                    </p>
                                </div>
                                <div class="date mt-1">
                                    {{ globalStore.formatDate(hist.created_at)}}
                                </div>
                            </div>
                        </li>
                   </ul>
                   <ul v-else>
                        <h6 class="mb-0 pt-4 pb-4 text-center">Nenhum resultado encontrado</h6>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { useCartStore } from '@/stores/cart';
import { storeToRefs } from 'pinia';

const globalStore = useGlobalStore();   
const cartStore = useCartStore();
const { cart } = storeToRefs(cartStore);

const timerMinutes = ref({});
const timerHours = ref({});

function showMinutesSeconds(value, key){
    let decimalTime = String(value/60);
    let splitedTime = decimalTime.split('.');
    let splitedSeconds = Math.round((parseFloat('0.' + splitedTime[1]).toFixed(2) * 60));
    if(splitedSeconds <= 9){
        splitedSeconds = '0' + splitedSeconds;
    }
    timerMinutes.value[key] = splitedTime[0]+':'+splitedSeconds+'m';
}

function showHoursMinutes(value, key){
    let hourMinuteTime = String(value/60);
    let hour = parseInt(hourMinuteTime/60)
    if(hour <= 9){
        hour = '0'+ hour;
    }

    let hourToSplit = parseFloat(hourMinuteTime/60).toFixed(2)
    let minuteSplit = hourToSplit.split('.');
    let minute = Math.round((parseFloat('0.' + minuteSplit[1]).toFixed(2) * 60));
    if(minute <= 9){
        minute = '0' + minute;
    }
    timerHours.value[key] = hour+':'+minute+'h';
}

</script>

<style scoped>


.timeline ul{
    padding-left: 0px;
    margin-top: 25px;
}

.timeline_item{
    display: flex;
}

.time{
    min-height: 50px;
    max-height: 50px;
    min-width: 50px;
    max-width: 50px;
    font-size: 12px;
    margin-right: 15px;
    line-height: 45px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background-color: #215eda;
    color: white;
    border: 2px solid #ddd;
    z-index: 100;
}

.title{
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.description{
    overflow: hidden;
}

.url {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.desc{
    width: auto;
    border-radius: 5px;
    padding: 14px;
    background-color: #ededed;
}

.reference{
    margin-bottom: 0px;
    color: #7a7a7a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.date{
    font-size: 13px;
    color: #a0a0a0;
}

.light{
    color: #2e2e2e;
    font-weight: 400;
    cursor:text;
    opacity: 0.5;
}

#cart_notes {
  margin-bottom: 0;
  margin-top: 16px;
}

#cart_notes li {
  list-style: none;
  padding-bottom: 15px;
  position: relative;
}

#cart_notes li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

#cart_notes li span {
  display: block;
  font-size: 12px;
  color: #a0a0a0;
}

#cart_notes li:after {
    content: '';
    width: 4px;
    height: 100%;
    background-color: #ededed;
    position: absolute;
    margin-top: 40px;
    top: -16px;
    left: 23px;
    z-index: 99;
}

#cart_notes li:last-child:after {
  margin-top: -100px;
}

#cart_notes .single_note:after{
    display: none!important;
}


</style>