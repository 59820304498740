<template v-if="store != undefined">
    <div class="box_content p-4">
        <h6>Receber notificações de:</h6>
        <div class="notifications_switches mt-3">
            <BaseSwitch
                label="Venda aprovada"
                id="aproved_sell"
                v-model:checked="storeStores.store.meta.notifications.order_paid"
            />
            <BaseSwitch
                label="Boleto emitido"
                id="emited_boleto"
                v-model:checked="storeStores.store.meta.notifications.order_boleto_created    "
            />
            <BaseSwitch
                label="Pix gerado"
                id="emited_pix"
                v-model:checked="storeStores.store.meta.notifications.order_pix_created"
            />
            <BaseSwitch
                label="Estorno"
                id="reversal"
                v-model:checked="storeStores.store.meta.notifications.order_refunded"
            />
            <BaseSwitch
                label="Chargeback"
                id="chargeback"
                v-model:checked="storeStores.store.meta.notifications.order_chargedback"
            />
            <BaseSwitch
                label="Erros"
                id="errors"
                v-model:checked="storeStores.store.meta.notifications.errors"
            />
        </div>
    </div>
</template>

<script setup>
import { useStoreStore } from '@/stores/store';
const storeStores = useStoreStore();

</script>