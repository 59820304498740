<template>
    <div>
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <div style="width: 650px">
                <header>
                    <font-awesome-icon icon="fa-solid fa-comment" style="color: #215eda;"/>
                    <span class="ms-2 align-middle">Responder comentário</span>
                </header>

                <div class="content">
                    <form @submit.prevent="">
                        <div class="p-4" id="comment">
                            <strong>{{ course_comment.name }}</strong>
                            <span class="date">{{ globalStore.formatDate(course_comment.created_at) }}</span>
                            <p class="mt-2">{{ course_comment.message }}</p>
                        </div>
                        <div v-for="(reply, key) in course_comment.replies" :key="key" class="p-4 mt-3" id="reply">
                            <button @click="openPopup(reply.id)" type="button" class="icon_buttons"  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover">
                                    <font-awesome-icon icon="fa-solid fa-trash-can" />
                            </button>
                            <strong>{{ reply.name }}</strong>
                            <span class="date">{{ globalStore.formatDate(reply.created_at) }}</span>
                            <p class="mt-2">{{ reply.message }}</p>
                        </div>
                        <div class="row mt-4">
                            <BaseInput
                                    class="col-12 mb-3"
                                label="Resposta"
                                type="text"
                                id="reply"
                                v-model="course_comment.reply"
                                :page="route.name"
                                textarea
                            />
                        </div>
                        <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                            Salvar
                        </BaseButton>
                    </form>
                    </div>
                <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
                    <div class="confirm_action">
                        <div class="popup ">
                            <p class="title">Atenção</p>
                            <p class="text">Deseja remover esse produto?</p>
                            <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                            <BaseInput
                                label=''
                                type="text"
                                id="excludeTextConfirm"
                                v-model="excludeTextConfirm"
                                :page="route.name"
                                class="col-12"
                            />
                            <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                            <baseButton @click="deleteComment" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                        </div>
                    </div> 
                </BasePopup>
            </div>
        </BasePopup>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, ref, shallowRef  } from 'vue'
import { useRoute } from "vue-router"; 
import { useGlobalStore } from '@/stores/global';
import { useProductStore } from '@/stores/products';
import { storeToRefs } from 'pinia';

const route = useRoute();

const productStore = useProductStore();

const globalStore = useGlobalStore();
const { course_comment } = storeToRefs(productStore);

const product_id = computed (function () {
    return route.params.id;
});

let isDeleting = false;
let commentToDelete = null;
const isLoaded = ref(false);
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null);
const isLoading = ref(false);

const emit = defineEmits(['emitComentToDelete'])

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(productStore.course_comment.id){
        globalStore.loader('hide')
        isLoaded.value = true;
    } else {
        productStore.tryCourseComment(product_id.value, route.params.slug).then(() =>{
            globalStore.loader('hide')
            isLoaded.value = true;
        })
    }
})

async function deleteComment() {
    if(!isDeleting && !isLoading.value && commentToDelete) {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await productStore.tryDeleteCourseComment(commentToDelete).then(() =>{
            useGlobalStore().successMessage('Comentário removido com sucesso');
            productStore.tryCourseComment(product_id.value, route.params.slug).then(() =>{
                globalStore.loader('hide');
                isDeleting = false;
                confirmAction.value = null;
                isLoading.value = false;
            });
            excludeTextConfirm.value = null;
        })
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')

        const params = [
            'reply',
            'id_parent',
        ];
        
        productStore.tryReplyCourseComment(params, route.name).then(function(success) {
            if(success){
                useGlobalStore().successMessage('Resposta salva com sucesso');
            }
            globalStore.loader('hide');
        })
    }
}

function openPopup(comment_id) {
    commentToDelete = comment_id;
    let answerComentToDelete = commentToDelete
    confirmAction.value = true;
    excludeTextConfirm.value = null;
    emit('emitComentToDelete', answerComentToDelete)
}

function clickToClose() {
    confirmAction.value = null
}

</script>
<style scoped>


#comment {
    background-color: #efefef;
    border-radius: 4px;
}

#reply {
    border-radius: 4px;
    border: 1px #d7d7d7 solid;
}

#reply button {
    float: right;
    margin-top: -10px;
    margin-right: -10px;
}

.date {
    display: block;
    font-size: 13px;
    color: #808080;
}

p {
    margin-bottom: 0;
}
</style>