<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <template v-if="isLoaded != false">
            <header>
                <font-awesome-icon icon="fa-solid fa-location-arrow" style="color: #215eda;"/>
                <span v-if="route.params.slug != 'new'" class="ms-2 align-middle">Editar evento Mautic</span>
                <span v-else class="ms-2 align-middle">Novo evento Mautic</span>
                <!--<span v-else class="ms-2 align-middle">Novo evento</span>-->
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseSelect
                            class="col-12 mb-3"
                            id="type_slug"
                            label="Evento"
                            :items="eventsList"
                            :page="route.name"
                            v-model="event_mautic.type_slug"
                        />
                        <BaseInput
                            class="col-12 mb-3"
                            id="content.code"
                            label="ID do segmento"
                            :page="route.name"
                            v-model="event_mautic.content.code"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </template>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { event_mautic }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false)
const isLoading = ref(false);

const product_id = computed (function () {
    return route.params.id;
});

let previousPage = router.options.history.state.back;

const eventsList = ref({
    'abandoned_checkout': 'Checkout abandonado',
    'pix_generated': 'Pix gerado',
    'boleto_generated': 'Boleto gerado',
    'ordered': 'Compra Realizada',
    'ordered_card': 'Compra Realizada com cartão',
    'ordered_pix': 'Compra Realizada com pix',
    'ordered_boleto': 'Compra Realizada com boleto',
    'refused_card': 'Cartão recusado',
    'refunded': 'Compra reembolsada',
    'empty_custom_fields': 'Campos personalizados não preenchidos',
    'all': 'Todos os eventos',
})

onBeforeMount(() =>{
    delete globalStore.errors[route.name];
    if(route.params.slug != 'new'){
        if(!productStore.event_mautic.id){
            productStore.tryEventMautic(route.params.slug).then(() =>{
                isLoaded.value = true
            })
        } else {
            isLoaded.value = true
        }
    }else {
        globalStore.cleanObject(productStore.event_mautic);
        productStore.event_mautic = {
            type_slug: '',
            content: {},
        }
        isLoaded.value = true
    }
})

function clickToClose(){
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(route.params.slug != 'new'){
            let params = ['type', 'content'];
            productStore.event_mautic.type = productStore.event_mautic.type_slug
            productStore.tryUpdateEventMautic(productStore.event_mautic.id, params, route.name).then(function(success) {
                if(success){
                    productStore.tryEventsMautic().then(function(success){
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/events/mautic')
                        } else{
                            globalStore.loader('hide');
                        }
                    })
                    isLoading.value = false;
                } else {
                    isLoading.value = false;
                    globalStore.loader('hide');

            }})
        } else {
            let params = ['type', 'content'];
            productStore.event_mautic.type = productStore.event_mautic.type_slug
            productStore.tryCreateEventMautic(params, route.name).then(function(success){
                if(success){
                    productStore.tryEventsMautic().then(function(success) {
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/events/mautic')
                        } else{
                            globalStore.loader('hide')
                        }
                    });
                    isLoading.value = false;
                }else {
                    isLoading.value = false;
                    globalStore.loader('hide');
                }
            })
        }
    }
}

</script>