<template>
    <div class="container">
        <div v-if="!isLoading" class="box_content mt-4">
            <div class="p-4">
                <h5>{{  message.title }}</h5>
                <p id="date">{{  message.created_at }}</p>

                <p v-html="message.content">
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
#date {
    color: #7f7f7f;
}
</style>

<script setup>
import { useAccountStore } from '@/stores/account'
import { useGlobalStore } from '@/stores/global';
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
const globalStore = useGlobalStore();
const accountStore = useAccountStore();

const isLoading = ref(false);
const route = useRoute();

const message_id = computed (function () {
    return route.params.id;
});

const message = ref(false);


onBeforeMount(() =>{
    isLoading.value = true;
    globalStore.loader('show');
    
    accountStore.tryAccountMessage(message_id.value).then((result) => {
        message.value = result;
        isLoading.value = false;
        globalStore.loader('hide');
    });

    if(isLoading.value) {
        return;
    }
});</script>