<template>
    <div id="footer_nav">

        <div class="btn-group dropup" id="stores_select">
            <baseButton v-if="globalStore.defaultStore.length == 0" type="button" class="btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Todas as lojas</baseButton>
            <baseButton :loading="loadingButton" v-else :style="{ backgroundColor: globalStore.defaultStore.color + '!important' }" type="button" class="btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><span id="btn_text">{{ globalStore.defaultStore.name }} </span></baseButton>

            <ul id="stores_select_dropdown" class="dropdown-menu">
                <li class="dropdown-item" @click="setDefaultStore(0)">Todas as lojas</li>

                <div class="dropdown-divider"></div>

                <li v-for="(store) in globalStore.storesList" :key="store.id" @click="setDefaultStore(store.id)" class="dropdown-item">{{ store.title }}</li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const loadingButton = ref(false);
async function setDefaultStore(id) {
    loadingButton.value = true;
    document.getElementById('stores_select_dropdown').classList.remove('show');
    await globalStore.setDefaultStore(id);
    loadingButton.value = false;
}
</script>

<style scoped>
#footer_nav {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: white;
    border-top: 1px #ddd solid !important;
    height: 80px;
    transition: 0.5s;
}

#stores_select {
    width: 100%;
    height: 60px;
    padding: 20px;
    padding-bottom: 0px;
    transition: 0.5s;
}

#stores_select li{
    overflow-x: hidden !important;
    text-overflow: ellipsis;
}

#app.menu-collapsed #stores_select {
    margin-left: -8px;
}

.btn-primary {
    background: #215eda;
    color: #fff !important;
    border-radius: 25px;
    padding: 8px 15px 9px 14px;
    font-family: Poppins;
    border: 0;
}

.btn-primary::after {
    float: right;
    position: relative;
    top: 3px;
    right: 5px;
}

.user_info {
    padding: 16px 20px 14px 20px;
    position: relative;
}
#app.menu-collapsed #stores_select button {
    font-size: 0!important;
    padding: 9px 12px !important;
}

#app #stores_select .dropdown-toggle::after{
    position: absolute;
    top: 13px;
    right: 16px;
}

#app.menu-collapsed #stores_select .dropdown-toggle::after {
    top: 1px;
    position: relative!important;
    right: 4px;
}

#btn_text{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    margin: 0 auto;
}

.toggle_sensitive_information {
    position: absolute;
    right: 20px;
    color: #215eda;
    top: 12px;
    cursor: pointer;
    user-select: none;
}

.toggle_sensitive_information svg {
    margin-right: 5px;
    margin-top: 7px;
}

#stores_select_dropdown {
    width: 214px;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-color: #cccccc #ffffff!important;
    scrollbar-width: thin !important;
}

@media screen and (min-width: 993px) {
    #footer_nav {
        display: block !important;
    }
}

@media screen and (max-width: 992px) {
    #footer_nav {
        display: none;
        width: 100%;
    }

    #btn_text{
        max-width: 650px!important;
    }
}

@media screen and (max-width: 768px){
    #btn_text{
        max-width: 475px!important;
    }
}

@media screen and (max-width: 576px){
    #btn_text{
        max-width: 200px!important;
    }
}

</style>