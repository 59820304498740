import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useWithdrawalStore = defineStore('withdrawal', {

    state: () => ({
        withdrawals: {},
        anticipations: {},
        receivables: {},
        paginate: {},
        withdrawal: {},
        request: {},
        exceeded_withdrawal: null,
        filters: {},
        receivables_filters: {},
        simulation_result: {},
    }),

    actions: {

        async tryWithdrawals() {
            try {
                let params = this.filters;
                const response = await Api.get('wallet', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.withdrawals = response.data.data;
                this.paginate = response.data.meta;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        async tryAnticipations() {
            try {
                let params = this.filters;
                const response = await Api.get('anticipations', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.anticipations = response.data.data;
                this.paginate = response.data.meta;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReceivables() {
            try {
                let params = this.receivables_filters;
                const response = await Api.get('wallet/receivables', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.receivables = response.data.data;
                this.paginate = response.data.meta;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryWithdrawal(id){
            try{
                const response = await Api.get('wallet/withdrawal/'+ id);
                this.withdrawal = response.data.data;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryWalletWithdrawal(){
            try{
                const response = await Api.get('/wallet/withdrawal/');
                this.request = response.data.data
                this.exceeded_withdrawal = response.data.exceeded_withdrawal;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryRequestWithdrawal(gateway, amount, page){
            try{
                const response = await Api.post('/wallet/withdrawal', {gateway: gateway, amount: amount});
                useGlobalStore().successMessage('Saque solicitado com sucesso');
                this.router.push('/withdrawals/' + response.data.data.withdrawal);
            } catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async trySimulateAnticipation(amount, page){
            try{
                const response = await Api.post('/wallet/anticipation/simulate', { amount: amount });
                this.simulation_result = response.data;
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryRequestAnticipation(page){
            try{
                const response = await Api.post('/wallet/anticipation', { amount: this.simulation_result.anticipation_amount , fees: this.simulation_result.anticipation_fee });
                useGlobalStore().successMessage('Solicitação de antecipação criada com sucesso');
                return response.data.data;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        }
    }
});