<template>
    <form @submit.prevent="forgotPassword">
        <BaseInput
            label="E-mail"
            type="email"
            id="email"
            v-model="inputs.email"
            :page="page"
            login
            required
        />

        <BaseButton
            class="mt-4 btn-primary w-100"
            :loading="isLoading"
            :disabled="isLoading"
            >Recuperar
        </BaseButton>

        <p class="text-center mt-5 spacer"><span>ou</span></p>
        <BaseButton class="mt-2 btn-secondary w-100" to="/login" link>Voltar</BaseButton>
    </form>
</template>

<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
const auth = useAuthStore();

const page = ref('forgotPassword');

const inputs = ref({
    email: '',
});

const isLoading = ref(false);

async function forgotPassword() {
    isLoading.value = false;    

    // eslint-disable-next-line
    grecaptcha.ready(() => {
        isLoading.value = true;
        // eslint-disable-next-line
        grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {  
            inputs.value.recaptcha_token = token;  
            auth.tryRequestResetPassword(page.value, inputs.value).then(() =>{
                isLoading.value = false;
            });
        });
    });
}
</script>

<style scoped>
form {
    position: relative;
}

.loader_body {
    position: absolute;
    margin-left: -20px;
}

.spacer {
    border-top: 1px #ccc solid;
}

.spacer span {
    background-color: #f7f7f7;
    top: -12px;
    display: inline-block;
    position: relative;
    padding: 0px 20px;
}
</style>