<template>
    <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="products_referral.length" @changePage="changePage">
        <tr v-for="(product, key) in products_referral" :key="key">
            <td width="10">{{ product.id }}</td>
            <td>
                <div class="d-flex align-items-center">
                    <div class="product_image" :style="'background: url(' + product.image + ');'"></div>
                    {{product.title }}
                    <BaseBadget v-if="product.tag" small class="ms-1" color="#424242" :text="product.tag" />
                </div>
            </td>
            <td class="sensitive_content">{{ globalStore.formatMoney(product.price) }}</td>
            <td><div class="sensitive_information">{{product.status_name }}</div></td>
            <td><BaseStoreDot :color="product.store.color" :title="product.store.title"/></td>
            <td class="actions">
                <span @click="generateCampaign(product.id, product.store.id_store)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Gerar campanha"><font-awesome-icon icon="fas fa-bullhorn"/></span>
            </td>
        </tr>
    </BaseTable>
</template>
<script setup>
import { ref, onBeforeMount, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';
import { useGlobalStore } from '@/stores/global';
import { useCampaignStore } from '@/stores/campaign';

const productStore = useProductStore();
const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);
const campaignStore = useCampaignStore();
const { products_referral, paginate } = storeToRefs(productStore);
    
const tableColumns = ['ID', 'Nome', 'Valor', 'Status', 'Loja'];

const route = useRoute();
const router = useRouter();
    
const isLoading = ref(false);
const emit = defineEmits(["removeLoading"]);
    

onBeforeMount(() => {
    
    if(productStore.products_referral.length == undefined || router.options.history.state.replaced === true){
        isLoading.value = true;
    }

    productStore.filters_products.type = "referral";

    if(route.query.page || route.query.type || route.query.status || route.query.tags) {
        productStore.filters_products.page = route.query.page;
        Object.keys(productStore.filters_products).forEach((key) => {
            let param = route.query[key];
            productStore.filters_products[key] = (param == undefined) ? null : param;

            if(key == 'tags') {
                if((productStore.filters_products[key]) && productStore.filters_products[key].constructor !== Array) {
                    let currentThing = productStore.filters_products[key];
                    productStore.filters_products[key] = [];
                    productStore.filters_products[key].push(currentThing);
                }
            } else if(key == 'status'){
                if((productStore.filters_products[key]) && productStore.filters_products[key].constructor !== Array) {
                    let currentThing = productStore.filters_products[key];
                    productStore.filters_products[key] = '';
                    productStore.filters_products[key] = currentThing;
                }
            }
        });
    }

    loadProducts();
});
    
async function loadProducts() {
    let usedParams = {};
    
    if(productStore.filters_products.page || productStore.filters_products.type  || productStore.filters_products.status || productStore.filters_products.tags) {
        router.replace({query: productStore.filters_products});
    }

    Object.keys(productStore.filters_products).forEach((key) => {
        if(productStore.filters_products[key] != null && productStore.filters_products[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: productStore.filters_products[key]
            };
            usedParams = currentParams;
        }
    });

    router.replace({query: usedParams});
    await productStore.tryProducts(productStore.filters_products).then(function (success){
        if(success){
            isLoading.value = false;
            emit('removeLoading')
            globalStore.loader('hide');
        }
    })
}
    
let isChangingPage = false;
async function changePage(p) {
    if(isChangingPage) return;
    productStore.filters_products.page = p;

    Object.keys(productStore.filters_products).forEach((key) => {
        if(key == 'tags'){
            productStore.filters_products[key] = route.query['tags']
        } else if(key == 'status'){
            productStore.filters_products[key] = route.query['status']
        }
    });

    globalStore.loader('show');
    isChangingPage = true;
    loadProducts().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}
    
let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
            loadDefaultStore = true;
        productStore.filters_products.page = null;
        loadProducts().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});
    
function generateCampaign(product_id, store_id){
    globalStore.loader('show');
    campaignStore.campaign.id_destination = product_id;
    campaignStore.campaign.type_destination = 'product'
    setTimeout(() =>{
        globalStore.loader('hide');
        router.push('/campaigns/create/store=' + store_id);
        return;
    },50)
}

</script>
    
<style scoped>
    
</style>