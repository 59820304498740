import { defineStore } from "pinia";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';
import { useAccountStore } from "./account";

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useAuthStore = defineStore('auth', {

    state: () => ({
        login: {},
        name: '',
        email: '',
        modules: [],
        isPrimary: null,
        isLogged: false,
        inInitialLoading: true,
        register: {},
    }),

    actions: {
        async tryLogout() {
            if(sessionStorage.getItem('redirectAfterLogin') === null) {
                let href = window.location.href.replace(window.location.protocol + '//' + window.location.host, '');
                sessionStorage.setItem('redirectAfterLogin', href);
            }
            Api.get('logout');
            this.$reset();
            this.inInitialLoading = false;
            this.router.push('/login');
        },

        async tryCheckLogin() {
            if(this.isLogged == true) {
                return true;
            }
            try {
                const response = await Api.get('user');
                this.email = response.data.data.email;
                this.name = response.data.data.name;
                this.modules = response.data.data.modules;
                this.isPrimary = response.data.data.is_primary;
                this.isLogged = true;
                this.inInitialLoading = false;
                await useAccountStore().tryAccount();

                if(useAccountStore().account.status.slug == 'new'){
                    this.router.push('/account/completion');
                }

                await useGlobalStore().getDefaultStore();
                return true;
            } catch (_) {
                return false;
            }
        },

        async tryLogin(page) {
            sessionStorage.removeItem('sessionMessage');
            await Api.get("sanctum/csrf-cookie");
            try {
                let response = await Api.post('login', this.login);

                response = response.data;

                if(response.data && response.data.login_with_code == true){
                    this.login.password = null;
                    return 'code_required';
                }

                await this.tryCheckLogin();

                useGlobalStore().cleanObject(this.login);

                if(useAccountStore().account.status.slug == 'new'){
                    this.router.push('/account/completion');
                }else {

                    const redirect = sessionStorage.getItem('redirectAfterLogin');
                    if(redirect) {
                        this.router.push(redirect);
                        sessionStorage.removeItem('redirectAfterLogin');
                    }else {
                        this.router.push('/');
                    }
                }

                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryRequestResetPassword(page, params) {
            await Api.get("sanctum/csrf-cookie");
            try {
                const response = await Api.post("login/request-reset-password", {
                    email: params.email,
                    recaptcha_token: params.recaptcha_token
                });

                const success = response.data.data.success;
                if(success) {
                    useGlobalStore().successMessage(success);
                }
                this.router.push('/login');
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return true;
            }
        },

        async tryCheckResetPasswordCode(code) {
            await Api.get("sanctum/csrf-cookie");
            try {
                await Api.post("login/check-reset-password-code", {
                    code: code
                });
                return true;
            } catch (_) {
                useGlobalStore().errorMessage('Código inválido ou expirado, tente novamente');
                this.router.push('/forgot-password');
            }
        },

        async tryResetPassword(page, params, token) {
            await Api.get("sanctum/csrf-cookie");
            try {
                const response = await Api.post("login/reset-password", {
                    email: params.email,
                    password: params.password,
                    password_confirmation: params.password_confirmation,
                    token: token,
                    recaptcha_token: params.recaptcha_token
                });
                const success = response.data.data.success;
                if(success) {
                    useGlobalStore().successMessage(success);
                }
                this.router.push('/login');
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryRegister(page, params) {
            let formData = useGlobalStore().getOnlyParams(params, this.register);

            await Api.get("sanctum/csrf-cookie");
            try {
                const response = await Api.post("register", formData);
                const status = response.data.data.status;

                if(status == 'success' || status == 'pre_registered') {
                    return true;
                }else {
                    return false;
                }
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async trySaveRecommendationCode(code) {
            await Api.get("sanctum/csrf-cookie");
            try {
                await Api.get("register/" + code);
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        }
    }

});
