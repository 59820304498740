<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
            <div class="item text-center" id="device_selector">
                <baseButton :class="'btn me-2 ' + [(device == 'desktop') ? 'btn-secondary' : 'btn-white']" @click="changeDevice('desktop')"><font-awesome-icon icon="fa-regular fa-desktop" class="me-1" /> Desktop</baseButton>
                <baseButton :class="'btn me-2 ' + [(device == 'mobile') ? 'btn-secondary' : 'btn-white']" @click="changeDevice('mobile')"><font-awesome-icon icon="fa-regular fa-mobile" class="me-1" /> Mobile</baseButton>
            </div>
            <div class="item btn-group action me-5">
                <baseButton @click="changeVersionTo('v3')" :disabled="isLoading" :class="[checkout.version == 'v3' ? 'btn-secondary active_version ' : 'btn-white ' ] + 'versions_btns v1'"><div class="column_box" style="border-radius: 2px;"></div></baseButton>
                <baseButton @click="changeVersionTo('v1')" :disabled="isLoading" :class="[checkout.version == 'v1' ? 'btn-secondary active_version ' : 'btn-white ' ]  + 'versions_btns v3'"><div class="column_box" style="border-radius: 2px 0px 0px 2px; border-width: 2px 1px 2px 2px;"></div><div class="column_box" style="border-radius: 0px 2px 2px 0px;  border-width: 2px 2px 2px 1px;"></div></baseButton>
                <baseButton @click="changeVersionTo('v2')" :disabled="isLoading" :class="[checkout.version == 'v2' ? 'btn-secondary active_version ' : 'btn-white ' ]  + 'versions_btns v2'"><div class="column_box" style="border-radius: 2px 0px 0px 2px; border-width: 2px 1px 2px 2px;"></div><div class="column_box" style="border-width: 2px 1px 2px 1px;"></div><div class="column_box" style="border-radius: 0px 2px 2px 0px; border-width: 2px 2px 2px 1px;"></div></baseButton>
            </div>
            <div class="item btn-group action ms-5">
                <baseButton @click="submit" :disabled="isLoading" class="btn btn-primary">Salvar</baseButton>
            </div>
        </BaseHeader>
        <div :class="'page_content ' + device">
            <CheckoutV1 ref="checkoutRef" :device="device" v-if="checkout.version == 'v1'" @emitPreviewFile="savePreviewFile" @emitMinimizeMenu="minimizeMenu" :previewObj="previewObj" :menuTitles="menu_titles"/>
            <CheckoutV2 ref="checkoutRef" :device="device" v-if="checkout.version == 'v2'" @emitPreviewFile="savePreviewFile" @emitMinimizeMenu="minimizeMenu" :previewObj="previewObj" :menuTitles="menu_titles"/>
            <CheckoutV3 ref="checkoutRef" :device="device" v-if="checkout.version == 'v3'" @emitPreviewFile="savePreviewFile" @emitMinimizeMenu="minimizeMenu" :previewObj="previewObj" :menuTitles="menu_titles"/>
        </div>
    </div>
</template>


<script setup>
const title = {title: 'Editar checkout', icon: 'fa-table-layout'};
import { useGlobalStore } from "@/stores/global";
import { computed, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router"; 
import CheckoutV1 from "./layouts/CheckoutV1.vue";
import CheckoutV2 from "./layouts/CheckoutV2.vue";
import CheckoutV3 from '@/views/stores/edit/checkouts/layouts/CheckoutV3.vue';
import { useStoreStore } from "@/stores/store";
import { storeToRefs } from "pinia";

const route = useRoute();
const router = useRouter();
const globalStore = useGlobalStore();
const storeStore = useStoreStore();

const store_id = computed(function () {
    return route.params.id;
});

const { checkout } = storeToRefs(storeStore);

const previousPage = ref(null);
const isLoading = ref(false);
const device = ref('desktop');

const checkoutRef = ref(null);

const previewObj = {}

function changeDevice(type){
    device.value = type;
}

const menu_titles = {
    cover_desktop: 'Banner superior',
    cover_mobile: 'Banner superior',
    banner_content: 'Banner formulário',
    banner_right: 'Banner lateral',
    checkout: 'Geral',
    logotipo: 'Logotipo',
    enable_header_contact_info: 'Contato',
    step_1_title: 'Título',
    step_1_subtitle: 'Subtítulo',
    step_2_title: 'Título',
    step_2_subtitle: 'Subtítulo',
    step_3_title: 'Título',
    step_3_subtitle: 'Subtítulo',
    proceed_button: 'Botão de continuar',
    header_secure: 'Ícone segurança',
    timer_numbers: 'Tempo',
    timer_text: 'Texto',
    timer_button: 'Botão de compre agora',
    coupon_code: 'Botão',
    coupon_code_hover: 'Botão ao passar o mouse',
    order_bump_title: 'Título',
    order_bump_label: 'Rótulo',
    order_bump: 'Descrição',
    order_button: 'Botão de compra',
    credit_card_method: 'Cartão de crédito',
    pix_method: 'Pix',
    boleto_method: 'Boleto',
    exit_popup: 'Exit-intent',
    exit_popup_button: 'Botão',
    exit_popup_discount: 'Cor do texto de desconto',
    postcode: 'Placeholder CEP',
    timer: 'Timer',
    header: 'Header',
    form: 'Formulário',
    step_1: 'Identificação',
    step_2: 'Entrega',
    payment: 'Pagamento',
    payment_step_3: 'Pagamento',
    payment_methods: 'Formas de pagamento',
    coupon: 'Cupom',
    order_bump_sub: 'Order bump',
    exit_intent_popup: 'Exit-intent popup',
    back_redirect: 'Back Redirect',
}

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('edit_store')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;

    globalStore.loader('show');

    if(!storeStore.store.id) {
        await storeStore.tryStore(store_id.value);
    }

    storeStore.tryCheckout(route.params.slug)
    globalStore.loader('hide')
});

function submit(){
    if(isLoading.value) {   
        return;
    }

    globalStore.loader('show');
    isLoading.value = true;

    let params = ['customization', 'version'];
    storeStore.tryUpdateCheckoutCustumization(params, route.name).then(function(success) {
        if(success){
            globalStore.successMessage('Checkout atualizado com sucesso');
        }

        globalStore.loader('hide');
        isLoading.value = false;

        storeStore.tryCheckouts();
    })

}

function changeVersionTo(version_selected){
    storeStore.checkout.version = version_selected;
}

function savePreviewFile(value){
    previewObj[Object.keys(value)[0]] = value[Object.keys(value)[0]];
}

function minimizeMenu(){
    let menuHeader = document.getElementById('menuCustomHeader');
    let menuList = document.getElementById('menuCustomBox');
    if(menuList.classList.contains('minimized') || menuHeader.classList.contains('minimized')){
        menuHeader.classList.remove('minimized');
        menuHeader.style.marginLeft = 0;
        menuList.classList.remove('minimized');
    } else{
        menuHeader.style.marginLeft = 255 + 'px';
        menuHeader.classList.add('minimized');
        menuList.classList.add('minimized'); 
    }
}

</script>

<style scoped>

.versions_btns {
    height: 39px;
    margin: 0 5px;
    display: flex;
    align-content: center;
}

.versions_btns:nth-child(1) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-right: 0;
}

.versions_btns:nth-child(2) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-right: 0;
}

.versions_btns:nth-child(3) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.active_version .column_box {
    border-color: #fff;
}

.column_box {
    border: 2px solid #595959;
    height: 15px;
    width: 8px;
    margin: auto;
}

.v1 .column_box {
    width:15px!important;
}

:deep(.credit_card_display){
    order: v-bind(checkout.customization.credit_card_ordenation);
}

:deep(.pix_display){
    order: v-bind(checkout.customization.pix_ordenation)
}

:deep(.boleto_display){
    order: v-bind(checkout.customization.boleto_ordenation);
}

:deep(.border_right){
    border-right: 1px solid #dedede!important;
}

:deep(.menu_custom_header){
    margin-bottom: 0px!important;
    border-radius:8px 8px 0px 0px;
    border-bottom: 0px;
    padding-bottom: 5px;    
}

:deep(.menu_custom_header h6){
    display: inline;
    vertical-align: top!important;
}

:deep(.menu_custom_header .minimize_icon){
    display: inline;
    float: right;
    color: #215EDA;
    cursor: pointer;
}

:deep(.menu_custom_header .back_arrow){
    margin-top: -5px;
    margin-left: -6.3px;
    margin-bottom: -6px;
}


:deep(.menu_custom){
    height: fit-content;
    padding: 0px 0px 0px 0px;
    width: 300px;
    z-index: 999;
    border-radius: 0px 0px 8px 8px;
    border-top: 0px;
    margin-top: -5px;
}

:deep(.main_nav){
    padding-left: 0px;
    padding-right: 0px;
}

:deep(.main_nav li){
    padding: 15px 20px 15px 18px;
    border-bottom: 1px #ddd solid;
    margin-bottom: 0px;
    border-radius: 0px !important;
    font-weight: 600;
    cursor: pointer;
    color: #767676 !important;
    border-left: 2px solid transparent;
    width: 100%;
}

:deep(.open_exit_intent){
    width: 65px;
}

:deep(.menu_list){
    margin-bottom: -16px;
    margin-top: -8px;
}

:deep(.menu_list ul li:last-child){
    border-bottom: none;
}

:deep(.minimized){
    width: 45px;
    height: 40px;
    overflow: hidden;
    display: none;
}

:deep(.main_nav li:has(.customization_config)){
    padding: 0px!important;
    border-bottom: 0px!important;
    border-top: 0px!important;
}

:deep(.main_nav li){
    border-top: 1px solid #ddd;
    border-bottom: 0px;
}

:deep(.minimized:has(.minimize_icon)){
    display: block;
    border-bottom: 1px solid #ccc;
    height: 52px;
    border-radius: 6px;
}
:deep(.minimized .menu_list), :deep(.minimized  h6),
:deep(.minimized .back_arrow), :deep(.minimized .customization_icon),
:deep(.minimized .customization_config), :deep(.minimized > .menu_custom){
    display: none!important;
}

:deep(.minimized .minimize_icon){
    width: 44px;
    height: 49px;
    display: block;
    margin-top: -16px;
    margin-left: -17px;
    float: none;
}

:deep(.minimized .minimize_icon svg){
    margin: 19px 16px 17px 16px;;
}

:deep(.pix_warn h6){
    font-weight: 600;
}

:deep(.pix_warn ul li){
    list-style: disc;
}

</style>