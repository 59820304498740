<template>
    <template v-if="order.chargeback.text">
        <div class="box_content">
            <div class="p-4">
                <h5>Status</h5>
                <span>{{ order.chargeback.status.title }}</span>
            </div>
            <div class="divisor_top">
                <div class="p-4">
                    <h5>Informações:</h5>
                    <div v-html="order.chargeback.text"></div>
                </div>
            </div>
        </div>

        <div v-for="(reply, key) in order.chargeback.replies" :key="key" :class="'box_content ' + [reply.type == 'admin' ? 'admin_reply' : '']">
            <div class="row">
                <div class="col-md-6">
                    <div class="p-4">
                        <h5>Respondido por:</h5>
                        <span>{{ reply.name }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="p-4">
                        <h5>Data:</h5>
                        <span>{{ global.formatDate(reply.created_at) }}</span>
                    </div>
                </div>
            </div>
            <div class="divisor_top">
                <div class="p-4">
                    <template v-if="reply.text">
                        <h5>Detalhes:</h5>
                        <div v-html="reply.text"></div>
                    </template>
                    <i class="reply_empty" v-else>Nenhum detalhe adicionado.</i>
                </div>
            </div>
            <div v-if="reply.files.length" class="divisor_top">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Arquivos</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(file, k) in reply.files" :key="k">
                                <td><a :href="file.url " target="_blank">{{ file.name }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <template v-if="order.chargeback.remaining_time_to_reply != false">
            <p class="alert mb-4">Prazo para envio de documentos e provas relacionados a esta transação se encerra: {{ global.formatDate(order.chargeback.remaining_time_to_reply) }}.</p>

            <div class="box_content p-4">
                <form @submit.prevent="submit">
                    <div class="row">
                        <BaseInput
                            class="col-12"
                            id="text"
                            name="text"
                            label="Mensagem"
                            textarea
                            :page="route.name"
                            v-model="order.chargeback.reply.text"
                        />
                        <BaseFileUpload
                            class="col-12"
                            label="Arquivos"
                            accept=".jpg, .jpeg, .png, .gif, .pdf, .xls, .txt, .xlt, .xml, .xlsx, .doc, .docx, .mp4, .mp3, .zip, .rar"
                            id="files"
                            name="files"
                            multiple
                            :page="route.name"
                            v-model="order.chargeback.reply.files"
                        />
                    </div>
                    <div class="text-end">
                        <BaseButton class="btn-primary btn-lg">Enviar</BaseButton>
                    </div>
                </form>
            </div>
        </template>

        <p v-else-if="order.chargeback.status.slug != 'finished'" class="alert mb-0">Prazo para envio da documentação se encerrou.</p>
    </template>
</template>

<script setup> 
import { onMounted, ref } from "vue";
import { storeToRefs } from 'pinia';
import { useOrderStore } from '@/stores/order';
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);
import { useRoute } from 'vue-router';
const route = useRoute();

import { useGlobalStore } from "@/stores/global";
const global = useGlobalStore();

onMounted(() => {
    delete global.errors[route.name];
});

const isLoading = ref(false);

function submit() {
    if(!isLoading.value) {
        isLoading.value = true;
        global.loader('show');
        orderStore.tryReplyChargeback(route.name, orderStore.order.id).then(() => {
            isLoading.value = false;
            global.loader('hide');
        });
    }
    
}
</script>

<style scoped>
h5{
    font-size: 14px;
    font-weight: 600;
    margin-top: 2px;
    margin-bottom: 6px;
}

.reply_empty {
    color: #bbb;
}

.table-responsive a:hover {
    text-decoration: underline !important;
}

.admin_reply {
    border: 2px #215eda solid;
}

.admin_reply .row {
    color: #215eda !important;
}
</style>