<template>
    <BaseTable  v-if="userStore.user.modules" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :multirows="true" :qty="userStore.user.stores.length">
        <template v-slot:thead>
            <th class="thead" width="90%">Permissões</th>
            <th class="switch_th"><BaseSwitch @click="switchAllValues()" id="switch_all" :page="page"/></th>
        </template>
        <template v-slot:tbody >
            <template v-for="(permission_module, key) in userStore.user.modules" :key="key">
                <tr class="module">
                    <td>{{ permission_module.name }}</td>
                    <td class="actions">
                        <BaseSwitch v-if="permission_module.enabled == true"  @click="switchValue(permission_module.id, key)" checked :id="'module_' + permission_module.id" :switch_class="'on'" class="switch" :page="page"/>
                        <BaseSwitch v-else :id="'module_' + permission_module.id"  @click="switchValue(permission_module.id, key)" :switch_class="'off'" class="switch" :page="page"/>
                    </td>
                </tr>
                <tr class="sub_permission" v-for="(subpermission, id) in permission_module.sub_permissions" :key="id">
                    <td> <font-awesome-icon icon="fa-solid fa-turn-down-right" class="pe-2"/> {{ subpermission.name }}</td>
                    <td class="actions">
                        <BaseSwitch v-if="subpermission.enabled == true" @click="switchValueSub(subpermission.id)" checked :id="'subpermission_' + subpermission.id" :switch_class="'on'" :class="'switch related_' + permission_module.id" :page="page" :disabled="permission_module.enabled == true ? false : true"/>
                        <BaseSwitch v-else :id="'subpermission_' + subpermission.id" @click="switchValueSub(subpermission.id)" :class="'switch related_' + permission_module.id" :switch_class="'off'" :page="page" :disabled="permission_module.enabled == true ? false : true"/>
                    </td>
                </tr>
            </template>
                <tr class="module">
                    <td>App</td>
                    <td class="actions">
                        <BaseSwitch class="switch" v-model:checked="userStore.user.app_permissions.login"/>
                    </td>
                </tr>
                <tr class="sub_permission">
                    <td> <font-awesome-icon icon="fa-solid fa-turn-down-right" class="pe-2"/>Informações da home</td>
                    <td class="actions">
                        <BaseSwitch class="switch" v-model:checked="userStore.user.app_permissions.home_informations"/>
                    </td>
                </tr>
                <tr class="sub_permission">
                    <td> <font-awesome-icon icon="fa-solid fa-turn-down-right" class="pe-2"/>Pedidos</td>
                    <td class="actions">
                        <BaseSwitch class="switch" v-model:checked="userStore.user.app_permissions.orders"/>
                    </td>
                </tr>
                <tr class="sub_permission">
                    <td> <font-awesome-icon icon="fa-solid fa-turn-down-right" class="pe-2"/>Validar ingressos</td>
                    <td class="actions">
                        <BaseSwitch class="switch" v-model:checked="userStore.user.app_permissions.validate_tickets"/>
                    </td>
                </tr>
        </template>
    </BaseTable>
    <BaseTable  v-if="!userStore.user.modules" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :multirows="true" :qty="userStore.user.stores.length">
        <template v-slot:tbody >
            <h6 class="text-center p-4 mb-0 divisor_bottom">Nenhum módulo de permissão encontrado</h6>
        </template>
    </BaseTable>
</template>

<script setup>
import { ref, onBeforeMount, onMounted } from 'vue'

import { useUsersStore } from '@/stores/users';
const userStore = useUsersStore();

const tableColumns = ['Permissões'];

const page = ref('editPermissions')

var modules = ([])

const isLoading = ref(true);

const props = defineProps({
    firstLoadPermissions:{
        default: false,
	},

    pageParams:{
        default: null,
    },
})

const thisFirstLoad = ref(false);

const emit = defineEmits(['emitPermissionsValues'])

onBeforeMount(() =>{
    formatAppPermissions()
    isLoading.value = false
})

onMounted(() =>{
    const getAllSwitches = document.querySelectorAll('.switch input');
    if(props.firstLoadPermissions == true){
        modules = []
        for(let index = 0; index < getAllSwitches.length; index++){
            getAllSwitches[index].checked = false
            let splitSwitchId = getAllSwitches[index].id.split('_');
            for(let i = 0; i < props.pageParams.length; i++){
                if(splitSwitchId[0] == 'module'){
                    let getThisModule = document.getElementById('module_' + splitSwitchId[1])
                    if(splitSwitchId[1] == props.pageParams[i]){
                        getThisModule.checked = true
                        modules.push(parseInt(splitSwitchId[1]))
                    }
                } else {
                    let getThisSubpermission = document.getElementById('subpermission_' + splitSwitchId[1])
                    if(splitSwitchId[1] == props.pageParams[i]){
                        getThisSubpermission.checked = true
                        modules.push(parseInt(splitSwitchId[1]))
                    }
                }
            }
        }
        emit('emitPermissionsValues', modules, thisFirstLoad)
        thisFirstLoad.value = true;
    } else {
        setTimeout(() =>{
            for (let index = 0; index < userStore.user.modules.length; index++){
                if(userStore.user.modules[index].enabled == true){
                    modules.push(userStore.user.modules[index].id)
                }
                for(let i = 0; i < userStore.user.modules[index].sub_permissions.length; i++){
                    if(userStore.user.modules[index].sub_permissions[i].enabled == true){
                        modules.push(userStore.user.modules[index].sub_permissions[i].id)
                    }
                }
            }
            thisFirstLoad.value = true;
            emit('emitPermissionsValues', modules, thisFirstLoad)
        },300)
    }
})

function switchAllValues(){
    const getSwitchAll = document.getElementById('switch_all');
    const getAllSwitches = document.querySelectorAll('.switch input');
    if(getSwitchAll.checked == false){
        for(let i = 0; i < getAllSwitches.length; i++){
            getAllSwitches[i].checked = false;
            modules = [];
        }
        formatAppPermissions('allFalse')

        emit('emitPermissionsValues', modules, thisFirstLoad)
    } else {
        for(let i = 0; i < getAllSwitches.length; i++){
            getAllSwitches[i].checked = true;
            let splitSwitchId = getAllSwitches[i].id.split('_');
            for(let i = 0; i < userStore.user.modules.length; i++){
                if(splitSwitchId[1] == userStore.user.modules[i].id){
                    userStore.user.modules[i].enabled = true;
                    modules.push(userStore.user.modules[i].id)
                    for(let index = 0; index < userStore.user.modules[i].sub_permissions.length; index++){
                        userStore.user.modules[i].sub_permissions[index].enabled = true;
                        modules.push(userStore.user.modules[i].sub_permissions[index].id)
                    }
                }
            }
        }
        formatAppPermissions('allTrue')
        emit('emitPermissionsValues', modules, thisFirstLoad)
    }
}

function switchValue(id, key){
    const getSwitch = document.getElementById("module_" + id);
    if(getSwitch.checked == false){
    for (let index = 0; index < userStore.user.modules.length; index++){
        while (index < userStore.user.modules.length) {
            if(modules[index] == id){
                modules.splice(index, 1);
            } else {
                index++;
            }
        }
    }
    userStore.user.modules[key].enabled = false;
    
    } else {
        userStore.user.modules[key].enabled = true;
        modules.push(id)
    }
    emit('emitPermissionsValues', modules, thisFirstLoad)
}

function switchValueSub(id){
    const getSwitch = document.getElementById("subpermission_" + id)
    if(getSwitch.checked == false){
        for (let index = 0; index < modules.length; index++){
            while (index < modules.length) {
                if(modules[index] == id){
                    modules.splice(index, 1);
                    index++
                } else {
                    index++;
                }
            } 
        }
    } else {
        modules.push(id)
    }
    emit('emitPermissionsValues', modules, thisFirstLoad)
}

function formatAppPermissions(option){
    if(option){
        if(option === 'allFalse'){
            for(let key in userStore.user.app_permissions){
                userStore.user.app_permissions[key] = false;
            }
        } else {
            for(let key in userStore.user.app_permissions){
                userStore.user.app_permissions[key] = true;
            }
        }
    }else{
        for(let key in userStore.user.app_permissions){
            if(userStore.user.app_permissions[key] == 1){
                userStore.user.app_permissions[key] = true;
            } else {
                userStore.user.app_permissions[key] = false;
            }
        }
    }
}

let paginate = {
    first_page: 1,
    from: 1,
    last_page: 1,
}
</script>

<style scoped>

.thead{
    font-weight: bold;
}

:deep(.form-group){
    margin: 0px!important;
}
    
table th{
    border-bottom: 1px solid #d7d7d7;
    padding: 0.6rem 1rem!important;
}

table .module, table .module td{
    border-top: 1px solid #d7d7d7;
    border-bottom: 0px;
}

table .sub_permission, table .sub_permission td{
    border: 0px!important;
}


table td{
    padding: 0.5rem 1rem!important;
}

.actions .switch{
    height: 25px;
    width: 45px;
}

.actions .switch, th .switch{
    width: 45px;
}

.switch_th{
    padding-right: 0px!important;
    padding-left: 7px!important;
}

</style>'