import { defineStore } from "pinia";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useAccountStore = defineStore('account', {
    state: () => ({
        account: {},
        have_unread_notifications: 0,
        notifications: {},
        classification: {},
        message: {},
        completion: {},
        all_banks: {},
        sensitive_info: {},

    }),

    actions: {
        async tryAccount(){
            try {
                const response = await Api.get('/account');
                this.account = response.data.data.account;
                this.all_banks = response.data.data.all_banks;
                let splitedDate = this.account.birth_date.split('-');
                this.account.birth_date = splitedDate[2] + '/' + splitedDate[1] + '/' + splitedDate[0];
                
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryAccountClassification(){
            try {
                const response = await Api.get('/account/classification');
                this.classification = response.data.data;    
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryAccountNotifications(){
            try {
                const response = await Api.get('/account/notifications');
                this.notifications = response.data.data;
                this.have_unread_notifications = response.data.have_unread_notifications;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryMarkReadNotification(id, key){
            try {
                await Api.put('/account/notifications/' + id + '/read');
                this.notifications[key].read = 1;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateAccountCompletion(params, page) {

            let formData = useGlobalStore().getOnlyTabsParams(params, this.completion);

            formData['_method'] = 'put';
            
            try {
                await Api.post('account/completion', formData);

                this.tryAccount();

                return true;
            }
            catch (error) {

                useGlobalStore().tryIncomingErrors(error, page, params);

                return false;
            }
        },

        async tryUpdateAccount(params, page) {

            let formData = useGlobalStore().getOnlyTabsParams(params, this.account);

            formData['_method'] = 'put';
            
            try {
                await Api.post('account', formData);

                this.tryAccount();

                return true;
            }
            catch (error) {

                useGlobalStore().tryIncomingErrors(error, page, params);

                return false;
            }
        },
        
        async tryAccountMessage(id){
            try {
                const response = await Api.get('/account/messages/' + id);
                return response.data.data;
                
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async trySensitiveInfo() {
            try {
                const response = await Api.get('/account/user-preferences');
                this.user_preferences = response.data.data;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async trySwichSensitiveInfo(value){
            try {
                await Api.post('/account/sensitive-information/' + value);
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
    }
})