<template>
    <apexchart
        id="chart"
        width="100%"
        height="89px"
        type="bar"
        :options="options"
        :series="series"
    ></apexchart>
</template>
  
  <script setup>
import { ref, onBeforeMount } from "vue";
import { useHomeStore } from "@/stores/home";

const homeStore = useHomeStore();

const options = ref({
    chart: {
        id: "chart",
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
        parentHeightOffset: 0,
        stacked: true,
    },
    plotOptions: {
    bar: {
        borderRadius: 4,
        borderRadiusApplication: 'around',
        borderRadiusWhenStacked: 'all',
        borderWidth: 0,
    }
 },
    grid: {
        show: false,
    },
    xaxis: {
        labels: {
            style: {
                colors: "#8f8f8f",
                fontSize: "10px",
            },
        },
        axisBorder: {
            show: false,
        },
        tickPlacement: 'on',
    },
    yaxis: {
        show: false,
        labels: {
            formatter: function (value) {
                return Math.abs(value) > 999
                    ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) +
                          "k"
                    : Math.sign(value) * Math.abs(value);
            },
            style: {
                colors: ["#8f8f8f"],
                fontSize: "11px",
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        x: {
            formatter: function (value) {
                return value + ' min atrás';
            },
        },
        y: {
            formatter: function (value) {
                return value;
            },
        },
    },
    legend: {
        show: false,
    },
    fill: {
        type: "color",
    },
    stroke: {
        width: 3
    },
    colors: ["#ccc"],
});

const series = ref([
    {
        name: "Acessos",
        data: [
            {x: 1, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 2, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 3, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 4, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 5, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 6, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 7, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 8, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 9, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 10, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 11, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 12, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 13, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 14, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 15, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 16, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 17, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 18, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
        ],
    },
    {
        name: 'Pedidos',
        data: [
            {x: 1, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 2, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 3, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 4, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 5, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 6, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 7, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 8, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 9, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 10, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 11, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 12, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 13, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 14, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 15, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 16, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 17, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
            {x: 18, y: 0, fillColor: '#cccccc', strokeColor: '#cccccc'},
        ]
    }
]);

onBeforeMount(async () => {
    homeStore.tryLiveTraffic().then(function (){
        for(let i = 0; i < series.value[0].data.length; i++){            
            series.value[0].data[i].y = homeStore.home_traffic.last_30_min[i].pageviews;
            if(homeStore.home_traffic.last_30_min[i].orders > 0 && homeStore.home_traffic.last_30_min[i].pageviews == 0){
                series.value[0].data[i].y = 1;
            }
            if(homeStore.home_traffic.last_30_min[i].orders > 0 ){
                series.value[0].data[i].fillColor = '#28a745';
                series.value[0].data[i].strokeColor = '#28a745';
            }
        }
    })
});

function updateChart(id) {
    let infoparams = {'id': id}
    homeStore.tryLiveTraffic(infoparams).then(function (){
        for(let i = 0; i < series.value[0].data.length; i++){            
            series.value[0].data[i].y = homeStore.home_traffic.last_30_min[i].pageviews;
            if(homeStore.home_traffic.last_30_min[i].orders > 0 && homeStore.home_traffic.last_30_min[i].pageviews == 0){
                series.value[0].data[i].y = 1;
            }
            if(homeStore.home_traffic.last_30_min[i].orders > 0 ){
                series.value[0].data[i].fillColor = '#28a745';
                series.value[0].data[i].strokeColor = '#28a745';
            }
        }
    })
}

defineExpose({
    updateChart,
});
</script>

<style scoped>

#chart {
    padding: 0px 0px 0px 0px;
    margin-left: -5px;
}

</style>