<template>
    <div v-if="cart.id" class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage" >
            <template v-slot:title>
                <span data-bs-toggle="tooltip" data-bs-placement="top"  data-bs-animation="true" v-tooltip :title="cart.checkout.device">
                    <font-awesome-icon 
                        class="top_icon"
                        :icon="'fas ' + [cart.checkout.device === 'Celular' ? 'fa-mobile-screen' : cart.checkout.device === 'Computador' ? 'fa-laptop' : '']"
                    />
                </span>
            </template>
            <div class="row">
                <div class="col-lg-6 col-12 btn-group">
                    <BaseButton class="btn-success " @click="accessCart(cart.checkout.url)">Acessar</BaseButton>
                </div>
                <div class="col-lg-6 col-12 mt-lg-0 mt-3 btn-group">
                    <BaseButton class="btn-primary" @click="cartSubmit" :disabled="isLoading">Salvar</BaseButton>
                </div>
            </div>
        </BaseHeader>
        <div class="box_content nav_div">
            <nav>
                <div class="nav" id="nav-tab" role="tablist">
                    <router-link :to="'/recovery/abandoned-carts/'+ cart_id" :class="[route.path === '/recovery/abandoned-carts/'+ cart_id ? 'active': '']">
                        Informações
                    </router-link>
                    <router-link :to="'/recovery/abandoned-carts/' + cart_id + '/timeline'" :class="[route.path === '/recovery/abandoned-carts/' + cart_id + '/timeline' ? 'active': '']">
                        Linha do tempo
                    </router-link>
                </div>
            </nav>
        </div>
        <router-view></router-view>
    </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia'
const route = useRoute();
const router = useRouter();

const cart_id = computed(function () {
    return route.params.id;
});

const title = {title: 'Checkout abandonado #' + cart_id.value + '', icon: 'fa-clock-rotate-left'};

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useCartStore } from '@/stores/cart';
const cartStore = useCartStore();
const { cart } = storeToRefs(cartStore);

const isLoading = ref(true);

const previousPage = ref(null)

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('abandoned_carts')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;

    if(cart.value.id == undefined) {
        await cartStore.tryCart(cart_id.value).then(() => {
            isLoading.value = false
        })
    } else{
        isLoading.value = false
    }
})

function accessCart(url){
    window.open(url, '_blank');
}

const formLoading = ref(false);

async function cartSubmit() {
    if(!isLoading.value){
        isLoading.value = true;
        if(formLoading.value === true) {
            isLoading.value = false;
            return;
        }

        let params = ['status', 'note', 'reminder']

        cartStore.cart.status = cartStore.cart.status.id

        formLoading.value = true;
        globalStore.loader('show');
        cartStore.tryUpdateCart(route.name, cartStore.cart.id, params).then(() =>{
            formLoading.value = false;
            cartStore.tryCart(cartStore.cart.id)
            router.push('/recovery/abandoned-carts/' + cart_id.value);
            isLoading.value = false;
            globalStore.loader('hide');
        });
    }
}

</script>

<style scoped>



</style>