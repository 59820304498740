<template>
        <div class="table-responsive box_content">
            <table class="table">
                <thead class="divisor_bottom">
                    <th>Título</th>
                </thead>
                <tbody v-if="testsAb.length">
                    <tr v-for="(testAb, key) in testsAb" :key="key">
                        <td width="20">{{ testAb.title }}</td>
                        <td class="actions">
                            <router-link data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Relatório" :to="'tests-ab/'+  testAb.id +'/report'"><font-awesome-icon icon="fas fa-chart-simple" /></router-link>
                            <span  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editTestAb(testAb.id)"><font-awesome-icon icon="fas fa-edit" /></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(testAb.id)"><font-awesome-icon icon="fas fa-trash-can" /></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!testsAb.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
        </div>
    <div class="box_content_add">
        <div style="text-align:center;" class="p-4">
            <div> 
                <BaseButton class="btn-primary btn-lg" @click="createTestAb()">
                    Adicionar
                </BaseButton>
            </div>
        </div>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
                <div class="popup">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover esse teste A/B?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteTestAb" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
    </BasePopup>
</template>
    
<script setup>
import { computed, shallowRef,ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute();
const router = useRouter();

const store_id = computed (function () {
    return route.params.id;
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useStoreStore } from '@/stores/store';
const storeStore = useStoreStore();
const { testsAb } = storeToRefs(storeStore);

let testAbToDelete = null;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null);
const isLoading = ref(false);

let isDeleting = false;
async function deleteTestAb() {
    if(!isDeleting && !isLoading.value && testAbToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await storeStore.tryDeleteTestsAb(testAbToDelete).then(function(success) {
            globalStore.loader('hide');
            isDeleting = false;
            confirmAction.value = null;
            if(success){
                storeStore.tryTestsAb(store_id.value);
            }
            isLoading.value = false;
            excludeTextConfirm.value = null;
        }); 
    }
}

function createTestAb(){
    router.push('/stores/' + store_id.value + '/tests-ab/new')
}

function editTestAb(id){
    globalStore.loader('show');
    storeStore.tryTestAb(id).then(function(success){
        if(success){
            router.push('/stores/' + store_id.value + '/tests-ab/' + id)
            globalStore.loader('hide');
        }else{
            globalStore.loader('hide');
        }
    })
}

function openPopup(testAb_id) {
    testAbToDelete = testAb_id
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}
</script>
    
<style scoped>

.box_content{
    margin-bottom: 0px;
    border-radius: 8px 8px 0px 0px;
}

.box_content_add{
    margin: 0;
    background: #fff;
    border-bottom: 1px #ddd solid;
    border-left: 1px #ddd solid;
    border-right: 1px #ddd solid;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 25px;
}
</style>