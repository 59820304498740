<template>
    <div v-if="order" class="box_content">
        <div v-if="order.events" class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Evento</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(event, key) in order.events" :key="key">
                        <td>{{ event.event }}</td>
                        <td>{{ event.text }}</td>
                        <td>{{ globalStore.formatDate(event.created_at)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h6 v-if="!order.events || order.events.length < 1" class="mb-0 pt-4 pb-4 text-center">Nenhum resultado encontrado</h6>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useOrderStore } from '@/stores/order';

const globalStore = useGlobalStore();
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);
</script>

<style scoped>

.confirm_action h6{
    border-bottom: none;
}

.table-responsive {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

</style>