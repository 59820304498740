<template>
    <div class="mt-2">
        <BaseTable :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="invitations.length"  @changePage="changePage" :initialSearch="initialSearch">
            <tr v-for="(invitation, key) in coproductorStore.invitations" :key="key">
                <td>{{ invitation.email }}</td>
                <td>{{ invitation.status }}</td>
                <td>{{ globalStore.formatDate(invitation.created_at)}}</td>
                <td class="actions">
                    <span v-if="globalStore.hasPermissionTo('coproductors')" @click="openPopup(invitation.id)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Deletar"><font-awesome-icon icon="fas fa-trash-can" /></span>
                </td>
            </tr>
        </BaseTable>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover esse convite?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteInvitation" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { ref, onBeforeMount, watch, shallowRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useCoproductorStore } from '@/stores/coproductor';
import { useRouter, useRoute } from 'vue-router';

const coproductorStore = useCoproductorStore();
const { invitations, paginate } = storeToRefs(coproductorStore);
const tableColumns = ['E-mail', 'Status', 'Data'];

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const route = useRoute();
const router = useRouter();

const isLoading = ref(true);
const params = ref({
    'search': null,
    'page': null
});

let initialSearch = null;
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null);

onBeforeMount(() => {

    if(route.query.search || route.query.page) {

        Object.keys(params.value).forEach((key) => {
            if(key == 'search') {
                initialSearch = route.query.search;
            }
            let param = route.query[key];
            params.value[key] = (param == undefined) ? null : param;
        });
    }

    loadInvitations().then(() => {
        isLoading.value = false;
    });
});

function search(r) {
    params.value.search = r;
    params.value.page = null;
    loadInvitations();
}



let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    params.value.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadInvitations().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let loadDefaultStore = false;
    watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        params.value.page = null;
        globalStore.loader('show');
        loadInvitations().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

async function loadInvitations() {
    let usedParams = {};
    Object.keys(params.value).forEach((key) => {
        if(params.value[key] != null && params.value[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: params.value[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    await coproductorStore.tryInvitations(params.value);
    globalStore.loader('hide');
}

let invitationToDelete = null;
let isDeleting = false;
async function deleteInvitation(){
    if(!isDeleting && !isLoading.value && invitationToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await coproductorStore.tryDeleteInvitation(invitationToDelete).then(function(success) {
            globalStore.loader('hide');
            isDeleting = false;
            confirmAction.value = null;
            excludeTextConfirm.value = null;
            if(success){
                isLoading.value = false;
                coproductorStore.tryInvitations();
            } else{
                isLoading.value = false;
            }
        }); 
    }
}

function openPopup(id) {
    invitationToDelete = id
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

defineExpose(({
    search
}))

</script>

<style scoped>

</style>