<template v-if="store.meta != undefined">
    <div class="box_content">
        <div class="row p-4">
            <h4 class="mb-3">Estilo</h4>
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do fundo"
                id="meta[background_color]"
                v-model="store.meta.background_color"
            />
        </div>
        <div class="divisor_top p-0 m-0"></div>
        <div class="row p-4">
            <h4 class="mb-3">Timer de Escassez</h4>
            <h4 class="mt-2">Estrutura</h4>
            <BaseInput
                class="col-md-4 col-12"
                label="Texto "
                type="text"
                id="meta[timer_text]"
                v-model="store.meta.timer_text"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do texto "
                id="meta[timer_text_color]"
                v-model="store.meta.timer_text_color"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do fundo "
                id="meta[timer_background_color]"
                v-model="store.meta.timer_background_color"
            />
            <h4 class="mt-2 ">Botão</h4>
            <BaseInput
                class="col-md-4 col-12"
                label="Texto"
                type="text"
                id="meta[timer_text_button]"
                v-model="store.meta.timer_text_button"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do texto"
                id="meta[timer_color_text_button]"
                v-model="store.meta.timer_color_text_button"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do fundo"
                id="meta[timer_color_button]"
                v-model="store.meta.timer_color_button"
            />
            <h4 class="mt-2 ">Timer</h4>
            <BaseInput
                class="col-md-4 col-12"
                label="Tempo em minutos"
                type="text"
                id="meta[timer_time]"
                v-model="store.meta.timer_time"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do fundo"
                id="meta[timer_numbers_background_color]"
                v-model="store.meta.timer_numbers_background_color"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do texto"
                id="meta[timer_numbers_text_color]"
                v-model="store.meta.timer_numbers_text_color"
            />
        </div>
        <div class="divisor_top"></div>
        <div class="row p-4">
            <h4 class="mb-3">Exit-Intent Popup</h4>
            <h4 class="mt-2">Estrutura</h4>
            <BaseColorPicker
                class="col-md-6 col-12"
                label="Cor do texto"
                id="meta[exit_popup_text_color]"
                v-model="store.meta.exit_popup_text_color"
            />
            <BaseColorPicker
                class="col-md-6 col-12"
                label="Cor do texto"
                id="meta[exit_popup_background_color]"
                v-model="store.meta.exit_popup_background_color"
            />
            <h4 class="mt-2 ">Botão</h4>
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Texto"
                id="meta[exit_popup_text_button]"
                v-model="store.meta.exit_popup_text_button"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do texto"
                id="meta[exit_popup_color_text_button]"
                v-model="store.meta.exit_popup_color_text_button"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor do fundo"
                id="meta[exit_popup_color_button]"
                v-model="store.meta.exit_popup_color_button"
            />
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useStoreStore } from '@/stores/store';
const storeStores = useStoreStore();
const { store } = storeToRefs(storeStores);
</script>

<style scoped>
h4{
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #414951;
    font-size: 1rem;
}
</style>