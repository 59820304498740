<template>
    <div class="title item main_title_icon">
      <slot>
            
      </slot>
    </div>
</template>

<script setup>

</script>

<style scoped>

.main_title_icon {
  width: 100% ;
  margin: 0 auto ;
}

.main_title_icon h2 {
  display: inline-block;
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;

}

.main_title_icon svg {
  width: 16px;
  height: 18px;
  margin-right: 10px;
  margin-left: 1px;
  fill: #215eda;
  margin-top: -5px;
  
}

:deep(.metrics_title) {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    font-weight: 600;
}

:deep(.metrics_title svg) {
    margin-right: 9.5px;
    color: #215eda;
    width: 23px;
    height: 18px !important;
}


</style>