<template>
  <div class="box">
    <div class="p-4">
      <h5>Documentos</h5>
      <p class="mb-0">Selecione os documentos da empresa.</p>
    </div>

    <div class="divisor_top"></div>

    <div class="p-4">

      <div class="row" v-if="completion.type == 'J'">

        <BaseFileUpload class="col-12" label="Cartão CNPJ *" id="file_cnpj_card" :page="route.name" v-model="completion.file_cnpj_card" />

        <BaseFileUpload class="col-12" label="Última alteração do contrato social *" id="file_social_contract" :page="route.name" v-model="completion.file_social_contract" />

        <BaseFileUpload class="col-12" label="Comprovante de endereço da empresa *" id="file_proof_address" :page="route.name" v-model="completion.file_proof_address" />

        <BaseFileUpload class="col-12" label="Comprovante bancário *" id="file_proof_bank" :page="route.name" v-model="completion.file_proof_bank" />

      </div>

      <div class="row" v-else-if="completion.type == 'F'">

        <BaseFileUpload class="col-12" label="Foto da frente do RG ou CNH *" id="file_document_front" :page="route.name" v-model="completion.file_document_front" />

        <BaseFileUpload class="col-12" label="Foto do verso do RG ou CNH *" id="file_document_back" :page="route.name" v-model="completion.file_document_back" />

        <BaseFileUpload class="col-12" label="Comprovante de endereço *" id="file_proof_address" :page="route.name" v-model="completion.file_proof_address" />

        <BaseFileUpload class="col-12" label="Comprovante bancário *" id="file_proof_bank" :page="route.name" v-model="completion.file_proof_bank" />

      </div>

    </div>
  </div>
</template>

<script setup>
import { useAccountStore } from '@/stores/account'
import { storeToRefs } from 'pinia';
import { onBeforeMount } from 'vue';
import { useRoute } from "vue-router";

const route = useRoute();

const accountStore = useAccountStore();

const { completion } = storeToRefs(accountStore);

onBeforeMount(() => {
  if(!accountStore.completion.type){
    accountStore.completion.type = 'J';
  }
})
</script>