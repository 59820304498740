<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
            <router-link v-if="globalStore.hasPermissionTo('new_store')" to="/stores/create" class="item action btn-group">
                <BaseButton  class="btn btn-primary w-100"> Nova</BaseButton>
            </router-link>
        </BaseHeader>

        <BaseFilter :title="title" @search="search" :initialSearch="initialSearch">
        </BaseFilter>

        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="stores.length"  @changePage="changePage">
            <tr v-for="(store, key) in storeStore.stores" :key="key">
            <td width="10">{{ store.id }}</td>
            <td>
                <div class="d-flex align-items-center">
                    <div class="store_image" :style="'background: url(' + store.logotipo + ');'"></div>
                    <BaseStoreDot :color="store.color" :title="store.title" />
                </div>
            </td>
            <td>
                <div class="sensitive_information">{{store.status_name }}</div>
            </td>
            <td class="actions">
                <router-link :href="'/stores/' + store.id" :to="/stores/ + store.id" v-if="globalStore.hasPermissionTo('edit_store')" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar"><font-awesome-icon icon="fas fa-edit" /></router-link>
                <span v-if="globalStore.hasPermissionTo('destroy_store')"  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(store.id, store.title)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
            </td>
            </tr>
        </BaseTable>
        <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
            <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text" >Deseja remover essa loja?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <BaseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</BaseButton>
                    <BaseButton @click="deleteStore" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</BaseButton>
                </div>
            </div> 
        </BasePopup>
    </div>
</template>
<script setup>
const title = {title: 'Lojas', icon: 'fa-store'};
import { ref, onBeforeMount, shallowRef, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';
import BaseFilter from '@/components/ui/BaseFilter';

const storeStore = useStoreStore();
const { stores, paginate } = storeToRefs(storeStore);

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const tableColumns = ['ID', 'Nome', 'Status'];

const isLoading = ref(false);
const params = ref({
    'search': null,
    'page': null
});

const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null)

const route = useRoute();
const router = useRouter();

let initialSearch = null;

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('stores')) {
        return router.push('/403');
    }

    if(storeStore.stores.length == undefined){
        isLoading.value = true;
    }

    if(route.query.search || route.query.page) {

        Object.keys(params.value).forEach((key) => {
            if(key == 'search') {
                initialSearch = route.query.search;
            }
            let param = route.query[key];
            params.value[key] = (param == undefined) ? null : param;
        });
    }

    loadStores().then(() => {
        isLoading.value = false;
    });
});

async function loadStores() {
    let usedParams = {};
    Object.keys(params.value).forEach((key) => {
        if(params.value[key] != null && params.value[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: params.value[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    await storeStore.tryStores(params.value)
    globalStore.loader('hide');
}

function search(r) {
    params.value.search = r;
    params.value.page = null;
    loadStores();
}

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    params.value.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadStores().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let isDeleting = false;
let storeToDelete = null;

function deleteStore() {
    if(!isDeleting && !isLoading.value && storeToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        storeStore.tryDeleteStore(storeToDelete).then(()=> {
            storeStore.tryStores();
            globalStore.loader('hide');
            isLoading.value = false;
            isDeleting = false;
            confirmAction.value = null;
            excludeTextConfirm.value = null;
        });
    }
}

function openPopup(id) {
    storeToDelete = id;
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        globalStore.loader('show');
        loadStores().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

</script>

<style scoped>
.store_image {
    width: 35px;
    height: 35px;
    background-position: center !important;
    border-radius: 4px;
    display: inline-block;
    margin-right: 20px;
    border: 1px #d7d7d7 solid;
    background-size: 80% !important;
    background-repeat: no-repeat !important;
}
</style>