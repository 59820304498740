<template>
  <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
      <template v-if="isLoaded != false">
          <header>
              <font-awesome-icon icon="fa-solid fa-location-arrow" style="color: #215eda;"/>
              <span v-if="route.params.slug != 'new'" class="ms-2 align-middle">Editar evento Leadlovers</span>
              <span v-else class="ms-2 align-middle">Novo evento Leadlovers</span>
              <!--<span v-else class="ms-2 align-middle">Novo evento</span>-->
          </header>
          <div class="content">
              <form @submit.prevent="">
                  <div class="row">
                      <BaseSelect
                          class="col-12 mb-3"
                          id="type"
                          label="Evento"
                          :items="eventsList"
                          :page="route.name"
                          v-model="event_leadlovers.type"
                      />
                      <BaseSelect
                          class="col-12 mb-3"
                          id="action"
                          label="Ação"
                          :items="actionsList"
                          :page="route.name"
                          v-model="event_leadlovers.content.action"
                      />
                      <BaseSelect 
                          v-if="event_leadlovers.content.action == 'move_lead' || event_leadlovers.content.action == 'remove_lead'"
                          class="col-12 mb-3"
                          id="origin_machine"
                          label="Máquina de origem"
                          :items="machinesList"
                          :page="route.name"
                          v-model="event_leadlovers.content.id_machine_origin"
                          @click="getLevelsEmails"
                      />
                      <BaseSelect
                          v-if="event_leadlovers.content.action == 'move_lead'"
                          class="col-12 mb-3"
                          id="origin_email"
                          label="Sequência de email de origem"
                          :items="emailsList"
                          :page="route.name"
                          v-model="event_leadlovers.content.id_email_origin"
                          @click="getLevels"
                      />
                      <BaseSelect 
                          v-if="event_leadlovers.content.action != 'remove_lead' && event_leadlovers.content.action != null"
                          class="col-12 mb-3"
                          id="target_machine"
                          label="Máquina de destino"
                          :items="machinesList"
                          :page="route.name"
                          v-model="event_leadlovers.content.id_machine_destination"
                          @click="getLevelsEmails"
                      />
                      <BaseSelect
                          v-if="event_leadlovers.content.action != 'remove_lead' && event_leadlovers.content.action != null"
                          class="col-12 mb-3"
                          id="target_email"
                          label="Sequência de email de destino"
                          :items="emailsList"
                          :page="route.name"
                          v-model="event_leadlovers.content.id_email_destination"
                          @click="getLevels"
                      />
                      <BaseSelect
                          v-if="event_leadlovers.content.action != 'remove_lead' && event_leadlovers.content.action != null"
                          class="col-12 mb-3"
                          id="level"
                          label="Nível"
                          :items="levelsList"
                          :page="route.name"
                          v-model="event_leadlovers.content.id_level"
                      />
                  </div>
                  <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                      Salvar
                  </BaseButton>
              </form>
          </div>
      </template>
  </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { event_leadlovers }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false);
const isLoading = ref(false);

const product_id = computed (function () {
    return route.params.id;
});

let previousPage = router.options.history.state.back;

const eventsList = ref({
    'abandoned_checkout': 'Checkout abandonado',
    'pix_generated': 'Pix gerado',
    'boleto_generated': 'Boleto gerado',
    'ordered': 'Compra Realizada',
    'ordered_card': 'Compra Realizada com cartão',
    'ordered_pix': 'Compra Realizada com pix',
    'ordered_boleto': 'Compra Realizada com boleto',
    'refused_card': 'Cartão recusado',
    'refunded': 'Compra reembolsada',
    'empty_custom_fields': 'Campos personalizados não preenchidos',
    'all': 'Todos os eventos',
})

const actionsList = ref({
    'create_lead': 'Adicionar lead',
    'move_lead' : 'Mover lead',
    'remove_lead': 'Remover lead',
})

const machinesList = ref({})
const emailsList = ref({})
const levelsList = ref({})

onBeforeMount(() =>{
    delete globalStore.errors[route.name];
    productStore.tryEventLeadloversMachines().then(() =>{
        for(let i = 0; i < productStore.event_leadlovers_machines.length; i++){
            machinesList.value[productStore.event_leadlovers_machines[i].id] = productStore.event_leadlovers_machines[i].title;
        }
    })
    if(route.params.slug != 'new'){
        if(!productStore.event_leadlovers.id){
            productStore.tryEventLeadlovers(route.params.slug).then(() =>{
                for(let i = 0; i < productStore.event_leadlovers_machines.length; i++){
                    machinesList.value[productStore.event_leadlovers_machines[i].id] = productStore.event_leadlovers_machines[i].title;
                    isLoaded.value = true
                }
            })  
        } else {
            for(let i = 0; i < productStore.event_leadlovers_machines.length; i++){
                machinesList.value[productStore.event_leadlovers_machines[i].id] = productStore.event_leadlovers_machines[i].title;
            }
            isLoaded.value = true
        }
    }else {
        globalStore.cleanObject(productStore.event_leadlovers);
        productStore.event_leadlovers = {
            content: {},
            type: '',
        }
        isLoaded.value = true
    }
})

async function getLevelsEmails(){
    if(productStore.event_leadlovers.content.id_machine_destination != null || productStore.event_leadlovers.content.id_machine_origin != null){
        globalStore.loader('show')
            var params = (productStore.event_leadlovers.content.id_machine_destination != null) ? {
                id_machine: productStore.event_leadlovers.content.id_machine_destination,
                id_email_sequence: productStore.event_leadlovers.content.id_email_destination,
            } : {
                id_machine: productStore.event_leadlovers.content.id_machine_origin,
            }  
        await productStore.tryEventLeadloversLevelsEmails(params).then(function (success){
            if(success){
                for(let i = 0; i < productStore.event_leadlovers_emails.length; i++){
                    emailsList.value[productStore.event_leadlovers_emails[i].id] = productStore.event_leadlovers_emails[i].title;
                }
                globalStore.loader('hide')
            } else{
                globalStore.loader('hide')
            }
        })
    } else {
        return;
    }
}

async function getLevels(){
    if(productStore.event_leadlovers.content.id_email_destination != null || productStore.event_leadlovers.content.id_email_origin != null){
        globalStore.loader('show');
        var param = (productStore.event_leadlovers.content.id_email_destination != null) ? {
            id_machine: productStore.event_leadlovers.content.id_machine_destination,
            id_email_sequence: productStore.event_leadlovers.content.id_email_destination,
        } : {
            id_machine: productStore.event_leadlovers.content.id_machine_origin,
            id_email_sequence: productStore.event_leadlovers.content.id_email_origin,
        }  
        await productStore.tryEventLeadloversLevels(param).then(function (success){
            if(success){
                for(let i = 0; i < productStore.event_leadlovers_levels.length; i++){
                    levelsList.value[productStore.event_leadlovers_levels[i].id] = productStore.event_leadlovers_levels[i].title;
                }
                globalStore.loader('hide')
            }else{
                globalStore.loader('hide')
            }
        })
    } else{
        return;
    }
}

function clickToClose(){
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(route.params.slug != 'new'){
            let params = ['type', 'content'];

            productStore.tryUpdateEventLeadlovers(productStore.event_leadlovers.id, params, route.name).then(function(success) {
                if(success){
                    productStore.tryEventsLeadlovers().then(function(success){
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/events/leadlovers')
                        } else{
                            globalStore.loader('hide');
                        }
                    })
                    isLoading.value = false;
                } else {
                    isLoading.value = false;
                    globalStore.loader('hide');
                }
            });
        } else {
            let params = ['type', 'content'];
            
            productStore.tryCreateEventLeadlovers(params, route.name).then(function(success){
                if(success){
                    productStore.tryEventsLeadlovers().then(function(success) {
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/events/leadlovers')
                        } else{
                            globalStore.loader('hide')
                        }
                    });
                    isLoading.value = false;
                }else {
                    isLoading.value = false;
                    globalStore.loader('hide');
                }
            })
        }
    }
}

</script>