import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useRefundStore = defineStore('refund', {

    state: () => ({
        refunds: {},
        paginate: {},
        refund: {},
        refunds_filters: {
            'page': null,
            'status': null,
        },
    }),

    actions: {

        async tryRefunds(params = null) {
            try {
                const response = await Api.get('refunds', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.refunds = response.data.data;
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryRefund(id) {
            try {
                const response = await Api.get('refunds/' + id);
                this.refund = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReplyRefund(page, id, params){

            const formData = new FormData();
            if(params.images && Object.keys(params.images).length) {
                Object.keys(params.images).forEach((key) => {
                    formData.append("images[" + key + "]", params.images[key]);
                });
            }
            formData.append("text", params.text);

            try {
                await Api.post('refunds/' + id + '/reply', formData);
                useGlobalStore().successMessage('Informações enviadas com sucesso!');
                this.tryRefund(id);
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryAcceptRefund(page, id, params){
            const formData = new FormData();
            if(params.images && Object.keys(params.images).length) {
                Object.keys(params.images).forEach((key) => {
                    formData.append("images[" + key + "]", params.images[key]);
                });
            }
            formData.append("text", params.text);
            formData.append("get_product_back", params.get_product_back);
            formData.append("transport_code", params.transport_code);

            try {
                await Api.post('refunds/' + id + '/accept', formData);
                useGlobalStore().successMessage('Informações enviadas com sucesso!');
                this.tryRefund(id);
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryArriveUpdate(page, id, params){
            const formData = new FormData();

            if(params.images && Object.keys(params.images).length) {
                Object.keys(params.images).forEach((key) => {
                    formData.append("images[" + key + "]", params.images[key]);
                });
            }
            formData.append("text", params.text);
            formData.append("send_shipping_info", params.send_shipping_info);

            try {
                await Api.post('refunds/' + id + '/arrive-update', formData);
                useGlobalStore().successMessage('Informações enviadas com sucesso!');
                this.tryRefund(id);
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        }
    }
});