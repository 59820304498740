<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
            <div class="item action btn-group">
                <router-link to="" class="btn-primary w-100">
                    <baseButton class="btn-primary w-100" @click="submit" :disabled="isLoading">Salvar</baseButton>
                </router-link>
            </div>
        </BaseHeader>
        <div class="page_content row" style="margin-top: 0px">
            <div class="nav_div col-xl-3 col-12 mx-0 mb-xl-0 mb-4">
                <div class="navigation_bar flex-column nav-pills"  role="tablist" aria-orientation="vertical">
                    <ul class="nav nav-pills">
                        <li>
                            <router-link to="/users/create"  :class="[route.path == '/users/create' ? 'active': '']">Dados de acesso</router-link>
                        </li>
                        <li>
                            <router-link to="/users/create/store-access"  :class="[route.path === '/users/create/store-access' ? 'active': '']">Acesso às lojas</router-link>
                        </li>
                        <li>
                            <router-link to="/users/create/permissions"  :class="[route.path === '/users/create/permissions' ? 'active': '']">Permissões</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="forms_div col-xl-9 col-12 mb-4">
                <router-view v-slot="{ Component }">
                    <component v-if="route.path === '/users/create'" @emitUserValues="setUserValues" ref="childMethod"  :pageParamsName="params.name" :pageParamsEmail="params.email" :pageParamsPassword="params.password" :is="Component"/>
                    <component v-else-if="route.path === '/users/create/store-access'" @emitStoreAcessValues="setStoreAcessValues" :pageParams="params.stores" ref="childMethod"  :is="Component"/>
                    <component v-else-if="route.path === '/users/create/permissions'" @emitPermissionsValues="setPermissionsValues" @emitAppValues="setAppPermissions" :pageParams="params.modules" :appPageParams="params.app_permissions" ref="childMethod"  :is="Component"/>
                </router-view>               
            </div>
        </div>
    </div>
</template>

<script setup>
const title = { title: "Novo usuário"};
import { ref, onBeforeMount } from "vue"

import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();

import { useUsersStore } from "@/stores/users";
const userStore = useUsersStore();

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const isLoading = ref(false);

const params = ref({
    name: '',
    email: '',
    password: null,
    stores: [],
    modules: [],
    app_permissions:{},
})

const previousPage = ref(null)

onBeforeMount(async () => {
    delete globalStore.errors[route.name];
    if(!globalStore.hasPermissionTo('new_user')) {
        return router.push('/403');
    }
    globalStore.getStoresList();
    previousPage.value = router.options.history.state.back;
    userStore.tryModules();
})

function setUserValues(userdata){
    params.value.name = userdata.value.name;
    params.value.email = userdata.value.email;
    if(userdata.value.password != null){
        params.value.password = userdata.value.password;
    }
}

function setStoreAcessValues(stores){
    params.value.stores = [];
    for(let index = 0; index < stores.length; index++){
        params.value.stores.push(stores[index])
    }
}

function setPermissionsValues(modules){
    params.value.modules = [];
    for(let index = 0; index < modules.length; index++){
        params.value.modules.push(modules[index])
    }
}

function setAppPermissions(apppermissions){
    params.value.app_permissions = apppermissions
    for (let key in params.value.app_permissions) {
        if(params.value.app_permissions[key] != 1){
            params.value.app_permissions[key] = 0;
        }
    }
}

async function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show');
        userStore.tryCreateUser(params.value, route.name).then(function(success){
            if(success){
                isLoading.value = false;
            } else{
                isLoading.value = false;
            }
            globalStore.loader('hide');
        })
    }
}
</script>