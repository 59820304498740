<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <div>
            <header>
                <font-awesome-icon :icon="'fa-solid '+ payment.icon" style="color: #215eda;"/>
                <span class="ms-2 align-middle">{{ payment.title }}</span>
            </header>
            <div class="content">
                <div class="row">
                    <BaseInput
                        class="col-12 mb-0"
                        label="Desconto"
                        type="text"
                        tag="%"
                        id="a"
                        v-model="payment.discount"
                        mask="['#', '#.#', '#.##', '##.##']"
                    />
                    <BaseSelect
                        class="col-12 mb-0"
                        label="Status *"
                        id="b"
                        type="text"
                        v-model="payment.status"
                        :items="statusList"
                        :required="false"
                        placeholder="Selecione"
                    />
                </div>
                <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">Salvar</BaseButton>
            </div>
        </div>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount , ref } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';

const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const store_id = computed (function () {
    return route.params.id;
});

const statusList = ref({
  1: 'Habilitado',
  0: 'Desabilitado',
})

const isLoaded = ref(false)
const isLoading = ref(false);

const storeStore = useStoreStore();
const { payment } = storeToRefs(storeStore);

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(route.name == 'storePaymentEdit'){
        if(storeStore.payment.id){
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            storeStore.tryPayment(route.params.slug).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value =  true;
        globalStore.loader('show')

        let params = ['discount', 'status'];

        storeStore.tryUpdatePayment(route.params.slug, params, route.name).then(function(success) {
            if(success){
                storeStore.tryPayments().then(function() {
                    globalStore.loader('hide');
                    router.push('/stores/' + store_id.value + '/payments')
                });
            }else {
                globalStore.loader('hide');
            }
            isLoading.value = false;
        })
    }
}
</script>