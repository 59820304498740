<template>
    <div id="FilterMain" class="filter_container" @scroll="closeCollapses">

        <div class="collapse_items">

            <div id="period_item" class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_period" role="button" aria-expanded="true" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Período</span>
                    </a>
                </div>
                <div class="collapse show preset_picker" id="collapse_period">
                    <BaseDatePicker range multi class="mb-2" v-model="filter.date_range" :enablePreset="true"/>
                </div>
            </div>

            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_payment_date" role="button" aria-expanded="true" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Data de pagamento</span>
                    </a>
                </div>
                <div class="collapse show preset_picker" id="collapse_payment_date">
                    <BaseDatePicker range multi class="mb-2" v-model="filter.payment_date" :enablePreset="true"/>
                </div>
            </div>

            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_payment_status" role="button" aria-expanded="true" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Status</span>
                    </a>
                </div>

                <ul class="collapse show" id="collapse_payment_status">
                    <li class="form-check check-status" v-for="(st, key) in status[0]" :key="key">
                        <input class="form-check-input" type="checkbox" value="" :id="'status_' + key" v-model="filter.status[key]">
                        <label class="form-check-label" :for="'status_' + key">
                            {{ st }}
                            <div @click="filterOnlyThisStatus" class="filter_only">
                                <span>   
                                    Apenas
                                </span>
                            </div>
                        </label>
                    </li>
                    <button button class="btn expand_button" type="button" data-bs-toggle="collapse" data-bs-target="#expand_collapse" aria-expanded="false" aria-controls="collapseExample">Ver mais</button>
                    <div class="collapse" id="expand_collapse" style="padding: 0px;">
                        <li class="form-check check-status" v-for="(st, key) in status[1]" :key="key">
                            <input class="form-check-input" type="checkbox" value="" :id="'status_' + key" v-model="filter.status[key]">
                            <label class="form-check-label" :for="'status_' + key">
                                {{ st }}
                                <div @click="filterOnlyThisStatus" class="filter_only">
                                    <span>   
                                        Apenas
                                    </span>
                                </div>
                            </label>
                        </li>
                    </div>
                </ul>
            </div>

            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_payment_method" role="button" aria-expanded="true" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Pagamento</span>
                    </a>
                </div>
                <ul class="collapse show" id="collapse_payment_method">
                   <li class="form-check check-method" v-for="(payment, key) in payment_methods" :key="key">
                        <input class="form-check-input" type="checkbox" value="" :id="'payment_' + key" v-model="filter.payment_methods[key]">
                        <label class="form-check-label" :for="'payment_' + key">
                            {{ payment }}
                            <div @click="filterOnlyThisMethod" class="filter_only">
                                <span>   
                                    Apenas
                                </span>
                            </div>
                        </label>
                    </li>
                </ul>
            </div>

            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_stores" role="button" :aria-expanded="hasStoreFilterSelected" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Lojas</span>
                    </a>
                </div>
                <div :class="'collapse ' + [hasStoreFilterSelected ? 'show' : '']" id="collapse_stores">
                    <BaseSelect placeholder="Selecione" :items="formatedStoreList" v-model="filter.store" id="store" page="orders" multi @click="selectPosition($event), filterProductsByStore()" />
                </div>
            </div>

            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_referrals" role="button" :aria-expanded="hasReferralFilterSelected" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Afiliados</span>
                    </a>
                </div>
                <div :class="'collapse ' + [hasReferralFilterSelected ? 'show' : '']" id="collapse_referrals">
                    <BaseSelect placeholder="Selecione" :items="formatedReferralsList" v-model="filter.referral" id="referral" page="orders" multi @click="selectPosition($event)" />
                    <BaseSwitch
                        v-model:checked="filter.direct_order"  
                        label="Vendas diretas"
                        id="direct_order"
                        :page="'orders'" 
                    />
                </div>
            </div>

            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_products" role="button" :aria-expanded="hasProductFilterSelected" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Produtos</span>
                    </a>
                </div>
                <div :class="'collapse ' + [hasProductFilterSelected ? 'show' : '']" id="collapse_products">
                    <BaseSelect placeholder="Selecione" :items="formatedProductList" v-model="filter.product" id="product" page="orders" multi @click="selectPosition($event)"/>
                </div>
            </div>

            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_ordination" role="button" :aria-expanded="hasOrderByFilterSelected" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Ordenação</span>
                    </a>
                </div>
                <div :class="'collapse ' + [hasOrderByFilterSelected ? 'show' : '']" id="collapse_ordination">
                    <BaseSelect placeholder="Selecione" :items="order_by" v-model="filter.order_by" id="order_by" page="orders" @click="selectPosition($event)"/>
                </div>
            </div>

            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_searchutm" role="button" :aria-expanded="hasSearchUtmSelected" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Utms/src</span>
                    </a>
                </div>
                <div :class="'collapse ' + [hasSearchUtmSelected ? 'show' : '']" id="collapse_searchutm">
                    <BaseInput placeholder="Buscar"  id="utms_src" page="orders" v-model="filter.utms_src"/>
                </div>
            </div>
            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_searchcoupon" role="button" :aria-expanded="hasSearchUtmSelected" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Cupom</span>
                    </a>
                </div>
                <div :class="'collapse ' + [ hasSearchCouponSelected ? 'show' : '']" id="collapse_searchcoupon">
                    <BaseInput placeholder="Buscar"  id="coupon" page="orders" v-model="filter.coupon"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useProductStore } from '@/stores/products';
import { useAccountStore } from '@/stores/account';

const globalStore = useGlobalStore();
const { storesList, referralsList } = storeToRefs(globalStore);

const  productStore = useProductStore();
const { productslist } = storeToRefs(productStore);

const accountStore = useAccountStore();

const formatedStoreList = computed (function () {
    let list = {};
    Object.keys(storesList.value).forEach((key) => {
        let currentList = {
            ...list,
            [storesList.value[key].id]: storesList.value[key].title
        };
        list = currentList;
    });
    return list;
});

const formatedReferralsList = computed (function () {
    let list = {};
    Object.keys(referralsList.value).forEach((key) => {
        let currentList = {
            ...list,
            [referralsList.value[key].id]: referralsList.value[key].name
        };
        list = currentList;
    });
    return list;
});

const formatedProductList = computed (function () {
    let list = {};
    Object.keys(productslist.value).forEach((key) => {
        let currentList = {
            ...list,
            [productslist.value[key].id]: productslist.value[key].title + ' ' + [productslist.value[key].tag ? '(' + productslist.value[key].tag + ')' : ''] 
        };
        list = currentList;
    });
    return list;
});

const status = [
    {
        'paid': 'Pago',
        'pending': 'Aguardando',
        'refused': 'Recusado'
    },
    {
        'canceled': 'Cancelado',
        'refunded': 'Devolvido',
        'chargedback': 'Chargeback',
        'completed': 'Concluído'
    }
];

const payment_methods = {
    'boleto': 'Boleto Bancário',
    'pix': 'Pix',
    'credit-card': 'Cartão de Crédito'
};

const order_by = {
    'created_at': 'Data do pedido',
    'paid_date': 'Data de pagamento',
    'amount': 'Valor total',
};

const filter = ref({
    payment_date: null,
    payment_date_start: null,
    payment_date_end: null,
    date_range: null,
    date_start: null,
    date_end: null,
    status: {
        'paid': true,
        'pending': true,
        'refused': true,
        'canceled': true,
        'refunded': true,
        'chargedback': true,
        'completed': true
    },
    store: [],
    referral: [],
    direct_order: false,
    product: [],
    payment_methods: {
        'boleto': true,
        'pix': true,
        'credit-card': true,
    },
    order_by: 'created_at',
    utms_src: null,
    coupon: null,
});

const props = defineProps({
    initialFilters: {
        required: false,
        default: null
    }
});

let ignoreOnloadEmit = true;

let hasStoreFilterSelected = false;
let hasReferralFilterSelected = false;
let hasProductFilterSelected = false;
let hasOrderByFilterSelected = false;
let hasSearchUtmSelected = false;
let hasSearchCouponSelected = false;

var getStoresList = [];

onBeforeMount(() => {
    globalStore.getReferralsList();
    if(props.initialFilters) {
        ignoreOnloadEmit = true;
        let filters = props.initialFilters;

        for(var i=0; i <= 1 ; i++) {
            let currentForFilter = (i == 0) ? 'status' : 'payment_methods';

            if((filters[currentForFilter]) && filters[currentForFilter].length != 0) {
                Object.keys(filter.value[currentForFilter]).forEach((key) => {

                    if((filters[currentForFilter]) && filters[currentForFilter].constructor === Array) {

                        if (!filters[currentForFilter].includes(key)) {
                            filter.value[currentForFilter][key] = false;
                        }
                    }else {
                        if(key != filters[currentForFilter])  {
                            filter.value[currentForFilter][key] = false;
                        }
                    }
                });
            }
        }
    
        if(filters['date_range']) {
            filter.value['date_range'] = filters['date_range'];
        }

        if(filters['payment_date']) {
            filter.value['payment_date'] = filters['payment_date'];
        }

        if(filters['order_by']) {
            filter.value['order_by'] = filters['order_by'];
            if(filters['order_by'] != 'created_at') {
                hasOrderByFilterSelected = true;
            }
        }

        if(filters['store']) {
            filter.value['store'] = filters['store'];
            hasStoreFilterSelected = true;
            setTimeout(() =>{
                filterProductsByStore()
            },100)
        }

        if(filters['referral']) {
            filter.value['referral'] = filters['referral'];
            hasReferralFilterSelected = true;
        }

        if(filters['direct_order']){
            filter.value['direct_order'] = filters['direct_order'];
            hasReferralFilterSelected = true;
        }

        if(filters['product']) {
            filter.value['product'] = filters['product'];
            hasProductFilterSelected = true;
        }

        if(filters['utms_src']) {
            filter.value['utms_src'] = filters['utms_src'];
            hasSearchUtmSelected = true;
        }
        if(filters['coupon']) {
            filter.value['coupon'] = filters['coupon'];
            hasSearchCouponSelected = true;
        }

        updateFiltersCount();
    }
});

const emit = defineEmits(["filters", "filtersQty"]);

watch(() => filter, (e) => {
    let status = [];
    Object.keys(e.value.status).forEach((key) => {
        if(e.value.status[key] == true) {
            status.push(key);
        }
    });

    if(status.length === Object.keys(filter.value.status).length) {
        status = null;
    }

    let payment_methods = [];
    Object.keys(e.value.payment_methods).forEach((key) => {
        if(e.value.payment_methods[key] == true) {
            payment_methods.push(key);
        }
    });

    if(payment_methods.length === Object.keys(filter.value.payment_methods).length) {
        payment_methods = null;
    }

    if(e.value.status){
        accountStore.user_preferences.orders_filter_status = []
        for(let key in e.value.status){
            if(e.value.status[key] === true){
                accountStore.user_preferences.orders_filter_status.push(key)
            }
        }
    }

    debounceFilter(e.value.date_start, e.value.date_end , e.value.payment_date_start, e.value.payment_date_end, status, payment_methods, e.value.order_by, e.value.store, e.value.referral, e.value.direct_order, e.value.product, e.value.utms_src, e.value.coupon);

},{ deep: true })



function filterOnlyThisStatus(key){
    Object.keys(filter.value.status).forEach((id) => {
        filter.value.status[id] = (key == id) ? true : false;
    })
}

function filterOnlyThisMethod(key){
    Object.keys(filter.value.payment_methods).forEach((id) => {
        filter.value.payment_methods[id] = (key == id) ? true : false;
    })
}

let debounce = null;
function debounceFilter(date_start, date_end, payment_date_start, payment_date_end, status, payment_methods, order_by, store, referral, direct_order, product, utms_src, coupon) {
    if(ignoreOnloadEmit == false) {
        globalStore.loader('show');
    }

    if(filter.value.date_range){
        filter.value.date_start = filter.value.date_range[0];
        filter.value.date_end = filter.value.date_range[1];
    } else if(filter.value.date_range == null){
        filter.value.date_start = null;
        filter.value.date_end = null;
    }

    if(filter.value.payment_date){
        filter.value.payment_date_start = filter.value.payment_date[0];
        filter.value.payment_date_end = filter.value.payment_date[1];
    } else if(filter.value.payment_date == null){
        filter.value.payment_date_start = null;
        filter.value.payment_date_end = null;
    }

    if(filter.value.direct_order == false){
        filter.value.direct_order = null;
    }

    clearTimeout(debounce)

    debounce = setTimeout(() => {
        if(ignoreOnloadEmit == false) {
            updateFiltersCount();
            emit("filters", {
                'date_start': date_start,
                'date_end': date_end,
                'payment_date_start': payment_date_start,
                'payment_date_end': payment_date_end,
                'status': status,
                'payment_methods': payment_methods,
                'order_by': order_by,
                'store': store,
                'referral': referral,
                'direct_order': direct_order,
                'product': product,
                'utms_src': utms_src,
                'coupon': coupon,
            });
        }
        if(ignoreOnloadEmit == true) {
            ignoreOnloadEmit = false;
        }
    }, 700)
}

function updateFiltersCount() {

    let count = 0;

    Object.keys(filter.value).forEach((key) => {

        if(key == 'status' || key == 'payment_methods') {

            let hasFalse = false;
            let hasTrue = false; 

            Object.keys(filter.value[key]).forEach((k) => {

                if(filter.value[key][k] == true) {
                    hasTrue = true;
                }else {
                    hasFalse = true;
                }

            });

            if(hasTrue && hasFalse) {
                count = count + 1;
            }

        }else if(key == 'order_by') {
            if(filter.value[key] != 'created_at') {
                count = count + 1;
            }

        }else if(key == 'payment_date' || key == 'date_range') {
            if(filter.value[key] != null) {
                count = count + 1;
            }
        }else if(key == 'store') {
            if(filter.value[key] != null && filter.value[key].length) {
                count = count + 1;
            }
        }else if(key == 'referral') {
            if(filter.value[key] != null && filter.value[key].length) {
                count = count + 1;
            }
        } else if (key == 'direct_order'){
            if(filter.value[key] != null && filter.value[key].length){
                count = count + 1;
            }
        } else if(key == 'product') {
            if(filter.value[key] != null && filter.value[key].length) {
                count = count + 1;
            }
        } else if (key == 'utms_src'){
            if(filter.value[key] != null && filter.value[key].length) {
                count = count + 1;
            }
        } else if (key == 'coupon'){
            if(filter.value[key] != null && filter.value[key].length) {
                count = count + 1;
            }
        }

    });

    emit("filtersQty", count);
}

function selectPosition(e){
    let getTargetPrecise = e.target.closest('div');

    if(getTargetPrecise !== null){
        const parentTop = window.scrollY + document.querySelector('#FilterMain').getBoundingClientRect().top;
        const childTop = window.scrollY + getTargetPrecise.getBoundingClientRect().top;
        const difference = childTop - parentTop;

        document.querySelector('#' + getTargetPrecise.id + ' .select-box').style.top = (difference + 45) + 'px';   
    }
}

function closeCollapses(){
    const getAllDropdowns = document.querySelectorAll('.select-box');
    const getAllInputs = document.querySelectorAll('input')

    for(let i = 0; i < getAllDropdowns.length; i++){
        getAllDropdowns[i].style.display= 'none';
    }

    for(let i = 0; i < getAllInputs.length;i++){
        getAllInputs[i].blur();
    }
}

function filterProductsByStore(){
    let allProductsArr = []
    if(filter.value.store !== null){
        if(getStoresList === null){
            getStoresList = [];
        }

        if(filter.value.store.length !== getStoresList.length){
            for(let index = 0; index < filter.value.store.length; index++){
                productStore.tryProductsList(filter.value.store[index]).then(() =>{
                    allProductsArr.push(productStore.productslist)
                })
                if(index === (filter.value.store.length - 1)){
                    productStore.productslist = [];
                    setTimeout(() =>{
                        for(var i of allProductsArr){
                            productStore.productslist.push(...i);
                        }
                    }, 500)
                    getStoresList.length = filter.value.store.length
                }
            }

        }
    } else {
        if(filter.value.store !== getStoresList){
            productStore.tryProductsList('')
            getStoresList = null;
        }
    }
}

</script>

<style scoped>

:deep(.select-box){
    position: fixed!important;
}
:deep(.dp__input) {
    padding: 6px 15px ;
}

:deep(input:checked){
    border-color: #215eda!important;
}

</style>