<template>
    <div>

    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStoreStore } from '@/stores/store';

const storeStore = useStoreStore();

const router = useRouter();
const route = useRoute();

onBeforeMount(() =>{
    storeStore.tryManagerAccept(route.params.code).then(() => {
        router.push('/')
    })
})

</script>

<style scoped>
</style>