<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
            <div class="item action btn-group">
                <router-link to="" class="btn-primary w-100">
                    <baseButton class="btn-primary w-100" @click="submit" :disabled="isLoading">Salvar</baseButton>
                </router-link>
            </div>
        </BaseHeader>
        <div v-if="userStore.user.id" class="page_content row" style="margin-top: 0px">
            <template v-if="!userStore.user.is_primary">
            <div class="nav_div col-xl-3 col-12 mx-0 mb-xl-0 mb-4">
                <div class="navigation_bar nav-pills flex-column"  role="tablist" aria-orientation="vertical">
                    <ul class="nav nav-pills">
                        <li><router-link :to="'/users/'+ user_id"  :class="[route.path == '/users/'+ user_id ? 'active': '']">Dados de acesso</router-link></li>
                        <li><router-link :to="'/users/'+ user_id +'/store-access/'"  :class="[route.path === '/users/'+ user_id +'/store-access/' ? 'active': '']">Acesso às lojas</router-link></li>
                        <li><router-link :to="'/users/'+ user_id +'/permissions/'"  :class="[route.path === '/users/'+ user_id +'/permissions/' ? 'active': '']">Permissões</router-link></li>
                    </ul>
                </div>
            </div>
            </template>
            <div :class="'forms_div ' + [!userStore.user.is_primary ? 'col-xl-9' : ''] + ' col-12 mb-4'">
                <router-view v-slot="{ Component }">
                    <component v-if="route.path === '/users/' + user_id" @emitUserValues="setUserValues"  :firstLoadAccess="firstLoad.access" ref="childMethod"  :pageParamsName="params.name" :pageParamsEmail="params.email" :pageParamsPassword="params.password" :is="Component"/>
                    <component v-else-if="route.path === '/users/' + user_id + '/store-access/'" @emitStoreAcessValues="setStoreAcessValues" :firstLoadStore="firstLoad.storeaccess" :pageParams="params.stores" ref="childMethod"  :is="Component"/>
                    <component v-else-if="route.path === '/users/' + user_id + '/permissions/'" @emitPermissionsValues="setPermissionsValues" :firstLoadPermissions="firstLoad.permissions" :pageParams="params.modules" ref="childMethod"  :is="Component"/>
                </router-view>
            </div>
        </div>
    </div>
</template>

<script setup>
const title = { title: "Editar usuário"};
import { ref, onBeforeMount, computed } from "vue"

import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();

import { useUsersStore } from "@/stores/users";
const userStore = useUsersStore();

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const user_id = computed(function (){
    return route.params.id
})

const page = ref('usersEdit')
const isLoading = ref(false);

const firstLoad = ref({
    access: false,
    storeaccess: false,
    permissions: false,
})

const params = ref({
    name: '',
    email: '',
    password: null,
    stores: [],
    modules: [],
    app_permissions: {
        home_informations: null,
        login: null,
        orders: null,
        validate_tickets: null,
    },
})

const previousPage = ref(null)

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('edit_user')) {
        return router.push('/403');
    }
    delete globalStore.errors[page.value]
    previousPage.value ='/users';

    globalStore.cleanObject(userStore.user);
    globalStore.loader('show');

    userStore.tryUser(user_id.value).then(() =>{
        loadValues();
        globalStore.loader('hide');
    })


})

function loadValues(){
    params.value.name = userStore.user.name;
    params.value.email = userStore.user.email;
    for (let index = 0; index < userStore.user.stores.length; index++){
        if(userStore.user.stores[index].enabled == true){
            params.value.stores.push(userStore.user.stores[index].id)
        }
    }
    for (let index = 0; index < userStore.user.modules.length; index++){
        if(userStore.user.modules[index].enabled == true){
            params.value.modules.push(userStore.user.modules[index].id)
        }
        for(let i = 0; i < userStore.user.modules[index].sub_permissions.length; i++){
            if(userStore.user.modules[index].sub_permissions[i].enabled == true){               
                params.value.modules.push(userStore.user.modules[index].sub_permissions[i].id)
            }
        }
    }
}

function setUserValues(userdata, thisFirstLoad){
    params.value.name = userdata.value.name;
    params.value.email = userdata.value.email;
    firstLoad.value.access = thisFirstLoad.value;
    if(userdata.value.password != null){
        params.value.password = userdata.value.password
    }
}

function setStoreAcessValues(stores, thisFirstLoad){
    params.value.stores = []
    firstLoad.value.storeaccess = thisFirstLoad.value;
    for(let index = 0; index < stores.length; index++){
        params.value.stores.push(stores[index])
    }
}

function setPermissionsValues(modules, thisFirstLoad){
    params.value.modules = []
    firstLoad.value.permissions = thisFirstLoad.value
    for(let index = 0; index < modules.length; index++){
        params.value.modules.push(modules[index])
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        for(let key in userStore.user.app_permissions){
            if(userStore.user.app_permissions[key] == false){
                params.value.app_permissions[key] = 0
            } else {
                params.value.app_permissions[key] = 1
            }
        }

        userStore.tryUpdateUser(user_id.value, params.value, page.value).then(() =>{
            userStore.tryUser(user_id.value)
            isLoading.value = false;
            globalStore.loader('hide')
        })
    }
}
</script>

<style scoped>
::placeholder{
   color: black!important;
}
</style>