<template>
<div class="row">
    <div class="col-12 mb-xl-0">
    <div class="box_content">
        <div class="p-4">
            <h6>Compra inteligente</h6>
            <p>Ofereça automaticamente um produto de ticket menor em casos de recusa da compra inicial por saldo insuficiente.</p>
            <BaseSelect
                class="col-md-6"
                label="Oferecer produto"
                id="meta[replace_product]"
                placeholder="Selecione"
                :items="products_list"
                v-model="product.meta.replace_product"
                :page="page"
                />
        </div>
    </div>
    </div>
</div>
</template>
    
<script setup>
const page = 'productEdit';
import { onBeforeMount, ref} from 'vue';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';

const productStore = useProductStore();
const { product } = storeToRefs(productStore);

const products_list = ref({
    null : 'Nenhum',
})

onBeforeMount(() =>{
    for (let i = 0; i < productStore.all_products.length; i++) {
        if(productStore.all_products[i].tag === null){
            products_list.value[productStore.all_products[i].id] = productStore.all_products[i].id + ' - ' + productStore.all_products[i].title;
        } else{
            products_list.value[productStore.all_products[i].id] = {title: productStore.all_products[i].id + ' - ' + productStore.all_products[i].title, tag: productStore.all_products[i].tag}
        }
    }
})

</script>