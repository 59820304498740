<template v-if="!!loadingPage">
    <AuthBase>
        <component :is="loadedComponent" :code="AccountCreated" />
    </AuthBase>
</template>
<script setup>
import AuthBase from '@/components/includes/auth/AuthBase.vue';
import { ref, onBeforeMount, onMounted, shallowRef } from "vue";
import AccountCreated from "@/components/includes/auth/account-created/AccountCreated.vue";

const loadedComponent = shallowRef(AccountCreated);

const loadingPage = ref(true);

onBeforeMount(async () => {
    loadingPage.value = false;
});

onMounted(() =>{
    window.fbq('track', 'PageView');
    window.fbq('track', 'Lead');

    //Google ADS
    window.gtag('js', new Date());
    window.gtag('config', 'AW-16765752575');

    //Analytics
    window.gtag('js', new Date());
    window.gtag('config', 'G-09R8X06YY1');
})

</script>