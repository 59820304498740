<template>
    <BaseTable v-if="userStore.user.stores" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="userStore.user.stores.length" :multirows="true">
        <template v-slot:thead>
            <th class="thead" width="90%">Lojas</th>
            <th class="switch_th"><BaseSwitch @click="switchAllValues()" id="switch_all" :page="page"/></th>
        </template>
        <template v-slot:tbody>
            <tr v-for="(store, key) in userStore.user.stores" :key="key">
                <td width="90%">{{ store.title }}</td>
                <td class="actions">
                    <BaseSwitch v-if="store.enabled == true" checked @click="switchValue(store.id)" :id="'store_' + store.id" class="switch" :page="page" />
                    <BaseSwitch v-else :id="'store_' + store.id"  @click="switchValue(store.id)" class="switch" :page="page"/>
                </td>
            </tr>
        </template>
    </BaseTable>
</template>
    
<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';

import { useUsersStore } from '@/stores/users';
const userStore = useUsersStore();

const tableColumns = ['Loja'];

var stores = ([])

const isLoading = ref(true);

const props = defineProps({
    firstLoadStore:{
        default: false,
	},

    pageParams:{
        default: null,
    },
})

const thisFirstLoad = ref(false);


const emit = defineEmits(['emitStoreAcessValues'])

onBeforeMount(() =>{
    isLoading.value = false;
})

onMounted(() =>{
    const getAllSwitches = document.querySelectorAll('.switch input');
    if(props.firstLoadStore == true){
        stores = []
        for(let index = 0; index < getAllSwitches.length; index++){
            getAllSwitches[index].checked = false
            let splitSwitchId = getAllSwitches[index].id.split('_');
            for(let i = 0; i < props.pageParams.length; i++){
                let getThisStore = document.getElementById('store_' + splitSwitchId[1])
                if(splitSwitchId[1] == props.pageParams[i]){
                    getThisStore.checked = true
                    stores.push(parseInt(splitSwitchId[1]))
                }
            }
        }
        emit('emitStoreAcessValues', stores, thisFirstLoad)
        thisFirstLoad.value = true;
    } else {
        setTimeout(() =>{
            for (let index = 0; index < userStore.user.stores.length; index++){
                if(userStore.user.stores[index].enabled == true){
                    stores.push(userStore.user.stores[index].id)
                }
            }
            thisFirstLoad.value = true;
            emit('emitStoreAcessValues', stores, thisFirstLoad)
        },300)
    }
})

function switchAllValues(){
    const getSwitchAll = document.getElementById('switch_all');
    const getAllSwitches = document.querySelectorAll('.switch input');
    if(getSwitchAll.checked == false){
        for(let i = 0; i < getAllSwitches.length; i++){
            getAllSwitches[i].checked = false;
            stores = [];
        }
    } else {
        stores =[]
        for(let i = 0; i < getAllSwitches.length; i++){
            getAllSwitches[i].checked = true;
            let splitSwitchId = getAllSwitches[i].id.split('_');
            stores.push(parseInt(splitSwitchId[1]));
        }
    }
    emit('emitStoreAcessValues', stores, thisFirstLoad)
}

function switchValue(id){
    const getSwitch = document.getElementById("store_" + id)
    if(getSwitch.checked == false){
        for (let index = 0; index < userStore.user.stores.length; index++){
            while (index < userStore.user.stores.length) {
                if(stores[index] == id){
                    stores.splice(index, 1);
                } else {
                    index++;
                }
            } 
        }
    } else {
        stores.push(id)
    }
    emit('emitStoreAcessValues', stores, thisFirstLoad)
}

const page = ref('editStoreAcess')

let paginate = {
    first_page: 1,
    from: 1,
    last_page: 1,
}
    
</script>
    
<style scoped>

.actions .switch{
    height: 25px;
    width: 45px;
}
.thead{
    font-weight: bold;
}

:deep(.form-group){
    margin: 0px!important;
}
    
table th{
    border-bottom: 1px solid #d7d7d7;
    padding: 0.6rem 1rem!important;
}

table td{
    padding: 0.8rem 1rem!important;
}

.actions .switch, th .switch{
    height: 25px;
    width: 45px;
}

.switch_th{
    padding-right: 0px!important;
    padding-left: 7px!important;
}

</style>