import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useReportStore = defineStore('reports', {

    state: () => ({
        insights: {},
        referrals: {},
        referral: null,
        metrics: {},
        chart: {},
        productors: {},
        products: {},
        paymentMethods: {},
        conversions: {},
        recommendations: {},
        recommendation: null,
        tests_ab:{},
        total: {},
        paginate: {},
        referrals_rep_filters:{
            'page': null,
            'date_start': null,
            'date_end': null,
        },
        productors_rep_filters:{
            'page': null,
            'date_start': null,
            'date_end': null,
        },
        products_rep_filters:{
            'page': null,
            'date_start': null,
            'date_end': null,
        },
        payment_rep_filters:{
            'page': null,
            'date_start': null,
            'date_end': null,
        },
        conversions_rep_filters:{
            'page': null,
            'date_start': null,
            'date_end': null,
        },
        recommendations_rep_filters:{
            'page': null,
        }
    }),

    actions: {

        async tryInsights(params) {
            try {
                const response = await Api.get('reports/insights', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.insights = response.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReferrals(params = null) {
            try {
                const response = await Api.get('reports/referrals', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.referrals = response.data.data;
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReferral(id) {
            try {
                const response = await Api.get('reports/referrals/' + id);
                this.referral = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReferralMetrics(id, params){
            try {
                const response = await Api.get('reports/referrals/' + id + '/metrics', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.metrics = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReferralChart(id, params){
            try {
                const response = await Api.get('reports/referrals/' + id + '/chart', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.chart = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryProductors(params = null) {
            try {
                const response = await Api.get('reports/productors', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.productors = response.data.data;
                this.total = response.data
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        getTotalProductorAmount() {
            let amount = 0;
            if(this.productors.length) {
                for(let i = 0; i < this.productors.length; i++){
                    amount = amount + this.productors[i].amount;
                }
            }
            return amount;
        },

        getTotalProductorWithdrawal() {
            let amount_withdrawal = 0;
            if(this.productors.length) {
                for(let i = 0; i < this.productors.length; i++){
                    amount_withdrawal = amount_withdrawal + this.productors[i].amount_withdrawal;
                }
            }
            return amount_withdrawal;
        },

        async tryProducts(params = null) {
            try {
                const response = await Api.get('reports/products', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.products = response.data.data;
                this.total = response.data
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        getTotalProductsQty() {
            let qty = 0;
            if(this.products.length) {
                for(let i = 0; i < this.products.length; i++){
                    qty = qty + this.products[i].qty;
                }
            }
            return qty;
        },

        getTotalProductsAmount() {
            let amount = 0;
            if(this.products.length) {
                for(let i = 0; i < this.products.length; i++){
                    amount = amount + this.products[i].amount;
                }
            }
            return amount;
        },

        async tryPaymentMethods(params = null) {
            try {
                const response = await Api.get('reports/payment-methods', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.paymentMethods = response.data.data;
                this.total = response.data
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },



        async tryConversions(params = null) {
            try {
                const response = await Api.get('reports/conversions', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.conversions = response.data.data;
                this.total = response.data
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryRecommendations(params = null) {
            try {
                const response = await Api.get('reports/recommendations', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.recommendations = response.data.data;
                this.total = response.data
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryRecommendation(id) {
            try {
                const response = await Api.get('reports/recommendations/' + id);
                this.recommendation = response.data.data.name;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryRecommendationMetrics(id, params){
            try {
                const response = await Api.get('reports/recommendations/' + id + '/metrics', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.metrics = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryRecommendationChart(id, params){
            try {
                const response = await Api.get('reports/recommendations/' + id + '/chart', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.chart = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        async tryTestsAb(params = null) {
            try {
                const response = await Api.get('reports/tests-ab/', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.tests_ab = response.data.data;
                this.paginate = response.data.meta;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
    }
});