<template>
    <div id="classification_header">
        <img src="@/assets/images/placas_fullsale.jpg" />
    </div>

    <div class="container">
        <div id="classification_box">
            <div class="row">
                <div class="col-lg-4 col-12 divisor_right">
                    <div class="p-5">
                        <TheLogoIcon width="100px" :color="accountStore.classification.color_dark" />
                        <h5 class="text-center mt-4">Carrinho de <span class="d-block">{{ accountStore.classification.name }}</span></h5>
                    </div>
                </div>
                <div class="col-lg-4 col-12 information">
                    <h1>{{ accountStore.classification.current_amount }}</h1>
                    <h5>Faturados</h5>
                </div>
                <div class="col-lg-4 col-12 information">
                    <h1>{{ accountStore.classification.qty_orders }}</h1>
                    <h5>Pedidos pagos</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAccountStore } from '@/stores/account';

const accountStore = useAccountStore();
</script>

<style scoped>
#classification_header {
    background-color: #17171b;
    padding: 80px 20px 170px 20px;
    text-align: center;
}

#classification_header img {
    max-width: 100%;
}

#classification_box {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    border-radius: 20px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: -140px;
}

.divisor_right {
    border-right: 1px #ddd solid;
}

.information {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.information h5 {
    font-weight: 400;
    color: #9c9c9c;
}

@media screen and (max-width: 991px){
    .information {
        margin-bottom: 40px;
    }

    .divisor_right {
        border: 0;
    }
}
</style>