<template>
  <div class="box_content pt-1">
    <div class="tab-content">
      <div class="tab-pane active address_data p-4" id="nav-profile">
        <div class="row">

          <BaseInput class="col-md-3" label="CEP" type="text" id="postcode" :mask="'#####-###'"
            v-model="accountStore.account.postcode" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Endereço" type="text" id="address" maxlength="150"
            v-model="accountStore.account.address" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-3" label="Número" type="text" id="address_number" maxlength="10"
            v-model="accountStore.account.address_number" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Bairro" type="text" id="neighborhood" maxlength="50"
            v-model="accountStore.account.neighborhood" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Complemento" type="text" id="complement" maxlength="50"
            v-model="accountStore.account.complement" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Cidade" type="text" id="city" v-model="accountStore.account.city"
            :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseSelect class="col-md-6" label="Estado" type="text" id="state" :items="states"
            v-model="accountStore.account.state" :page="route.name" :disabled="!accountStore.account.is_editable" />

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAccountStore } from '@/stores/account'
import { useRoute } from "vue-router";

const accountStore = useAccountStore();
const route = useRoute();

const states = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};
</script>

<style scoped>
.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

:deep(.form-group) {
  height: 70px;
}

@media screen and (max-width: 992px) {
  .main_title_icon {
    width: 150px;
    margin: 0 auto;
  }
}
</style>