<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar redirectBack="/support">
            <div class="item action btn-group">
                <baseButton :disabled="isLoading" @click="submit" class="btn btn-primary w-100">Enviar</baseButton>
            </div>
        </BaseHeader>
        <div class="box_content p-4">
            <form @submit.prevent="submit">
                <div class="row">
                    <BaseInput
                        class="col-12"
                        id="title"
                        label="Assunto *"
                        type="text"
                        required
                        v-model="support.title"
                        :page="route.name"
                    />
                    <BaseInput
                        class="col-12"
                        id="content"
                        label="Mensagem *"
                        textarea
                        required
                        v-model="support.content"
                        :page="route.name"
                    />
                    <BaseFileUpload
                        class="col-12"
                        label="Imagem"
                        type="image"
                        accept="image/png, image/gif, image/jpeg"
                        id="attachment"
                        v-model="support.attachment"
                    />
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
const title = {title: 'Novo suporte', icon: 'fa-comment-lines'};
import { useRoute, useRouter } from 'vue-router'
import { ref, onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useSupportStore } from "@/stores/support"

const route = useRoute();
const router = useRouter()

const supportStore = useSupportStore();
const { support } = storeToRefs(supportStore);

const globalStore = useGlobalStore();

onBeforeMount(() => {   
    if(!globalStore.hasPermissionTo('support')) {
        return router.push('/403');
    }
    delete globalStore.errors[route.name];
    supportStore.support = [];
})

const isLoading = ref(false);

async function submit() {
    if(isLoading.value) {
        return;
    }

    let params = ['title', 'content', 'attachment'];

    isLoading.value = true;
    globalStore.loader('show');
    
    supportStore.tryCreateSupport(params, route.name).then(function(success) {
        isLoading.value = false;
        globalStore.loader('hide');

        if(success){
            supportStore.trySupports();
            router.push('/support/' + supportStore.support.id);
        }
    })
}   

</script>
<style scoped>



</style>