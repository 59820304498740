import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import App from './App';
import FontAwesomeIcon from '@/fontawesome';
const app = createApp(App);

//ROUTER
import router from './router';


//IMPORT, CREATE AND USE PINIA
const pinia = createPinia();
pinia.use(({ store }) => {
    //ADD router TO PINIA
    store.router = markRaw(router) 
}); 
app.use(pinia);


//USER STORE
import { useAuthStore } from '@/stores/auth';
const auth = useAuthStore();


router.beforeEach(async function(to, _, next) {

    let tooltip = document.querySelectorAll(".tooltip");

    if(to.meta.requiresAuth === false) {
        auth.inInitialLoading = false;
        tooltip.forEach(el => el.remove());
        next();

    }else if(await auth.tryCheckLogin() === true) {
        
        // eslint-disable-next-line
        grecaptcha.ready(() => {
            document.querySelectorAll(".grecaptcha-badge").forEach(el => el.style.visibility = "hidden");
        });
        tooltip.forEach(el => el.remove());

        if(to.meta.dontScrollUp !== true) {
            window.scrollTo({ top:0, left:0, behavior: "instant"});
        }

        next();
    }else {
        auth.tryLogout();
        tooltip.forEach(el => el.remove());
        next('/login');
    }
});
app.use(router);
//ROUTER

//GLOBAL COMPONENTS
import TheLogo from '@/components/assets/TheLogo';
import TheLogoIcon from '@/components/assets/TheLogoIcon';
import BaseHeader from '@/components/ui/BaseHeader.vue';
import BaseInput from '@/components/ui/BaseInput';
import BaseButton from '@/components/ui/BaseButton';
import BaseSelect from '@/components/ui/BaseSelect'
import BaseBadget from '@/components/ui/BaseBadget';
import BaseStoreDot from '@/components/ui/BaseStoreDot';
import BaseSwitch from '@/components/ui/BaseSwitch';
import BaseTable from '@/components/ui/BaseTable';
import BaseRightDialog from '@/components/ui/BaseRightDialog';
import BaseFileUpload from '@/components/ui/BaseFileUpload';
import BaseDatePicker from '@/components/ui/BaseDatePicker';
import BasePopup from '@/components/ui/BasePopup';
import BaseDefineStore from '@/components/ui/BaseDefineStore';
import BaseMetricsBox from '@/components/ui/BaseMetricsBox';
import BaseInfoGroup from '@/components/ui/BaseInfoGroup';
import BaseColorPicker from '@/components/ui/BaseColorPicker';
import VueApexCharts from "vue3-apexcharts";
import BaseInputCode from '@/components/ui/BaseInputCode';
import VOtpInput from "vue3-otp-input";
import BaseInputRange from '@/components/ui/BaseInputRange';
import BaseRange from '@/components/ui/BaseRange';
import BaseDragDrop from '@/components/ui/BaseDragDrop';

//USE GLOBAL COMPONENTS
app.component('TheLogo', TheLogo);
app.component('TheLogoIcon', TheLogoIcon);
app.component('BaseHeader', BaseHeader);
app.component('BaseInput', BaseInput);
app.component('BaseButton', BaseButton);
app.component('BaseSelect', BaseSelect);
app.component('BaseBadget', BaseBadget);
app.component('BaseStoreDot', BaseStoreDot);
app.component('BaseSwitch', BaseSwitch);
app.component('BaseTable', BaseTable);
app.component('BaseRightDialog', BaseRightDialog);
app.component('BaseFileUpload', BaseFileUpload);
app.component('BaseDatePicker', BaseDatePicker);
app.component('BasePopup', BasePopup);
app.component('BaseDefineStore', BaseDefineStore);
app.component('BaseMetricsBox', BaseMetricsBox);
app.component('BaseInfoGroup', BaseInfoGroup);
app.component('BaseColorPicker', BaseColorPicker);
app.component('BaseInputCode', BaseInputCode);
app.component('VOtpInput', VOtpInput);
app.component('BaseInputRange', BaseInputRange);
app.component('BaseRange', BaseRange);
app.component('BaseDragDrop', BaseDragDrop);

//IMPORT USE TOAST
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
app.use(Toast);

//TOOLTIP BOOTSTRAP
import { tooltip } from "@/tooltip";
app.directive('tooltip', tooltip)

//IMPORT APEX CHARTS
app.use(VueApexCharts);

//INIT
app.component('FontAwesomeIcon', FontAwesomeIcon)
.mount('#app')


//BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
