<template>
    <div class="box_content">
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Comissão</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <template v-if="managers.managers || managers.invitations">
                    <tbody>
                        <tr v-for="(row, key) in managers.managers" :key="key">
                            <td>{{ row.name }}</td>
                            <td>{{ row.comission }}%</td>
                            <td>{{ row.status }}</td>
                            <td class="actions">
                                <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editManager(row.id)"><font-awesome-icon icon="fas fa-edit" /></span>
                                <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(row.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                            </td>
                        </tr>
                        <tr v-for="(row, key) in managers.invitations" :key="key">
                            <td>{{ row.email }}</td>
                            <td>{{ row.comission }}%</td>
                            <td>{{ row.status }}</td>
                            <td class="actions">
                                <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopupInvite(row.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                            </td>
                        </tr> 
                    </tbody>
                </template>
            </table>
            <h6 v-if="!managers.managers && !managers.invitations" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
        </div>
        <div style="text-align:center;" class="p-4 divisor_top">
            <router-link :to="'/stores/' + store_id + '/managers/new'"> 
                <BaseButton class="btn-primary btn-lg">
                    Adicionar
                </BaseButton>
            </router-link>
        </div>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover este gerente?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteManager" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { computed, shallowRef, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useGlobalStore } from "@/stores/global";
import { useStoreStore } from '@/stores/store';
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const storeStores = useStoreStore();
const { managers } = storeToRefs(storeStores);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

let managerToDelete = null;
let inviteToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null);
const isLoading = ref(false);

async function deleteManager(){
    if(!isDeleting && !isLoading.value && managerToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await storeStores.tryDeleteManager(store_id.value, managerToDelete).then(() => {
            storeStores.tryManagers.then(() =>{
                router.push('/stores/' + store_id.value + '/managers')
                globalStore.loader('hide');
                isDeleting = false;
                confirmAction.value = null;
            })
            isLoading.value = false;
            excludeTextConfirm.value = null;
        }); 
    } else if(!isDeleting && inviteToDelete){
        isDeleting = true;
        globalStore.loader('show');
        await storeStores.tryDeleteManagerInvite(store_id.value, inviteToDelete).then(() => {
            storeStores.tryManagers().then(() =>{
                router.push('/stores/' + store_id.value + '/managers')
                globalStore.loader('hide');
                isDeleting = false;
                confirmAction.value = null;
            })
            isLoading.value = false;
            excludeTextConfirm.value = null;
        }); 
    }
}

function editManager(id){
    globalStore.loader('show')
    storeStores.tryManager(id).then(() =>{
    router.push('/stores/' + store_id.value + '/managers/' + id).then(() =>{
        globalStore.loader('hide')
    })
    })
}

function openPopup(id) {
    managerToDelete = id
    confirmAction.value = true
}

function openPopupInvite(id) {
    inviteToDelete = id;
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

</script>