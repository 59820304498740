<template>
    <form @submit.prevent="submit" class="row">
        <BaseInput
            label="Nome completo"
            type="text"
            id="name"
            v-model="register.name"
            :page="route.name"
            login
            class="col-12"
        />
        <BaseInput
            label="E-mail"
            type="text"
            id="email"
            v-model="register.email"
            :page="route.name"
            login
            class="col-12"
        />
        <BaseInput
            label="Senha"
            type="password"
            id="password"
            v-model="register.password"
            :page="route.name"
            login
            class="col-md-6"
        />
        <BaseInput
            label="Telefone"
            type="tel"
            id="phone"
            v-model="register.phone"
            :page="route.name"
            mask="['(##) ####-####', '(##) #####-####']"
            login
            class="col-md-6"
        />
        <BaseInput
            label="CPF"
            type="text"
            id="cpf"
            v-model="register.cpf"
            :page="route.name"
            :mask="'###.###.###-##'"
            login
            class="col-md-6"
        />
        <BaseInput
            label="Data de nascimento"
            type="text"
            id="birthdate"
            v-model="register.birthdate"
            :page="route.name"
            :mask="'##/##/####'"
            login
            class="col-md-6"
        />
        <BaseSelect 
            label="Sua atividade"
            placeholder="Selecione" 
            :items="act" 
            v-model="register.act" 
            id="act"
            :page="route.name"
            login
            class="col-md-6"
        />
        <BaseInput
            label="Seu site"
            type="text"
            id="website"
            v-model="register.website"
            :page="route.name"
            login
            class="col-md-6"
        />
        <BaseInput
            label="Como ele conheceu a Fullsale?"
            :textarea="true"
            id="met"
            v-model="register.met"
            :page="route.name"
            login
            class="col-md-12"
        />
        <div class="col-12" id="terms_input">
            <BaseSwitch
                id="terms"
                label='Confirmo que li e concordo com os <a target="_blank" href="https://fullsale.com.br/termos-de-uso">Termos de Uso</a> e confirmo que li e entendi a <a target="_blank" href="https://fullsale.com.br/politica-de-privacidade">Política de Privacidade</a>.'
                :page="route.name" 
                v-model:checked="register.terms"  
                labelWithHtml
            />
        </div>

        <div class="text-end">
            <router-link to="/login">Já tem cadastro?</router-link>
        </div>

        <BaseButton 
            class="mt-4 btn-primary w-100"
            :loading="isLoading"
            :disabled="isLoading">
            Continuar
        </BaseButton>
    </form>
</template>
<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useAuthStore } from '@/stores/auth';
import { useGlobalStore } from '@/stores/global';
import { useRoute } from "vue-router";
import { storeToRefs } from 'pinia';

const act = ref({
    'Produtor': 'Produtor',
    'Afiliado': 'Afiliado'
})

const authStore = useAuthStore();
const global = useGlobalStore();
const route = useRoute();
const { register } = storeToRefs(authStore);

const isLoading = ref(false);

const emit = defineEmits(["code_required"]);

async function submit() {
    isLoading.value = false;

    // eslint-disable-next-line
    grecaptcha.ready(() => {
        isLoading.value = true;
        // eslint-disable-next-line
        grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {
            authStore.register.recaptcha_token = token;
            authStore.register.pre_register = true;

            let params = ['name', 'email', 'password', 'phone', 'cpf', 'birthdate', 'terms', 'pre_register', 'recaptcha_token', 'met', 'act', 'website'];
            authStore.tryRegister(route.name, params).then(function(r) {
                isLoading.value = false;
                if(r == true) {
                    emit('code_required');
                }
            });
        });
    });
}

onMounted(() => {
    delete global.errors[route.name];
    document.getElementById('app').style.height="auto"
    document.getElementById('app').style.minHeight="100%"
});

onUnmounted(() => {
    document.getElementById('app').style.height="100%"
    document.getElementById('app').style.minHeight="auto"
})
</script>

<style scoped>
.form-check-label {
    vertical-align: middle;
    font-size: 13px;
    display: flex;
}
.form-check-input {
    width: 100%;
    height: 20px;
    max-width: 20px;
    margin-right: 10px;
}
</style>