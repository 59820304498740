<template>
  <div class="box">
    <div class="p-4">
      <h5>Dados bancários</h5>
      <p class="mb-0">A conta deve pertencer ao titular do cadastro.</p>
    </div>

    <div class="divisor_top"></div>

    <div class="p-4">
      <div class="row">

        <BaseSelect
          class="col-md-6"
          label="Banco"
          type="text"
          id="bank_account_code"
          :items="banksList"
          v-model="completion.bank_account_code"
          :page="route.name"
        />

        <BaseSelect
          class="col-md-6"
          label="Tipo de conta" type="text"
          id="bank_account_type"
          maxlength="150"
          :items="accountTypes"
          v-model="completion.bank_account_type"
          :page="route.name"
        />

        <BaseInput
          class="col-md-4"
          label="Agência"
          type="text"
          id="bank_account_agency"
          maxlength="8"
          v-model="completion.bank_account_agency"
          :page="route.name"
        />

        <BaseInput
          class="col-md-4"
          label="Conta" type="text"
          id="bank_account_number"
          maxlength="10"
          v-model="completion.bank_account_number"
          :page="route.name"
        />

        <BaseInput
          class="col-md-4"
          label="Dígito da conta"
          type="text" id="bank_account_number_digit"
          maxlength="1"
          v-model="completion.bank_account_number_digit"
          :page="route.name"
        />

        <BaseSelect
          class="col-md-6 col-12"
          label="Tipo de Chave Pix"
          type="text"
          id="pix_type"
          :items="pixKeyTypes"
          v-model="completion.pix_type"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6 col-12"
          label="Chave Pix"
          type="text"
          id="pix_key"
          v-model="completion.pix_key"
          :page="route.name"
        />

      </div>

    </div>
  </div>

  <div class="space"></div>

  <div class="actions">
    <div class="container d-flex justify-content-between">
      <router-link to="/account/completion/address">
        <BaseButton class="btn-white btn-lg"><font-awesome-icon class="mx-2" icon="fa-solid fa-arrow-left-long" /> Voltar
        </BaseButton>
      </router-link>
      <router-link to="/account/completion/documents">
        <BaseButton class="btn-primary btn-lg">Continuar <font-awesome-icon class="mx-2"
            icon="fa-solid fa-arrow-right-long" /></BaseButton>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useAccountStore } from '@/stores/account'
import { storeToRefs } from 'pinia';
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';

const accountStore = useAccountStore();
const { completion } = storeToRefs(accountStore);

const route = useRoute();

const accountTypes = {
  C: 'Corrente',
  P: 'Poupança',
};

const pixKeyTypes = {
  CNPJ: 'CNPJ',
  CPF: 'CPF',
  TELEFONE: 'TELEFONE',
  EMAIL: 'E-MAIL',
  CHAVE_ALEATORIA: 'CHAVE ALEATORIA',
};

const banksList = ref({})

onBeforeMount(() => {
  if(!accountStore.completion.type){
    accountStore.completion.type = 'J';
  }

  for (let i = 0; i < accountStore.all_banks.length; i++) {
    banksList.value[parseInt(accountStore.all_banks[i].code)] = accountStore.all_banks[i].name
  }
})
</script>