<template v-if="!!loadingPage">
    <AuthBase :title="header.title" :subtitle="header.subtitle">
        <component :is="loadedComponent" :code="forgotPasswordCode" />
    </AuthBase>
</template>

<script setup>
import AuthBase from "@/components/includes/auth/AuthBase.vue";
import ForgotPassword from "@/components/includes/auth/forgot-password/ForgotPassword.vue";
import ForgotPasswordCode from "@/components/includes/auth/forgot-password/ForgotPasswordCode.vue";
import { onBeforeMount, ref, shallowRef, computed } from "vue";

const header = ref({
    title: 'Recuperar senha',
    subtitle: 'Digite seu e-mail para iniciar.'
});

const loadedComponent = shallowRef(ForgotPassword);

import { useRoute } from 'vue-router';
const route = useRoute();

const forgotPasswordCode = computed(function () {
    return route.params.code;
});

import { useAuthStore } from '@/stores/auth';
const auth = useAuthStore();

const loadingPage = ref(true);

onBeforeMount(async () => {
    if(forgotPasswordCode.value) {
        let r = await auth.tryCheckResetPasswordCode(forgotPasswordCode.value);
        if(r) {
            header.value = {
                title: "Redefinição senha",
                subtitle: "Digite seu e-mail e uma nova senha.",
            };
            loadedComponent.value = ForgotPasswordCode; 
        }
    }
    loadingPage.value = false;
});
</script>
