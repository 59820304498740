<template v-if="isLoaded.value = true">
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <header>
            <font-awesome-icon icon="fa-solid fa-pen-field" style="color: #215eda;"/>
            <span v-if="route.params.slug != 'new'"  class="ms-2 align-middle">Editar campo personalizado</span>
            <span v-else class="ms-2 align-middle">Novo campo personalizado</span>
        </header>
        <div class="content">
        <form @submit.prevent="">
            <div class="row">
                <BaseInput
                    class="col-12"
                    id="title"
                    label="Título *"
                    type="text"
                    v-model="personalized_field.title"
                    :page="route.name"
                />
                <BaseSelect
                    class="col-12"
                    id="type"
                    label="Tipo "
                    :items="typeList"
                    v-model="personalized_field.type"
                    :page="route.name"
                />
                <BaseSwitch
                    id="required"
                    class="col-12"
                    label='Campo obrigatório'
                    :page="route.name" 
                    v-model:checked="personalized_field.required"
                />
            </div>
            <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                Salvar
            </BaseButton>
        </form>
        </div>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { personalized_field }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false)
const isLoading = ref(false);

const product_id = computed (function () {
    return route.params.id;
});

const typeList = ref({
    'text': 'Texto',
    'date': 'Data',
    'hour': 'Horario',
    'cpf': 'CPF',
    'cnpj': 'CNPJ',
    'rg': 'RG',
    'email': 'E-mail',
})

onBeforeMount(() =>{
    delete globalStore.errors[route.name];
    if(route.params.slug != 'new'){
        if(productStore.personalized_field.id == undefined){
            globalStore.loader('show')
            productStore.tryField(route.params.slug).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true
            })
        } else {
            isLoaded.value = true
        }
    }else {
        globalStore.cleanObject(productStore.personalized_field);
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
    
        globalStore.loader('show')
        if(route.params.slug != 'new'){
            let params = ['title', 'type', 'required'];

            productStore.tryUpdateField(route.params.slug, params, route.name).then(function(success) {
                if(success){
                    productStore.tryFields().then(function(){
                        globalStore.loader('hide');
                        router.push('/products/' + product_id.value + '/custom-fields')
                    })
                    isLoading.value = false;
                }
            });
        } else {
            let params = ['title', 'type', 'required'];

            productStore.tryCreateField(params, route.name).then(function(success){
                if(success){
                    productStore.tryFields().then(function() {
                        globalStore.loader('hide');
                        router.push('/products/' + product_id.value + '/custom-fields')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    }
}

</script>