<template v-if="!!loadingPage">
    <AuthBase :title="header.title" :subtitle="header.subtitle">
        <component :is="loadedComponent" @code_required="changeComponent"/>
    </AuthBase>
</template>
<script setup>
import AuthBase from '@/components/includes/auth/AuthBase.vue';
import TheRegister from '@/components/includes/auth/register/TheRegister.vue';
import TheRegisterCode from '@/components/includes/auth/register/TheRegisterCode.vue';
import { ref, shallowRef, computed, onMounted, onBeforeMount } from "vue";

const header = ref({
    title: "Seja bem vindo",
    subtitle: "Preencha seus dados para se cadastrar.",
});

import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

const recommendationConde = computed(function () {
    return route.params.code;
});

import { useAuthStore } from '@/stores/auth';
const auth = useAuthStore();

const loadingPage = ref(true);

onBeforeMount(async () => {
    if(recommendationConde.value) {
        await auth.trySaveRecommendationCode(recommendationConde.value);
        router.push('/register');
    }
    loadingPage.value = false;
});

onMounted(() =>{
    window.fbq('track', 'PageView');
    window.fbq('track', 'Contact');

    //Google ADS
    window.gtag('js', new Date());
    window.gtag('config', 'AW-16765752575');

    //Analytics
    window.gtag('js', new Date());
    window.gtag('config', 'G-09R8X06YY1');
})

const loadedComponent = shallowRef(TheRegister);

function changeComponent() {
    header.value = {
        title: "Enviamos um código para seu e-mail",
        subtitle: "Digite o código recebido para continuar o cadastro.",
    };
    loadedComponent.value = TheRegisterCode;
}
</script>