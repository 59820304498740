import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useCoproductorStore = defineStore('coproductors', {

    state: () => ({
        coproductors: {},
        paginate: {},
        coproductor: {},
        invitations:{},
        affiliations: {},
    }),

    actions: {

        async tryCoproductors(params = null) {
            try {
                const response = await Api.get('coproductors', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.coproductors = response.data.data;
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCoproductor(id){
            try{
                const response = await Api.get('coproductors/' + id)
                this.coproductor = response.data.data;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateCoproductor(id, params){
            try{
                await Api.put('coproductors/' + id , params, { headers: {'Content-Type': 'application/json'} });

                useGlobalStore().successMessage('Coprodutor atualizado com sucesso');
                this.tryCoproductors();
                this.router.push('/coproductors')
            }catch(error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryDeleteCoproductor(id) {
            try {
                await Api.delete('coproductors/' + id);
                await this.tryCoproductors();
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryInvitations(params = null) {
            try {
                const response = await Api.get('coproductors/invitations', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.invitations = response.data.data;
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateInvitation(params) {
            const formData = new FormData();
            for (let key in params) {
                if(params[key]){
                    formData.append(key, params[key]);
                }
            }
            try {
                await Api.post('coproductors/invitations/', formData);
                await this.tryInvitations();
                useGlobalStore().successMessage('Convite enviado com sucesso');
                await this.router.back();
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryDeleteInvitation(id){
            try {
                await Api.delete('coproductors/invitations/' + id);
                useGlobalStore().successMessage('Convite deletado com sucesso');
                await this.tryInvitations();
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCoproductorAccept(code){
            try {
                await Api.get('invitation/coproductor/' + code);
                useGlobalStore().successMessage('Convite aceito com sucesso');
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryReferrals(params = null) {
            try {
                const response = await Api.get('coproductors/products', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.affiliations = response.data.data;
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

    }
});