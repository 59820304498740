<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar>
            <div class="item action btn-group">
            <baseButton @click="submit" class="btn-primary w-100"> Salvar </baseButton>
            </div>
        </BaseHeader>
        <form class="pt-2">
            <div class="box_content">
                <div class="px-4 pt-2 pb-4">
                    <div class="row mt-1">
                        <BaseInput
                            class="col-md-4 col-12 mt-2 input_focus"
                            label="Nome *"
                            type="text"
                            id="user_name"
                            v-model="user.name"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-4 col-12 mt-2"
                            label="E-mail *"
                            type="email"
                            id="email"
                            disabled="disabled"
                            v-model="user.email"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-4 col-12 mt-2 input_focus"
                            label="Senha *"
                            type="password"
                            id="user_password"
                            v-model="user.user_password"
                            :page="route.name"
                        />
                        <div class="col-md-12 mt-3">
                            <BaseSwitch
                            label="Habilitar autenticação 2F"
                            id="auth_2f"
                            v-model:checked="user.auth_2f"
                            :page="route.name"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useGlobalStore } from '@/stores/global'

const userStore = useUserStore()
const { user } = storeToRefs(userStore);

const globalStore = useGlobalStore();

const route = useRoute();

onBeforeMount(() =>{
    userStore.tryUser()
})

function submit(){
    globalStore.loader('show')
    userStore.user['user_name'] = userStore.user.name;
    let params = ['user_name', 'email', 'user_password', 'auth_2f']

    userStore.tryUpdateUser(route.name, params).then(() =>{
        globalStore.loader('hide')
    })
}

const title = { title: "Dados de acesso ", icon: "fa-user" };
</script>

<style scoped>
.main_title_icon {
    height: 82px;
}

.header_actions {
    float: right;
    padding-top: 16px;
    margin-right: 1px;
    vertical-align: middle;
    text-align: center;
}

.header_actions .btn {
    border-radius: 25px;
    padding: 7px;
    min-width: 104px;
    height: 37px;
    width: 100%;
}

:deep(.form-control:focus) {
    color: #495057;
    background-color: #fff;
    border-color: #b6b6b6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 11%);
}

@media screen and (max-width: 768px) {
    .title h2 {
        width: 100%;
        text-align: center;
    }
    .p-4 {
        padding: 1.2rem !important;
    }

    .pl-4,
    .px-4 {
        padding-left: 1.2rem !important;
    }

    .pr-4,
    .px-4 {
        padding-right: 1.2rem !important;
    }

    .header_actions {
        float: none;
        padding: 0px;
    }

    .main_title_icon {
        height: 126px;
    }
}
</style>