<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <template v-if="order.id && params.title != undefined">
            <header>
                <span v-if="IdForm != 'new'" class="me-2 align-middle">Editar arquivo</span>
                <span v-else class="me-2 align-middle">Novo arquivo</span>
            </header>
            <div class="content">
            <div class="row">
                <BaseInput
                    class="col-12"
                    label="Título *"
                    id="title"
                    :page="page"
                    v-model="params.title"
                />

                <BaseInput
                    class="col-12"
                    label="Descrição"
                    id="description"
                    :page="page"
                    textarea
                    v-model="params.description"
                />

                <BaseFileUpload
                    class="col-12"
                    label="Arquivo *"
                    type="file"
                    id="file"
                    :page="page"
                    v-model="params.file"
                />

                <div v-if="IdForm != 'new'" class="col-12">
                    <a class="mt-2 mb-2 p-3 lesson_file" target="_blank" :download="oldFile.name" :href="oldFile.url">
                        <div class="d-flex">
                            <font-awesome-icon icon="fas fa-memo" class="me-3" />
                            <div>
                                <h5>{{oldFile.name}}</h5> <span>{{oldFile.size}}</span>
                            </div>
                        </div>
                    </a>
                </div>

                <BaseInput
                    class="col-12"
                    label="Expiração do arquivo (dias)"
                    id="expiration"
                    :page="page"
                    v-model="params.expiration"
                    mask="####"
                    type="tel"
                />

                <BaseSwitch
                    label="Enviar e-mail"
                    id="send_mail"
                    :page="page"
                    v-model:checked="params.send_mail"
                />

                <div class="form-group col-12">
                    <BaseButton :loading="isLoading" @click="sendForm" class="btn-primary w-100" :disabled="isLoading">Salvar</BaseButton>
                </div>
            </div>
            </div>
        </template>
    </BasePopup>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue';

import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();

let previousPage = router.options.history.state.back;
const IdForm = computed (function () {
    return route.params.file;
});

const page = ref('orderFormFIle');

import { storeToRefs } from 'pinia';
import { useOrderStore } from '@/stores/order';
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

import { useGlobalStore } from '@/stores/global';
const global = useGlobalStore();

const params = ref({});
const oldFile = ref(null);

onBeforeMount(() => {
    delete global.errors[page.value];

    if(IdForm.value != 'new') {
        let found = false;
        Object.keys(order.value.files).forEach((key) => {
            if(order.value.files[key].id == IdForm.value) {
                params.value = {
                    id: order.value.files[key].id,
                    title: order.value.files[key].title,
                    description: order.value.files[key].description,
                    file: order.value.files[key].file,
                    expiration: order.value.files[key].expiration,
                    send_mail: false,
                }
                oldFile.value = order.value.files[key].file;
                found = true;
            }
        });
        if(found == false) {
            router.push('/404');
        }
    }else {
        params.value = {
            title: '',
            description: '',
            file: null,
            expiration: '',
            send_mail: false,
        }
    }

});

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

const isLoading = ref(false);
async function sendForm() {
    if(isLoading.value) {
        return;
    }

    let errors = false;

    if(!params.value.title) {
        global.localErrors('title', 'Campo obrigatório', page.value);
        errors = true;
    }

    if(!params.value.file && IdForm.value == 'new') {
        global.localErrors('file', 'Campo obrigatório', page.value);
        errors = true;
    }

    if(errors) {
        return;
    }

    isLoading.value = true;
    if(IdForm.value != 'new') {
        await orderStore.tryUpdateFile(page.value, order.value.id, params.value.id, params.value);
    }else {
        await orderStore.tryCreateFile(page.value, order.value.id, params.value);
    }
    isLoading.value = false;
}
</script>

<style scoped>

header{
    width: 100%;
    display: block!important;
    margin: 0;
    padding-top: 6px;
    font-size: 16px;
    color: #646464;
    font-weight: 600;
}

.lesson_file {
  background: #f4f4f4;
  border-radius: 8px;
  display: inline-block;
  border: 1px #ced4da solid;
  color: #595959 !important;
  text-decoration: none !important;
}

.lesson_file:hover {
  background-color: #e2e2e2;
}

.lesson_file h5 {
  font-size: 14px;
  margin-bottom: 2px;
}

.lesson_file span {
  opacity: 0.7;
  font-weight: 400;
}

.lesson_file svg {
  font-size: 32px;
  margin-top: 5px;
}

.lesson_file p {
  padding-right: 20px;
}
</style>