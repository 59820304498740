<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
        </BaseHeader>
        <BaseFilter removeSearchBar>
            <div class="item action btn-group">
                <BaseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </BaseButton>
                <div class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <FilterReferrals @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty"/>
                </div>
                <BaseButton class="btn-primary w-100 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    Exportar
                </BaseButton>
                <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'csv' + exportParams">CSV</a>
                    <a class="dropdown-item d-flex justify-content-center flex-column" target="_blank" :href="exportUrl + 'xls' + exportParams">XLS</a>
                </div>
            </div>
        </BaseFilter>
        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="referrals.length"  @changePage="changePage">
            <tr v-for="(referral, key) in reportStore.referrals" :key="key">
                <td>{{ referral.name }}</td>
                <td>{{ referral.clicks }}</td>
                <td>{{ referral.orders }}</td>
                <td>{{ conversion(referral.clicks, referral.orders) }}%</td>
                <td>{{ globalStore.formatMoney(referral.comission) }}</td>
                <td>{{ globalStore.formatMoney(referral.amount) }}</td>
                <td class="actions">
                    <a :href="'/reports/referrals/' + referral.code" @click.prevent="viewReferralReport(referral.code, referral.name)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Visualizar">
                        <font-awesome-icon icon="fas fa-eye" />
                    </a>
                </td>
            </tr>
        </BaseTable>
    </div>
</template>
<script setup>
const title = {title: 'Relatório: Meus afiliados', icon: 'fa-chart-simple'};
import FilterReferrals from '@/components/includes/reports/ReportsFilter';

import { ref, onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';
import qs from "qs";

import { useReportStore } from '@/stores/report';
const reportStore = useReportStore();
const { referrals, paginate } = storeToRefs(reportStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const tableColumns = ['Afiliado', 'Sessões', 'Vendas', 'Conversão', 'Comissão', 'Valor vendido'];

const isLoading = ref(false);

import { useRouter, useRoute } from 'vue-router';
import BaseFilter from '@/components/ui/BaseFilter.vue';
const route = useRoute();
const router = useRouter();

let initialFilters = null;

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('report_referrals')) {
        return router.push('/403');
    }

    if(reportStore.referrals.length == undefined){
        isLoading.value = true;
    }

    if(route.query.page || route.query.date_start || route.query.date_end) {
        Object.keys(reportStore.referrals_rep_filters).forEach((key) => {
            let currentThing = route.query[key];
            reportStore.referrals_rep_filters[key] = '';
            reportStore.referrals_rep_filters[key] = currentThing;
            
            initialFilters = reportStore.referrals_rep_filters;
            let param = route.query[key];
            reportStore.referrals_rep_filters[key] = (param == undefined) ? null : param;

        });
    }


    loadReferrals().then(() => {
        isLoading.value = false;
    });

    if(reportStore.referrals_rep_filters.date_start && reportStore.referrals_rep_filters.date_end){
        initialFilters = {};
        initialFilters['date_range'] = reportStore.referrals_rep_filters.date_start + '@' + reportStore.referrals_rep_filters.date_end;
    }
});


let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    reportStore.referrals_rep_filters.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadReferrals().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}
// eslint-disable-next-line
const exportUrl = ref(process.env.VUE_APP_API_URL + 'reports/referrals/export/');

const exportParams = ref('');

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        reportStore.referrals_rep_filters.page = null;
        globalStore.loader('show');
        loadReferrals().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

async function loadReferrals() {
    let usedParams = {};
    
    if(initialFilters){
        initialFilters.date_range = null
    }
    
    Object.keys(reportStore.referrals_rep_filters).forEach((key) => {
        if(reportStore.referrals_rep_filters[key] != null && reportStore.referrals_rep_filters[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: reportStore.referrals_rep_filters[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    exportParams.value = (usedParams) ? '?' + qs.stringify(usedParams, { arrayFormat: "brackets" }) : '';
    await reportStore.tryReferrals(reportStore.referrals_rep_filters);
    globalStore.loader('hide');
}

const totalFilters = ref(0);

function filtersQty(r) {
    totalFilters.value = r;
}

function filters(r) {
    Object.keys(r).forEach((key) => {
        reportStore.referrals_rep_filters[key] = r[key];
    });
    globalStore.loader('show');
    loadReferrals();
}

async function viewReferralReport(id, name){
    globalStore.loader('show');
    reportStore.referral = name;
    await reportStore.tryReferralMetrics(id);
    await reportStore.tryReferralChart(id);
    router.push('/reports/referrals/' + id);
}

function conversion(clicks, orders) {
    if(!clicks || !orders) {
        return 0;
    }
    return (Math.round(orders * 100) / clicks).toFixed(2);
}

</script>

<style scoped>
.base-title .action {
    flex: 0 0 225px!important;
}

.btn-filter{
    margin-right: 10px;
}

.btn-filter::after {
    display: none !important;
}

</style>