<template>
    <div v-if="methodsLoaded != false">
        <div v-if="route.params.id"  class="container px-2 px-lg-4">
            <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
                <div class="item action btn-group">
                    <router-link to="" class="btn-primary w-100">
                        <baseButton class="btn-primary w-100" @click="submit" :disabled="isLoading"> Salvar </baseButton>
                    </router-link>
                </div>
            </BaseHeader>
            <div>
                <div class="box_content p-4">
                    <form @submit.prevent="">
                        <div class="row">
                            <BaseInput
                                class="col-md-6 col-12"
                                label="Título *"
                                type="text"
                                id="title"
                                :required="true"
                                v-model="campaign.title"
                                :page="route.name"
                            />
                            <BaseSelect
                                v-if="previousPage.value == '/campaigns' || previousPage.value == null"
                                class="col-md-6 col-12"
                                label="Destino *"
                                type="text"
                                id="id_destination"
                                :items="destinationItems"
                                :required="true"
                                :page="route.name"
                                :multiList="true"
                                @emitListType="setDestinationType"
                            />
                            <BaseSelect
                                v-else
                                class="col-md-6 col-12"
                                label="Destino *"
                                type="text"
                                id="id_destination"
                                :items="destinationItems"
                                :required="true"
                                :page="route.name"
                                :multiList="true"
                                @emitListType="setDestinationType"
                            />
                            <BaseInput
                                class="col-12"
                                label="UTM Source"
                                type="text"
                                id="utm_source"
                                v-model="campaign.utm_source"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-12"
                                label="UTM Medium"
                                type="text"
                                id="utm_medium"
                                v-model="campaign.utm_medium"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-12"
                                label="UTM Campaign"
                                type="text"
                                id="utm_campaign"
                                v-model="campaign.utm_campaign"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-12"
                                label="UTM Term"
                                type="text"
                                id="utm_term"
                                v-model="campaign.utm_term"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-12"
                                label="UTM Content"
                                type="text"
                                id="utm_content"
                                v-model="campaign.utm_content"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-12"
                                label="SRC"
                                type="text"
                                id="src"
                                v-model="campaign.src"
                                :page="route.name"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <BaseDefineStore v-else :create_view="'campaigns'"  @loadMethods="loadMethods"/>
    </div>
</template>
<script setup>
import { onBeforeMount, ref} from "vue"
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from "vue-router";
import { useCampaignStore } from '@/stores/campaign';
import { useGlobalStore } from '@/stores/global';

const title = { title: "Nova campanha", icon: "fa-bullhorn" };

const router = useRouter();
const route = useRoute();

const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore)

const campaignStore = useCampaignStore();
const { campaign } = storeToRefs(campaignStore);

const previousPage = ref(null);

const destinationItems = ref({
    pages: {},
    products: {},
})

const isLoading = ref(false)
var methodsLoaded = ref(false)

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('new_campaign')) {
        return router.push('/403');
    }
    delete globalStore.errors[route.name];
    previousPage.value = router.options.history.state.back;

    if(previousPage.value == '/campaigns' || previousPage.value == null){
        globalStore.cleanObject(campaignStore.campaign);
    }
    
    if(route.params.id){
        methodsLoaded.value = true;
        loadMethods(route.params.id)
    } else if(defaultStore._object.defaultStore.id != undefined){
        methodsLoaded.value = true;
        loadMethods(defaultStore._object.defaultStore.id)
        router.push('/campaigns/create/store=' + defaultStore._object.defaultStore.id)
    } else{
        methodsLoaded.value = true
    }
})


function loadMethods(id){
    campaignStore.campaign.id_store = route.params.id;

    campaignStore.tryDestination(id).then(() =>{
        destinationItems.value.pages = campaignStore.destination_pages
        destinationItems.value.products = campaignStore.destination_products
        setTimeout(() =>{
            loadSelect();
        }, 50)

    })
}

function loadSelect(){
    if(previousPage.value != '/campaigns' && previousPage.value != null){
        for(let index = 0; index < campaignStore.destination_products.length; index++){
            if(campaignStore.destination_products[index].id == campaignStore.campaign.id_destination){
                setTimeout(() =>{
                    document.querySelector('#id_destination').value = campaignStore.destination_products[index].title
                },300)
            }  
        }
    }
}

function setDestinationType({typeValue, itemId}){
    campaignStore.campaign.type_destination = typeValue;
    campaignStore.campaign.id_destination= itemId;
}

async function submit() {
    if(!isLoading.value){
        useGlobalStore().loader('show');
        isLoading.value = true;

        if(campaignStore.campaign.id_store === undefined){
            campaignStore.campaign.id_store = route.params.id;
        }

        let params = ['title', 'id_destination', 'type_destination', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'src', 'id_store'];

        campaignStore.tryCreateCampaign(params, route.name).then(function(success) {
            globalStore.loader('hide');

            if(success){
                campaignStore.tryCampaign(campaignStore.campaign.id).then(() =>{
                    router.push('/campaigns/' + campaignStore.campaign.id);
                })
                isLoading.value = false;
            } else{
                isLoading.value = false;
            }
        })
        useGlobalStore().loader('hide');
    }
}   
</script>