<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <div>
            <header>
                <font-awesome-icon icon="fa-solid fa-shipping-fast" style="color: #215eda;"/>
                <span v-if="route.name == 'storeShippingCompanyEdit'" class="ms-2 align-middle">Editar  transportadora</span>
                <span v-else class="ms-2 align-middle">Nova  transportadora</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseInput
                            class="col-md-6 col-12"
                            label="Nome *"
                            type="input"
                            id="title"
                            v-model="shipping_company.title"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-6 col-12"
                            label="Chave da API"
                            type="input"
                            id="api_key"
                            v-model="shipping_company.api_key"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-6 col-12"
                            label="Razão social *"
                            type="input"
                            id="social_reason"
                            v-model="shipping_company.social_reason"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-6 col-12"
                            label="CNPJ"
                            type="input"
                            id="cnpj"
                            v-model="shipping_company.cnpj"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-6 col-12"
                            label="Inscrição Estadual"
                            type="input"
                            id="state_registration"
                            v-model="shipping_company.state_registration"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-6 col-12"
                            label="Rua"
                            type="input"
                            id="street"
                            v-model="shipping_company.street"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-4 col-12"
                            label="Número"
                            type="input"
                            id="street_number"
                            v-model="shipping_company.street_number"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-4 col-12"
                            label="Bairro"
                            type="input"
                            id="neighborhood"
                            v-model="shipping_company.neighborhood"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-4 col-12"
                            label="Telefone"
                            type="input"
                            id="phone"
                            v-model="shipping_company.phone"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-6 col-12"
                            label="Cidade"
                            type="input"
                            id="city"
                            v-model="shipping_company.city"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-md-6 col-12"
                            label="Estado"
                            type="input"
                            id="state"
                            v-model="shipping_company.state"
                            :page="route.name"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </div>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global'
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false);
const isLoading = ref(false);

const globalStore = useGlobalStore();
const storeStore = useStoreStore();
const { shipping_company } = storeToRefs(storeStore);


const store_id = computed (function () {
    return route.params.id;
});

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(route.name == 'storeShippingCompanyEdit'){
        if(storeStore.shipping_company.id){
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            storeStore.tryShippingCompany(route.params.slug).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'storeShippingCompanyCreate'){
        globalStore.cleanObject(storeStore.shipping_company);
        globalStore.loader('hide')
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')

        if(route.name == 'storeShippingCompanyEdit'){

            let params = ['title', 'social_reason', 'api_key', 'cnpj', 'state_registration', 'street', 'street_number', 'neighborhood', 'phone', 'city', 'state', 'id_store'];

            storeStore.tryUpdateShippingCompany(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryShippingCompanies().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/shipping/companies')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
        else if(route.name == 'storeShippingCompanyCreate') {

            let params = ['title', 'social_reason', 'api_key', 'cnpj', 'state_registration', 'street', 'street_number', 'neighborhood', 'phone', 'city', 'state'];

            storeStore.tryCreateShippingCompany(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryShippingCompanies().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/shipping/companies')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    } 
}

</script>