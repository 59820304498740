<template>
    <div class="box_content">
        <div class="p-4">
            <form>
                <div class="row">
                    <BaseInput
                        class="col-12"
                        id="name"
                        label="Nome *"
                        type="text"
                        v-model="userdata.name"
                        @input="setValue"
                        :page="page"
                    />
                    <BaseInput
                        class="col-md-6 col-12"
                        id="email"
                        label="E-mail *"
                        type="text"
                        @input="setValue"
                        v-model="userdata.email"
                        :page="page"
                    />
                    <BaseInput 
                        class="col-md-6 col-12"
                        id="password"
                        label="Senha"
                        type="password"
                        @input="setValue"
                        v-model="userdata.password"
                    />
                </div>
            </form>
        </div>
    </div>
</template>
    
<script setup>
import { onBeforeMount, ref } from 'vue';

import { useUsersStore } from '@/stores/users';
const userStore = useUsersStore();

const emit = defineEmits(['emitUserValues']);

const props = defineProps({
    firstLoadAccess:{
        default: null,
	},
    
    pageParamsName:{
        default: null,
    },

    pageParamsEmail :{
        default: null,
    },

    pageParamsPassword :{
        default: null,
    },
})

const page = ref('usersEdit')

const userdata = ref({
    name:  null,
    email: null,
    password: null,
});

const thisFirstLoad = ref(false);

onBeforeMount (() =>{
    if(props.firstLoadAccess == true){
        thisFirstLoad.value = true;
        userdata.value.name = props.pageParamsName;
        userdata.value.email = props.pageParamsEmail;
        userdata.value.password = props.pageParamsPassword;
        setValue();
    } else {
        thisFirstLoad.value = true;
        userdata.value.name = userStore.user.name;
        userdata.value.email = userStore.user.email;
        setValue();
    }
})

function setValue(){
    thisFirstLoad.value = true;
    emit('emitUserValues', userdata, thisFirstLoad)
}

</script>
    
<style scoped>

:deep(.eye){
    display: none;
}

:deep(.eye){
   padding: 0px;
}

</style>