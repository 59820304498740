<template>
    <div class="box_content">
        <TableComissions :comissions="order.comissions" />
    </div>

    <div v-if="order.comissions.debts_paid_by_order.length" class="box_content">
        <TableDebts :comissions="order.comissions" />
    </div>

    <div v-if="order.comissions_table" class="box_content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Comissionados</th>
                        <th class="row_right">Total</th>
                    </tr>
                </thead>
                <tbody>

                    <tr  v-for="(item, key) in order.comissions_table" :key="key">
                        <td><strong class="text-uppercase">{{ item.name }}</strong> <br/><span v-if="item.type != 'fullsale'">{{ item.title }}</span>
                        <small v-if="item.assume_fees">Juros do cartão incluídos</small>
                        </td>
                        <td class="row_right"><span class="sensitive_information">{{ globalStore.formatMoney(item.comission) }}</span></td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>

</template>

<script setup>
import TableComissions from '@/components/includes/orders/TableComission';
import TableDebts from '@/components/includes/orders/TableDebts.vue';
import { storeToRefs } from 'pinia';

import { useOrderStore } from '@/stores/order';
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
</script>