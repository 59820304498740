<template>
    <apexchart
        id="line_chart"
        width="100%"
        height="300"
        type="area"
        :options="options"
        :series="series"
    ></apexchart>
</template>
  
  <script setup>
import { ref, onMounted } from "vue";
import { useHomeStore } from "@/stores/home";

const homeStore = useHomeStore();

const options = ref({
    chart: {
        id: "line_chart",
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
        parentHeightOffset: 0,
    },
    grid: {
        show: false,
    },
    xaxis: {
        categories: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
        ],
        labels: {
            style: {
                colors: "#8f8f8f",
                fontSize: "11px",
            },
        },
        axisBorder: {
            show: false,
        },
    },
    yaxis: {
        labels: {
            formatter: function (value) {
                return Math.abs(value) > 999
                    ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) +
                          "k"
                    : Math.sign(value) * Math.abs(value);
            },
            style: {
                colors: ["#8f8f8f"],
                fontSize: "11px",
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        x: {
            formatter: function (value) {
                return "Dia " + value;
            },
        },
        y: {
            formatter: function (value) {
                var amount = value;
                amount = parseFloat(value).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                });

                return amount;
            },
        },
    },
    legend: {
        show: false,
    },
    stroke: {
        width: 3,
    },
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 95, 100],
        },
    },
    colors: ["#1dccdf", "#215eda"],
});

const series = ref([
    {
        name: "Vendas",
        data: [
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
        ],
    },
    {
        name: "Vendas",
        data: [
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
        ],
    },
]);

const props = defineProps({
    chart: {},

    chart_params: {},

    chartStoreLoaded: {},
});

onMounted(() => {
    if (props.chartStoreLoaded == true) {
        series.value[0].data = homeStore.home_chart.last_month.orders;
        series.value[1].data = homeStore.home_chart.this_month.orders;
    }
});

function updateChart() {
    homeStore.tryHomeChart(props.chart_params).then(() => {
        const days = homeStore.home_chart.days;
        const this_month = homeStore.home_chart.this_month.orders;
        const last_month = homeStore.home_chart.last_month.orders;

        options.value.categories = days;

        if (last_month) {
            series.value[0].data = last_month;
        }

        if (this_month) {
            series.value[1].data = this_month;
        }
    });
}

function updateChartClear(monthPicker) {
    if (monthPicker === "thisMonth") {
        series.value[0].data = [
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
        ];
    }
    if (monthPicker === "lastMonth") {
        series.value[1].data = [
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
        ];

    }
}

defineExpose({
    updateChart,
    updateChartClear,
});
</script>

<style scoped>
#line_chart {
    padding: 0px 15px 25px 10px;
}
</style>