<template>
    <div id="content">
        <div id="container">
            <div>
                <TheLogo id="logo"/>
                <h1>403. Sem permissão.</h1>
                <h2 class="text-start">Você não tem permissão para acessar essa página</h2>
                <div class="text-end">
                    <small><router-link to="/">Página inicial</router-link></small>
                </div>
            </div>
            <div id="icon" class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
            </div>
        </div>
    </div>
</template>

<style scoped>
#content {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #f7f7f7;
    color: #414951;
}

#container {
    text-align: left;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
}

#logo {
    width: 180px;
    margin-bottom: 36px;
}

h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 13px;
}

h2 {
    max-width: 276px;
    font-weight: 400;
    font-size: 18px;
    color: #808080;
    margin: 0 auto;
    margin-top: 10px;
}

#icon {
    width: 125px;
    margin-left: auto;
    fill: #d5d5d5;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media  screen and (max-width: 900px){
    #container {
        width: 100%;
        text-align: center;
        display: block;
    }

    #icon {
        margin: 0 auto;
        margin-top: 40px;
    }
    .text-end {
        max-width: 210px;
        margin: 0 auto;
    }
}
</style>