<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" @search="search" :initialSearch="initialSearch">
            <template v-if="globalStore.hasPermissionTo('coproductor_invitation')">
                <router-link v-if="route.path === '/coproductors' || route.path === '/coproductors' " to="/coproductors/create" class="item action btn-group">
                    <BaseButton  class="btn btn-primary w-100">
                        Novo
                    </BaseButton>
                </router-link>
                <router-link v-else-if="route.path === '/coproductors/invitations' || route.path === '/coproductors/invitations/create' " to="/coproductors/invitations/create" class="item action btn-group">
                    <BaseButton  class="btn btn-primary w-100">
                        Novo
                    </BaseButton>
                </router-link>
            </template>
        </BaseHeader>
        <div class="box_content" style="margin-top: 1px">
            <nav>
                <div class="nav" id="nav-tab" role="tablist">
                    <router-link to="/coproductors" :class="[route.path === '/coproductors' ? 'active': '' || route.path === '/coproductors/create' ? 'active': '']"> 
                        Meus coprodutores 
                    </router-link>
                    <router-link to="/coproductors/invitations" :class="[route.path === '/coproductors/invitations' ? 'active': '' || route.path === '/coproductors/invitations/create' ? 'active': '']">
                        Convites
                    </router-link>
                    <router-link to="/coproductors/products"  :class="[route.path === '/coproductors/products' ? 'active': '']"> 
                        Minhas coproduções
                    </router-link>
                </div>
            </nav>
        </div>
        <router-view v-slot="{ Component }">
            <component ref="childMethod" :is="Component"/>
        </router-view>
    </div>
</template>
<script setup>
const title = {title: 'Coprodutores', icon: 'fa-user-vneck'};
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'; 
const route = useRoute();
const router = useRouter();

let initialSearch = null;

const childMethod = ref()
function search(r){
    childMethod.value.search(r);
}

const params = ref({
    'search': null,
    'page': null
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('coproductors')) {
        return router.push('/403');
    }
    if(route.query.search) {

    Object.keys(params.value).forEach((key) => {
        if(key == 'search') {
            initialSearch = route.query.search;
        }
    });
    }

});
</script>

<style scoped>
.active{
    background-color: white;
}
</style>