<template>
    <div v-if="withdrawalStore.withdrawal.code">
        <div class="container px-2 px-lg-4">
            <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
                <BaseBadget class="ms-4 mt-1" :text="withdrawalStore.withdrawal.code" color="#6f6f6f" />
                <BaseBadget class="ms-4 mt-1" :text="withdrawalStore.withdrawal.status.name" :color="withdrawalStore.withdrawal.status.color" />
            </BaseHeader>
        </div>
        <div class="container px-2 px-lg-4">
            <div class="box_content nav_div">
                <nav>
                    <div class="nav" id="nav-tab" role="tablist">
                        <router-link :to="'/withdrawals/' + route.params.id" :class="[route.path === '/withdrawals/'+ route.params.id ? 'active': '']">
                            Informações
                        </router-link>
                        <router-link :to="'/withdrawals/' + route.params.id + '/orders'" :class="[route.path === '/withdrawals/'+ route.params.id + '/orders'? 'active' : '']">
                            Pedidos
                        </router-link>
                        <router-link v-if="withdrawalStore.withdrawal.amount_canceled > 0" :to="'/withdrawals/' + route.params.id + '/debts'" :class="[route.path === '/withdrawals/'+ route.params.id + '/debts'? 'active' : '']">
                            Débitos
                            <span class="canceled_qty">{{ withdrawalStore.withdrawal.order_canceled.length }}</span>
                        </router-link>
                    </div>
                </nav>
            </div>
        </div>
        <router-view></router-view>
        <div class="container px-2 px-lg-4">
            <div class="box_content nav_div">
                <table class="table">
                    <tfoot class="border-top-0">
                        <tr>
                            <td>Subtotal</td>
                            <td>{{ globalStore.formatMoney(withdrawalStore.withdrawal.amount) }}</td>
                        </tr>
                        <tr>
                            <td>Débitos
                                <span data-bs-toggle="tooltip" data-bs-placement="right" data-bs-animation="true" v-tooltip title="Referente a pedidos que sofreram chargeback ou foram devolvidos após serem sacados." class="ms-1">
                                    <font-awesome-icon icon="fas fa-circle-info"/>
                                </span>
                            </td>
                            <td>{{ globalStore.formatMoney(withdrawalStore.withdrawal.amount_canceled) }}</td>
                        </tr>
                        <tr>
                            <td>Taxas</td>
                            <td>{{ globalStore.formatMoney(withdrawalStore.withdrawal.fees)}}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{{ globalStore.formatMoney(withdrawalStore.withdrawal.total)}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
const title = {title: 'Detalhes do saque', icon: 'fa-wallet'};
import { ref, onBeforeMount, computed } from 'vue'
import { useRoute, useRouter} from "vue-router";
const route = useRoute();
const router = useRouter();

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useWithdrawalStore } from '@/stores/withdrawal'
const withdrawalStore = useWithdrawalStore();

const withdrawal_id = computed(function (){
    return route.params.id
})

const isLoading = ref(true)
const previousPage = ref(null)

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('show_withdrawal')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;
    if(!withdrawalStore.withdrawal.code){
        withdrawalStore.tryWithdrawal(withdrawal_id.value).then(() =>{
            isLoading.value = false
            globalStore.loader('hide')
        })
    } else {
        isLoading.value = false
        globalStore.loader('hide')
    }
})
</script>

<style scoped>
:deep(.base-title .space-between){
    justify-content:left!important;
}
.canceled_qty{
    background: #d71a1a;
    padding: 2px 8px;
    font-size: 12px;

    color: #fff;
    border-radius: 25px;
    white-space: nowrap;
    position: relative;
    font-weight: 500;
    margin-left: 5px;
}
</style>