import axios from 'axios';

const Api = axios.create({
    // eslint-disable-next-line
    'baseURL': process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {"Content-Type": "multipart/form-data"},
    timeout: 30000,
    timeoutErrorMessage: 'Não foi possível realizar essa operação, tente novamente mais tarde'
});

Api.interceptors.response.use(function (r) {
    document.querySelectorAll(".tooltip").forEach(el => el.remove());
    return r;
});

export default Api;