import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useSupportStore = defineStore('support', {

    state: () => ({
        supports: {},
        paginate: {},
        support: {},
        reply: {},
    }),

    actions: {

        async trySupports(params = null) {
            try {
                const response = await Api.get('support', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.supports = response.data.data;
                this.paginate = response.data.meta;

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async trySupport(id){
            try {
                const response = await Api.get('support/' + id);
                this.support = response.data.data;
                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateSupport(id, params, page){
            let formData = useGlobalStore().getOnlyParams(params, this.reply);

            formData['_method'] = 'put';

            try {
                await Api.post('support/'+ id, formData);

                useGlobalStore().successMessage('Mensagem enviada com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryCreateSupport(params, page){

            let formData = useGlobalStore().getOnlyParams(params, this.support);

            try{
                const response = await Api.post('support/create', formData);
                this.support = response.data.data;
                
                useGlobalStore().successMessage('Suporte criado com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

    }
});