<template>
 <div class="box_content">
    <div class="row">
        <BaseSwitch 
            id="enable_summary"
            class="my-1"  
            label="Exibir resumo da compra" 
            tooltip="Exibe o resumo da compra no checkout contendo informações do produto"
            v-model:checked="product.enable_summary"
        />
        <BaseSwitch 
            id="enable_timer"
            class="switch_btn" 
            label="Timer de Escassez" 
            tooltip="Exibe temporizador de escassez do produto no topo do checkout"
            v-model:checked="product.enable_timer"
        />
        <BaseSwitch
            id="enable_notify"
            class="switch_btn" 
            label="Notificação de Escassez" 
            tooltip="Exibe notificações de pedidos recentes"
            v-model:checked="product.enable_notify"
        />
        <BaseSwitch 
            id="enable_exit_popup"
            class="switch_btn" 
            label="Exit-Intent Popup" 
            tooltip="Exibe pop-up ofertando desconto quando o usuário tentar sair do checkout"
            v-model:checked="product.enable_exit_popup"
        />
        <BaseInput 
            v-if="product.enable_exit_popup == true || product.enable_exit_popup == 1"
            id="coupon_exit_popup"
            class="col-md-5 col-12"
            label="Cupom de desconto"
            v-model="product.coupon_exit_popup"
            :page="page"
        />
    </div>
 </div>

</template>

<script setup>
const page = 'productEdit';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';
const productStore = useProductStore();
const { product } = storeToRefs(productStore);
</script>