<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
            <div id="DayPicker" class="col-md-5 col-12  metrics_amount_picker preset_picker">
                <BaseDatePicker 
                    @updateInfos="updateInsightsinfo()"
                    @clearDayPicker="clearInsightinfos()"
                    ref="childMethodClear"
                    placeholder="Este mês"
                    :hideInputIcon="false"
                    style="margin-top: 0px !important"
                    range
                    multi
                    v-model="params.date_range"
                    :storeLoaded="reportStoreLoaded"
                    :loadedDayPicker="true"
                    enablePreset
                />
            </div>
        </BaseHeader>
        <div id="SalePerHour">
            <div class="box_content d-flex">
                <BaseMetricsBox>
                    <h2 class="p-4 metrics_title">
                        <font-awesome-icon icon="fas fa-clock" />
                        Vendas por horário
                    </h2>
                    <ColumnChart v-if="hour_peak_data !== null" :graphic_data="hour_peak_data" ref="updatePeakChart"/>
                    <ColumnChart v-else ref="updatePeakChart"/>
                </BaseMetricsBox>
            </div>
        </div>
        <div class="insights_blocks row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Melhor dia</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="globalStore.formatMoney([insights.best_day ? insights.best_day.amount : 0])"
                    />
                    <p v-if="insights.best_day" class="best_day_date mt-2">{{globalStore.formatDescriptDate(insights.best_day.date)}}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Melhor dia de conversão</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="insights.best_conversion ? insights.best_conversion.conversion + '%' : '0%'"
                    />
                    <p v-if="insights.best_conversion" class="best_day_date mt-2">{{ globalStore.formatDescriptDate(insights.best_conversion.date)}}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Abandonado</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="globalStore.formatMoney([insights.abandoned ? insights.abandoned.amount : 0])"
                    />
                    <p v-if="insights.abandoned" class="best_day_date mt-2">{{ insights.abandoned.checkouts }} acesso{{ insights.abandoned.checkouts == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Bump</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="globalStore.formatMoney(insights.bump ? insights.bump.amount : 0)"
                    />
                    <p v-if="insights.bump" class="best_day_date mt-2">{{ insights.bump.orders}} venda{{ insights.bump.orders == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Cross-sell</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="globalStore.formatMoney(insights.cross_sell ? insights.cross_sell.amount : 0)"
                    />
                    <p v-if="insights.cross_sell" class="best_day_date mt-2">{{ insights.cross_sell.orders}} venda{{ insights.cross_sell.orders == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Devoluções</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="globalStore.formatMoney(insights.refunded ? insights.refunded.amount : 0)"
                        />
                    <p v-if="insights.refunded" class="best_day_date mt-2">{{ insights.refunded.orders}} venda{{ insights.refunded.orders == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Chargebacks</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="globalStore.formatMoney(insights.chargeback ? insights.chargeback.amount : 0)"
                        />
                    <p v-if="insights.chargeback" class="best_day_date mt-2">{{ insights.chargeback.orders}} venda{{ insights.chargeback.orders == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Comissão</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="globalStore.formatMoney(insights.comission ? insights.comission.amount : 0)"
                        />
                    <p v-if="insights.comission" class="best_day_date mt-2">{{ insights.comission.orders }} venda{{ insights.comission.orders == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Desktop</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="insights.device ? insights.device.desktop.amount + '%'  : '0%'"
                        />
                    <p v-if="insights.device" class="best_day_date mt-2">{{ insights.device.desktop.orders }} venda{{ insights.device.desktop.orders == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Smartphone</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="insights.device ? insights.device.phone.amount + '%' : '0%'"
                        />
                    <p v-if="insights.device" class="best_day_date mt-2">{{ insights.device.phone.orders }} venda{{ insights.device.phone.orders == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Tablet</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="insights.device ? insights.device.tablet.amount + '%' : '0%'"
                        />
                    <p v-if="insights.device" class="best_day_date mt-2">{{ insights.device.tablet.orders }} venda{{ insights.device.tablet.orders == 1 ? '' : 's' }}</p>
                    <p v-else class="best_day_date mt-2">...</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="box_content best_day py-2">
                    <h6 class="mt-3 mb-3">Sessões</h6>
                    <BaseBadget
                        color="#215eda"
                        :text="insights.sessions ? insights.sessions : '0'"
                        />
                    <p class="best_day_date mt-2">...</p>
                </div>
            </div>
        </div>
    </div>
</template>x

<script setup>
const title = {title: 'Insights', icon: 'fa-chart-simple'};
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useReportStore } from '@/stores/report';
import ColumnChart from '@/components/includes/reports/ColumnChart';

const route = useRoute();
const router = useRouter();

const globalStore = useGlobalStore();

const reportStore = useReportStore();
const { insights } = storeToRefs(reportStore);

const updatePeakChart = ref(null);
const hour_peak_data = ref(null);
const reportStoreLoaded = ref(false)
const childMethodClear = ref(null);
const todayDate = ref(null);
var initialFilters = null;

const today = new Date();

const params = ref({
    date_range: null,
});

const infoparams = ref({
    date_start: null,
    date_end: null,
});

onBeforeMount(() =>{
    if(route.query){
        params.value = route.query
    }

    if(route.query.date_start && route.query.date_end){
        params.value.date_range = route.query.date_start + '@' + route.query.date_end
    }
    
    setTodayDate();
})

onMounted(() =>{
    if(!params.value.date_range){
        reportStore.tryInsights(infoparams.value).then(() =>{
            globalStore.loader('hide')
            reportStoreLoaded.value = true;
            hour_peak_data.value = reportStore.insights.hourly_orders;
            setTimeout(() =>{
                updatePeakChart.value.updateChart();
            }, 100)
        })
    } else{
        reportStoreLoaded.value = true;
        updateInsightsinfo();
    }
    if(initialFilters){
        childMethodClear.value.resizeDayPicker();
        initialFilters.date_range = null
    }
})

function updateInsightsinfo() {
    if(params.value.date_range){
        globalStore.loader('show')
        if(typeof params.value.date_range === 'string'){
            infoparams.value.date_start = params.value.date_range.split('@')[0];
            infoparams.value.date_end = params.value.date_range.split('@')[1];
        }else{
            infoparams.value.date_start = params.value.date_range[0]
            infoparams.value.date_end = params.value.date_range[1]
        }
        let getInput = document.querySelector('.metrics_amount_picker');
        getInput.setAttribute("style","width:fit-content;");

        childMethodClear.value.resizeDayPicker();

        router.replace({query: infoparams.value})
        reportStore.tryInsights(infoparams.value).then(() =>{
            hour_peak_data.value = reportStore.insights.hourly_orders
            setTimeout(() =>{
                updatePeakChart.value.updateChart();
            }, 100)
            globalStore.loader('hide')
        })
    }
}

function clearInsightinfos(){
    globalStore.loader('show')
    params.value.date_range = null;
    setTodayDate();

    document.querySelector('.metrics_amount_picker').setAttribute("style","width:120px;");

    reportStore.tryInsights(infoparams.value).then(() => {
        hour_peak_data.value = reportStore.insights.hourly_orders;
        setTimeout(() =>{
            updatePeakChart.value.updateChart();
        }, 100)
        globalStore.loader('hide');
    })

    var inputDayPicker = document.querySelector(".metrics_amount_picker .dp__input_wrap");
    inputDayPicker.style.width = 100 + '%';
    router.replace('');
}


function setTodayDate(){
    let getFullYear = today.getFullYear();
    let getMonth = today.getMonth() +1;
    let getDate = today.getDate();
    let dateCorrected = null;
    let monthCorrected = null;
    
    if(getMonth <= 9){
        monthCorrected = {start: '0' + getMonth, end: '0' + getMonth,}
    } else{
        monthCorrected = {start: getMonth, end: getMonth};
    }

    if(getDate <= 9){
        dateCorrected = {start: '01', end: '0' + getDate,}
    } else {
        dateCorrected = {start: '01', end : getDate,};
    }

    infoparams.value.date_start = getFullYear + '-' + monthCorrected.start + '-' + dateCorrected.start
    infoparams.value.date_end = getFullYear + '-' + monthCorrected.end + '-' + dateCorrected.end;
    todayDate.value = getFullYear + '-' + getMonth.start + '-' + dateCorrected.end;
}

</script>

<style scoped>

.insights_blocks{
    display: flex;
    flex-wrap: wrap;
}

.best_day{
    text-align: center;
    margin: 0 0 0 0;
    margin-bottom: 15px;
}

.best_day h6{
    margin-bottom: 0px;
}

.best_day_date{
    margin-bottom: 15px;
}

#DayPicker :deep(#size-calibration){
    position: absolute;
}

#DayPicker :deep(.dp__input){
    border-radius: 10px;
}

.metrics_amount_picker:deep(input::placeholder) {
    font-family: Poppins;
    font-size: 14px;
    padding-left: 0px;
    position: absolute;
    margin-top: 1px;
    font-weight: 500;
}

.metrics_amount_picker{
    width: 120px;
}

@media screen and (max-width: 992px) {
    .metrics_amount_picker,
    .metrics_amount_picker :deep(.dp__input_wrap),
    .metrics_amount_picker :deep(.dp__input) {
        width: 100% !important;
        text-align: center;
    }

    .metrics_amount_picker:deep(input::placeholder) {
        padding-left: 10px;
        position: absolute;
        margin-top: 1px;
        font-weight: 500;
    }

    .metrics_amount_picker :deep(.dp__input_icon) {
        left: 90% !important;
    }
}

</style>