<template>
    <div class="text-center">
        <div class="check_icon">
            <font-awesome-icon :icon="['fass', 'circle-check']" />
        </div>
        <h5 class="my-4">Conta criada com sucesso</h5>
        <BaseButton @click="goToLogin()" @click.middle="goToLogin()" class="mt-4 btn-primary w-75">Fazer login</BaseButton>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

function goToLogin(){
    router.push('/login');
}
</script>

<style scoped>
.check_icon svg{
    fill: #28a745;
    color: #28a745;
    font-size: 120px;
}
</style>