<template>
    <h2>
        <font-awesome-icon icon="fa-solid fa-badge-dollar" style="color: #215eda"/>
        <span class="ms-2">Informações sobre o produto - Pedido #{{ props.refund.id }}</span>
    </h2>
    <p>Prossiga com a solicatação de reembolso ou informe algum problema.</p>

    <BaseSwitch
        type="radio"
        id="send_shipping_info1"
        label="Recebi o produto, está tudo certo. Prosseguir com o reembolso."
        v-model:checked="proceed"
    />

    <BaseSwitch
        type="radio"
        id="send_shipping_info2"
        label="Tive um problema/Preciso de ajuda. Solicito intermediação da Fullsale."
        v-model:checked="help"
    />

    <BaseInput
        v-if="help"
        class="col-12"
        label="Informe os detalhes sobre o seu problema:"
        textarea
        :page="route.name"
        id="text"
        v-model="params.text"
    />
    <BaseFileUpload
        v-if="help"
        class="col-12"
        label="Imagens"
        id="images"
        :page="route.name"
        multiple
        tooltip_text="Selecione no máximo 3 imagens."
        v-model="params.images"
        accept=".jpg, .jpeg, .png"
    />

    <BaseButton :disabled="isLoading || disableSubmitButton" class="mt-2 btn-primary w-100" @click="submit">{{ (help) ? 'Solicitar ajuda' : 'Prosseguir com o reembolso' }}</BaseButton>
</template>

<script setup>
import { ref, onBeforeMount, watch } from "vue";
import { useGlobalStore } from '@/stores/global';
const global = useGlobalStore();

import { useRoute } from "vue-router";
const route = useRoute();

onBeforeMount(() => {
    delete global.errors[route.name];
});

const params = ref({
    send_shipping_info: 'success',
    text: '',
    images: null,
});

const proceed = ref(1);
const help = ref(0);

watch(proceed, (v) => {
    help.value = !v;
    params.value.send_shipping_info = 'success';
});

watch(help, (v) => {
    proceed.value = !v;
    params.value.send_shipping_info = 'help';
});

const props = defineProps({
    refund: {
        required: true,
    }
});

import { useRefundStore } from '@/stores/refund';
const refundStore = useRefundStore();

const emit = defineEmits(["closePopup"]);

const disableSubmitButton = ref(false);

const isLoading = ref(false);
function submit() {
    if(!isLoading.value){
        global.loader('show');
        isLoading.value = true;
        disableSubmitButton.value = true;
        refundStore.tryArriveUpdate(route.name, props.refund.id, params.value).then(function (success){
            global.loader('hide');
            if(success){
                emit('closePopup');
            }else {
                disableSubmitButton.value = false;
            }
            isLoading.value = false;
        });
    }
}
</script>

<style scoped>
h2 {
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}
</style>