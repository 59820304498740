import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA    
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useCampaignStore = defineStore('campaign', {

    state: () => ({
        campaigns: {},
        campaigns_filters:{
            'search': null,
            'page': null,
            'date_start': null,
            'date_end': null,
        },
        campaign: {},
        campaigns_checkouts: {},
        paginate: {},
        destination_products: {},
        destination_pages: {},
        trackers: {},
        tracker: {
            content: {}
        },
    }),

    actions: {

        async tryCampaigns(params = null) {
            try {
                const response = await Api.get('campaigns', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.campaigns = response.data.data;
                this.paginate = response.data.meta;

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCampaign(id){
            try {
                const response = await Api.get('campaigns/'+ id);
                this.campaign = response.data.data.campaign;
                this.trackers = response.data.data.trackers;

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateCampaign(params, page){
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.campaign);

                const response = await Api.post('campaigns/', formData);

                this.campaign = response.data.data.campaign;

                useGlobalStore().successMessage('Campanha criada com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateCampaign(params, page){
            try {               
                let formData = useGlobalStore().getOnlyTabsParams(params, this.campaign);

                formData['_method'] = 'put';

                await Api.post('campaigns/' + this.campaign.id , formData);

                useGlobalStore().successMessage('Campanha atualizada com sucesso');

                return true;

            }catch (error) {
                useGlobalStore().tryIncomingErrors(error, page, params);
                return false;
            }
        },
        
        async tryDuplicateCampaign(id){
            try {
                const response = await Api.get('campaigns/' + id + '/duplicate');

                useGlobalStore().successMessage('Campanha duplicada com sucesso');

                return response.data.data.campaign.id;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryDeleteCampaign(campaign_id) {
            try {
                await Api.delete('campaigns/' + campaign_id);

                useGlobalStore().successMessage('Campanha removida com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryDestination(id){
            try {
                const response_pages = await Api.get('campaigns/destinations/'+ id + '/pages');
                const response_products = await Api.get('campaigns/destinations/'+ id + '/products');
                this.destination_pages = response_pages.data.data;
                this.destination_products = response_products.data.data;
                
                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },  

        async tryTrackers(){
            try {
                const response_trackers = await Api.get('campaigns/' + this.campaign.id + '/trackers/');
                this.trackers = response_trackers.data.data;

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryTracker(id){
            try {
                let tracker = useGlobalStore().getObjectById(this.trackers, id);

                if(tracker){
                    this.tracker = tracker;
                }else {
                    const response_tracker = await Api.get('campaigns/' + this.campaign.id + '/trackers/' + id);
                    this.tracker = response_tracker.data.data;
                }

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryCreateTracker(id, params, page){
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.tracker);

                await Api.post('campaigns/' + id + '/trackers', formData);
            
                useGlobalStore().successMessage('Rastreamento criado com sucesso');
                
                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },
            
        async tryDeleteTracker(tracker_id) {
            try {
                await Api.delete('campaigns/' + this.campaign.id + '/trackers/' + tracker_id);

                useGlobalStore().successMessage('Rastreador removido com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateTracker(params, page){
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.tracker);

                formData['_method'] = 'put';

                await Api.post('campaigns/'+ this.campaign.id + '/trackers/' + this.tracker.id , formData);

                useGlobalStore().successMessage('Rastreamento atualizado com sucesso');

                return true;
            }
            catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

    }
});