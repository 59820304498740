<template>
  <div class="box">
    <div class="p-4">
      <h5>Endereço</h5>
      <p class="mb-0" v-if="completion.type == 'J'">Local onde a empresa está situada.</p>
      <p class="mb-0" v-else-if="completion.type == 'F'">Local onde você reside.</p>
    </div>

    <div class="divisor_top"></div>

    <div class="p-4">
      <div class="row">

        <BaseInput
          class="col-md-3"
          label="CEP" type="text"
          id="postcode" 
          :mask="'#####-###'"
          v-model="completion.postcode"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6" 
          label="Endereço" 
          type="text"
          id="address"
          maxlength="150"
          v-model="completion.address"
          :page="route.name"
        />

        <BaseInput
          class="col-md-3"
          label="Número"
          type="text"
          id="address_number"
          maxlength="10"
          v-model="completion.address_number"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Bairro"
          type="text"
          id="neighborhood"
          maxlength="50"
          v-model="completion.neighborhood"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Complemento"
          type="text"
          id="complement"
          maxlength="50"
          v-model="completion.complement"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Cidade"
          type="text" 
          id="city"
          v-model="completion.city"
          :page="route.name"
        />

        <BaseSelect
          class="col-md-6"
          label="Estado"
          type="text"
          id="state" 
          :items="states"
          v-model="completion.state"
          :page="route.name"
        />

      </div>
    </div>
  </div>

  <div class="space"></div>

  <div class="actions">
    <div class="container d-flex justify-content-between">
      <router-link to="/account/completion/informations">
        <BaseButton class="btn-white btn-lg"><font-awesome-icon class="mx-2" icon="fa-solid fa-arrow-left-long" /> Voltar
        </BaseButton>
      </router-link>
      <router-link to="/account/completion/bank">
        <BaseButton class="btn-primary btn-lg">Continuar <font-awesome-icon class="mx-2"
            icon="fa-solid fa-arrow-right-long" /></BaseButton>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useAccountStore } from '@/stores/account'
import { storeToRefs } from 'pinia';
import { onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const accountStore = useAccountStore();
const { completion } = storeToRefs(accountStore);

const states = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

onBeforeMount(() => {
  if(!accountStore.completion.type){
    accountStore.completion.type = 'J';
  }
})
</script>