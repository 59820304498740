<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
            <div class="item action btn-group">
            <baseButton @click="updateCoproductor" class="btn-primary w-100" :disabled="isLoading">
                Salvar
            </baseButton>
            </div>
        </BaseHeader>
        <div class="box_content p-4">
            <div class="row">
                <BaseInput
                    class="col-md-6 col-12"
                    id="email"
                    label="E-mail *"
                    disabled
                    v-model="initialparams.email"
                />
                <BaseSelect 
                    label="Status *"
                    class="col-md-6 col-12"
                    id="CoproductorStatus"
                    :items="statusList"
                    v-model="params.status"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
const title = { title: "Editar coprodutor"};
import { ref, onBeforeMount, computed } from "vue"

import { useCoproductorStore } from '@/stores/coproductor';
const coproductorStore = useCoproductorStore();

import { useGlobalStore } from "@/stores/global";
const globalStore = useGlobalStore();

import { useRouter, useRoute } from "vue-router";
const route = useRoute();
const router = useRouter();

const coproductor_id = computed (function () {
    return route.params.id;
});

const previousPage = ref(null)
const initialparams = ref({})
const params = ref({})
const statusList = {
    '1' : 'Habilitado',
    '2' : 'Desabilitado', 
}
const isLoading = ref(false)

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('edit_coproductor')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;
    if(coproductorStore.coproductor.id){
        initialparams.value = {
            email: coproductorStore.coproductor.email,
        }
        params.value = {
            status: coproductorStore.coproductor.status,
        }
        setTimeout(() =>{
            document.querySelector('#select-box-container-CoproductorStatus input').value = coproductorStore.coproductor.status
            globalStore.loader('hide')
        }, 50)
    }
    else{
        coproductorStore.tryCoproductor(coproductor_id.value).then(() =>{
            initialparams.value = {
                email: coproductorStore.coproductor.email,
            }
            params.value = {
                status: coproductorStore.coproductor.status,
            }
            setTimeout(() =>{
                document.querySelector('#select-box-container-CoproductorStatus input').value = coproductorStore.coproductor.status
                globalStore.loader('hide')
            }, 100)
        })
    }
})

function updateCoproductor(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(params.value.status === 'Habilitado'){
            params.value.status = '1'
            coproductorStore.tryUpdateCoproductor(coproductor_id.value, params.value).then(() =>{
                globalStore.loader('hide')
                isLoading.value = false;
            })
        } else if(params.value.status === 'Desabilitado'){
            params.value.status = '2'
            coproductorStore.tryUpdateCoproductor(coproductor_id.value, params.value).then(() =>{
                globalStore.loader('hide')
                isLoading.value = false;
            })
        } else {
            coproductorStore.tryUpdateCoproductor(coproductor_id.value, params.value).then(() =>{
                globalStore.loader('hide')
                isLoading.value = false;
            })
        }
    }
}

</script>