<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
            <router-link v-if="globalStore.hasPermissionTo('new_product')" to="/products/create" class="item action btn-group">
                <BaseButton  class="btn btn-primary w-100"> Novo</BaseButton>
            </router-link>
        </BaseHeader>
        <div class="row" style="margin-top: -15px">
            <div class="col-lg col-12">
                <div @click="goToProducts" :class="[route.name === 'productsProductor' ? 'active' : '']" class="box_content select_filter mt-lg-3 mt-4 py-3 px-3 box_content_load">
                    <BaseInfoGroup
                        svg_icon="fa-regular fa-person-carry-box"
                        title="Produtor"
                        :info_value="total_productor + ' Produtos'"
                    />
                </div>
            </div>
            <div class="col-lg col-12">
                <div @click="goToCoprodProducts" :class="[route.name === 'productsCoproductor'? 'active': '',]" class="box_content select_filter mt-lg-3 mt-0 py-3 px-3 box_content_load">
                    <BaseInfoGroup
                        svg_icon="fa-regular fa-people-carry-box"
                        title="Coprodutor"
                        :info_value="total_coproductor + ' Produtos'"
                    />
                </div>
            </div>
            <div class="col-lg col-12">
                <div @click="goToRefProducts" :class="[route.name === 'productsReferral' ? 'active' : '',]" class="box_content select_filter mt-lg-3 mt-0 py-3 px-3 box_content_load">
                    <BaseInfoGroup
                        svg_icon="fas fa-link-simple"
                        title="Afiliado"
                        :info_value="total_referral + ' Produtos'"
                    />
                </div>
            </div>
        </div>
        <BaseFilter :title="title" @search="search" :initialSearch="initialSearch">
            <div class="item action">
                <BaseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </BaseButton>
                <div v-if="isLoadingFilters != true" class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <ProductsFilters @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty" />
                </div>
            </div>
        </BaseFilter>
        <router-view @removeLoading="removeLoading" ref="productTypeRef"></router-view>
    </div>
</template>
<script setup>
const title = { title: "Produtos", icon: "fa-box" };
import { ref, onBeforeMount } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from 'pinia'
import { useProductStore } from '@/stores/products';
import ProductsFilters from '@/components/includes/products/ProductsFilter'
import BaseFilter from '@/components/ui/BaseFilter';

const productStore = useProductStore();
const {  total_productor, total_coproductor ,total_referral } = storeToRefs(productStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
const route = useRoute();
const router = useRouter();

const isLoading = ref(false);
const isLoadingFilters = ref(true);

let initialFilters = null;
let initialSearch = null;

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('products')) {
        return router.push('/403');
    }

    let checkTotalProd = Number.isInteger(productStore.total_productor);
    let checkTotalCoprod = Number.isInteger(productStore.total_coproductor);
    let checkTotalRef = Number.isInteger(productStore.total_referral);

    if(checkTotalProd && checkTotalCoprod && checkTotalRef){
        let r = document.querySelector(':root');
        r.style.setProperty('--loaderFontSize', '15px')
    } 
    
    if(route.query.search || route.query.tags || route.query.status) {
            Object.keys(productStore.filters_products).forEach((key) => {
            let param = route.query[key];
            productStore.filters_products[key] = (param == undefined) ? null : param;

            if(key == 'tags') {
                if((productStore.filters_products[key]) && productStore.filters_products[key].constructor !== Array) {
                    let currentThing = productStore.filters_products[key];
                    productStore.filters_products[key] = [];
                    productStore.filters_products[key].push(currentThing);
                    }
            } else if(key == 'status'){
                if((productStore.filters_products[key]) && productStore.filters_products[key].constructor !== Array) {
                    let currentThing = productStore.filters_products[key];
                    productStore.filters_products[key] = '';
                    productStore.filters_products[key] = currentThing;
                }
            }
        })
        initialFilters = productStore.filters_products; 
        isLoadingFilters.value = false
        router.replace({query: initialFilters});
        initialSearch = initialFilters.search;
    } else {
        initialFilters = productStore.filters_products;
        isLoadingFilters.value = false
        initialSearch = initialFilters.search;
    }
    
    if(route.name === 'productsProductor'){
        route.query.type === 'productor'
        productStore.filters_products.type='productor';
    } 
    
    if(route.name === 'productsCoproductor'){
        route.query.type === 'coproductor'
        productStore.filters_products.type='coproductor';
    } 
    
    if(route.name === 'productsReferral'){
        route.query.type === 'referral';
        productStore.filters_products.type='referral';
    }

    productStore.tryProducts(productStore.filters_products).then(() =>{
        if(productStore.total_productor == 0 && productStore.total_coproductor > 0){
            router.push('/products/coproductor')
            productStore.filters_products.type = 'coproductor';
        }

        if(productStore.total_coproductor == 0 && productStore.total_productor == 0){
            router.push('/products/referral')
            productStore.filters_products.type = 'referral';
        }

        if(productStore.total_coproductor == 0 && productStore.total_referral == 0){
            router.push('/products/')
            productStore.filters_products.type = 'productor';
        }

        let usedParams = {};

        if(productStore.filters_products){
            router.replace({query: productStore.filters_products})
            Object.keys(productStore.filters_products).forEach((key) => {
                if(productStore.filters_products[key] != null && productStore.filters_products[key] != '') {
                    let currentParams = {
                        ...usedParams,
                        [key]: productStore.filters_products[key]
                    };
                    usedParams = currentParams;
                }
            });
            router.replace({query: usedParams})
        }
     })

     if(route.name === 'productsProductor'){
        route.query.type = 'productor'
        productStore.filters_products.type='productor';
    } 
    if(route.name === 'productsCoproductor'){
        route.query.type = 'coproductor'
        productStore.filters_products.type='coproductor';
    } 
    if(route.name === 'productsReferral'){
        route.query.type = 'referral';
        productStore.filters_products.type='referral';
    }
     
})

const getContentLoad = document.getElementsByClassName("select_filter")
function removeLoading(){
    for(var i = 0 ; i < getContentLoad.length; i++){
        getContentLoad[i].classList.remove("box_content_load")
    }
}

async function loadProducts() {
    let usedParams = {};
    Object.keys(productStore.filters_products).forEach((key) => {
        if(productStore.filters_products[key] != null && productStore.filters_products[key] != '') {
            let currentParams = {
                ...usedParams,
                [key]: productStore.filters_products[key]
            };
            usedParams = currentParams;
        }
    });

    router.replace({query: usedParams});
    await productStore.tryProducts(productStore.filters_products).then( function (success){
        if(success){
            isLoading.value = false
        }
    })
    globalStore.loader('hide');
}

function search(r) {
    productStore.filters_products.search = r;
    if (route.name == 'productsProductor'){
        productStore.filters_products.type='productor';
    } else if(route.name === 'productsCoproductor'){
        productStore.filters_products.type='coproductor'
    }else if(route.name === 'productsReferral'){
        productStore.filters_products.type='referral'
    }
    loadProducts();
}

function goToProducts(){
    let usedParams = null;
    productStore.filters_products.type = 'productor'

    Object.keys(productStore.filters_products).forEach((key) => {
        if(productStore.filters_products[key] != null && productStore.filters_products[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: productStore.filters_products[key]
            };
            usedParams = currentParams;
        }
    });

    if(route.query){
        router.replace({name: 'productsProductor', query: usedParams})
    }

}

function goToCoprodProducts(){
    let usedParams = null;
    productStore.filters_products.type = 'coproductor'

    Object.keys(productStore.filters_products).forEach((key) => {
        if(productStore.filters_products[key] != null && productStore.filters_products[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: productStore.filters_products[key]
            };
            usedParams = currentParams;
        }
    });

    if(route.query){
        router.replace({name: 'productsCoproductor', query: usedParams})
    }

}

function goToRefProducts(){
    let usedParams = null;
    productStore.filters_products.type = 'referral'

    Object.keys(productStore.filters_products).forEach((key) => {
        if(productStore.filters_products[key] != null && productStore.filters_products[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: productStore.filters_products[key]
            };
            usedParams = currentParams;
        }
    });

    if(route.query){
        router.replace({name: 'productsReferral', query: usedParams})
    }


}

function filters(r) {
    Object.keys(r).forEach((key) => {
        productStore.filters_products[key] = r[key];
    });
    globalStore.loader('show');
    loadProducts();
}

const totalFilters = ref(0);

function filtersQty(r) {
    totalFilters.value = r;
}

</script>

<style scoped>

a {
    text-decoration: none;
    color: inherit !important;
}

.box_content:hover {
    cursor: pointer;
    background-color: #f7f7f7;
}

.active {
    background-color: #ececec;
}

.box_content_load :deep(.info_value){
    width: 100%;
    border-radius: 3px;
    height: 6px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
    background: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 100%);
    font-size: var(--loaderFontSize);
    background-size: 800px 21px;
    animation: loading 1s linear infinite forwards;
}
</style>