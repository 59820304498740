import TheStores from '@/views/stores/TheStores';
import StoresCreate from '@/views/stores/create/StoreCreate';
import StoreEdit from '@/views/stores/edit/StoreEdit';

import TheInformations from '@/views/stores/edit/informations/TheInformations';
import ThePersonalization from '@/views/stores/edit/personalization/ThePersonalization';

import TheIntegrations from '@/views/stores/edit/integrations/TheIntegrations';
import IntegrationDialog from '@/views/stores/edit/integrations/IntegrationDialog';

import TheCoupons from '@/views/stores/edit/coupons/TheCoupons';
import CouponDialog from '@/views/stores/edit/coupons/CouponDialog';

import ThePages from '@/views/stores/edit/pages/ThePages';
import PagesDialog from '@/views/stores/edit/pages/PageDialog';

import TheNotifications from '@/views/stores/edit/notifications/TheNotifications';

import TheTrackers from '@/views/stores/edit/trackers/TheTrackers';
import TrackersDialog from '@/views/stores/edit/trackers/TrackersDialog';

import ThePaymentsMethods from '@/views/stores/edit/payments/ThePaymentsMethods';
import PaymentsMethodDialog from '@/views/stores/edit/payments/PaymentMethodDialog';

import TheWebhooks from '@/views/stores/edit/webhooks/TheWebhooks';
import WebhookDialog from '@/views/stores/edit/webhooks/WebhookDialog';

import TheManagers from '@/views/stores/edit/managers/TheManagers';
import ManagersDialog from '@/views/stores/edit/managers/ManagerDialog';
import TheManagerAccept from '@/views/invitation/TheManagerAccept'

import TheReferrals from '@/views/stores/edit/referrals/TheReferrals';
import ReferralDialog from '@/views/stores/edit/referrals/ReferralDialog';
import TheReferralAccept from '@/views/invitation/TheReferralAccept'

import TheShippingCompanies from '@/views/stores/edit/shipping/companies/TheShippingCompanies';
import CompanyDialog from '@/views/stores/edit/shipping/companies/CompanyDialog';

import TheShippingMethods from '@/views/stores/edit/shipping/methods/TheShippingMethods';
import MethodDialog from '@/views/stores/edit/shipping/methods/MethodDialog';

import TheCheckouts from '@/views/stores/edit/checkouts/TheCheckouts';
import CheckoutDialog from '@/views/stores/edit/checkouts/CheckoutDialog';
import CheckoutCustomization from '@/views/stores/edit/checkouts/CheckoutCustomization';

import TheTestsAb from '@/views/stores/edit/tests-ab/TheTestsAb.vue';
import TestAbDialog from '@/views/stores/edit/tests-ab/TestAbDialog';
import TestAbReport from '@/views/stores/edit/tests-ab/TestAbReport';

export default [
    {
        name: 'stores',
        path: '/stores',
        component: TheStores,
        meta: {
            requiresAuth: true,
            menuPage: 'stores'
        }
    },
    {
        path: '/stores/create',
        name: 'storeCreate',
        component: StoresCreate,
        meta: {
            requiresAuth: true,
            menuPage: 'stores'
        }
    },
    {
        name: 'storeEdit',
        path: '/stores/:id',
        component: StoreEdit,
        meta: {
            requiresAuth: true,
            menuPage: 'stores'
        },
        children: [
            {
                name: 'storeInformations',
                path: '/stores/:id',
                component: TheInformations,
            },

            {
                name: 'storePersonalization',
                path: '/stores/:id/personalization',
                component: ThePersonalization,
            },

            {
                name: 'storeIntegrations',
                path: '/stores/:id/integrations',
                component: TheIntegrations,
                children: [
                    {
                        name: 'storeIntegrationEdit',
                        path: '/stores/:id/integrations/:slug',
                        component: IntegrationDialog,
                    },
                ],
            },

            {
                name: 'storeCoupons',
                path: '/stores/:id/coupons',
                component: TheCoupons,
                children: [
                    {
                        name: 'storeCouponCreate',
                        path: '/stores/:id/coupons/new',
                        component: CouponDialog,
                    },
                    {
                        name: 'storeCouponEdit',
                        path: '/stores/:id/coupons/:slug',
                        component: CouponDialog,
                    },
                ],

            },

            {
                name: 'storePages',
                path: '/stores/:id/pages',
                component: ThePages,
                children: [
                    {
                        name: 'storePageCreate',
                        path: '/stores/:id/pages/new',
                        component: PagesDialog,
                    },
                    {
                        name: 'storePageEdit',
                        path: '/stores/:id/pages/:slug',
                        component: PagesDialog,
                    },
                ]
            },

            {
                name: 'storeNotifications',
                path: '/stores/:id/notifications',
                component: TheNotifications,
            },

            {
                name: 'storeTrackers',
                path: '/stores/:id/trackers',
                component: TheTrackers,
                children: [
                    {
                        name: 'storeTrackersCreate',
                        path: '/stores/:id/trackers/new',
                        component: TrackersDialog,
                    },
                    {
                        name: 'storeTrackersEdit',
                        path: '/stores/:id/trackers/:slug',
                        component: TrackersDialog,
                    },

                ]
            },

            {
                name: 'storePayments',
                path: '/stores/:id/payments',
                component: ThePaymentsMethods,
                children: [
                    {
                        name: 'storePaymentEdit',
                        path: '/stores/:id/payments/:slug',
                        component: PaymentsMethodDialog,
                    }

                ]
            },

            {
                name: 'storeWebhooks',
                path: '/stores/:id/webhooks',
                component: TheWebhooks,
                children: [
                    {
                        name: 'storeWebhookCreate',
                        path: '/stores/:id/webhooks/new',
                        component: WebhookDialog,
                    },
                    {
                        name: 'storeWebhookEdit',
                        path: '/stores/:id/webhooks/:slug',
                        component: WebhookDialog,
                    },
                ]
            },

            {
                name: 'storeCheckouts',
                path: '/stores/:id/checkouts',
                component: TheCheckouts,
                children: [
                    {
                        name: 'storeCheckoutCreate',
                        path: '/stores/:id/checkouts/new',
                        component: CheckoutDialog,
                    },
                    {
                        name: 'storeCheckoutEdit',
                        path: '/stores/:id/checkouts/:slug',
                        component: CheckoutDialog,
                    },
                ]
            },

            {
                name: 'storeTestsAb',
                path: '/stores/:id/tests-ab',
                component: TheTestsAb,
                children: [
                    {
                        name: 'storeTestsAbCreate',
                        path: '/stores/:id/tests-ab/new',
                        props: true,
                        component: TestAbDialog,
                    },
                    {
                        name: 'storeTestsAbEdit',
                        path: '/stores/:id/tests-ab/:slug',
                        props: true,
                        component: TestAbDialog,
                    },
                ]
            },

            {
                name: 'storeManagers',
                path: '/stores/:id/managers',
                component: TheManagers,
                children: [
                    {
                        name: 'storeManagerCreate',
                        path: '/stores/:id/managers/new',
                        component: ManagersDialog,
                    },
                    {
                        name: 'storeManagerEdit',
                        path: '/stores/:id/managers/:slug',
                        component: ManagersDialog,
                    }

                ]
            },

            {
                name: 'storeReferrals',
                path: '/stores/:id/referrals',
                component: TheReferrals,
                children: [
                    {
                        name: 'storeReferralCreate',
                        path: '/stores/:id/referrals/new',
                        component: ReferralDialog,
                    },
                    {
                        name: 'storeReferralEdit',
                        path: '/stores/:id/referrals/:slug',
                        component: ReferralDialog,
                    }
                ]
            },

            {
                name: 'storeShippingCompanies',
                path: '/stores/:id/shipping/companies',
                component: TheShippingCompanies,
                children: [
                    {
                        name: 'storeShippingCompanyCreate',
                        path: '/stores/:id/shipping/companies/new',
                        component: CompanyDialog,
                    },
                    {
                        name: 'storeShippingCompanyEdit',
                        path: '/stores/:id/shipping/companies/:slug',
                        component: CompanyDialog,
                    }
                ]
            },

            {
                name: 'storeShippingMethods',
                path: '/stores/:id/shipping/methods',
                component: TheShippingMethods,
                children: [
                    {
                        name: 'storeShippingMethodCreate',
                        path: '/stores/:id/shipping/methods/new',
                        component: MethodDialog,
                    },
                    {
                        name: 'storeShippingMethodEdit',
                        path: '/stores/:id/shipping/methods/:slug',
                        component: MethodDialog,
                    }
                ]
            },
        ]
    },

    {
        path: '/invitation/referral/:code',
        name: 'referralAccept',
        component: TheReferralAccept,
        meta:{
            requiresAuth: true,
            menuPage: 'home',
        }
    },

    {
        path: '/invitation/manager/:code',
        name: 'managerAccept',
        component: TheManagerAccept,
        meta:{
            requiresAuth: true,
            menuPage: 'home',
        }
    },

    {
        name: 'storeCheckoutCustomization',
        path: '/stores/:id/checkouts/:slug/customization',
        component: CheckoutCustomization,
        meta: {
            requiresAuth: true,
            menuPage: 'stores'
        }
    },

    {
        name: 'storeTestAbReport',
        path: '/stores/:id/tests-ab/:slug/report',
        component: TestAbReport,
        meta: {
            requiresAuth: true,
            menuPage: 'stores'
        }
    },
];