import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useCartStore = defineStore('carts', {

    state: () => ({
        carts: {},
        paginate: {},
        cart: {},
        carts_filters:{
            'search': null,
            'page': null,
            'date_start': null,
            'date_end': null,
            'status': null,
        },
    }),

    actions: {

        async tryCarts(params = null) {
            try {
                const response = await Api.get('recovery/abandoned-carts', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.carts = response.data.data;
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryCart(id) {
            try {
                const response = await Api.get('recovery/abandoned-carts/' + id);
                this.cart = response.data.data;

                this.tryCartTimeline(id);
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateCart(page, id, params){
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.cart);

                formData['_method'] = 'put';

                await Api.post('recovery/abandoned-carts/' + id, formData);
                
                Object.keys(params).forEach((key) => {
                    this.cart[key] = params[key];
                });

                useGlobalStore().successMessage('Informações atualizadas com sucesso');
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryCartTimeline(id) {
            try {
                const response = await Api.get('recovery/abandoned-carts/' + id + '/track');
                this.cart.timeline = response.data.data;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
    }
    }
);