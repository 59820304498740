import TheRegister from '@/views/auth/TheRegister';
import TheLogin from '@/views/auth/TheLogin';
import TheForgotPassword from '@/views/auth/TheForgotPassword';
import TheAccountCreated from '@/views/auth/TheAccountCreated';

export default [
    {
        path: '/login',
        name: 'login',
        component: TheLogin,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/forgot-password/:code?',
        name: 'forgot-password',
        props: true,
        component: TheForgotPassword,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/register/:code?',
        name: 'register',
        props: true,
        component: TheRegister,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/account-created',
        name: 'account-created',
        props: false,
        component: TheAccountCreated,
        meta: {
            requiresAuth: false
        }
    }
];