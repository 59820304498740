<template>
    <div class="box_content">
        <div class="table-responsive ">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>Método de envio	</th>
                        <th>Padrão</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, key) in render_shipping_methods" :key="key">
                            <td>
                                <BaseSwitch
                                    :id="'shipping_' + row.id"
                                    class="my-lg-2 mt-3 mb-5 enable_switch"
                                    :label="row.title"
                                    :page="route.name"
                                    @click="switchEnableShipping(row.id)"
                                />
                            </td>
                            <td class="actions">
                                <BaseSwitch
                                    :id="'default_' + row.id"
                                    class="my-lg-2 mt-3 mb-5 default_switch"
                                    :page="route.name"
                                    @input="switchDefault(row.id)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h6 v-if="!render_shipping_methods.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
            </div>
        </div>
        <div class="box_content px-3 py-3 pb-0">
                <h6 class="mb-4">Comissão</h6>
                <BaseSwitch
                    id="shipping_comission"
                    label="Distribuir valor do frete para comissionados"
                    :page="route.name"
                    v-model:checked="product.shipping_comission"
                    @input="switchShipping"
                />
                <div class="col-md-12 mt-3">
					<p>
						<span>Possibilita que gerentes, afiliados e coprodutores também recebam o frete como parte da comissão.</span>
					</p>
				</div>
        </div>
</template>

<script setup>
import { onMounted, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';

const route = useRoute();

const productStore = useProductStore();
const { render_shipping_methods, product} = storeToRefs(productStore);

onBeforeMount(() =>{
    if(productStore.product.shipping_comission == 1 || productStore.product.shipping_comission == '1'){
        product.shipping_comission = 1
    } else {
        product.shipping_comission = 0
    }
})

onMounted(() =>{
    const getAllSwitches = document.querySelectorAll('.enable_switch input');
    const getAllDefaultSwitches = document.querySelectorAll('.default_switch input');

    for(let i = 0; i < getAllSwitches.length; i++){
        getAllSwitches[i].checked = false;
        let splitSwitchId = getAllSwitches[i].id.split('_');
 
        for(let index = 0; index < productStore.product.shipping_methods.length; index++){
            let getThisModule = document.getElementById('shipping_' + splitSwitchId[1])
            if(splitSwitchId[1] == productStore.product.shipping_methods[index]){
                getThisModule.checked = true
            }
        }
    }

    for (let i = 0; i < getAllDefaultSwitches.length; i++){
        getAllSwitches.checked = false;
        let splitSwitchId = getAllDefaultSwitches[i].id.split('_');
        let getThisDefault = document.getElementById('default_' + splitSwitchId[1])
        if(splitSwitchId[1] == productStore.product.product_default_shipping_method){
            getThisDefault.checked = true;
        }
    }

})


function switchShipping(){
    const getSwitch = document.getElementById('shipping_comission').checked
    if(getSwitch == true){
        productStore.product.shipping_comission = 1
    } else {
        productStore.product.shipping_comission = 0
    }
}

function switchEnableShipping(id){
    const getSwitch = document.getElementById("shipping_" + id);
    if(getSwitch.checked === false){
        for (let index = 0; index < productStore.product.shipping_methods.length; index++){
            while (index < productStore.product.shipping_methods.length) {
                if(productStore.product.shipping_methods[index] === id){
                    productStore.product.shipping_methods.splice(index, 1);
                } else {
                    index++;
                }
            }
        }
        const getSwitch = document.getElementById('default_' + id)
        if(getSwitch.checked === true){
            productStore.product.product_default_shipping_method = 0;
            getSwitch.checked = false;
        }

    } else {
        productStore.product.shipping_methods.push(id);
    }
}

function switchDefault(id){
    const getSwitch = document.getElementById('default_' + id)
    const getEnableSwitch = document.getElementById("shipping_" + id);
    if(getSwitch.checked == true){
        const getAllSwitches = document.querySelectorAll('.default_switch input')
        for(let i = 0; i < getAllSwitches.length; i++){
            if(getAllSwitches[i].id == getSwitch.id){
                getSwitch.checked = true
                if(getEnableSwitch.checked === false){
                    getEnableSwitch.checked = true;
                    productStore.product.shipping_methods.push(id);
                }
            } else{
                getAllSwitches[i].checked = false
            }
        }
        productStore.product.product_default_shipping_method = id;
    } else {
        getSwitch.checked = false;
    }
}

</script>