<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <div>
            <header>
                <font-awesome-icon icon="fa-solid  fa-handshake" style="color: #215eda;"/>
                <span class="ms-2 align-middle" v-if="route.params.slug">Editar afiliado</span>
                <span class="ms-2 align-middle" v-else>Novo afiliado</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseInput
                            class="col-md-8 col-12"
                            label="Email *"
                            id="email"
                            v-model="referral.email"
                            :page="route.name"
                            :disabled="route.params.slug ? true : false"
                        />
                        <BaseInput
                            class="col-md-4 col-12"
                            label="Comissão *"
                            type="number"
                            id="comission"
                            tag="%"
                            v-model="referral.comission"
                            :page="route.name"
                        />
                        <BaseSelect
                            v-if="route.params.slug"
                            class="col-md-6 col-12"
                            label="Status *"
                            :items="statusList"
                            id="status"
                            v-model="referral.status"
                            :page="route.name"
                        />
                        <BaseSelect
                            v-if="route.params.slug"
                            class="col-md-6 col-12"
                            label="Gerente *"
                            :items="managersList"
                            id="manager"
                            v-model="referral.id_manager"
                            :page="route.name"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </div>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global'
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { referral } = storeToRefs(storeStore)

const isLoaded = ref(false);
const isLoading = ref(false);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

const statusList = {
    1: 'Habilitado',
    2: 'Desabilitado',
}

const managersList = ref({
    ' ': 'Nenhum'
})

onBeforeMount(() =>{
    delete globalStore.errors[route.name];
    globalStore.loader('show')

    if(route.name == 'storeReferralEdit'){
        if(storeStore.referral.id){
            setManagerList(); 
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            storeStore.tryReferral(route.params.slug).then(() =>{
                setManagerList();
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'storeReferralCreate'){
        globalStore.cleanObject(storeStore.referral);
        globalStore.loader('hide')
        isLoaded.value = true
    }
})

function setManagerList(){
    for(let i = 0; i < storeStore.referralmanagers.length; i++){
        managersList.value[storeStore.referralmanagers[i].id] = storeStore.referralmanagers[i].name;
    }
}

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        globalStore.loader('show');
        isLoading.value = true;

        if(route.name == 'storeReferralEdit'){

            let params = ['email', 'comission', 'status', 'id_manager']

            storeStore.tryUpdateReferral(route.params.slug, params, route.name).then(function(success) {
                if(success){
                    useGlobalStore().successMessage('Afiliado atualizado com sucesso');

                    storeStore.tryReferrals().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/referrals')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        } else if(route.name == 'storeReferralCreate') {

            let params = ['email', 'comission']

            storeStore.tryCreateReferral(params, route.name).then(function(success){
                if(success){
                    useGlobalStore().successMessage('Convite enviado com sucesso');

                    storeStore.tryReferrals().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/referrals')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    }
}

</script>