<template>
    <div class="opt-box">
        <VOtpInput
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="maxlength"
            :class="'base-input ' + [error ? 'danger-border' : '' ]"
            :should-auto-focus="true"
            :is-input-num="true"
            :value="modelValue"
            @on-change="updateInput"
            @on-complete="updateInput"
        />
        <span v-if="error" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
    </div>
</template>

<script>
import VOtpInput from "vue3-otp-input";
import { useGlobalStore } from '@/stores/global';

export default {
    emits: ["update:modelValue"],
    components: {
        VOtpInput 
    },
    data() {
        return {
            global: useGlobalStore(),
            currentType: this.$props.type,
            pageProp: this.$props.page,
        }
    },
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        
        sub_label: {
            type: String,
            required: false,
            default: null,
        },  

        id: {
            type: String,
            required: true,
        },

        type: {
            type: String,
            required: false,
            default: 'text'
        },

        required: {
            type: Boolean,
            required: false,
            default: null,
        },

        modelValue: {
        },

        page: {
            type: String,
            required: false,
        },

        maxlength: {
            required: false,
            default: 6,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        updateInput(value) {
            this.$emit("update:modelValue", value);

            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },
    },
    watch: {
        pageProp (value){
            this.global.fields_pages[this.$props.id] = value;
        }
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>