<template>
    <div class="base-title-header">
        <div class="p-lg-4 pe-lg-0 p-0 pt-4">
            <div class="align justify-content-end">
                <div class="title item flex-grow-1">
                    <h2>
                        <font-awesome-icon v-if="redirectBack"  @click="goBack" class="icon back_arrow" icon="fas fa-arrow-left"/>
                        <font-awesome-icon v-else-if="props.title.icon" class="icon" :icon="'fas ' + props.title.icon" />
                        <span v-if="redirectBack" class="back_arrow_title">
                            {{ props.title.title}}
                            <slot name="title"></slot>
                        </span>
                        <span v-else>
                            {{ props.title.title}}
                            <slot name="title"></slot>
                        </span>
                    </h2>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
    title: {
        required: true,
        default: null
    },

    removeSearchBar: {
        type: Boolean,
        required: false,
        default: false,
    },

    initialSearch: {
        required: false,
        default: null
    },

    redirectBack: {
        required: false,
        default: null,
    },
});

function goBack(){
    router.push(props.redirectBack)
}

const search = ref(null);

onBeforeMount(() => {
    if(props.initialSearch != null) {
        search.value = props.initialSearch;
    }
});

</script>

<style>

.base-title-header {
    position: relative;
    top: 0px;
    z-index: 99;
    border-bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 0px;
    background: #f7f7f7;
}

.base-title-header .align {
    display: flex;
    align-items: center;
    min-height: 38px;
    max-width: 1350px;
    margin: 0 auto;
}

.base-title-header .space-between {
    justify-content: space-between;
}

.base-title-header .item {
    flex: 1;
}

.base-title-header .title {
    flex: inherit;
    margin-left: -24px;
}

.base-title-header h2 {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}

.base-title-header h2 .icon {
    margin-right: 10px;
    color:#215eda;
}

.base-title-header .action {
    min-width: 100px;
    flex: 0 0 120px;
}

.base-title-header .badget {
    position: relative;
    top: -2px;
    margin-left: 10px;
}

.back_arrow{
    cursor: pointer;
    border-radius: 50%;
    padding: 7px 8px;
}

.back_arrow:hover{
    box-shadow: inset 0px 0px 50px rgb(0 0 0 / 20%);
}

.back_arrow_title{
    position: relative;
    top: -7px;
}

.search_item {
    max-width: 300px;
}

@media screen and (max-width: 992px){

    .base-title-header{
        position: relative;
        padding-left: 0px;
        text-align: center;
        padding-bottom: 1rem;
        border-bottom: 0px !important;
        top: 0;
    }

    .base-title-header .align{
        display: block;
    }

    .base-title-header .item, .base-title-header .btn-group{
        width: 100%;
        margin: 8px 0px;
    }

}
</style>