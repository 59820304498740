<template>
    <div class="container px-2 px-lg-4">
        <BaseTable :columns="['ID', 'Valor']" :qty="withdrawalStore.withdrawal.order.length" :noActions="true">
            <tr v-for="(order, key) in withdrawalStore.withdrawal.order" :key="key">
                <td width="50%">
                    <a :href="order.url" target="_blank">
                        {{ order.id_order }}
                        <font-awesome-icon :icon="['far', 'square-arrow-up-right']" />
                    </a>
                    <b v-if="order.installment">{{ order.installment }}º Parcela</b>
                </td>
                <td v-if="order.anticipation_fee > 0">
                    <s>{{ globalStore.formatMoney(order.amount) }}</s> {{ globalStore.formatMoney(order.amount - order.anticipation_fee) }}
                    <span data-bs-toggle="tooltip" data-bs-placement="right" data-bs-animation="true" v-tooltip title="Alteração no valor referente a taxa de antecipação" class="ms-1">
                        <font-awesome-icon icon="fas fa-circle-info"/>
                    </span>
                </td>
                <td v-else>
                    {{ globalStore.formatMoney(order.amount) }}
                </td>
            </tr>
        </BaseTable>
    </div>
</template>
    
<script setup>
import { useWithdrawalStore } from '@/stores/withdrawal'
const withdrawalStore = useWithdrawalStore();
 
import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
</script>
    
<style scoped>
a:hover{
    text-decoration: underline!important;
}

b {
    display: inline-block;
    margin-left: 15px;
}
</style>