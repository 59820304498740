<template>
    <div class="box_content">
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Status</th>
                        <th class="row_right"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(payment, key) in payments" :key="key">
                        <td>{{ payment.title }}</td>
                        <td>{{ payment.status_name }}</td>
                        <td class="actions">
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editPayment(payment.slug)"><font-awesome-icon icon="fas fa-pen-to-square" /></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!payments.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
        </div>
    </div>
    <router-view></router-view>
</template>

<script setup>
import { useRouter } from 'vue-router'; 
import { useRoute } from "vue-router";
import { computed } from 'vue'
import { useStoreStore } from '@/stores/store';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';

const route = useRoute();
const router = useRouter();

const store_id = computed (function () {
    return route.params.id;
});

const globalStore = useGlobalStore();

const storeStore = useStoreStore();
const { payments } = storeToRefs(storeStore);

function editPayment(slug){
    globalStore.loader('show')
    storeStore.tryPayment(slug).then(() =>{
        router.push('/stores/' + store_id.value + '/payments/' + slug)
        globalStore.loader('hide')
    })
}
</script>