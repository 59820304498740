<template>
    <div v-if="isLoaded">
        <div class="container px-2 px-lg-4">
            <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
                <template v-slot:title>
                    <BaseBadget :text="refund.status.name" :color="refund.status.color" :style="[refund.status.slug !== 'accepted' && refund.status.slug !== 'finalized' ? 'margin: 0 auto 0 0;' : ' ']"/>
                </template>
               <a :href="exportUrl + 'refunds/' + refund.id + '/refund-letter'" v-if="refund.status.slug === 'accepted' || refund.status.slug === 'finalized'" class="btn-group download_card" target="_blank">
                    <baseButton class="btn btn-primary w-100">Carta de cancelamento</baseButton>
                </a>
            </BaseHeader>
            <div class="row">
                <div class="col-lg-8 col-12">
                    <div class="box_content">
                        <div class="divisor_bottom">
                            <div class="row p-4">
                                <div class="col-12 mb-4">
                                    <strong>Solicitante</strong>
                                    <br>
                                    <span>{{ (refund.author_type == 'productor') ? refund.account.name : refund.client.name }}</span>
                                </div>
                                <div class="col-lg col-12">
                                    <strong>Motivo da solicitação</strong>
                                    <br>
                                    <span v-html="refund.reason"></span>
                                </div>
                                <div class="col-lg col-12">
                                    <strong>Data da solicitação</strong>
                                    <br>
                                    <td>{{ globalStore.formatDate(refund.created_at) }}</td>
                                </div>
                                <div v-if="refund.status.slug == 'pending'" class="col-lg col-12">
                                    <strong>Responder até a data de</strong>
                                    <br>
                                    <td>{{ globalStore.formatDate(refund.replay_deadline) }}</td>
                                </div>
                            </div>
                        </div>
                        <div class="row p-4">
                            <div class="col-12">
                                <strong>Detalhes da solicitação</strong>
                                <br>
                                <span v-html="refund.refund_text"></span>
                            </div>
                        </div>
                        <div class="divisor_top" v-if="refund.images">
                            <div class="table-responsive">
                                <table class="table">  
                                    <thead>
                                        <tr>
                                            <th class="ps-3">Imagens</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(image, index) in refund.images" :key="index">
                                            <td>
                                                <a :href="image.url"  target="_blank">
                                                {{ image.name }}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div v-for="(reply, index) in refund.replies" :key="index" class="box_content box_replies">
                        <div class="divisor_bottom">
                            <div class="row p-4">
                                <div class="col-lg-4 col-12">
                                    <strong>Respodido por</strong>
                                    <br>
                                    <span>{{ reply.replied_by }}</span>
                                </div>
                                <div class="col-lg-4 col-12">
                                    <strong>Data</strong>
                                    <br>
                                    <span>{{ globalStore.formatDate(reply.created_at)}}</span>
                                </div>
                                <div class="col-lg-4 col-12">
                                    <strong>Status</strong>
                                    <br>
                                    <BaseBadget class="mt-1" :text="reply.status.name" :color="reply.status.color" />
                                </div>
                            </div>
                        </div>
                        <div class="row p-4">
                            <div class="col-12">
                                <strong>Detalhes</strong>
                                <br>
                                <span v-html="reply.text"></span>
                            </div>
                        </div>

                        <div v-if="reply.status.slug == 'waiting_shipping'" class="p-4 divisor_top">
                            <p class="mb-1"><strong class="d-block mb-1">Código para envio do produto </strong> {{ reply.shipping_reverse_code }}</p>
                        </div>

                        <div class="divisor_top"  v-if="reply.images">
                            <div class="table-responsive">
                                <table class="table">  
                                    <thead>
                                        <tr>
                                            <th class="ps-3">Imagens</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(image, index) in reply.images" :key="index">
                                            <td>
                                                <a :href="image.url"  target="_blank">
                                                {{ image.name }}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="box_content box_replies_reply" v-if="refund.is_refundable || refund.is_answerable || refund.status.slug == 'waiting_for_arrive'">
                        <div class="p-4 d-flex flex-row-reverse">
                            <div> 
                                <div @click="changeReplyBox('arrive')" class="me-2 btns" v-if="refund.status.slug == 'waiting_for_arrive'">
                                    <BaseButton class="btn-success">Informar sobre o recebimento do produto</BaseButton>
                                </div>
                                <div @click="changeReplyBox('accept')" class="me-2 btns">
                                    <BaseButton class="btn-success" v-if="refund.is_refundable">Aceitar reembolso</BaseButton>
                                </div>
                                <div @click="changeReplyBox('reply')" class="me-2 btns" v-if="refund.is_answerable && refund.status.slug == 'pending'">
                                    <BaseButton class="btn-primary">Contestar</BaseButton>
                                </div>
                                <div @click="changeReplyBox('reply')" class="btns" v-if="refund.is_answerable && refund.status.slug != 'pending'">
                                    <BaseButton class="btn-primary">Responder</BaseButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="box_content">
                        <div class="divisor_bottom">
                            <div class="row p-4">
                                <div class="col-lg-6 col-12">
                                    <strong>Loja</strong>
                                    <br>
                                    <BaseStoreDot :title="refund.store.title" :color="refund.store.color" />
                                </div>
                                <div class="col-lg-6 col-12">
                                    <strong>Data da Compra</strong>
                                    <br>
                                    <span>{{ globalStore.formatDate(refund.order_created_at) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="divisor_bottom">
                            <div class="row p-4">
                                <div class="col-lg-6 col-12">
                                    <strong>Total</strong>
                                    <br>
                                    <span>{{ globalStore.formatMoney(refund.total) }}</span>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <strong>Pagamento</strong>
                                    <br>
                                    <span>{{ refund.payment }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="divisor_bottom">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                    <th class="ps-3">Produto</th>
                                    <th class="ps-3 row_right">Qtd</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(product, index) in refund.products" :key="index">
                                    <td>
                                        {{ product.title }}
                                        <BaseBadget v-if="product.type !== 'primary'" :text="product.title" color="#28A745" />
                                    </td>
                                    <td>
                                        {{ product.qty }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <div class="">
                            <div class="row p-4">
                                <div class="col-12 mb-1">
                                    <strong>Cliente: </strong>
                                    <span>{{ refund.client.name }}</span>
                                </div>
                                <div class="col-12 mb-1">
                                    <strong>E-mail: </strong>
                                    <span>{{ refund.client.email }}</span>
                                </div>
                                <div class="col-12 mb-1">
                                    <strong>Telefone:</strong>
                                    <span>{{ refund.client.phone }}</span>
                                </div>
                                <div class="mt-4">
                                    <BaseButton link target="_blank" :to="'/orders/' + refund.id_order" class="btn-secondary w-100">Visualizar pedido completo</BaseButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BasePopup v-if="replyBox" closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <div class="content">
                <component :is="replyBox" :refund="refund" @closePopup="clickToClose"/>
            </div>
        </BasePopup>
    </div>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { ref, onBeforeMount, computed, shallowRef } from 'vue';

import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

const refund_id = computed (function () {
    return route.params.id;
});

import { useRefundStore } from '@/stores/refund';
const refundStore = useRefundStore();
const { refund } = storeToRefs(refundStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const isLoaded = ref(false);
const previousPage = ref(null);

// eslint-disable-next-line
const exportUrl = ref(process.env.VUE_APP_API_URL);

onBeforeMount(async() => {
    if(!globalStore.hasPermissionTo('refunds')) {
        return router.push('/403');
    }
    
    previousPage.value = router.options.history.state.back;
    globalStore.loader('show');
    if(refund.value.id == undefined || ((refund.value.id) && refund.value.id != refund_id.value)) {
        await refundStore.tryRefund(refund_id.value)
    } 
    isLoaded.value = true;
    globalStore.loader('hide');
});

const title = {title: 'Reembolso #' + refund_id.value , icon: 'fa-badge-dollar'};

//POPUP

//POPUP

const replyBox = shallowRef(null);
import RefundReply from '@/views/refunds/RefundReply.vue';
import RefundAccept from '@/views/refunds/RefundAccept.vue';
import RefundArriveUpdate from '@/views/refunds/RefundArriveUpdate.vue';

function changeReplyBox(r) {
    if(r == 'reply') {
        replyBox.value = RefundReply;
    } else if(r == 'accept') {
        replyBox.value = RefundAccept;
    } else if(r == 'arrive') {
        replyBox.value = RefundArriveUpdate;
    }
}

function clickToClose() {
    replyBox.value = null;
}

</script>
    
<style scoped>
.box_replies_reply{
    margin-top: -30px;
    border-radius: 0px 0px 8px 8px;
}
.btns{
    display: inline-block;
}

.download_card{
    margin: 0 0 0 auto;
}

.base-title .badget {
    position: relative;
    top: -2px;
    margin-left: 10px!important;
}

@media screen and (max-width: 992px){
    .btns, .btns button{
        width: 100%;
        margin-top: 15px;
    }
    .d-flex{
        display: block!important;
    }
}
</style>