<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <header>
                <font-awesome-icon icon="fa-solid fa-file" style="color: #215eda;"/>
                <span v-if="module.id != null"  class="ms-2 align-middle">Editar módulo</span>
                <span v-else class="ms-2 align-middle">Novo módulo</span>
            </header>

            <div class="content">
            <form @submit.prevent="">
                <div class="row">
                    <BaseInput
                        class="col-12 mb-3"
                        label="Título *"
                        type="text"
                        id="title"
                        v-model="module.title"
                        :page="route.name"
                    />
                </div>
                <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                    Salvar
                </BaseButton>
            </form>
            </div>
    </BasePopup>
</template>

<script setup>

import { computed, onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from "vue-router"; 
import { useGlobalStore } from '@/stores/global';
import { useProductStore } from '@/stores/products';
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();

const productStore = useProductStore();

const globalStore = useGlobalStore();
const { module } = storeToRefs(productStore);

const product_id = computed (function () {
    return route.params.id;
});

const isLoaded = ref(false)
const isLoading = ref(false);

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(route.name == 'productCourseModulesEdit'){
        if(productStore.module.id){
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            productStore.tryModule(product_id.value, route.params.slug).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'productCourseModulesCreate'){
        globalStore.cleanObject(productStore.module);
        globalStore.loader('hide')
        isLoaded.value = true
    }
})


let previousPage = router.options.history.state.back;
function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        previousPage = previousPage.substring(0, previousPage.lastIndexOf("/"));
        router.push(previousPage)
    }
}   

function submit(){
    if(!isLoading.value){
        globalStore.loader('show')

        const params = [
            'title',
        ]

        if(productStore.module.id == null){
            productStore.tryCreateModule(product_id.value, params, route.name).then(function(success) {
                if(success){
                    useGlobalStore().successMessage('Módulo cadastrado com sucesso');

                    productStore.tryCourseContent(product_id.value).then(function() {
                        globalStore.loader('hide');
                    });

                    router.push('/products/' + product_id.value + '/course');
                }else {
                    globalStore.loader('hide');
                }
            })
        } else {
            productStore.tryUpdateModule(product_id.value, productStore.module.id, params, route.name).then(function(success) {
                globalStore.loader('hide');
                    if(success){
                        useGlobalStore().successMessage('Módulo atualizado com sucesso');

                        productStore.tryCourseContent(product_id.value).then(function() {
                            globalStore.loader('hide');
                        });

                        router.push('/products/' + product_id.value + '/course');
                    }else {
                        globalStore.loader('hide');
                    }
            })
        }
    }
}

</script>