<template>
    <div class="row">
        <div :class="'first_box col-12 ' + [order.ordered_as_productor ? 'col-xl-8' : 'mb-4 mb-xl-0']">
            <div class="box_content">
                <div class="divisor_bottom pb-1">
                    <div class="row p-4">
                        <div class="col-md my-lg-0 my-2">
                            <h5>Loja</h5>
                            <BaseStoreDot :color="order.store.color" :title="order.store.title" />
                        </div>
                        <div v-if="order.duration" class="col-md my-lg-0 my-2">
                            <h5>Duração</h5>
                            <span v-if="order.duration !== undefined">{{ globalStore.formatSeconds(order.duration) }}</span>
                            <div v-else class="loading_component" style="width:80%"></div>
                        </div>
                        <div class="col-md my-lg-0 my-2">
                            <h5>Data</h5>
                            <span>{{ globalStore.formatDate(order.payment.date) }}</span>
                        </div>
                    </div>
                </div>
                <div class="divisor_bottom">
                    <div class="p-4">
                        <div class="row">
                            <div class="col-12 col-lg-6 my-2">
                                <h6>Pagamento</h6>
                                <a v-if="order.payment.payment_method == 'boleto'" :href="order.payment.boleto_url" target="_blank" class="boleto">{{ order.payment.payment }} <font-awesome-icon icon="far fa-external-link-square" /></a>
                                <span class="" v-else-if="order.payment.payment_method == 'credit-card'">{{ order.payment.payment }} em {{ order.payment.credit_card_installments }}x</span>
                                <span v-else>{{ order.payment.payment }}</span>
                                <div v-if="order.payment.credit_card_brand" class="mt-2">
                                    <img v-if="order.payment.credit_card_brand" :src="require(`@/assets/brands/${order.payment.credit_card_brand.toLowerCase()}.png`)" class="me-2" />
                                    <span v-if="order.payment.credit_card_last_digits">**** **** **** {{ order.payment.credit_card_last_digits }}</span>
                                </div>
                            </div>
                            <template v-if="order.payment.recurrent && order.payment.recurrent_info">
                                <div class="col-12">
                                    <h6>Recorrência</h6>
                                    <template v-if="order.payment.recurrent_info.status == 1">
                                        <p class="mb-0"><b>Período:</b> {{ order.payment.recurrent_info.recurrent_period.count }} {{ recurrenceName(order.payment.recurrent_info.recurrent_period.interval) }}</p>
                                        <b style="color:#28a745">Ativa</b>
                                        <span> - Nova cobrança: {{ globalStore.formatDateSimple(order.payment.recurrent_info.next_payment) }}</span>
                                        <baseButton @click="openPopup" class="btn-danger d-block mt-2">Cancelar</baseButton>
                                    </template>
                                    <b v-else style="color:#c51515">Cancelada</b>
                                </div>
                            </template>
                            <template v-if="order.payment.payment_method != 'credit-card'">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="input_copy">
                                                <input v-if="order.payment.payment_method == 'pix'" id="content" disabled type="text" :value="order.payment.pix">
                                                <input v-else id="content" disabled type="text" :value="order.payment.boleto_barcode">
                                                <button @click="copy" id="copy_button" class="btn">Copiar código</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="divisor_bottom" v-if="order.custom_fields && order.custom_fields.length">
                    <div class="p-4">
                        <h6 class="mb-3">Campos personalizados</h6>
                        <div class="row">
                            <div class="col-12">
                                <p v-for="(field, key) in order.custom_fields" :key="key" class="mb-1"><strong>{{ field.title }}:</strong> {{ field.value }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="p-4">
                        <h6 class="mb-3">Dados do comprador</h6>
                        <div class="row">
                            <div class="col-12">
                                <p class="mb-1"><strong>Nome:</strong> {{ order.client.name}}</p>
                                <p class="mb-1"><strong>Telefone:</strong> {{ order.client.phone}}</p>
                                <p class="mb-1"><strong>E-mail:</strong> {{ order.client.email}}</p>
                                <p v-if="order.ordered_as_productor" class="mb-1"><strong>CPF/CNPJ:</strong> {{ order.client.cpf}}</p>
                                <p v-if="order.ordered_as_productor && order.client.address">
                                    <strong>Endereço:</strong> {{ order.client.address }}, {{ order.client.address_number }}, <span v-if="order.client.complement">{{ order.client.complement }},</span> {{ order.client.neighborhood }}<br />
                                    {{ order.client.city }} - {{ order.client.state }}<br />{{ order.client.postcode }}
                                </p>
                            </div>
                        </div>

                        <div v-if="order.ordered_as_productor" class="mt-2">
                            <BaseButton link :to="'/orders/' + order.id + '/client'" class="btn-secondary">Editar dados</BaseButton>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box_content mt-4" v-if="order.references || order.http_referer">
                <div class="p-4">
                    <h6 class="mb-3">Rastreamento</h6>
                    <div class="row mb-1" v-if="order.http_referer">
                        <div class="col-md-12 tracking_referer">
                            <p><strong>Origem:</strong> {{ order.http_referer }}</p>
                        </div>
                    </div>

                    <div class="row" v-if="order.references">
                        <div class="col-md-12" v-for="(reference, index) in order.references" :key="index">
                            <p class="mb-1"><strong>{{ formatReference(index) }}:</strong> {{ reference }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box mt-4">
                <TableProducts :products="order.products" :table_values="order.table_values" />
            </div>
        </div>
        <div v-if="order.ordered_as_productor" class="col-xl-4 col-12 second_box mt-md-0 mt-4">
            <div class="box_content ">
                <div class="p-4">

                    <div v-if="order.can_generate_nfe" class="sidebar_widget">
                        <baseButton :loading="nfeButtonLoading" @click="genrateNfe(order.id)" class="btn-primary w-100">Gerar NF-e</baseButton>
                    </div>

                    <div v-if="order.nfe" class="sidebar_widget">

                        <div class="btn-group w-100">
                            <baseButton class="btn-primary dropdown-toggle w-100" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">NF-e</baseButton>

                            <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                                <a v-for="(nfe, key) in order.nfe" :key="key" class="dropdown-item text-uppercase" :href="nfe" target="_blank">{{ key }}</a>
                            </div>
                        </div>

                    </div>

                    <div v-if="order.tracking" class="sidebar_widget">
                        <h6 class="mb-3">Rastreio de transporte</h6>
                            <BaseInput v-model="order.tracking.code" label="Código" :page="page" id="track_code" />
                        <div class="mb-3">
                            <BaseInput v-model="order.tracking.url" label="Url para consulta" :page="page" id="track_url" />
                        </div>
                        <BaseSwitch v-model:checked="order.tracking.send_mail" label="Enviar e-mail" :page="page" id="send_mail" />

                        <BaseButton :loading='sendTrackingButtonLoading' @click="sendTracking" class="btn-primary w-100">Salvar</BaseButton>
                    </div>

                    <div v-else class="sidebar_widget">
                        <h6 class="mb-3">Rastreio</h6>
                            <BaseInput :disabled="!order.historic" label="Código" :page="page" id="track_code" />
                        <div class="mb-3">
                            <BaseInput :disabled="!order.historic" label="Url para consulta" :page="page" id="track_url" />
                        </div>
                        <BaseSwitch :disabled="!order.historic" label="Enviar e-mail" :page="page" id="send_mail" />

                        <BaseButton :disabled="!order.historic" class="btn-primary w-100">Salvar</BaseButton>
                    </div>

                    <div class="sidebar_widget">
                        <h6>Histórico do Pedido</h6>
                        <div>
                            <ul v-if="order.historic" id="order_notes">
                                <li v-for="(hist, index) in order.historic" :key="index" :class="order.historic.length === 1 ? 'single_note': ''">
                                    <p class="m-0" v-html="hist.text"></p>
                                    <span>{{ globalStore.formatDateLong(hist.date) }}</span>
                                </li>
                            </ul>
                            <ul v-else id="order_notes">
                                <li><div class="loading_component"></div></li>
                                <li><div class="loading_component"></div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <BasePopup v-if="showPopup && order.payment.recurrent" hideCloseButton closeType="stayOnPage" width="400" @clickToClose="closePopup">
        <div class="popup text-center">
            <h2>Atenção</h2>
            <p class="mb-0">Tem certeza que deseja cancelar essa recorrência?</p>
            <p class="mb-0">Essa ação não pode ser desfeita.</p>
            <div class="mt-4">
                <BaseButton style="width: 120px;" class="btn-secondary m-1" @click="closePopup">Não</BaseButton>
                <BaseButton class="btn-danger m-1" @click="cancelRecurrent">Cancelar recorrência</BaseButton>
            </div>
        </div>
    </BasePopup>
    <router-view></router-view>
</template>

<script setup>
import { ref } from 'vue';
import TableProducts from '@/components/includes/orders/TableProduct';
import { storeToRefs } from 'pinia';

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useOrderStore } from '@/stores/order';
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

function formatReference(r) {
    let size = r.length;
    let replace = r.replace('_', ' ');
    let upper = replace.substring(0, 3).toUpperCase();
    let over = replace.substring(3, size);
    return upper + over;
}

function copy() {
    var copyText = document.getElementById('content');
    copyText.focus();
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    var button = document.getElementById('copy_button');
    button.innerHTML = 'Copiado';
    button.style.backgroundColor = '#28a745';
    setTimeout(() => {
        button.innerHTML = 'Copiar código';
        button.style.backgroundColor = '#000';
    }, 1000);
}

const page = ref('orderEdit');

const nfeButtonLoading = ref(false);
function genrateNfe(id) {
    if(nfeButtonLoading.value) {
        return;
    }
    nfeButtonLoading.value = true;
    orderStore.tryGenerateNfe(id).then(() =>{
        nfeButtonLoading.value = false;
    });
}

const sendTrackingButtonLoading = ref(false);
function sendTracking() {
    if(sendTrackingButtonLoading.value) {
        return;
    }

    sendTrackingButtonLoading.value = true;

    let params = {
        'track_code': order.value.tracking.code,
        'track_url': order.value.tracking.url,
        'send_mail': order.value.tracking.send_mail
    }

    orderStore.tryUpdateTracking(page.value, order.value.id, params).then(() =>{
        sendTrackingButtonLoading.value = false;
    });
}

const showPopup = ref(false);

function openPopup() {
    showPopup.value = true;
}

function closePopup() {
    showPopup.value = false;
}

function cancelRecurrent() {
    globalStore.loader('show');
    showPopup.value = false;
    order.value.payment.recurrent = false;
    orderStore.tryCancelRecurrent(page.value, order.value.id).then(() =>{
        orderStore.tryOrderEdit(order.value.id);
        globalStore.loader('hide');
    });
}

function recurrenceName(slug) {
    if(slug == 'day') {
        return 'Dia(s)';
    } else if(slug == 'week') {
        return 'Semana(s)';
    } else if(slug == 'month') {
        return 'Mês(es)';
    } else if(slug == 'year') {
        return 'Ano(s)';
    }
}
</script>

<style scoped>
.box_content{
    margin-top: -1px;
}

.first_box{
    padding-right: 10px;
}

.col-md{
    padding-left: 10px;
    padding-right: 10px;
}

h5{
    font-size: 14px;
    font-weight: 600;
    margin-top: 2px;
    margin-bottom: 6px;
}
.input_copy{
    border: 1px #ddd solid;
    padding: 4px 10px;
    height: 40px;
    padding-right: 140px;
    font-size: 18px;
    text-align: left;
    border-radius: 40px;
    position: relative;
    width: 100%;
}

.input_copy input{
    border: 0;
    width: 100%;
    font-size: 14px;
    background-color: white;
}

.input_copy button {
    background-color: #000;
    color: #fff !important;
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 5px 15px 5px 15px;
    border-radius: 25px;
    font-size: 14px;
    width: 132px;
    text-align: center;
}

.input_copy button:hover{
    background-color: #505050;
}

strong{
    font-weight: 600;
}

.tracking_referer{
    word-wrap: break-word;
}

#order_notes {
  padding: 5px;
  margin-bottom: 0;
  margin-top: 16px;
}

#order_notes li {
  list-style: none;
  padding-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

#order_notes li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

#order_notes li span {
  display: block;
  font-size: 12px;
  color: #a0a0a0;
}

#order_notes li:before {
  content: '';
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  border-radius: 25px;
  position: absolute;
  margin-top: 4px;
  left: -2px;
  border: 3px #205ed9 solid;
  z-index: 1;
}

#order_notes li:after {
  content: '';
  width: 4px;
  height: 100%;
  background-color: #205ed9;
  position: absolute;
  margin-top: 0px;
  top: -16px;
  left: 3px;
}

#order_notes li:first-child:after {
  margin-top: 22px;
}

#order_notes li:last-child:after {
  margin-top: -6px;
}

.sidebar_widget {
    margin-bottom: 40px;
}

.sidebar_widget:last-child {
    margin-bottom: 0px;
}

.loading_component {
    margin: 0;
}

.col-md-12:last-child p {
    margin-bottom: 0px !important;
}

.boleto:hover {
    text-decoration: underline !important;
}

#order_notes .single_note:after{
    display: none!important;
}

.input_copy input:focus {
    outline: none;
}

</style>