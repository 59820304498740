<template>
    <div :class="'mb-3 ' + [!login ? 'form-group' : ''] + [(type == 'password' || tag) && !textarea ? ' position-relative' : '']">
        <label v-if="label" :for="id" :class="[login ? 'login-label' : '']+ ' ' + [error && !login ? 'danger-label' : '']">
            {{ label }}
            <span 
                v-if="tooltip" data-bs-toggle="tooltip" 
                data-bs-placement="top" data-bs-animation="true" 
                v-tooltip :title="tooltip" class="ms-1">
                <font-awesome-icon icon="fas fa-circle-info" />
            </span>
        </label>
        
        <div class="position-relative">
            <input
                :id="id"
                :type="currentType"
                :required="!!required"
                :value="modelValue"
                :min="min"
                :max="max"
                @input="updateInput"
                @focus="focusedInput"
                @blur="bluredInput"
                :disabled="disabled == 1"
            />
            <span>{{ modelValue }}{{ format }}</span>
        </div>
    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global';
import { vMaska } from "maska"

export default {
    emits: ["update:modelValue", "focusedInput", 'bluredInput'],
    directives: { maska: vMaska },
    data() {
        return {
            global: useGlobalStore(),
            currentType: this.$props.type,
            pageProp: this.$props.page,
        }
    },

    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },

        id: {
            type: String,
            required: true,
        },

        type: {
            type: String,
            required: false,
            default: 'text'
        },

        required: {
            type: Boolean,
            required: false,
            default: null,
        },


        modelValue: {
        },

        page: {
            type: String,
            required: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        tooltip:{
            required: false,
        },

        format:{
            required: false,
            default: null
        },

        min:{
            required: false,
            default: null
        },
        
        max:{
            required: false,
            default: null
        },
    },
    methods: {
        updateInput(event) {
            this.$emit("update:modelValue", event.target.value);

            if(event.isTrusted == true && this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },
        clickEye() {
            if(this.$props.type == this.currentType && this.currentType == 'password') {
                this.currentType = 'text';
            }else {
                this.currentType = 'password';
            }
        },
        focusedInput(){
            this.$emit("focusedInput")
        },

        bluredInput(){
            this.$emit("bluredInput")
        }
    },
    watch: {
        pageProp (value){
            this.global.fields_pages[this.$props.id] = value;
        }
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>

<style scoped>
label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: #545454;
}

input {
    width: 100%
}
</style>

