<template>
    <div class="box_content">
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th class="ps-3">Produto</th>
                        <th class="ps-3">Ordem de exibição</th>
                        <th class="ps-3">Status</th>
                        <th class="ps-3"></th>
                    </tr>
                </thead>
                <tbody v-if="upsells.length">
                    <tr v-for="(upsell, key) in upsells" :key="key">
                        <td >
                            {{ upsell.title }}
                        </td>
                        <td >
                            {{ upsell.sequence }}ª
                        </td>
                        <td >
                            {{ upsell.status_name }}
                        </td>
                        <td class="actions">
                            <router-link data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Personalizar" :to="'upsells/' + upsell.id + '/customization'"><font-awesome-icon icon="fas fa-paint-roller" /></router-link>

                            <button @click="editUpsell(upsell.id)" type="button" class="icon_buttons icon_view">
                                 <font-awesome-icon icon="fas fa-pen-to-square" />
                            </button>
                            <button @click="openPopup(upsell.id)" type="button" class="icon_buttons icon_view">
                                <font-awesome-icon icon="fa-solid fa-trash-can" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!upsells.length" class="text-center p-4 mb-0 divisor_bottom">Nenhum resultado encontrado</h6>
        </div>
        <div style="text-align:center;" class="p-4">
            <BaseButton @click="createUpsell()" class="btn-primary btn-lg">
                Adicionar
            </BaseButton>
        </div>
    </div>
    <router-view></router-view>

    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover este cross-sell?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteUpsell" style="width:150px" class="btn-success ms-2" :disabled="excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
    </BasePopup>
</template>

<script setup>
import { computed, shallowRef, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter} from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { upsells }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const product_id = computed (function () {
    return route.params.id;
});


let upsellToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null)

function editUpsell(id){
    router.push('/products/' + product_id.value + '/upsells/' + id)
    globalStore.loader('hide');
}

function createUpsell(){
    router.push('/products/' + product_id.value + '/upsells/new')
}
async function deleteUpsell(){
    if(!isDeleting && upsellToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        globalStore.loader('show');
        await productStore.tryDeleteUpsell(upsellToDelete).then(function(success) {
            isDeleting = false;
            if(success){
                useGlobalStore().successMessage('Cross-sell removido com sucesso');

                productStore.tryUpsells().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    router.push('/products/' + product_id.value + '/upsells')
                });

                confirmAction.value = null;
            }else {
                globalStore.loader('hide');
                isDeleting = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function openPopup(id) {
    upsellToDelete = id
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}
</script>

<style scoped>

.table_bordered {
    background: #fff;
    border-radius: 8px;
}

thead {
    background: #fff;
    color: #595959;
}

tbody{
    border-bottom: 1px #ddd solid ;
}

tbody td {
    padding: 12px 16px;
    vertical-align: middle;
    color: #595959;
}

tbody tr:last-of-type {
    border-bottom: 0px solid #fff !important;
}

tbody tr:hover {
    background: #f3f3f3 !important;
}
</style>