<template>
    <div class="container px-2 px-lg-4">
        <div class="box_content nav_div p-4">
            <p class="mb-1" v-if="withdrawalStore.withdrawal.created_at"><strong>Data:</strong> {{withdrawalStore.withdrawal.created_at }}</p>
            <p class="mb-3" v-if="withdrawalStore.withdrawal.total" ><strong>Valor:</strong> {{ globalStore.formatMoney(withdrawalStore.withdrawal.total) }}</p>
            <template v-if="withdrawalStore.withdrawal.transfer_information.Banco">
                <p class="mb-1"><strong>Banco: </strong>{{ withdrawalStore.withdrawal.transfer_information.Banco  }}</p>
                <p class="mb-1"><strong>Agência: </strong>{{ withdrawalStore.withdrawal.transfer_information.Agência  }}</p>
                <p class="mb-1"><strong>Conta: </strong>{{ withdrawalStore.withdrawal.transfer_information.Conta  }}</p>
            </template>
            <template v-else-if="withdrawalStore.withdrawal.transfer_information.pix_key">
                <p class="mb-1"><strong>Chave pix: </strong>{{ withdrawalStore.withdrawal.transfer_information.pix_key }}</p>
            </template>
        </div>
    </div>
    <div v-if="withdrawalStore.withdrawal.batches.length != 0" class="container px-2 px-lg-4">
        <BaseTable :noActions="true" :columns="['Depósitos']" :qty="withdrawalStore.withdrawal.order.length">
            <tr v-for="(batche, key) in withdrawalStore.withdrawal.batches" :key="key">
                <td>
                    {{ globalStore.formatMoney(batche) }}
                </td>
            </tr>
        </BaseTable>
    </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useWithdrawalStore } from '@/stores/withdrawal'
const withdrawalStore = useWithdrawalStore();
</script>

<style scoped>
p {
    margin-bottom: 5px;
}
strong {
    font-weight: 600;
}
</style>