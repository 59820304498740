<template>
    <div id="header">
        <div class="container">

            <div v-if="accountStore.classification.current_amount" id="classification" class="overlay_mobile">
                <router-link to="/account/classification" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" data-bs-html="true" v-tooltip title="Classificação">
                    <font-awesome-icon icon="fa-trophy-star" id="trophy_icon" />
                    <div id="bar"><span></span></div>
                    R$ {{ accountStore.classification.current_amount }} / R$ {{ accountStore.classification.title }}
                </router-link>
            </div>
            <span id="HideSensitive" class="hide_sensitive overlay_mobile" @click="toggleSensitive"><font-awesome-icon :icon="['fas', 'eye']" class="eye_visible"/><font-awesome-icon :icon="['fas', 'eye-slash']" class="eye_slash"/></span>
            <router-link to="/reports/recommendations" class="overlay_mobile"><button class="btn btn_white_border me-3"><font-awesome-icon icon="fa-regular fa-bullhorn" class="me-1" /> Indique e ganhe</button></router-link>
            <div v-bind:class="{ 'has ' : accountStore.have_unread_notifications}" id="notifications">

                <button class="btn btn_icon dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <font-awesome-icon v-if="accountStore.have_unread_notifications" icon="fa-regular fa-bell" class="fa-shake" />
                    <font-awesome-icon v-else icon="fa-regular fa-bell" />
                </button>
                    <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                        <ul v-if="accountStore.notifications.length">
                            <li v-for="(notification, key) in accountStore.notifications" :key="key" :class="{ 'unread' : notification.read == 0}" @click="closeMenu"><router-link class="dropdown-item" :to="'/' + notification.path" @click="[ notification.read == 0 ? markReadNotification(notification.id, key) : '']">{{ notification.message }}<span>{{ globalStore.formatDate(notification.date) }}</span></router-link></li>
                        </ul>
                        <div v-else id="no_notifications" class="p-3">Nenhuma mensagem</div>
                    </div>
            </div>

            <div class="btn-group dropdown">
                <button class="btn btn_icon dropdown-toggle dropdown-toggle-no-caret user_letter" id="dropdownLetterAccount" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>{{ authStore.name.charAt(0) }}</span></button>
                <div class="dropdown-menu" id="dropdown_account"
                 x-placement="bottom-start" aria-labelledby="dropdownLetterAccount" >
                    <p>
                        Olá, {{ first_name }}
                        <span>{{ authStore.email }}</span>
                    </p>
                    <router-link to="/account" class="dropdown-item d-flex justify-content-center flex-column"  @click="closeMenu">Minha conta</router-link>
                    <router-link to="/account/user" class=" dropdown-item d-flex justify-content-center flex-column"  @click="closeMenu">Dados de acesso</router-link>
                    <a @click="logOut" class="dropdown-item d-flex justify-content-center flex-column">Sair</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useAccountStore } from '@/stores/account';
import { useGlobalStore } from '@/stores/global';

const authStore = useAuthStore();
const accountStore = useAccountStore();
const globalStore = useGlobalStore();
const first_name = ref(null)
const emit = defineEmits(['isHidingSensitive']);

onBeforeMount(() =>{
    first_name.value = authStore.name.split(" ")[0];
    accountStore.tryAccountClassification();
    accountStore.tryAccountNotifications();
})

onMounted(() =>{
    const getSpan = document.getElementById('HideSensitive');
    const getSvg = document.querySelector('.eye_visible');
    const getSvgHiding = document.querySelector('.eye_slash');

    if(getSvg){
        if(accountStore.user_preferences.sensitive_information == 0){
            getSvg.style.display = 'block';
            getSvgHiding.style.display = 'none';
            getSpan.classList.remove('hiding')

            emit('isHidingSensitive', false)
        } else{
            getSvgHiding.style.display = 'block';
            getSvg.style.display = 'none'
            getSpan.classList.add('hiding')

            emit('isHidingSensitive', true)
        }
    }
})

async function logOut() {
    await authStore.tryLogout();
}

async function markReadNotification(id, key){
    await accountStore.tryMarkReadNotification(id, key)
}

function toggleSensitive(){
    globalStore.loader('show');
    const getSpan = document.getElementById('HideSensitive');
    const getSvg = document.querySelector('.eye_visible');
    const getSvgHiding = document.querySelector('.eye_slash');

    if(getSpan.classList.contains('hiding')){
        accountStore.trySwichSensitiveInfo(0).then(function(success){
            if(success){
                emit('isHidingSensitive', false)

                getSvg.style.display = 'block';
                getSvgHiding.style.display = 'none';
                getSpan.classList.remove('hiding')
                globalStore.loader('hide');
                accountStore.trySensitiveInfo();
            } else {
                globalStore.loader('hide');
                return;
            }
        })
    } else{
        accountStore.trySwichSensitiveInfo(1).then(function(success){
            if(success){
                emit('isHidingSensitive', true)

                getSvgHiding.style.display = 'block';
                getSvg.style.display = 'none'
                getSpan.classList.add('hiding')
                globalStore.loader('hide');
                accountStore.trySensitiveInfo();
            } else {
                globalStore.loader('hide');
                return;
            }
        })

    }
}

function closeMenu() {
    if(window.innerWidth <= 992){
        document.getElementById('navbar').style.height = '65px';
        document.getElementById('footer_nav').style.display = 'none';
    }
}

</script>

<style scoped>
#header {
    border-bottom: 1px #ddd solid;
    position: fixed;
    z-index: 100;
    background-color: #f7f7f7;
    width: 100%;
    padding-left: 70px;
}

#header .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    padding-left: 25px;
    padding-right: 25px;
}

.btn_white_border {
    background-color: #fff;
    border: 1px #d7d7d7 solid;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 25px;
    white-space: nowrap;
    width: 100%;
    border: 1px solid #d7d7d7;
    color: #595959;
}

.btn_white_border:hover {
    background-color: #e6e6e6;
}

.btn_icon {
    font-size: 16px;
    padding: 12px;
    border-radius: 25px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 !important;
    font-size: 18px;
}

.btn_icon:hover {
    background-color: #ececec;
}

.btn_icon:active {
    background-color: #ececec;
}

.hide_sensitive {
    color: #215eda;
    cursor: pointer;
    user-select: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
}

.hide_sensitive:hover{
    background-color: #d7d7d7;
}

.hide_sensitive svg{
    margin: auto;
}

#notifications {
    position: relative;
    margin-left: 5px;
}

#notifications .dropdown-toggle::after {
    display: none;
}

.eye_slash{
    display: none;
}

#notifications.has:before {
    content: '';
    width: 13px;
    height: 13px;
    display: block;
    background-color: #ffb000;
    border-radius: 25px;
    position: absolute;
    top: 3px;
    right: 8px;
    border: 3px #f7f7f7 solid;
    z-index: 2;
}

#notifications ul {
    padding: 5px;
    margin: 0;
    max-height: 290px;
    overflow-y: scroll;
}

#notifications ul li {
    margin-bottom: 5px;
}

#notifications ul li:last-child {
    margin-bottom: 0px;
}

#notifications ul li span {
    display: block;
    font-size: 13px;
    color: #a1a1a1;
} 

#notifications .dropdown-menu {
    padding: 0;
    min-width: 250px;
}

#notifications .dropdown-menu .dropdown-item {
    white-space: inherit;
}

#notifications ul li.unread a {
    background-color: #e2ebf2;
    font-weight: 500;
}

#no_notifications {
    font-size: 14px;
    white-space: nowrap;
}

#dropdown_account {
    min-width: 200px;
}

#dropdown_account p {
    padding: 10px 15px 15px 15px;
    font-size: 14px;
    border-bottom: 1px #e4e4e4 solid;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 10px;
}

#dropdown_account p span {
    font-size: 12px;
    color: #9d9d9d;
    font-weight: 400;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.user_letter {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 25px;
    margin-right: -5px;
}

.user_letter span {
    background-color: #215eda;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
}

.user_letter::after {
    display: none;
}

#classification {
    margin-right: 50px;
    font-size: 12px;
    display: flex;
    width: 310px;
    margin-right: auto;
    color: #818181 !important;
}


#classification a {
    display: flex;
    align-items: center;
    width: 100%;
    color: #818181 !important;
    padding: 15px 0px;
}

#classification a:hover {
    opacity: 0.7;
}   

#classification #bar {
    background: v-bind(accountStore.classification.color_light);
    border-radius: 25px;
    max-width: 140px;
    width: 100%;
    height: 6px;
    margin-right: 15px;
    overflow: hidden;
}

#classification #bar span {
    background-color: v-bind(accountStore.classification.color_dark);
    display: block;
    width: v-bind(accountStore.classification.percent + '%');
    height: 100%;
}

#classification #trophy_icon {
    margin-right: 10px;
    color: v-bind(accountStore.classification.color_dark);
    font-size: 16px;
}

@media screen and (max-width: 992px){
    #header{
        position: fixed !important;
        z-index: 102;
        top: 0 !important;
        right: 0;
        background-color: transparent !important;
        border: none !important;
        width: 100px;
        padding: 0px;
    }

    .overlay_mobile{
        display: none!important;
    }
}

</style>