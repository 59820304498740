<template>
    <div class="box_content">
        <div style="text-align:center;" class="p-4">
            <router-link :to="''"> 
                <BaseButton class="btn-primary btn-lg dropdown-toggle"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    Adicionar conteúdo
                </BaseButton>
                <div class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                    <router-link class="dropdown-item" :to="'/products/' + product_id + '/course/modules/new'">Módulo</router-link>
                    <span @click="newLessonModule" class="dropdown-item" :to="'/products/' + product_id + '/course/lessons/text/new'">Texto</span>
                    <span @click="newVideoModule" class="dropdown-item" :to="'/products/' + product_id + '/course/lessons/video/new'">Vídeo</span>
                    <span @click="newQuizModule" class="dropdown-item" :to="'/products/' + product_id + '/course/lessons/quiz/new'">Quiz</span>
                </div>
            </router-link>
        </div>
        <div class="divisor_bottom"></div>
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <template v-if="course_content.length">
                    <tbody v-for="(modules, key_module) in  course_content" :key="key_module">
                        <tr class="module_title">
                            <td>
                                <font-awesome-icon icon="fa-solid fa-bars" class="me-3"  v-if="modules.lessons"/>
                                <font-awesome-icon v-else-if="modules.type == 'text'" icon="fa-regular fa-file-lines" class="me-3"/>
                                <font-awesome-icon v-else-if="modules.type == 'quiz'" icon="fa-regular fa-puzzle-piece" class="me-3"/>
                                <font-awesome-icon v-else-if="modules.type == 'video'" icon="fa-regular fa-video" class="me-3"/>
                                {{ modules.title }}
                            </td>
                            <td class="actions">
                                <button  v-if="modules.lessons" type="button" class="icon_buttons dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <font-awesome-icon icon="fa-solid fa-circle-plus" />
                                </button>
                                <div v-if="modules.lessons" class="dropdown-menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                                    <span @click="newLesson(modules.id)" class="dropdown-item">Texto</span>
                                    <span @click="newVideo(modules.id)" class="dropdown-item">Vídeo</span>
                                    <span @click="newQuiz(modules.id)" class="dropdown-item">Quiz</span>
                                </div>
                                
                                <button v-if="modules.lessons" @click="editModule(modules.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fas fa-pen-to-square" />
                                </button>

                                <button  v-else @click="editLesson(modules.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fas fa-pen-to-square" />
                                </button>

                                <button v-if="modules.lessons" @click="openPopupModule(modules.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fa-solid fa-trash-can" />
                                </button>

                                <button  v-else @click="openPopupLesson(modules.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fa-solid fa-trash-can" />
                                </button>
                            </td>
                            <td class="move">
                                <button @click="moveModuleUp(key_module, modules.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fa-solid fa-angle-up" />
                                </button
                                >
                                <button @click="moveModuleDown(key_module, modules.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fa-solid fa-angle-down" />
                                </button>
                            </td>
                        </tr>
                        <tr v-for="(lesson, key_lesson) in modules.lessons" class="module_item" :key="key_lesson">
                            <td>
                                <font-awesome-icon v-if="lesson.type == 'text'" icon="fa-regular fa-file-lines" class="me-3"/>
                                <font-awesome-icon v-else-if="lesson.type == 'quiz'" icon="fa-regular fa-puzzle-piece" class="me-3"/>
                                <font-awesome-icon v-else-if="lesson.type == 'video'" icon="fa-regular fa-video" class="me-3"/>

                                {{ lesson.title }}
                            </td>
                            <td>
                                <button @click="editLesson(lesson.id, modules.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fas fa-pen-to-square" />
                                </button>
                                <button @click="openPopupLesson(lesson.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fa-solid fa-trash-can" />
                                </button>
                            </td>
                            <td class="move">
                                <button @click="moveLessonUp(key_module, key_lesson, lesson.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fa-solid fa-angle-up" />
                                </button>

                                <button @click="moveLessonDown(key_module, key_lesson, lesson.id)" type="button" class="icon_buttons">
                                    <font-awesome-icon icon="fa-solid fa-angle-down" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <tbody v-else>
                    <h6 style="text-align: center; padding: 20px;">Nenhum resultado encontrado</h6>
                </tbody>
            </table>
        </div>
    </div>
    <router-view :moduleId="module_id"></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover este conteúdo?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteContent" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
    </BasePopup>
</template>

<script setup>
import { computed, ref, shallowRef } from 'vue'
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';
import { useGlobalStore } from '@/stores/global'
import { useRoute } from "vue-router";
import router from '@/router';

const route = useRoute();

const productStore = useProductStore();
const { course_content } = storeToRefs(productStore);

const globalStore = useGlobalStore();
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null);
const isLoading = ref(false);

let isDeleting = false;
const product_id = computed (function () {
    return route.params.id;
});

const module_id = ref({})

function editModule(id){
    globalStore.loader('show');
    productStore.tryModule(product_id.value, id).then(() =>{
        globalStore.loader('hide');
        router.push('/products/' + product_id.value + '/course/modules/' + id)
    })
}

function editLesson(id, id_module){
    globalStore.loader('show');
    module_id.value = id_module
    productStore.tryLesson(product_id.value, id).then(() =>{
        globalStore.loader('hide');
        router.push('/products/' + product_id.value + '/course/lessons/' + id)
    })  
}

let debounceModule = null;
function moveModuleUp(key_module){
    for(let index = 0; index < productStore.course_content.length; index++){
        if(productStore.course_content[0].id == productStore.course_content[key_module].id){
            return;
        }
    }

    clearTimeout(debounceModule);
    productStore.module_sequence.modules = {};
    productStore.module_sequence.lessons = {};
    let element = productStore.course_content[key_module];

    productStore.course_content.splice(key_module, 1);
    productStore.course_content.splice(key_module - 1, 0, element);

    for(let index = 0; index < productStore.course_content.length; index++){
        if(!productStore.course_content[(index)].type){
            productStore.module_sequence.modules[productStore.course_content[index].id] = index;
        } else{
            productStore.module_sequence.lessons[productStore.course_content[index].id] = index;
        }
    }

    debounceModule = setTimeout(() => {
        productStore.tryUpdateModuleSequence(product_id.value)
    }, 1500)
}

function moveModuleDown(key_module){
    for(let index = 0; index < productStore.course_content.length; index++){
        if(productStore.course_content[(productStore.course_content.length - 1)].id == productStore.course_content[key_module].id){
            return;
        }
    }

    clearTimeout(debounceModule);
    productStore.module_sequence.modules = {};
    productStore.module_sequence.lessons = {};
    let element = productStore.course_content[key_module];


    productStore.course_content.splice(key_module, 1);
    productStore.course_content.splice(key_module + 1, 0, element);

    for(let index = 0; index < productStore.course_content.length; index++){
        if(!productStore.course_content[(index)].type){
            productStore.module_sequence.modules[productStore.course_content[index].id] = index;
        } else{
            productStore.module_sequence.lessons[productStore.course_content[index].id] = index;
        }
    }

    debounceModule = setTimeout(() => {
        productStore.tryUpdateModuleSequence(product_id.value)
    }, 1500)
}

let debounceLesson = null;
function moveLessonUp(key_module, key_lesson){
    for(let index = 0; index < productStore.course_content[key_module].lessons.length; index++){
        if(productStore.course_content[key_module].lessons[0].id == productStore.course_content[key_module].lessons[key_lesson].id){
            return;
        }
    }

    clearTimeout(debounceLesson)
    productStore.lesson_sequence = {};
    let element = productStore.course_content[key_module].lessons[key_lesson];

    productStore.course_content[key_module].lessons.splice(key_lesson, 1);
    productStore.course_content[key_module].lessons.splice(key_lesson - 1, 0, element);

    for(let index = 0; index < productStore.course_content[key_module].lessons.length; index++){
        productStore.lesson_sequence[productStore.course_content[key_module].lessons[index].id] = index;
    }
    debounceLesson = setTimeout(() => {
        productStore.tryUpdateLessonSequence(product_id.value);
    }, 1500)
}

function moveLessonDown(key_module, key_lesson){
    for(let index = 0; index < productStore.course_content[key_module].lessons.length; index++){
        if(productStore.course_content[key_module].lessons[(productStore.course_content[key_module].lessons.length - 1)].id == productStore.course_content[key_module].lessons[key_lesson].id){
            return;
        }
    }

    clearTimeout(debounceLesson)
    productStore.lesson_sequence = {};
    let element = productStore.course_content[key_module].lessons[key_lesson];

    productStore.course_content[key_module].lessons.splice(key_lesson, 1);
    productStore.course_content[key_module].lessons.splice(key_lesson + 1, 0, element);

    for(let index = 0; index < productStore.course_content[key_module].lessons.length; index++){
        productStore.lesson_sequence[productStore.course_content[key_module].lessons[index].id] = index;
    }

    debounceLesson = setTimeout(() => {
        productStore.tryUpdateLessonSequence(product_id.value);
    }, 1500)
}

function newLesson(id_module){
    module_id.value = id_module
    router.push('/products/' + product_id.value + '/course/lessons/text/new')
 
}

function newLessonModule(){
    module_id.value = null
    router.push('/products/' + product_id.value + '/course/lessons/text/new')
}

function newVideoModule(){
    module_id.value = null
    router.push('/products/' + product_id.value + '/course/lessons/video/new')
}

function newVideo(id_module){
    module_id.value = id_module
    router.push('/products/' + product_id.value + '/course/lessons/video/new')
}


function newQuizModule(){
    module_id.value = null
    router.push('/products/' + product_id.value + '/course/lessons/quiz/new')
}

function newQuiz(id_module){
    module_id.value = id_module
    router.push('/products/' + product_id.value + '/course/lessons/quiz/new')
}

let lessonToDelete = null;
let moduleToDelete = null;
async function deleteContent() {
    if(!isDeleting && !isLoading.value && moduleToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await productStore.tryDeleteModule(product_id.value, moduleToDelete).then(function(success) {
            if(success){
                useGlobalStore().successMessage('Módulo removido com sucesso');
            }

            globalStore.loader('hide');
            productStore.tryCourseContent(product_id.value);
            router.push('/products/' + product_id.value + '/course');
            isDeleting = false;
            lessonToDelete = null;
            confirmAction.value = null;
            excludeTextConfirm.value = null;
            isLoading.value = false;
        }
    )}else if(!isDeleting && lessonToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        globalStore.loader('show');
        await productStore.tryDeleteLesson(product_id.value, lessonToDelete).then(function(success) {
            if(success){
                useGlobalStore().successMessage('Lição removida com sucesso');
            }

            globalStore.loader('hide');
            productStore.tryCourseContent(product_id.value);
            router.push('/products/' + product_id.value + '/course');
            isDeleting = false;
            moduleToDelete = null;
            confirmAction.value = null;
            excludeTextConfirm.value = null;
            isLoading.value = false;
        })
    }
}

function openPopupModule(module_id) {
    moduleToDelete = module_id;
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function openPopupLesson(lesson_id) {
    lessonToDelete = lesson_id;
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}


</script>

<style scoped>
.first_content td{
    padding-left: 30px!important;
}

tr{
    border-bottom: 1px solid #d7d7d7;
}

.module_item td{
    padding-left: 50px !important;
    border-bottom: 0px;
}

/* navigation */

.page_item {
    background-color: white;
    border: 1px solid #d7d7d7;
    padding: 5px 11px;
}

.move {
    width: 100px;
    border-left: 1px #ddd solid;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.module_item td.move {
    padding-left: 10px !important;
}

table tbody tr:last-child.module_item td {
    border-bottom: 1px #ddd solid !important;
}
</style>