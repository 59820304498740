<template>
    <svg
        viewBox="0 44 116 94"
        style="enable-background: new 0 44 116 94"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    class="st0"
                    d="M47.4,117.4h11.5c5.1,0,8.9,3.9,8.9,9c0,5.2-3.8,9-8.9,9H47.4c-4.7,0-8.9-3.9-8.9-9
                    C38.5,121.2,42.8,117.4,47.4,117.4z"
                />
                <path
                    class="st0"
                    d="M18.1,96.6L1.5,57.9c-0.3-0.8-0.6-1.7-0.6-2.5c-0.1-0.3-0.1-0.7-0.1-1v-1.2c0-4.6,3.7-8.4,8.3-8.4h98.1
                    c4.6,0,8.3,3.8,8.3,8.4v1.2c0,4.6-3.7,8.4-8.3,8.4H23.1l12,28.1h42.7c4.6,0,8.3,3.8,8.3,8.4v1.2c0,4.6-3.7,8.4-8.3,8.4H28.9
                    C22.3,108.5,20.6,102.1,18.1,96.6L18.1,96.6z"
                />
            </g>
        </g>
    </svg>
</template>
    
<style scoped>
    .st0 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: v-bind('color');
    }
</style>

<script>
export default {
    props: {
        color: {
            required: false,
            default: '#20cfcb'
        },
    }
};
</script>