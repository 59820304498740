import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useHomeStore = defineStore('home', {

    state: () => ({
        home_infos: {
            payment_methods: []
        },
        home_balance: {},
        home_chart: {},
        home_traffic: {},
    }),

    actions: {

        async tryHomeInfos(params = null) {
            try {
                const response = await Api.get('home/informations',{params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.home_infos = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        async tryHomeBalance(params = null) {
            try {
                const response = await Api.get('home/',{params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.home_balance = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        async tryHomeChart(params = null) {
            try {
                const response = await Api.get('home/chart',{params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.home_chart = response.data.data.chart;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryLiveTraffic(params = null) {
            try {
                const response = await Api.get('home/live-traffic',{params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.home_traffic = response.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
    }
    }
);