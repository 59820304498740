<template>
    <div class="table-responsive">
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        Valor pago pelo comprador
                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Este é o valor que foi pago pelo comprador, considerando tarifas de parcelamento e possíveis cupons de desconto." class="ms-1"><font-awesome-icon icon="fas fa-circle-info" /></span>
                    </td>
                    <td class="row_right">{{ globalStore.formatMoney(props.comissions.total) }}</td>
                </tr>
                <tr>
                    <td>
                        Valor pago pelo comprador sem tarifas
                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Este é o valor pago pelo comprador, descontando possíveis tarifas de parcelamento e possíveis cupons de desconto." class="ms-1"><font-awesome-icon icon="fas fa-circle-info" /></span>
                    </td>
                    <td class="row_right">{{ globalStore.formatMoney(props.comissions.total_without_fees) }}</td>
                </tr>
                <tr>
                    <td>
                        Valor base para cálculo de tarifas e comissões
                        <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="O valor base para o cálculo das tarifas da Fullsale e comissões, é valor pago pelo comprador, descontando tarifas de parcelamento e possíveis cupons de desconto." class="ms-1"><font-awesome-icon icon="fas fa-circle-info" /></span>
                    </td>
                    <td class="row_right">{{ globalStore.formatMoney(props.comissions.total_without_fees_comissions) }}</td>
                </tr>
                <tr>
                    <td>Tarifa Fullsale</td>
                    <td class="row_right">{{ globalStore.formatMoney(props.comissions.total_fullsale - props.comissions.debts) }}</td>
                </tr>
                <tr v-if="props.comissions.debts > 0">
                    <td style="color: #c51515!important; font-weight: 600;">Débitos</td>
                    <td style="color: #c51515!important; font-weight: 600;" class="row_right">{{ globalStore.formatMoney(props.comissions.debts) }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td style="color: #28a745!important;">Sua comissão</td>
                    <td style="color: #28a745!important;" class="row_right">{{ globalStore.formatMoney(props.comissions.your_comission) }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script setup>
const props = defineProps({
    comissions: {
        required: true,
        default: null
    }
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
</script>