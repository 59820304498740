<template>
    <div class="box_content">
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th class="ps-3">Coprodutor</th>
                        <th class="ps-3">Comissão</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="productStore.coproductors.length">
                    <tr v-for="(row, key) in productStore.coproductors" class="divisor_bottom" :key="key">
                        <td>
                            {{ row.name }}
                        </td>
                        
                        <td>
                            <template v-if="row.comission_fixed">
                                {{ globalStore.formatMoney(row.comission) }}
                            </template>
                            <template v-else>
                                {{ row.comission }}%
                            </template>
                        </td>
                        <td class="actions">
                            <button @click="editCoproductor(row.id)" type="button" class="icon_buttons  ">
                                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                            </button>
                            <button @click="openPopup(row.id)" type="button" class="icon_buttons icon_view">
                                <font-awesome-icon icon="fa-solid fa-trash-can" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!coproductors.length" class="text-center p-4 mb-0 divisor_bottom">Nenhum resultado encontrado</h6>
        </div>
        <div style="text-align:center;" class="p-4">
            <BaseButton @click="createCoproductor" class="btn-primary btn-lg">
                Adicionar
            </BaseButton>
        </div>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover esse coprodutor?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="removeCoproductor" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true: excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
    </BasePopup>
</template>

<script setup>
import { computed, shallowRef, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter} from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { coproductors }  = storeToRefs(productStore); 

const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const product_id = computed (function () {
    return route.params.id;
});

let coproductorToRemove = null;
let isDeleting = false;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null)
const isLoading = ref(false);

function editCoproductor(id){
    globalStore.loader('show')
    productStore.tryCoproductor(id).then( function(success){
        if(success){
            router.push('/products/' + productStore.product.id + '/coproductor/' + id)
            globalStore.loader('hide')
        } else{
            globalStore.loader('hide')
        }
    })
}

function createCoproductor(){
    router.push('/products/' + productStore.product.id + '/coproductor/new')
}

async function removeCoproductor(){
    if(!isDeleting && isLoading.value &&coproductorToRemove && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        globalStore.loader('show');
        isLoading.value = true;
        await productStore.tryDeleteCoproductor(coproductorToRemove).then(function(success) {
            isDeleting = false;
            if(success){
                productStore.tryCoproductors().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    confirmAction.value = null;
                    isLoading.value = false;
                    router.push('/products/' + product_id.value + '/coproductor');
                });
            }else {
                globalStore.loader('hide');
                isDeleting = false;
                isLoading.value = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function openPopup(id) {
    coproductorToRemove = id
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

</script>

<style scoped>
.table_bordered {
    background: #fff;
    border-radius: 8px;
}

thead {
    background: #fff;
    color: #595959;
}

tbody{
    border-bottom: 1px #ddd solid ;
}

tbody td {
    padding: 12px 16px;
    vertical-align: middle;
    color: #595959;
}

tbody tr:last-of-type {
    border-bottom: 0px solid #fff !important;
}

tbody tr:hover {
    background: #f3f3f3 !important;
}
</style>