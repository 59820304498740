<template v-if="store != undefined">
    <div class="box_content p-4">
        <div class="row">
            <BaseInput
                class="col-md-8 col-12"
                label="Nome da Loja *"
                type="text"
                v-model="store.title"
                id="title"
                :page="route.name"
            />
            <BaseColorPicker
                class="col-md-4 col-12"
                label="Cor da Loja"
                id="color"
                v-model="store.color"
                :page="route.name"
            />
            <BaseFileUpload
                class="col-12"
                label="Logotipo *"
                id="logotipo"
                recommended_size="200 x 100"
                v-model="store.logotipo"
                :page="route.name"
                :preview="true"
            />
            <BaseInput
                class="col-md-6 col-12"
                label="URL do site da Loja *"
                type="text"
                v-model="store.url"
                id="url"
                :page="route.name"
            />
            <BaseInput
                class="col-md-6 col-12"
                label="Telefone *"
                id="phone"
                mask="['(##) ####-####', '(##) #####-####']"
                :page="route.name"
                v-model="store.phone"
            />
            <BaseInput
                class="col-12"
                label="Email de contato *"
                type="email"
                id="email_support"
                v-model="store.email_support"
                :page="route.name"
            />
            <BaseInput
                class="col-12"
                label="Email para receber notificações *"
                type="email"
                id="email"
                v-model="store.email"
                :page="route.name"
            />
            <div class="col-md-6 col-12">
                <BaseInput
                    class="w-100"
                    label="Soft descriptor *"
                    tooltip_text="Nome da loja que aparecerá na fatura do cartão. Campo alfanumérico, permitindo também: - *"
                    type="text"
                    id="soft_descriptor"
                    v-model="store.soft_descriptor"
                    :page="route.name"
                />
                <small class="show_descriptor mb-3">Nome na fatura será: Fullsale*{{ store.soft_descriptor }}</small>
            </div>
            <BaseSelect
                class="col-md-6 col-12"
                label="Garantia de produtos*"
                id="warranty"
                placeholder="30 dias"
                :items="all_warranties"
                v-model="store.warranty"
                :page="route.name"
            />
            <BaseSwitch 
                style="margin-bottom:0px!important" 
                label="Permitir venda para CNPJ"
                id="meta[sell_to_cnpj]"
                v-model:checked="store.meta.sell_to_cnpj"
                :page="route.name"
            />
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useStoreStore } from '@/stores/store';
import { useRoute } from "vue-router";
const route = useRoute();
const storeStores = useStoreStore();
const { store, all_warranties } = storeToRefs(storeStores);
</script>