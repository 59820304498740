<template>
    <teleport to="body">
        <div :class="'backdrop_popup ' + [close ? 'close' : 'open']" @click.self="clickToClose" :style="displayGrid">
            <div :class="'popup ' + [close ? 'close' : 'open']" :style="'max-width: ' + width + 'px'">
                <div v-if="!hideCloseButton" class="close_button" @click="clickToClose"><font-awesome-icon icon="fa-solid fa-xmark" /></div>
                <slot></slot>
            </div>
        </div>
    </teleport>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const props = defineProps({
    closeType: {
        required: true,
        type: String,
        validator: (value) => {
            return ['stayOnPage', 'goBack'].includes(value);
        }
    },
    width: {
        required: false,
        default: 700
    },
    hideCloseButton: {
        required: false,
        type: Boolean,
        default: false
    },
    termsAndConditions: {
        required: false,
        default: false,
    },
    displayGrid:{
        required:false,
        default:null,
    }

});

onBeforeMount(() => {
    document.body.style.overflow = "hidden";
});

onBeforeUnmount(() => {
    document.body.style.overflow = "auto";
});

const emit = defineEmits(["clickToClose"]);

const close = ref(false);
function clickToClose() {
    if(props.termsAndConditions === true){
        return;
    }
    close.value = true;
    setTimeout(() => {
        if(props.closeType == 'stayOnPage') {
            emit('clickToClose');
        }else {
            let winLoc = window.location;
            let url = winLoc.href.replace(winLoc.protocol + '//' + winLoc.host, '').split('/');
            url.pop();
            router.push(url.join('/'));
        }
    }, 200);
}
</script>

<style scoped> 
:deep(header){
    width: 100%;
    display: block!important;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px #d7d7d7 solid;
    padding: 20px;
}

:deep(header) span {
    margin-left: 10px !important;
}

:deep(header) .badget span {
    margin-left: 0px !important;
}

:deep(.popup) .content {
    padding: 20px;
}

.backdrop_popup {
    overflow-y: auto;
    display: flex;
    position: fixed;
    z-index: 997;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3em;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 0.6);
}
.popup {
    background: #fff;
    position: relative;
    border: none;
    border-radius: 12px;
    width: 100%;
}

.close_button{
    position: absolute;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    top: 15px;
    right: 15px;
    border-radius: 20px;
    padding: 6px;
    float: right;
    text-align: center;
    cursor: pointer;
    color: #828282;
}

.close_button svg {
    height: 20px;
}

.close_button:hover{
    color: #cfcfcf;
}

.open {
    animation-duration: 0.2s;
    animation-name: in;
}
.close {
    animation-duration: 0.2s;
    animation-name: out;
    opacity: 0;
}
@keyframes in {
    0% {
       opacity: 0;
    }
    100% {
        opacity: 1;
    }   
}
@keyframes out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }   
}

@media screen and (max-width: 750px) {
    .backdrop_popup {
        padding: 1em !important;
    }
}

:deep(.confirm_action .popup) {
    margin: 0 auto;
    width: 32em;
    max-width: 100%;
    text-align: center;
    padding: 20px;

}

:deep(.confirm_action .popup .title) {
    font-size: 20px;
    color: #414951;
    font-weight: 500;
    margin: 0 0 0.4em;
    width: fit-content;
    margin: 0 auto;
}

:deep(.confirm_action .popup .text) {
    font-size: 1.125em;
    color: #545454;
    font-weight: 400;
    word-wrap: break-word;
}
</style>