import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useRefusedStore = defineStore('refuseds', {

    state: () => ({
        refuseds: {},
        paginate: {},
        refused: {},
        refuseds_filters: {
            'search': null,
            'page': null,
            'date_start': null,
            'date_end': null,
        },
    }),

    actions: {

        async tryRefuseds(params = null) {
            try {
                const response = await Api.get('recovery/orders/refused', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});
                this.refuseds = response.data.data;
                this.paginate = response.data.meta;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryRefused(id) {
            try {
                const response = await Api.get('orders/' + id);
                this.refused = response.data.data;
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
    }
});