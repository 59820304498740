<template>
        <div class="container customize_item" @click.self="openDialogCustomization('checkout', true)"
            v-bind:style="[background_image_preview ? { backgroundImage: 'url(' + background_image_preview + ')' } : { backgroundImage: 'url(' + checkout.customization.background_image + ')' }]">

            <div v-if="open_exit_popup == true" id="exit_popup" style="display: block;" @click.self="closeExitPopup">
                <div class="content customize_item" @click.self="openSubmenu('exit_intent_popup', true)">
                    <div class="close">x</div>
                    Você ganhou <span class="amount customize_item"
                        @click="openDialogCustomization('exit_popup_discount', true)"><strong>50</strong>%</span> de desconto
                    <button class="customize_item" @click="openDialogCustomization('exit_popup_button', true)">{{
                        checkout.customization.exit_popup_button_text }}</button>
                </div>
            </div>

            <div v-if="checkout.customization.enable_timer == 1" id="countdown_wrapper" class="customize_item"
                @click.self="openSubmenu('timer', true)">
                <div class="row" @click.self="openSubmenu('timer', true)">
                    <div class="col customize_item" @click="openDialogCustomization('timer_text', true)">
                        <h5>{{ checkout.customization.timer_text }}</h5>
                    </div>
                    <div class="col customize_item" @click="openDialogCustomization('timer_numbers', true)">
                        <div id="countdown" data-time="10">
                            <span>
                                <strong>{{ timerHours }}</strong>
                                Horas
                            </span>
                            <span>
                                <strong>{{ timerMinutes }}</strong>
                                Minutos
                            </span>
                            <span>
                                <strong>00</strong>
                                Segundos
                            </span>
                        </div>
                    </div>
                    <div class="col" @click.self="openSubmenu('timer', true)">
                        <a v-if="checkout.customization.enable_timer_sell_button == 1" @click="openDialogCustomization('timer_button', true)" class="btn customize_item" id="order_now">
                            {{ checkout.customization.timer_text_button }}
                        </a>
                    </div>
                </div>
            </div>

            <header v-if="checkout.customization.header_logotipo == 1" id="header" class="customize_item" @click.self="openSubmenu('header', true)">
                    <div class="row" @click.self="openSubmenu('header', true)">
                        <div class="col" @click.self="openSubmenu('header', true)">
                            <img v-if="header_logotipo_preview" :src="header_logotipo_preview" id="logotipo" class="customize_item"  @click.self="openDialogCustomization('logotipo', true)"/>
                            <img v-else-if="checkout.customization.logotipo" :src="checkout.customization.logotipo" id="logotipo" class="customize_item"  @click.self="openDialogCustomization('logotipo', true)"/>
                            <img v-else :src="store.logotipo" class="customize_item " id="logotipo"  @click.self="openDialogCustomization('logotipo', true)">
                        </div>

                        <div id="header_contact_info" v-if="checkout.customization.show_email_support_on_header == 1" class="col header_contact customize_item" @click="openDialogCustomization('enable_header_contact_info', true)">
                            <svg fill="#494949" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"></path></svg>
                            <p>
                                <span>Dúvidas?</span>{{ store.email }}
                            </p>
                        </div>
                        
                        <div id="header_secure_info" class="col secure customize_item"  @click="openDialogCustomization('header_secure', true)">
                                <svg fill="#10933a" width="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve"><g><path d="M95.6,490h298.7c21.4,0,38.8-17.4,38.8-38.8V237.1c0-21.4-17.4-38.8-38.8-38.8h-1v-51.2C393.3,66,327.3,0,246.2,0S99.1,66,99.1,147.1v51.2h-3.4c-21.4,0-38.8,17.4-38.8,38.8v214.1C56.8,472.6,74.2,490,95.6,490z M163.4,147.1c0-45.6,37.1-82.8,82.8-82.8c45.6,0,82.8,37.1,82.8,82.8v51.2H163.4V147.1z"></path></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                                <p>
                                    <span>Pagamento</span>100% seguro
                                </p>
                        </div>
                    </div>
            </header>

            <div id="checkout">
                <div class="row" id="checkout_row">
                    <div class="col-12 ">
                        <header v-if="checkout.customization.enable_banner_cover == '1'" class="customize_item">
                            <div v-if="props.device == 'desktop'"
                                @click="openDialogCustomization('cover_desktop', true)">
                                <img v-if="cover_desktop_preview" :src="cover_desktop_preview" />
                                <img v-else-if="checkout.customization.cover_desktop" :src="checkout.customization.cover_desktop" />
                                <img v-else-if="checkout.product_demo.cover_desktop" :src="checkout.product_demo.cover_desktop" />
                                <span v-else></span>
                            </div>
                            <div v-else-if="props.device == 'mobile'"
                                @click="openDialogCustomization('cover_mobile', true)">
                                <img v-if="cover_mobile_preview" :src="cover_mobile_preview" />
                                <img v-else-if="checkout.customization.cover_mobile" :src="checkout.customization.cover_mobile" />
                                <img v-else-if="checkout.product_demo.cover_mobile" :src="checkout.product_demo.cover_mobile" />
                                <span v-else></span>
                            </div>
                        </header>
                    </div>
                    <div class="col-12 col-lg mb-3" id="informations" @click.self="openDialogCustomization('checkout', true)">
                        <div id="form_fields">
                            <div class="box">
                                <h2 class="form_heading">
                                    <div class="customize_item" @click.self="openDialogCustomization('step_1_title', true)">
                                        <span>1</span>{{ checkout.customization.step_1_title_text }}
                                    </div>
                                    <small class="customize_item" @click.self="openDialogCustomization('step_1_subtitle', true)" style="min-height: 18px">{{ checkout.customization.step_1_subtitle_text }}</small>
                                </h2>

                                <div class="spacing pt-0">
                                    <div id="informations_selected" style="display: none;">
                                        <p></p>
                                        <button type="button" class="btn_default btn_change" id="change_informations"><svg
                                                xmlns="http://www.w3.org/2000/svg" height="1em"
                                                viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                <path
                                                    d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z">
                                                </path>
                                            </svg></button>
                                    </div>

                                    <div class="content_step form_checkout">
                                        <div class="row">

                                            <div class="col-12">
                                                <label>Nome completo <span>*</span></label>
                                                <input autocomplete="off" type="text" name="name" id="name" value="">
                                            </div>

                                            <div class="col-12 mt-3">
                                                <label>E-mail <span>*</span></label>

                                                <input autocomplete="off" type="email" name="email" id="email" value="">
                                                <small class="email_sugestion"></small>
                                            </div>

                                            <div class="col-12 mt-3">
                                                <label>Celular com DDD <span>*</span></label>
                                                <input autocomplete="off" type="tel" name="phone" id="phone" value=""
                                                    maxlength="15">
                                            </div>



                                            <div class="col-12">
                                                <button @click="openDialogCustomization('proceed_button', true)" type="button" class="btn_proceed btn_default next_step mt-4 customize_item proceed_button"
                                                    data-next-step="shipping_address">
                                                    {{ checkout.customization.proceed_button_text }}
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                                        viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                        <path
                                                            d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div class="box mt-4" id="shipping_address">
                                <h2 class="form_heading">
                                    <div class="customize_item" @click.self="openDialogCustomization('step_2_title', true)">
                                        <span>2</span>{{ checkout.customization.step_2_title_text }}
                                    </div>
                                    <small class="customize_item" @click.self="openDialogCustomization('step_2_subtitle', true)" style="min-height: 18px">{{ checkout.customization.step_2_subtitle_text }}</small>
                                </h2>

                                    <div id="address_selected" class="spacing pt-0" style="display: none;">
                                        <p></p>
                                        <button type="button" class="btn_default btn_change" id="change_address"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"></path></svg></button>
                                    </div>

                                <div class="content_step form_checkout">
                                    <div class="spacing pt-0">
                                        <div class="row">
                                            <div class="col">
                                                <label>CEP <span>*</span></label>
                                                <input autocomplete="off" type="tel" class="postcodeinputs" name="postcode" id="postcode" value="" placeholder="" maxlength="9">
                                            </div>
                                        </div>

                                        <div id="hidden_fields_checkout">
                                            <div class="row">
                                                <div class="col-12 mt-3">
                                                    <label>Endereço <span>*</span></label>
                                                    <input autocomplete="off" type="text" class="postcodeinputs" name="address" id="address" value="">
                                                </div>

                                                <div class="col-12 col-sm-4 mt-3">
                                                    <label>Número <span>*</span></label>
                                                    <input autocomplete="off" type="text" name="address_number" id="address_number" value="">
                                                </div>

                                                <div class="col-12 col-sm-8 mt-3">
                                                    <label>Bairro <span>*</span></label>
                                                    <input autocomplete="off" type="text" class="postcodeinputs" name="neighborhood" id="neighborhood" value="">
                                                </div>

                                                <div class="col-12 mt-3">
                                                    <label>Complemento</label>
                                                    <input autocomplete="off" type="text" name="complement" id="complement" value="">
                                                </div>

                                                <div class="col-6 mt-3">
                                                    <label>Cidade <span>*</span></label>
                                                    <input autocomplete="off" type="text" class="postcodeinputs" name="city" id="city" value="">
                                                </div>

                                                <div class="col-6 mt-3">
                                                    <label>Estado <span>*</span></label>
                                                    <select class="postcodeinputs" name="state" id="state">
                                                        <option value="" style="display: none;">Selecione</option>
                                                                                                            <option value="AC">Acre</option>
                                                                                                            <option value="AL">Alagoas</option>
                                                                                                            <option value="AP">Amapá</option>
                                                                                                            <option value="AM">Amazonas</option>
                                                                                                            <option value="BA">Bahia</option>
                                                                                                            <option value="CE">Ceará</option>
                                                                                                            <option value="DF">Distrito Federal</option>
                                                                                                            <option value="ES">Espírito Santo</option>
                                                                                                            <option value="GO">Goiás</option>
                                                                                                            <option value="MA">Maranhão</option>
                                                                                                            <option value="MT">Mato Grosso</option>
                                                                                                            <option value="MS">Mato Grosso do Sul</option>
                                                                                                            <option value="MG">Minas Gerais</option>
                                                                                                            <option value="PA">Pará</option>
                                                                                                            <option value="PB">Paraíba</option>
                                                                                                            <option value="PR">Paraná</option>
                                                                                                            <option value="PE">Pernambuco</option>
                                                                                                            <option value="PI">Piauí</option>
                                                                                                            <option value="RJ">Rio de Janeiro</option>
                                                                                                            <option value="RN">Rio Grande do Norte</option>
                                                                                                            <option value="RS">Rio Grande do Sul</option>
                                                                                                            <option value="RO">Rondônia</option>
                                                                                                            <option value="RR">Roraima</option>
                                                                                                            <option value="SC">Santa Catarina</option>
                                                                                                            <option value="SP">São Paulo</option>
                                                                                                            <option value="SE">Sergipe</option>
                                                                                                            <option value="TO">Tocantins</option>
                                                                                                        </select>
                                                </div>


                                                <div class="col-12">
                                                    <button type="button" class="btn_default next_step mt-4 proceed_button" data-next-step="payment">
                                                        {{ checkout.customization.proceed_button_text }}
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 col-lg mb-3" id="payment" @click.self="openDialogCustomization('checkout', true)">
                        <div>
                            <div class="box">
                                <h2 class="form_heading">
                                    <div class="customize_item" @click.self="openDialogCustomization('step_3_title', true)">
                                        <span>3</span>{{ checkout.customization.step_3_title_text }}
                                    </div>
                                    <small class="customize_item" @click.self="openDialogCustomization('step_3_subtitle', true)" style="min-height: 18px">{{ checkout.customization.step_3_subtitle_text }}</small>
                                </h2>

                                <div class="content_step form_checkout">
                                    <ul id="payment_methods" class="customize_item" @click="openSubmenu('payment_methods', true)">
                                        <li v-if="checkout.customization.credit_card_status == 1" :class="storeStore.checkout.customization.credit_card_ordenation == 1 ? 'active' : '' + ' credit_card_display'" data-crip-key="" data-id="151" data-type="credit-card">
                                            <div class="select_item">
                                                <div></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                                viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                <path
                                                    d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z">
                                                </path>
                                            </svg>
                                            Crédito
                                            <div v-if="storeStore.checkout.customization.credit_card_discount > 0 && storeStore.checkout.customization.credit_card_discount <= 100" class="checkout_payment_discount">{{ storeStore.checkout.customization.credit_card_discount }}% OFF</div>
                                        </li>
                                        <li v-if="checkout.customization.pix_status == 1" :class="storeStore.checkout.customization.pix_ordenation == 1 ? 'active' : '' + ' pix_display'" data-crip-key="" data-id="150" data-type="pix">
                                            <div class="select_item">
                                                <div></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                                viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                <path
                                                    d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z">
                                                </path>
                                            </svg> Pix
                                            <div v-if="storeStore.checkout.customization.pix_discount > 0 && storeStore.checkout.customization.pix_discount <= 100" class="checkout_payment_discount">{{ storeStore.checkout.customization.pix_discount }}% OFF</div>
                                        </li>
                                        <li v-if="checkout.customization.boleto_status == 1" :class="[storeStore.checkout.customization.boleto_ordenation == 1 ? 'active border_right ' : ''] + [storeStore.checkout.customization.boleto_ordenation == 2 ? ' border_right ' : ''] + ' boleto_display'" data-crip-key="" data-id="149" data-type="boleto">
                                            <div class="select_item">
                                                <div></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                                viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                <path class="fa-primary"
                                                    d="M64 56c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V192H64V56zm0 264h64V456c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24V320zm96 0h32V464c0 8.8-7.2 16-16 16s-16-7.2-16-16V320zm64 0h64V456c0 13.3-10.7 24-24 24H248c-13.3 0-24-10.7-24-24V320zm64-128H224V56c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V192zm32 128h64V456c0 13.3-10.7 24-24 24H344c-13.3 0-24-10.7-24-24V320zm64-128H320V56c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V192zm64 128h32V464c0 8.8-7.2 16-16 16s-16-7.2-16-16V320zm32-128H448V48c0-8.8 7.2-16 16-16s16 7.2 16 16V192zm32 128h64V456c0 13.3-10.7 24-24 24H536c-13.3 0-24-10.7-24-24V320zm64-128H512V56c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V192zm-384 0H160V48c0-8.8 7.2-16 16-16s16 7.2 16 16V192z">
                                                </path>
                                                <path class="fa-secondary"
                                                    d="M0 256c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24z">
                                                </path>
                                            </svg> Boleto
                                            <div v-if="storeStore.checkout.customization.boleto_discount > 0 && storeStore.checkout.customization.boleto_discount <= 100" class="checkout_payment_discount">{{ storeStore.checkout.customization.boleto_discount }}% OFF</div>
                                        </li>
                                    </ul>

                                    <div class="spacing pt-0">

                                        <div class="payment_box" data-type="pix">
                                            <div class="row">
                                                <div class="column">
                                                    <label>CPF/CNPJ <span>*</span></label>
                                                    <input autocomplete="off" type="tel" name="cpf_pix" id="cpf_pix"
                                                        class="cpf_cnpj" value="" placeholder="Para emissão da nota fiscal"
                                                        maxlength="18">
                                                </div>
                                            </div>
                                            <ul>
                                                <li>Faça seu pagamento em poucos segundos sem custos adicionais</li>
                                                <li>Para pagar basta abrir o aplicativo do seu banco, procurar pelo PIX e
                                                    escanear o QRcode

                                                    Seguro</li>
                                            </ul>
                                        </div>
                                        <div class="payment_box" data-type="credit-card" style="display: block;">
                                            <div id="one_card">
                                                <div class="card_item" id="card_one">

                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>Número do cartão <span>*</span></label>
                                                            <input autocomplete="off" placeholder="Digite somente números"
                                                                type="tel" name="one_card_number" id="one_card_number">
                                                        </div>

                                                        <div class="col-8 mt-3">
                                                            <label>Validade (MM/AA) <span>*</span></label>
                                                            <div class="row" id="one_card_expiry_date_group">
                                                                <div class="col-6 mb-0 pe-0">

                                                                    <select name="one_card_expiry_month"
                                                                        id="one_card_expiry_month">
                                                                        <option value="" style="display: none;">Mês</option>
                                                                        <option value="01">01</option>
                                                                        <option value="02">02</option>
                                                                        <option value="03">03</option>
                                                                        <option value="04">04</option>
                                                                        <option value="05">05</option>
                                                                        <option value="06">06</option>
                                                                        <option value="07">07</option>
                                                                        <option value="08">08</option>
                                                                        <option value="09">09</option>
                                                                        <option value="10">10</option>
                                                                        <option value="11">11</option>
                                                                        <option value="12">12</option>
                                                                    </select>
                                                                </div>

                                                                <div class="col-6 mb-0">
                                                                    <select name="one_card_expiry_year"
                                                                        id="one_card_expiry_year">
                                                                        <option value="" style="display: none;">Ano</option>
                                                                        <option value="23">23</option>
                                                                        <option value="24">24</option>
                                                                        <option value="25">25</option>
                                                                        <option value="26">26</option>
                                                                        <option value="27">27</option>
                                                                        <option value="28">28</option>
                                                                        <option value="29">29</option>
                                                                        <option value="30">30</option>
                                                                        <option value="31">31</option>
                                                                        <option value="32">32</option>
                                                                        <option value="33">33</option>
                                                                        <option value="34">34</option>
                                                                        <option value="35">35</option>
                                                                        <option value="36">36</option>
                                                                        <option value="37">37</option>
                                                                        <option value="38">38</option>
                                                                        <option value="39">39</option>
                                                                        <option value="40">40</option>
                                                                        <option value="41">41</option>
                                                                        <option value="42">42</option>
                                                                        <option value="43">43</option>
                                                                    </select>
                                                                </div>

                                                                <input type="hidden" id="one_card_expiry">
                                                            </div>
                                                        </div>

                                                        <div class="col-4 mt-3">
                                                            <label>CVV <span>*</span></label>
                                                            <input autocomplete="off" type="tel" name="one_card_cvc"
                                                                id="one_card_cvc">
                                                        </div>

                                                        <div class="col-12 mt-3">
                                                            <label>Nome impresso no cartão <span>*</span></label>
                                                            <input autocomplete="off" placeholder="Seu nome impresso no cartão"
                                                                type="text" name="one_card_name" id="one_card_name">
                                                        </div>

                                                        <div class="col-12 mt-3">
                                                            <label>CPF/CNPJ do titular do cartão <span>*</span></label>
                                                            <input autocomplete="off" type="tel" name="cpf_credit_card"
                                                                id="cpf_credit_card" class="cpf_cnpj" value=""
                                                                placeholder="Para emissão da nota fiscal" maxlength="18">
                                                        </div>

                                                        <div class="col-12 mt-3">
                                                            <label>Parcelas <span>*</span></label>
                                                            <select name="one_card_installments" id="one_card_installments">
                                                                <option value="1">1x de R$ 0,95</option>
                                                                <option value="2">2x de R$ 0,50</option>
                                                                <option value="3">3x de R$ 0,34</option>
                                                                <option value="4">4x de R$ 0,26</option>
                                                                <option value="5">5x de R$ 0,21</option>
                                                                <option value="6" selected="">6x de R$ 0,18</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="payment_box" data-type="boleto">
                                            <div class="row">
                                                <div class="column">
                                                    <label>CPF/CNPJ <span>*</span></label>
                                                    <input autocomplete="off" type="tel" name="cpf_boleto" id="cpf_boleto"
                                                        class="cpf_cnpj" value="" placeholder="Para emissão da nota fiscal"
                                                        maxlength="18">
                                                </div>
                                            </div>
                                            <ul>
                                                <li>Pagamentos com Boleto somente à vista e levam até 3 dias úteis para serem
                                                    compensados</li>
                                                <li>Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer
                                                    banco ou casa lotérica até o dia do vencimento</li>
                                                <li>Depois do pagamento, verifique seu e-mail para receber os dados de acesso ao
                                                    produto (verifique também a caixa de SPAM)</li>
                                            </ul>
                                        </div>

                                        <div v-if="storeStore.checkout.customization.pix_ordenation == 1 && storeStore.checkout.customization.pix_status == 1" class="pix_warn mt-4">
                                            <h6>Pagamento via Pix: </h6>
                                            <ul>
                                                <li>Faça seu pagamento em poucos segundos sem custos adicionais</li>
                                                <li>Para pagar basta abrir o aplicativo do seu banco, procurar pelo PIX e escanear o QRcode Seguro</li>
                                                <li>O pagamento via PIX foi desenvolvido pelo Banco Central para facilitar suas compras</li>
                                            </ul>
                                        </div>

                                        <div id="link_coupon">
                                            <a>Possui cupom de desconto?</a>
                                        </div>
                                        <div id="order_coupon" class="row">
                                            <div class="col-6">
                                                <input 
                                                    autocomplete="off" type="text" name="coupon"
                                                    id="coupon" class="col-6" value=""
                                                    placeholder="Código do cupom"
                                                >
                                            </div>
                                            <button @click="openDialogCustomization('coupon_code', true)" type="text" class="col-6 place_holder btn btn-default customize_item">
                                                <span>
                                                    {{ checkout.customization.coupon_button_text }}
                                                </span>
                                            </button>
                                        </div>
                                        <div id="order_dump" class="mt-4">
                                            <h3 class="mt-0 customize_item" @click="openDialogCustomization('order_bump_title', true)">{{
                                                checkout.customization.order_bump_title }}
                                            </h3>
                                            <ul>
                                                <li class="row customize_item" @click.self="openDialogCustomization('order_bump', true)">
                                                    <div class="col-12 bump_image mb-3" @click="openDialogCustomization('order_bump', true)">
                                                        <img width="100px!important"
                                                            :src="checkout.product_demo.image">
                                                    </div>
                                                    <div class="col-12">
                                                    <label @click="openDialogCustomization('order_bump_label', true)" class="customize_item" for="product_bump_FFQUnkOTX5yfcIQdp8YF">
                                                        <input class="products_dump_input" type="checkbox"
                                                            name="products_dump[]" id="product_bump_FFQUnkOTX5yfcIQdp8YF"
                                                            value="FFQUnkOTX5yfcIQdp8YF"
                                                            :checked="checkout.customization.order_bump_selected == 1 ? true : false"
                                                            >
                                                            <div class="select_box_item">
                                                                <font-awesome-icon class="check_icon" :icon="['fas', 'check']"/>
                                                            </div>
                                                        <div>{{ extractVariables(checkout.customization.order_bump_box_title) }} - Por apenas {{ globalStore.formatMoney(checkout.product_demo.price)}}</div>
                                                    </label>

                                                        <p @click="openDialogCustomization('order_bump', true)">{{ extractVariables(checkout.customization.order_bump_description) }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div id="container_coupon" style="display: none;">
                                            <div class="row">
                                                <div class="col">
                                                    <input type="text" name="code_coupon" id="code_coupon"
                                                        placeholder="Código do cupom">
                                                </div>


                                            </div>

                                        </div>
                        </div>

                    </div>
                    </div>


                    </div>
                </div>
                <div v-if="checkout.customization.enable_summary == 1" class="col-12 col-lg-4 mb-3" id="summary" @click.self="openDialogCustomization('checkout', true)">
                        <div id="checkout_summary">
                            <div class="box">
                                <h2 class="form_heading">Resumo da compra</h2>

                                <div class="spacing pt-0">
                                    <div class="checkout_products">
                                        <div class="row">
                                            <div class="col product_image">
                                                <img :src="checkout.product_demo.image" />
                                            </div>
                                            <div class="col"><strong>{{ checkout.product_demo.title }}</strong>
                                                <div class="mt-1">{{ globalStore.formatMoney(checkout.product_demo.price) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row green_text" id="checkout_shipping_container" style="display: none;">
                                        <div class="col">
                                            Envio
                                        </div>
                                        <div class="col align_right" id="checkout_shipping_cost">
                                            -
                                        </div>
                                    </div>
                                    <div class="row" id="checkout_discount_container" style="">
                                        <div class="col">
                                            Desconto
                                        </div>
                                        <div class="col align_right" id="checkout_discount_amount">- R$&nbsp;0,05 (5% OFF)</div>
                                    </div>
                                    <div class="row green_text pb-0">
                                        <div class="col">
                                            Total
                                        </div>
                                        <div class="col align_right" id="checkout_total">R$&nbsp;0,95</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                <div v-if="checkout.customization.enable_notify == 1" id="popup_orders">
                    <div id="popup_orders_close"><svg width="12px" height="12px" viewBox="0 0 329.26933 329"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0">
                            </path>
                        </svg></div>
                    <div id="popup_orders_image"><img :src="checkout.product_demo.image"></div>
                    <div id="popup_orders_text">Aline de Curitiba comprou<strong>{{ checkout.product_demo.title }}</strong>
                        <span>Poucos minutos atrás</span>
                    </div>
                </div>
                </div>

            </div>
        </div>
    <BaseDragDrop :initialTop="'160px'" :startRight="true" :dragFromHeader="true">
        <template v-slot:header="{ onmousedown }">
            <div id="menuCustomHeader" class="menu_custom_header box_content"  @mousedown="onmousedown">
                <div class=" m-3">
                    <font-awesome-icon v-if="customization_type == 'home'" icon="fa-solid fa-table-layout" style="color: #215eda;" class="me-1 customization_icon"/>
                    <font-awesome-icon class="back_arrow me-1" v-else icon="fa-solid fa-arrow-left" style="color: #215eda;"  @click="customizationPrevious()"/>
                    <h6 v-if="customization_type == 'home'" class="ms-2">Personalização</h6>
                    <h6 v-else-if="customization_type == 'submenu'">{{ props.menuTitles[submenu_selected] }}</h6>
                    <h6 v-else>{{ props.menuTitles[customization_type] }}</h6>
                    <div class="minimize_icon" @click="emitMinimizeMenu"><font-awesome-icon :icon="['fas', 'bars']" /></div>
                </div>
            </div>
        </template>
        <template v-slot:content>
            <div id="menuCustomBox" class="menu_custom box_content col-3" @emitCloseDialog="closeDialog">
                <div v-if="customization_type == 'home'" class="menu_list">
                    <ul class="main_nav">
                        <li class="nav_item" @click="openDialogCustomization('checkout', false)">Geral</li>
                        <li class="nav_item" @click="openSubmenu('timer')">Timer</li>
                        <li class="nav_item" @click="openSubmenu('header')">Header</li>
                        <li class="nav_item" @click="openDialogCustomization([props.device == 'desktop' ? 'cover_desktop' : 'cover_mobile'], false)">Banner</li>
                        <li class="nav_item" @click="openSubmenu('step_1')">Identificação</li>
                        <li class="nav_item" @click="openSubmenu('step_2')">Entrega</li>
                        <li class="nav_item" @click="openSubmenu('payment_step_3')">Pagamento</li>
                        <li class="nav_item" @click="openSubmenu('coupon')">Cupom</li>
                        <li class="nav_item" @click="openSubmenu('order_bump_sub')">Order bump</li>
                        <li class="nav_item" @click="openSubmenu('exit_intent_popup')">Exit-intent popup</li>
                        <li class="nav_item" @click="openDialogCustomization('back_redirect')">Back Redirect</li>
                    </ul>
                </div>
                <div v-else-if="customization_type == 'submenu'" class="menu_list">
                    <ul class="main_nav">
                        <li v-for="config in subMenuComponents[submenu_selected]" :class="!config.inputComponent ? 'nav_item' : 'customization_config'" v :key="config.id" @click="config.inputComponent ? '' : config.dialog_customization ? openDialogCustomization(config.dialog_customization, false) : openSubmenu(config.dialog_submenu)">
                            {{ config.title }}
                            <div v-if="config.inputComponent" class="customization_config px-3" >
                                <div :key="config.id" style='display: block;'>
                                    <component
                                        class="col"
                                        v-if="!config.display_if || (config.display_if && config.display_if.split(':')[1] == customization.customization[config.display_if.split(':')[0]])"
                                        :is="config.component" :label="config.label"
                                        v-model="checkout.customization[config.model]"
                                        v-model:checked="checkout.customization[config.model]"
                                        :id="config.id"
                                        :tooltip="config.tooltip"
                                        :format="config.format"
                                        :type="config.type"
                                        :min="config.min"
                                        :max="config.max"
                                        :accept="config.accept"
                                        v-bind="config.props"
                                        @base64="config.onBase64"
                                        :showBadget="config.showBadget"
                                        :badgetColor= "config.badgetColor"
                                        :badgetText="config.badgetText"
                                        :tag="config.tag"
                                    />
                                    <p class="info" v-if="config.id === 'cover_desktop' || config.id === 'cover_mobile' || config.id === 'banner' || config.id === 'banner_content'">* Ao selecionar um banner, ele substituirá a imagem cadastrada no produto.</p>
                                </div>
                            </div>
                        </li>
                        <div class="me-2 ms-auto open_exit_intent" v-if="submenu_selected == 'exit_intent_popup'">
                            <BaseButton  @click="openExitPopup" class="btn btn-secondary" id="btn_show_exit_popup" style="margin-top: 10px; margin-bottom: 10px;">Abrir</baseButton>
                        </div>
                    </ul>
                </div>
                <div class="customization_config  px-3" v-else>
                    <div v-for="config in customizationComponents[customization_type]" :key="config.id" style="display: block;">
                        <component
                            class="col"
                            v-if="!config.display_if || (config.display_if && config.display_if.split(':')[1] == customization.customization[config.display_if.split(':')[0]])"
                            :is="config.component" :label="config.label"
                            v-model="checkout.customization[config.model]"
                            v-model:checked="checkout.customization[config.model]"
                            :id="config.id"
                            :tooltip="config.tooltip"
                            :format="config.format"
                            :type="config.type"
                            :min="config.min"
                            :max="config.max"
                            :accept="config.accept"
                            v-bind="config.props"
                            @base64="config.onBase64"
                            :showBadget="config.showBadget"
                            :badgetColor= "config.badgetColor"
                            :badgetText="config.badgetText"
                            :tag="config.tag"
                        />
                        <p class="info" v-if="config.id === 'cover_desktop' || config.id === 'cover_mobile' || config.id === 'banner' || config.id === 'banner_content'">* Ao selecionar um banner, ele substituirá a imagem cadastrada no produto.</p>
                    </div>
                </div>
            </div>
        </template>
    </BaseDragDrop>
</template>

<script setup>
import { useStoreStore } from '@/stores/store';
import { storeToRefs } from 'pinia';
import { ref, onMounted, watch } from 'vue';
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
    device: {
        required: false,
        default: 'desktop'
    },    
    previewObj: {
        required: true,
        default: null,
    },
    menuTitles: {
        required: true,
        default: null,
    }
});

const globalStore = useGlobalStore();
const storeStore = useStoreStore();
const { store, checkout } = storeToRefs(storeStore);

const customization_type = ref('home');
const submenu_selected = ref(null);
const previous_menu_selected = ref(null)
const open_exit_popup = ref(false);

const timerMinutes = ref('00');
const timerHours = ref('00');

const emit = defineEmits(['emitPreviewFile', 'emitMinimizeMenu']);

function openDialogCustomization(type, layout_click) {
    if(layout_click === true){
        previous_menu_selected.value = 'home';
        let menuHeader = document.getElementById('menuCustomHeader');
        let menuList = document.getElementById('menuCustomBox');
        if(menuList.classList.contains('minimized') || menuHeader.classList.contains('minimized')){
            menuHeader.classList.remove('minimized');
            menuHeader.style.marginLeft = 0;
            menuList.classList.remove('minimized');
        } 
    }else if(type != null){
        previous_menu_selected.value = customization_type.value
    }else{
        previous_menu_selected.value = 'home'
    }
    customization_type.value = type;
}

function openSubmenu(type) {
    previous_menu_selected.value = 'home';
    customization_type.value = 'submenu';
    submenu_selected.value = type
}

function customizationPrevious() {
    if(customization_type.value == 'submenu' && previous_menu_selected.value != 'home'){
        customization_type.value = 'home';
        previous_menu_selected.value = customization_type.value;
    }else if(previous_menu_selected.value == customization_type.value){
        customization_type.value = 'home';
        previous_menu_selected.value = customization_type.value;
    }else {
        customization_type.value = previous_menu_selected.value;
        previous_menu_selected.value = customization_type.value;
    }
}

function openExitPopup() {
    open_exit_popup.value = true;
}

function closeExitPopup() {
    open_exit_popup.value = false;
}

const background_image_preview = ref(null);
function backgroundImage(value) {
    background_image_preview.value = value;
}

const cover_desktop_preview = ref(null);
function coverDesktop(value) {
    cover_desktop_preview.value = value;
    let valuesObj = {
        'cover_desktop_preview': cover_desktop_preview.value,
    }
    emit('emitPreviewFile', valuesObj)
}

const cover_mobile_preview = ref(null);
function coverMobile(value) {
    cover_mobile_preview.value = value;
    let valuesObj = {
        'cover_mobile_preview': cover_mobile_preview.value,
    }
    emit('emitPreviewFile', valuesObj)
}

const header_logotipo_preview = ref(null);
function logotipoHeader(value){
    header_logotipo_preview.value = value;
    let valuesObj = {
        'header_logotipo_preview': header_logotipo_preview.value,
    }
    emit('emitPreviewFile', valuesObj)
}

onMounted(() =>{
    if(storeStore.checkout.customization.timer_time){
        addToTimer();
    }
    watch(storeStore.checkout.customization ,function () {
        addToTimer()
    })
    if(props.previewObj !== null){
        for(let key in props.previewObj){
            if(key === 'cover_desktop_preview'){
                cover_desktop_preview.value = props.previewObj.cover_desktop_preview
            } else if(key === 'cover_mobile_preview'){
                cover_mobile_preview.value = props.previewObj.cover_mobile_preview;
            } else if(key === 'header_logotipo_preview'){
                header_logotipo_preview.value = props.previewObj.header_logotipo_preview;
            } 
        }
    }
})

const customizationComponents = {
    cover_desktop: [
        {
            component: 'BaseFileUpload',
            label: 'Banner superior',
            model: 'cover_desktop',
            tooltip: 'Desktop',
            id: 'cover_desktop',
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: coverDesktop,
            showBadget: true,
            badgetColor: "#6f6f6f", 
            badgetText: "Desktop",
            props: {
                short: true,
            }
        },
    ],
    cover_mobile: [
        {
            component: 'BaseFileUpload',
            label: 'Banner superior',
            model: 'cover_mobile',
            tooltip: 'Mobile',
            id: 'cover_mobile',
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: coverMobile,
            showBadget: true,
            badgetColor: "#6f6f6f", 
            badgetText: "Mobile",
            props: {
                short: true,
            }
        },
    ],
    checkout: [
        {
            component: 'BaseFileUpload',
            label: 'Imagem de fundo',
            model: 'background_image',
            id: 'background_image',
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: backgroundImage,
            props: {
                short: true,
            }
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'background_color',
            id: 'background_color',
        },
        {
            component: 'BaseSwitch',
            label: 'Resumo da compra',
            tooltip: 'Exibe o resumo da compra no checkout contendo informações do produto',
            model: 'enable_summary',
            id: 'enable_summary',
        },
        {
            component: 'BaseSwitch',
            label: 'Banner superior',
            tooltip: 'Exibe banner superior',
            model: 'enable_banner_cover',
            id: 'enable_banner_cover',
        },
        {
            component: 'BaseSwitch',
            label: 'Timer de Escassez',
            tooltip: 'Exibe temporizador de escassez do produto no topo do checkout',
            model: 'enable_timer',
            id: 'enable_timer',
        },
        {
            component: 'BaseSwitch',
            label: 'Cabeçalho',
            tooltip: 'Exibe o cabeçalho',
            model: 'header_logotipo',
            id: 'header_logotipo',
        },
        {
            component: 'BaseSwitch',
            label: 'Notificação de Escassez',
            tooltip: 'Exibe notificações de pedidos recentes',
            model: 'enable_notify',
            id: 'enable_notify',
        },
    ],
    logotipo: [
        {
            component: 'BaseFileUpload',
            label: 'Logotipo',
            model: 'logotipo',
            id: 'header_logotipo_preview',
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: logotipoHeader,
            props: {
                short: true,
            }
        },
        {
            component: 'BaseInputRange',
            label: 'Tamanho',
            model: 'logotipo_size',
            type: 'range',
            min: '100',
            max: '400',
            format: 'px',
            id: 'logotipo_size',
        },
    ],
    enable_header_contact_info:[
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto e ícone',
            model: 'header_contact_color',
            id: 'header_contact_color',
        },
    ],
    header_secure:[
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto e ícone',
            model: 'header_secure_color',
            id: 'header_secure_color',
        },
    ],
    timer_numbers:[
        {
            component: 'BaseInput',
            label: 'Duração em minutos',
            model: 'timer_time',
            id: 'timer_time'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'timer_numbers_background_color',
            id: 'timer_numbers_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor dos números',
            model: 'timer_numbers_text_color',
            id: 'timer_numbers_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor da legenda',
            model: 'timer_numbers_legend_color',
            id: 'timer_numbers_legend_color',
        },
    ],
    timer_text: [
        {
            component: 'BaseInput',
            label: 'Duração em minutos',
            model: 'timer_text',
            id: 'timer_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'timer_text_color',
            id: 'timer_text_color',
        },
    ],
    timer_button: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'timer_text_button',
            id: 'timer_text_button'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'timer_button_color',
            id: 'timer_button_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'timer_text_button_color',
            id: 'timer_text_button_color',
        },
    ],
    coupon_code:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'coupon_button_text',
            id: 'coupon_button_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'coupon_button_text_color',
            id: 'coupon_button_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'coupon_button_background_color',
            id: 'coupon_button_background_color',
        }, 
    ],
    coupon_code_hover:[
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto ao passar o mouse',
            model: 'coupon_button_text_hover_color',
            id: 'coupon_button_text_hover_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão ao passar o mouse',
            model: 'coupon_button_background_hover_color',
            id: 'coupon_button_background_hover_color',
        },
    ],
    order_bump_title:[
        {
            component: 'BaseInput',
            label: 'Título',
            model: 'order_bump_title',
            id: 'order_bump_title'
        },
    ],
    order_bump:[
        {
            component: 'BaseInput',
            label: 'Descrição',
            model: 'order_bump_description',
            id: 'order_bump_description'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'order_bump_text_color',
            id: 'order_bump_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor da borda',
            model: 'order_bump_border_color',
            id: 'order_bump_border_color',
        },
    ],
    order_bump_label:[
        {
            component: 'BaseInput',
            label: 'Título',
            model: 'order_bump_box_title',
            id: 'order_bump_box_title'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'order_bump_label_background_color',
            id: 'order_bump_label_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'order_bump_label_text_color',
            id: 'order_bump_label_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor da sombra',
            model: 'order_bump_label_shadow_color',
            id: 'order_bump_label_shadow_color',
        },
        {
            component: 'BaseSwitch',
            label: 'Order bump selecionado',
            tooltip: 'Define o order bump selecionado como padrão no checkout',
            model: 'order_bump_selected',
            id: 'order_bump_selected',
        },
    ],
    proceed_button:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'proceed_button_text',
            id: 'proceed_button_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'proceed_button_background_color',
            id: 'proceed_button_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão ao passar o mouse',
            model: 'proceed_button_background_hover_color',
            id: 'proceed_button_background_hover_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'proceed_button_text_color',
            id: 'proceed_button_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto ao passar o mouse',
            model: 'proceed_button_hover_text_color',
            id: 'proceed_button_hover_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do ícone',
            model: 'proceed_button_icon_color',
            id: 'proceed_button_icon_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do ícone ao passar o mouse',
            model: 'proceed_button_icon_hover_color',
            id: 'proceed_button_icon_hover_color',
        },
    ],
    order_button: [
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'order_button_text',
            id: 'order_button_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'order_button_background_color',
            id: 'order_button_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão ao passar o mouse',
            model: 'order_button_background_hover_color',
            id: 'order_button_background_hover_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'order_button_text_color',
            id: 'order_button_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto ao passar o mouse',
            model: 'order_button_text_hover_color',
            id: 'order_button_text_hover_color',
        },
    ],
    popup_orders:[
        {
            component: 'BaseSelect',
            label: 'Formato',
            model: 'popup_orders_type',
            id: 'popup_orders_type',
            props: {
                items: {
                    'time': 'Compras baseado em período',
                    'customer': 'Compradores recentes'
                },
            }
        },
        {
            component: 'BaseSelect',
            label: 'Período',
            model: 'popup_orders_periods',
            id: 'popup_orders_periods',
            props: {
                items: {
                    'now': 'Neste momento',
                    'last_30_min': 'Nos últimos 30 minutos',
                    'last_hour': 'Na última hora',
                    'day': 'Hoje',
                    'week': 'Esta semana',
                },
            }
        },
        {
            component: 'BaseInput',
            label: 'Quantidade mínima',
            model: 'popup_orders_type',
            id: 'popup_orders_type'
        },
    ],
    credit_card_method:[
        {
            component: 'BaseInput',
            label: 'Desconto cartão de crédito',
            model: 'credit_card_discount',
            id: 'credit_card_discount',
            tag: '%',
        },
        {
            component: 'BaseSelect',
            label: 'Status',
            model:  'credit_card_status',
            id: 'credit_card_status',
            props: {
                items: {
                    0: 'Desabilitado',
                    1: 'Habilitado'
                },
            }
        },
        {
            component: 'BaseSelect',
            label: 'Ordem no checkout',
            model: 'credit_card_ordenation',
            id: 'credit_card_ordenation',
            props: {
                items: {
                    1: '1°',
                    2: '2º',
                    3: '3º',
                },
            }
        },
    ],
    pix_method:[
        {
            component: 'BaseInput',
            label: 'Desconto pix',
            model: 'pix_discount',
            id: 'pix_discount',
            tag: '%',
        },
        {
            component: 'BaseSelect',
            label: 'Status',
            model: 'pix_status',
            id: 'pix_status',
            props: {
                items: {
                    0: 'Desabilitado',
                    1: 'Habilitado'
                },
            }
        },
        {
            component: 'BaseSelect',
            label: 'Ordem no checkout',
            model: 'pix_ordenation',
            id: 'pix_ordenation',
            props: {
                items: {
                    1: '1°',
                    2: '2º',
                    3: '3º',
                },
            }
        },
    ],
    boleto_method:[
        {
            component: 'BaseInput',
            label: 'Desconto boleto',
            model: 'boleto_discount',
            id: 'boleto_discount',
            tag: '%',
        },
        {
            component: 'BaseSelect',
            label: 'Status',
            model: 'boleto_status',
            id: 'boleto_status',
            props: {
                items: {
                    0: 'Desabilitado',
                    1: 'Habilitado'
                },
            }
        },
        {
            component: 'BaseSelect',
            label: 'Ordem no checkout',
            model: 'boleto_ordenation',
            id: 'boleto_ordenation',
            props: {
                items: {
                    1: '1°',
                    2: '2º',
                    3: '3º',
                },
            }
        },
    ],
    exit_popup_button:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'exit_popup_button_text',
            id: 'exit_popup_button_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'exit_popup_button_background_color',
            id: 'exit_popup_button_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'exit_popup_button_text_color',
            id: 'exit_popup_button_text_color',
        },
    ],
    exit_popup_discount:[
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'exit_popup_discount_text_color',
            id: 'exit_popup_discount_text_color',
        },
    ],
    step_1_title:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'step_1_title_text',
            id: 'step_1_title_text'
        },
    ],
    step_1_subtitle:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'step_1_subtitle_text',
            id: 'step_1_subtitle_text'
        },
    ],
    step_2_title:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'step_2_title_text',
            id: 'step_2_title_text'
        },
    ],
    step_2_subtitle:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'step_2_subtitle_text',
            id: 'step_2_subtitle_text'
        },
    ],
    step_3_title:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'step_3_title_text',
            id: 'step_3_title_text'
        },
    ],
    step_3_subtitle:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'step_3_subtitle_text',
            id: 'step_3_subtitle_text'
        },
    ],
    back_redirect:[
        {
            component: 'BaseInput',
            label: 'URL',
            model: 'back_redirect_url',
            id: 'back_redirect_url',
        },    
        {
            component: 'BaseSwitch',
            label: 'Ativar back redirect',
            model: 'back_redirect_enable',
            id: 'back_redirect_enable',
        }, 
    ], 
}

const subMenuComponents = {
    timer:[
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'timer_background_color',
            id: 'timer_background_color',
        },
        {
            inputComponent: true,
            component: 'BaseSwitch',
            label: 'Botão de compra',
            model: 'enable_timer_sell_button',
            id: 'enable_timer_sell_button',
        },
        {
            title: 'Texto',
            dialog_customization: 'timer_text'
        },
        {
            title: 'Tempo',
            dialog_customization: 'timer_numbers',
        },
        {
            title: 'Botão de compre agora',
            dialog_customization: 'timer_button',
        }
    ],
    header:[
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'header_background_color',
            id: 'header_background_color',
        },
        {
            inputComponent: true,
            component: 'BaseSwitch',
            label: 'Contato da loja',
            model: 'show_email_support_on_header',
            id: 'show_email_support_on_header',
        },
        {
            title: 'Logotipo',
            dialog_customization: 'logotipo',
        },
        {
            title: 'Contato',
            dialog_customization: 'enable_header_contact_info',
        },
        {
            title: 'Ícone segurança',
            dialog_customization: 'header_secure',
        },
    ],
    step_1:[
        {
            title: 'Título',
            dialog_customization: 'step_1_title',
        },
        {
            title: 'Subtítulo',
            dialog_customization: 'step_1_subtitle',
        },
        {
            title: 'Botão de continuar',
            dialog_customization: 'proceed_button',
        },
    ],
    step_2:[
        {
            title: 'Título',
            dialog_customization: 'step_2_title',
        },
        {
            title: 'Subtítulo',
            dialog_customization: 'step_2_subtitle',
        },
        {
            title: 'Botão de continuar',
            dialog_customization: 'proceed_button',
        },
    ],
    payment_step_3:[
        {
            title: 'Título',
            dialog_customization: 'step_3_title',
        },
        {
            title: 'Subtítulo',
            dialog_customization: 'step_3_subtitle',
        },
        {
            title: 'Formas de pagamento',
            dialog_submenu: 'payment_methods',
        },
        {
            title: 'Botão de compra',
            dialog_customization: 'order_button',
        },
    ],
    payment_methods:[
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor de seleção',
            model: 'payment_methods_selected_color',
            id: 'payment_methods_selected_color',
        },
        {
            title: 'Cartão de crédito',
            dialog_customization: 'credit_card_method',
        },
        {
            title: 'Pix',
            dialog_customization: 'pix_method',
        },
        {
            title: 'Boleto',
            dialog_customization: 'boleto_method',
        },
    ],
    coupon:[
        {
            title: 'Botão',
            dialog_customization: 'coupon_code',
        },
        {
            title: 'Botão ao passar o mouse',
            dialog_customization: 'coupon_code_hover',
        }
    ],
    order_bump_sub:[
        {
            title: 'Título',
            dialog_customization: 'order_bump_title',
        },
        {
            title: 'Rótulo',
            dialog_customization: 'order_bump_label',
        },
        {
            title: 'Descrição',
            dialog_customization: 'order_bump',
        },
    ],
    
    exit_intent_popup:[
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'exit_popup_background_color',
            id: 'exit_popup_background_color',
        },
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'exit_popup_text_color',
            id: 'exit_popup_text_color',
        },
        {
            inputComponent: true,
            component: 'BaseInput',
            label: 'Cupom',
            model: 'exit_popup_coupon',
            id: 'exit_popup_coupon'
        },
        {
            inputComponent: true,
            component: 'BaseSwitch',
            label: 'Habilitar exit-intent popup',
            model: 'enable_exit_popup',
            id: 'enable_exit_popup',
        },
        {
            title: 'Cor do texto de desconto',
            dialog_customization: 'exit_popup_discount',
        },
        {
            title: 'Botão',
            dialog_customization: 'exit_popup_button',
        }
    ]
}

function addZero(r) {
    return (Number(r) < 10) ? '0' + r  : r;
}

function addToTimer(){
    if(storeStore.checkout.customization.timer_time > 59){
        let getValueInHours = storeStore.checkout.customization.timer_time / 60;
        if(Number.isInteger(getValueInHours)){
            timerHours.value = addZero(getValueInHours)
            timerMinutes.value = '00'
        }else{
            let splitHour = getValueInHours.toString().split('.');
            
            timerHours.value = addZero(splitHour[0]);
            timerMinutes.value =  addZero(Math.round((getValueInHours - splitHour[0]) * 60));
        }
    } else if(storeStore.checkout.customization.timer_time <= 59) {
        timerHours.value = '00';
        timerMinutes.value = addZero(storeStore.checkout.customization.timer_time);
    }
}

const previousOrdenations = ref([storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation])

watch(() => [storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation] , (e) => {
    for(let i = 0; i < e.length; i++){
        if(e[i] != previousOrdenations.value[i]){
            for(let index = 0; index < previousOrdenations.value.length;index++){
                if(previousOrdenations.value[index] == e[index]){
                    if(e[i] == previousOrdenations.value[index]){
                        if(index == 0){
                            storeStore.checkout.customization.credit_card_ordenation = previousOrdenations.value[i];
                            previousOrdenations.value = [storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation]
                            return;
                        } else if(index == 1){
                            storeStore.checkout.customization.pix_ordenation = previousOrdenations.value[i];
                            previousOrdenations.value = [storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation]
                            return;
                        } else if(index == 2){
                            storeStore.checkout.customization.boleto_ordenation = previousOrdenations.value[i];
                            previousOrdenations.value = [storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation]
                            return;
                        }
                    }
                }
            }
        }
    }
});

var index_variables = 0;
function extractVariables(string){
    if (storeStore.checkout.id) {
        if(string == null || string == undefined){
            if(index_variables == 0){
                string = '{produto:titulo}'
                string = string.replace('{produto:titulo}', storeStore.checkout.product_demo.title);
                index_variables++
            } else if(index_variables == 1){
                string = '{produto:descricao}'
                string = string.replace('{produto:descricao}', storeStore.checkout.product_demo.description );
                index_variables++
            } 
            return;
        }
        string = string.replace('{produto:titulo}', storeStore.checkout.product_demo.title);
        string = string.replace('{produto:descricao}', storeStore.checkout.product_demo.description);
    }

    return string;
}

function emitMinimizeMenu(){
    emit('emitMinimizeMenu');
}
</script>

<style scoped src="@/assets/css/checkouts/v2/checkout_v2.css"></style>
<style scoped src="@/assets/css/checkouts/v2/checkout_v2_mobile.css"></style>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700;900&display=swap");

#countdown_wrapper {
    margin-bottom: 0;
}

.container {
    background-color: #fff;
    border: 1px #ccc solid;
    border-radius: 8px;
    padding: 0;
    padding-bottom: 40px;
    font-family: 'Source Sans Pro';
}

#checkout {
    max-width: 1000px;
    margin: 0 auto;
}

#checkout img{
    width: 100%;
}

.form-control {
    border: 0;
}

.product_image {
    width: auto;
    height: 45px;
    border: 0;
    margin: 0;
}

.product_image img {
    width: 50px !important;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

#checkout header {
    background-color: #d2d2d2;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0;
    padding: 0;
}

#checkout header span {
    min-height: 100px;
    display: block;
}

.form_checkout label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600 !important;
}

.bump_image span {
    background-color: #000000;
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 4px
}

.bump_image img{
    width: 100px;
}

.box {
    margin-top: 15px
}

#popup_orders {
    position: absolute;
    right: 20px;
    bottom: 20px;
    opacity: 1;
}

.container {
    background-color: v-bind('checkout.customization.background_color');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

#header{
    background-color: v-bind('checkout.customization.header_background_color');
}

#header_contact_info, .header_contact  svg{
    color: v-bind('checkout.customization.header_contact_color');
    fill: v-bind('checkout.customization.header_contact_color')!important;
}

#header_secure_info, #header_secure_info svg{
    color: v-bind('checkout.customization.header_secure_color');
    fill: v-bind('checkout.customization.header_secure_color')!important;
}

#countdown_wrapper {
    background-color: v-bind('checkout.customization.timer_background_color');
}

#countdown_wrapper h5 {
    color: v-bind('checkout.customization.timer_text_color');
}

#countdown_wrapper span {
    color: v-bind('checkout.customization.timer_numbers_legend_color');
}

#countdown_wrapper #countdown_numbers{
    display: inline-block;
    color: v-bind('checkout.customization.timer_numbers_text_color')!important;
}

#countdown_wrapper #countdown strong {
    background-color: v-bind('checkout.customization.timer_numbers_background_color');
    color: v-bind('checkout.customization.timer_numbers_text_color');
}

#countdown_wrapper .btn {
    background-color: v-bind('checkout.customization.timer_button_color');
    color: v-bind('checkout.customization.timer_text_button_color');
}

#countdown_wrapper label{
    color: v-bind('checkout.customization.timer_text_color');
    margin-top: 0;
    margin-bottom: 0;
}

#countdown label svg{
    fill: v-bind('checkout.customization.timer_icon_color')
}

#exit_popup .content,
#exit_popup .content .percent {
    background-color: v-bind('checkout.customization.exit_popup_background_color');
    color: v-bind('checkout.customization.exit_popup_text_color');
}

#exit_popup .content button {
    background-color: v-bind('checkout.customization.exit_popup_button_background_color');
    color: v-bind('checkout.customization.exit_popup_button_text_color');
}

#exit_popup .content .amount {
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
    color: v-bind('checkout.customization.exit_popup_discount_text_color');
}

#order_coupon button{
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    color: v-bind('checkout.customization.coupon_button_text_color');
    background-color: v-bind('checkout.customization.coupon_button_background_color');
}

#order_coupon button:hover{
    color: v-bind('checkout.customization.coupon_button_text_hover_color');
    background-color: v-bind('checkout.customization.coupon_button_background_hover_color');
}

#order_dump ul li {
    background-color: v-bind('checkout.customization.order_bump_background_color');
    border-color: v-bind('checkout.customization.order_bump_border_color');
}

#order_dump ul li p {
    color: v-bind('checkout.customization.order_bump_text_color');
}

#order_dump ul li label {
    background-color: v-bind('checkout.customization.order_bump_label_background_color');
    color: v-bind('checkout.customization.order_bump_label_text_color');
    box-shadow: 2px 3px 0 v-bind('checkout.customization.order_bump_label_shadow_color');
}

#order_dump ul li div {
    color: v-bind('checkout.customization.order_bump_label_text_color');
}

.btn_default {
    background-color: v-bind('checkout.customization.order_button_background_color');
    color: v-bind('checkout.customization.order_button_text_color');
}

.btn_default:hover{
    background-color: v-bind('checkout.customization.order_button_background_hover_color');
    color: v-bind('checkout.customization.order_button_text_hover_color');
}

.proceed_button{
    background-color: v-bind('checkout.customization.proceed_button_background_color');
    color: v-bind('checkout.customization.proceed_button_text_color');
}

.proceed_button:hover, .proceed_button:hover > svg{
    fill: v-bind('checkout.customization.proceed_button_icon_hover_color');
    background-color: v-bind('checkout.customization.proceed_button_background_hover_color');
    color: v-bind('checkout.customization.proceed_button_hover_text_color');
}

.proceed_button svg{
    fill: v-bind('checkout.customization.proceed_button_icon_color')
}

#payment_methods li.active {
    color: v-bind('checkout.customization.payment_methods_selected_color');
    box-shadow: inset 0px 4px 0px -1px v-bind('checkout.customization.payment_methods_selected_color');
}

#payment_methods li.active svg {
    fill: v-bind('checkout.customization.payment_methods_selected_color');
}

#btn_show_exit_popup {
    height: 30px;
    padding: 4px;
    top: 2px;
    position: relative;
}

#exit_popup {
    z-index: 4;
    position: absolute;
}
#exit_popup .content {
    position: fixed;
    margin: -100px -210px;
}

header {
    padding: 10px 15px;
}

header .row {
    max-width: 1000px;
    margin: 0 auto;
}

#logotipo {
    width: v-bind(checkout.customization.logotipo_size + 'px');
}
.payment_box ul{

    padding: 0px;
}
.secure{
    margin-top: 0;
}
</style>