<template>
    <div class="box_content">
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th class="ps-3">Evento</th>
                        <th  class="actions"></th>
                    </tr>
                </thead>
                <tbody v-if="events_leadlovers.length">
                    <tr v-for="(row, key) in events_leadlovers" class="divisor_bottom" :key="key">
                        <td>
                            {{ row.type }}
                        </td>
                        <td class="actions">
                            <button @click.middle="editEventBlank(row.id)" @click="editEvent(row.id)" type="button" class="icon_buttons">
                                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                            </button>
                            <button @click="openPopup(row.id)" type="button" class="icon_buttons icon_view">
                                <font-awesome-icon icon="fa-solid fa-trash-can" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!events_leadlovers.length" class="text-center p-4 mb-0 divisor_bottom">Nenhum resultado encontrado</h6>
        </div>
        <div style="text-align:center;" class="p-4">
            <BaseButton @click="createEvent" class="btn-primary btn-lg">
                Adicionar
            </BaseButton>
        </div>
        <router-view></router-view>
        <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
            <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover este evento?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="removeEvent" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true: excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
        </BasePopup>
    </div>
</template>

<script setup>
import { computed, shallowRef, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { events_leadlovers }  = storeToRefs(productStore); 

const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const product_id = computed (function () {
    return route.params.id;
});

let eventToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null);
const isLoading = ref(false);

function editEvent(id){
    globalStore.loader('show');
    productStore.tryEventLeadlovers(id).then(() =>{
        router.push('/products/' + product_id.value + '/events/leadlovers/' + id)
        globalStore.loader('hide');
    })
}

function editEventBlank(id){
    window.open('/products/' + product_id.value + '/events/leadlovers/' + id)
}

function createEvent(){
    router.push('/products/' + product_id.value + '/events/leadlovers/new')
}

async function removeEvent(){
    if(!isDeleting && !isLoading.value && eventToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isLoading.value = true;
        globalStore.loader('show');
        await productStore.tryDeleteEventLeadlovers(eventToDelete).then(function(success) {
            isDeleting = false;
            if(success){
                productStore.tryEventsLeadlovers().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    confirmAction.value = null;
                    isLoading.value = false;
                    router.push(route.path)
                });
            }else {
                globalStore.loader('hide');
                isLoading.value = false;
                isDeleting = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function openPopup(id) {
    eventToDelete = id
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}


</script>

<style scoped> 

</style>