<template>
    <h2>
        <font-awesome-icon icon="fa-solid fa-badge-dollar" style="color: #215eda"/>
        <span class="ms-2">{{ (props.refund.status.slug == 'pending') ? 'Contestar' : 'Responder' }} solicitação - Pedido #{{ props.refund.id }}</span>
    </h2>
    <p class="mt-2" v-if="props.refund.status.slug == 'pending'">Você não aceitou a solicitação de reembolso de <b>{{ props.refund.client.name }}</b>, preencha os campos abaixo para justificar recusa e enviar mais informações para o cliente.</p>
    <p v-else>Envie uma resposta sobre a solicitação ou deixe uma mensagem para o cliente.</p>
    <BaseInput
        class="col-12"
        :label="(props.refund.status.slug == 'pending') ? 'Escreva em detalhes o motivo da contestação:' : 'Informações:'"
        textarea
        :page="route.name"
        id="text"
        v-model="params.text"
    />
    <BaseFileUpload
        class="col-12"
        label="Imagens"
        id="images"
        :page="route.name"
        multiple
        tooltip_text="Selecione no máximo 3 imagens."
        v-model="params.images"
        accept=".jpg, .jpeg, .png"
    />
    <BaseButton :disabled="isLoading || disableSubmitButton" class="mt-2 btn-primary w-100" @click="submit">{{ (props.refund.status.slug == 'pending') ? 'Contestar' : 'Responder' }}</BaseButton>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useGlobalStore } from '@/stores/global';
const global = useGlobalStore();

import { useRoute } from "vue-router";
const route = useRoute();

onBeforeMount(() => {
    delete global.errors[route.name];
});

const props = defineProps({
    refund: {
        required: true,
    }
});

const params = ref({
    text: '',
    images: null,
});

import { useRefundStore } from '@/stores/refund';
const refundStore = useRefundStore();

const emit = defineEmits(["closePopup"]);

const disableSubmitButton = ref(false);

const isLoading = ref(false);
function submit() {
    if(!isLoading.value){
        global.loader('show');
        disableSubmitButton.value = true;
        refundStore.tryReplyRefund(route.name, props.refund.id, params.value).then(function (success){
            global.loader('hide');
            if(success){
                emit('closePopup');
            }else {
                disableSubmitButton.value = false;
            }
        });
        isLoading.value = false;
    }
}
</script>

<style scoped>
h2 {
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}
</style>