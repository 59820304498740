<template>
  <div class="box_content">
    <div class="tab-content">
      <div class="tab-pane active bank_data p-4" id="nav-contact">
        <div class="row">
          <div class="col-md-12">
            <label>Chave de api</label>
            <BaseInput type="password" v-model="userStore.user.api_token" :page="route.name"/>
          </div>
          <div class="col-md-12">
            <a href="https://fullsale.readme.io/" target="_blank">
              <BaseButton class="btn-primary">Acessar documentação</BaseButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { onBeforeMount } from 'vue';
import { useAccountStore } from '@/stores/account';
import { useUserStore } from '@/stores/user';
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const accountStore = useAccountStore();
const userStore = useUserStore();

onBeforeMount(() => {
  if(accountStore.account.status.slug !== 'validated'){
    router.push('/account')
  }
})

</script>