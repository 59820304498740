<template>
    <div>
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
                <header>
                    <font-awesome-icon icon="fa-solid fa-table-layout" style="color: #215eda;"/>
                    <span v-if="route.name == 'storeCheckoutEdit'" class="ms-2 align-middle">Editar checkout</span>
                    <span v-else class="ms-2 align-middle">Novo checkout</span>
                </header>
                <div class="content">
                    <form @submit.prevent="">
                        <div class="row">
                            <BaseInput
                                class="col-12 mb-3"
                                label="Nome *"
                                type="text"
                                id="title"
                                v-model="checkout.title"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-md-12 col-12"
                                label="Forma de aplicação *"
                                type="text"
                                id="display_type"
                                placeholder="Selecione"
                                :items="displayList"
                                v-model="checkout.display_type"
                                :page="route.name"
                            />
                            <BaseSelect
                                v-if="checkout.display_type === 'product'"
                                class="col-md-12 col-12"
                                label="Produto"
                                type="text"
                                id="id_product"
                                placeholder="Todos"
                                :items="props.productsList"
                                v-model="checkout.id_product"
                                :page="route.name"
                            />
                            <BaseInput
                                v-if="checkout.display_type === 'url'"
                                class="col-md-12 col-12"
                                label="Código de referência *"
                                type="text"
                                id="url_param"
                                v-model="checkout.url_param"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-12"
                                label="Status *"
                                id="status"
                                placeholder="Selecione"
                                :items="statusList"
                                v-model="checkout.status"
                                :page="route.name"
                            />
                            
                        <p v-if="checkout.display_type === 'url'">
                            Adicione o parâmetro no checkout: &c={{  checkout.url_param }}
                        </p>
                        </div>

                        <BaseButton class="btn-primary w-100 mt-4" @click="submit" :disabled="isLoading"> Salvar </BaseButton>
                    </form>
                </div>
        </BasePopup>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from "vue-router";
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

const storeStores = useStoreStore();

const { checkout } = storeToRefs(storeStores);

const isLoaded = ref(false);
const isLoading = ref(false);

const props = defineProps({
    productsList:{
        type: Object,
    }
})

const displayList = {
    'product': 'Produtos',
    'url': 'Parâmetro na url do checkout',
}

const statusList = ref({
    0 : 'Desabilitado',
    1: 'Habilitado',
})

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(route.name == 'storeCheckoutEdit'){
        if(storeStore.page.id){
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            storeStore.tryCheckout(route.params.slug, store_id.value).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'storeCheckoutCreate'){

        globalStore.cleanObject(storeStore.checkout);

        globalStore.loader('hide')
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')

        if(storeStore.checkout.display_type === 'url'){
            storeStore.checkout.id_product = 0;
        }
        if(route.name == 'storeCheckoutEdit'){
            
            let params = ['title', 'url', 'id_product', 'display_type', 'url_param', 'status'];

            storeStore.tryUpdateCheckout(params, route.name).then(function(success) {
                if(success){
                    
                    useGlobalStore().successMessage('Checkout atualizado com sucesso');

                    storeStore.tryCheckouts().then(function() {
                        router.push('/stores/' + store_id.value + '/checkouts')
                    });
                }
                isLoading.value = false;
                globalStore.loader('hide');
            })
        } else if(route.name == 'storeCheckoutCreate') {

            let params = ['title', 'url', 'id_product', 'display_type', 'url_param', 'status'];

            storeStore.tryCreateCheckout(params, route.name).then(function(success) {
                if(success){
                    useGlobalStore().successMessage('Checkout criado com sucesso');

                    storeStore.tryCheckouts().then(function() {
                        router.push('/stores/' + store_id.value + '/checkouts')
                    });
                }
                isLoading.value = false;
                globalStore.loader('hide');
            })
        } 
    }
}

</script>