<template>
    <div class="form-group mb-3">

        <label :class="'custom-control custom-switch custom-switch-md ' + [error ? 'danger-label' : '']" :for="id">
            <input 
                :id="id"
                :checked="checked"
                @input="updateInput"
                :disabled="disabled === true"
                :class="[disabled === true ? ' disabled_switch' : '']"
                :type="type"
            />

            <span v-if="labelWithHtml" v-html="label"></span>
            <span v-else>{{ label }}</span>

            <span v-if="tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="tooltip" class="ms-1">
                <font-awesome-icon icon="fas fa-circle-info"/>
            </span>

            <span v-if="!!error" class="invalid-feedback d-block" role="alert">{{ error }}</span>
        </label>
    </div>
</template>

<script>
import { useGlobalStore } from "@/stores/global";

export default {
    emits: ["update:checked"],
    data() {
        return {
            global: useGlobalStore(),
        };
    },
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },

        labelWithHtml: {
            type: Boolean,
            default: false,
        },

        id: {
            type: String,
            required: false,
        },

        page: {
            type: String,
            required: false,
        },

        tooltip: {
            required: false,
        },

        checked: {
            default: 0,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            required: false,
            type: String,
            default: 'checkbox',
        }
    },
    methods: {
        updateInput(event) {
            this.$emit("update:checked", Number(event.target.checked));
            if (this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },
    },
    computed: {
        error() {
            if (this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        },
    },
    mounted() {
        this.$emit("update:checked", Number(this.$props.checked));
    },
};
</script>


<style scoped>
label {
    cursor: pointer;
}

input {
    background: white;
    border: #ddd solid 1px;
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 20px;
    position: relative;
    float: left;
    margin-right: 10px;
}

input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #b6b6b6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 11%);
}

input::before {
    border-radius: 50px;
    content: "";
    height: 70%;
    position: absolute;
    top: 50%;
    transform: translate(7px, -50%);
    width: 85%;
}

input::after {
    background-color: #adb5bd;
    border-radius: 50px;
    content: "";
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translate(2px, -50%);
    transition: all 0.1s;
    width: 20px;
}

input:checked::after {
    border-color: #4caf50;
    background-color: white;
    transform: translate(100%, -50%);
}

input:checked {
    background-color: #4caf50;
    border-color: #4acf50;
}

span {
    vertical-align: middle;
}

.danger-label input, .danger-label span {
    border-color: #fd6d6d;
    color: #d60e0e;
}

.disabled_switch:checked{
    border-color:#acb5bd!important;
    background-color: #acb5bd!important;
} 

.disabled_switch:checked::before{
    background-color: #acb5bd!important;
}


.disabled_switch:not(:checked)::after{
    background-color: #acb5bd!important;
}

.disabled_switch:not(:checked){
    background-color: #dbe1e6!important;
    color: #dbe1e6!important;
}

</style>