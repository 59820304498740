<template>
    <div class="filter_container">
        <div class="collapse_items">
            <div class="collapse_item">
                <div class="collapse_title">
                    <a data-bs-toggle="collapse" href="#collapse_status" role="button" :aria-expanded="true" aria-controls="collapseExample">
                        <span class="opened_collapse">
                            <font-awesome-icon icon="fas fa-angle-down"/>
                        </span>
                        <span class="closed_collapse">
                            <font-awesome-icon icon="fas fa-angle-right" />
                        </span>
                        <span>Status</span>
                    </a>
                </div>
                <div class="collapse show" id="collapse_status">
                    <BaseSelect placeholder="Selecione" :items="formatedStatusList" v-model="filter.status" multi id="status" page="refunds" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, watch } from 'vue';
import { useGlobalStore } from '@/stores/global'

const globalStore = useGlobalStore();

const formatedStatusList = ref({
    'reversed': 'Revertido',
    "finalized": 'Reembolso aceito',
    'denied': 'Reembolso negado'
})

const filter = ref({
    status: [],
});

const props = defineProps({
    initialFilters: {
        required: false,
        default: null
    }
});

let ignoreOnloadEmit = true;

onBeforeMount(() => {
    if(props.initialFilters) {
        ignoreOnloadEmit = true;
        let filters = props.initialFilters;
        
        for(var i=0; i <= 1 ; i++) {
            let currentForFilter = (i == 0) ? 'status' : '';

            if((filters[currentForFilter]) && filters[currentForFilter].length != 0) {
                Object.keys(filter.value[currentForFilter]).forEach((key) => {

                    if((filters[currentForFilter]) && filters[currentForFilter].constructor === Array) {

                        if (!filters[currentForFilter].includes(key)) {
                            filter.value[currentForFilter][key] = false;
                        }
                    }else {
                        if(key != filters[currentForFilter])  {
                            filter.value[currentForFilter][key] = false;
                        }
                    }
                });
            }
        }

    
        if(filters['status']) {
            filter.value['status'] = filters['status'];
        }

        updateFiltersCount();

    }
});

const emit = defineEmits(["filters", "filtersQty"]);

watch(() => filter, (e) => {
    debounceFilter(e.value.status);
},{ deep: true })

let debounce = null;
function debounceFilter(status) {
    if(ignoreOnloadEmit == false) {
        globalStore.loader('show');
    }

    clearTimeout(debounce)

    debounce = setTimeout(() => {
        if(ignoreOnloadEmit == false) {
            updateFiltersCount();
            emit("filters", {
                'status': status,
            });
        }
        if(ignoreOnloadEmit == true) {
            ignoreOnloadEmit = false;
        }
    }, 700)
}

function updateFiltersCount() {

    let count = 0;

    Object.keys(filter.value).forEach((key) => {

        if(key == 'status') {
            if(filter.value[key] != null && filter.value[key].length) {
                count = count + 1;
        }}
    });

    emit("filtersQty", count);
}
</script>
<style scoped>

:deep(.select-box){
    position: fixed!important;
}

</style>