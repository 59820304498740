import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useOrderStore = defineStore('order', {

    state: () => ({
        orders: {},
        paginate: {},
        order: {},
        report: {},
        filters_orders: {
            'search': null,
            'payment_date_start': null,
            'payment_date_end': null,
            'date_start': null,
            'date_end': null,
            'status': null,
            'payment_methods': null,
            'page': null,
            'order_by': 'created_at',
            'store': null,
            'referral': null,
            'product': null,
            'utms_src': null,
            'coupon': null,
        },
    }),

    actions: {

        async tryOrders() {
            try {
                let params = this.filters_orders;
                const response = await Api.get('orders', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.orders = response.data.data;
                this.paginate = response.data.meta;
                this.report = response.data.report;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryloadAttempts(id) {
            let order = null;
            let order_key = null;

            Object.keys(this.orders).forEach((key) => {
                if(this.orders[key].id == id) {
                    order = this.orders[key];
                    order_key = key;
                    return;
                }
            });

            if(!order.orders_attempts) {
                try {
                    const response = await Api.get('orders/' + id + '/attempts');
                    this.orders[order_key].orders_attempts = response.data.data;                    
                } catch (error) {
                    useGlobalStore().tryIncomingErrors(error);
                    return false;
                }
            }

            this.orders[order_key].showing_attempts = !(this.orders[order_key].showing_attempts);

            if(this.orders[order_key].showing_attempts) {

                let orders = this.orders;
                let position = Number(order_key) + 1;

                this.orders[order_key].orders_attempts.forEach((i) => {
                    let current_order = i;
                    current_order['isAttempt'] = true;
                    current_order['isAttemptid'] = id;
                    current_order['attempts'] = 0;
                    orders.splice(position, 0, current_order);
                    position =  position + 1;
                });
                this.orders = orders;
            }else {
                let orders = this.orders;
                var i = 0;
                while (i < orders.length) {
                    if (Object.hasOwn(orders[i], 'isAttempt') && orders[i].isAttemptid == id) {
                        this.orders.splice(i, 1);
                    }else {
                        i++;
                    }
                }
                this.orders = orders;
            }

        },
    
        async trychangeFinishedStatus(id, status, key) {
            try {
                await Api.post('orders/' + id + '/finished-mark', { status: status });
                this.orders[key].finished_mark = status;

                return true;
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
    
        async tryOrder(id) {
            try {
                const response = await Api.get('orders/' + id + '/view');
                this.order = response.data.data;
                return true;
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryOrderEdit(id) {
            try {
                const response = await Api.get('orders/' + id);
                this.order = response.data.data;
                if(!this.order.ordered_as_productor){
                    this.router.push('/orders/'+ this.order.id + '/show');
                    return;
                }
                this.tryFiles(id);
                this.tryEvents(id);
                if(this.order.has_chargeback) {
                    this.tryChargeback(id);
                }
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateClient(page, id, params) {

            let formData = useGlobalStore().getOnlyParams(params, this.order.client);
            formData['_method'] = 'put';

            try {
                await Api.post("orders/" + id + '/client', formData);

                Object.keys(params).forEach((key) => {
                    this.order.client[key] = params[key];
                });

                useGlobalStore().successMessage('Informações atualizadas com sucesso');
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryRefundRequest(page, id, params) {
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.order.refund);

                await Api.post('orders/' + id + '/refund', formData);
                this.tryOrderEdit(id);
                useGlobalStore().successMessage('Solicitação realizada com sucesso');
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },
    
        async tryGenerateNfe(id) {
            try {
                const response = await Api.post('orders/' + id + '/generate-nfe');
                if(response.data.data.error) {
                    useGlobalStore().warningMessage(response.data.data.message);
                }else {
                    useGlobalStore().successMessage('Solicitação realizada com sucesso');
                }
                this.tryOrderEdit(id);

            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryFiles(id) {
            try {
                const response = await Api.get('orders/' + id + '/files');
                this.order.files = response.data.data;
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryEvents(id) {
            try {
                const response = await Api.get('orders/' + id + '/events');
                this.order.events = response.data.data;
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryChargeback(id) {
            try {
                const response = await Api.get('orders/' + id + '/chargeback');
                this.order.chargeback = response.data.data;
                this.order.chargeback.reply = [];
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReplyChargeback(page, id) {
            try{
                const formData = new FormData();
                let files = this.order.chargeback.reply.files;
                if(files) {
                    Object.keys(files).forEach((key) => {
                        formData.append("files[" + key + "]", files[key]);
                    });
                }

                let text = this.order.chargeback.reply.text;
                if(text) {
                    formData.append("text", text);
                }

                await Api.post('orders/' + id + '/chargeback/reply', formData);
                this.tryChargeback(id);

                useGlobalStore().successMessage('Informações enviadas com sucesso!');

            }catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteFile(id, file) {
            try {
                await Api.delete('orders/' + id + '/files/' + file);
                await this.tryFiles(id);
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        tryGetFileInList(file) {
            Object.keys(this.order.files).forEach((key) => {
                if(this.order.files[key].id == file) {
                    //????
                }
            });
        },

        async tryCreateFile(page, id_order, params) {
            const formData = new FormData();
            for (let key in params) {
                if(params[key]) {
                    formData.append(key, params[key]);
                }
            }

            try {
                await Api.post('orders/' + id_order + '/files', formData);
                await this.tryFiles(id_order);
                useGlobalStore().successMessage('Arquivo adicionado com sucesso');
                this.router.push('/orders/' + id_order + '/files');
                return true;
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateFile(page, id_order, file, params) {

            const formData = new FormData();
            for (let key in params) {
                if(params[key]) {
                    formData.append(key, params[key]);
                }
            }

            formData.append('_method', 'put');

            try {
                await Api.post('orders/' + id_order + '/files/' + file, formData);
                await this.tryFiles(id_order);
                useGlobalStore().successMessage('Arquivo alterado com sucesso');
                this.router.push('/orders/' + id_order + '/files');
                return true;
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryUpdateTracking(page, id, params) {
            try {
                await Api.put("orders/" + id + '/update-tracking', params, { headers: {'Content-Type': 'application/json'} });
                useGlobalStore().successMessage('Informações atualizadas com sucesso');
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryCancelRecurrent(page, id) {
            try {
                await Api.post("orders/" + id + '/cancel-recurrence');
                useGlobalStore().successMessage('Solicitação enviada com sucesso');
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryResendWebhoks(page, id){
            try{
                await Api.post("orders/" + id + '/resend-webhook');
                useGlobalStore().successMessage('Solicitação enviada com sucesso');
                return true;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },
        
    }
});