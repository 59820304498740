<template>
    <div class="box_content">
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <slot v-if="props.multirows" name="thead" class="thead"></slot>
                <thead v-else>
                    <tr>
                        <th v-for="(th, key) in props.columns" :key="key">{{ th }}</th>
                        <th v-if="!props.noActions"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="isLoading">
                        <tr v-for="i in 15" :key="i">
                            <td v-for="(th, key) in props.columns" :key="key"><div class="loading_component"></div></td>
                            <td v-if="!props.noActions"><div class="loading_component"></div></td>
                        </tr>
                    </template>
                    <slot v-else-if="props.multirows" name="tbody"></slot>
                    <slot v-else></slot>
                </tbody>
                <tfoot></tfoot>
            </table>
            <h6 v-if="!isLoading && 1 > props.qty" style="text-align: center; padding: 20px;">Nenhum resultado encontrado</h6>
        </div>
        <div class="ps-4 py-4 mb-2 pages" v-if="props.paginate && (!isLoading && props.qty > 0) && props.paginate.last_page > 1">
            <template v-if="!isLoading">                  
                <span @click="changePage(page.label, page.disabled)" v-for="(page, key) in pages" :key="key" :class="'page_item ' + [page.active == true ? 'active' : ''] + ' ' + [page.disabled == true ? 'disabled' : '']">{{ page.label }}</span>
            </template>

            <template v-else>
                <span class="page_item">‹</span><span class="page_item">...</span><span class="page_item">...</span><span class="page_item">...</span><span class="page_item">›</span>
            </template>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps({
    columns: {
        required: true,
    },
    isLoading: {
        required: false,
        default: false
    },
    paginate: {
        required: false,
        default: null
    },
    qty : {
        required: true,
        default: 0
    },
    multirows: {
        required: false,
        default: false,
    },
    noActions: {
        required: false,
        default: false,
    }
});
let currentPage = null;
let lastPage = null;

const pages = computed(function () {
    let p = [];

    if(props.paginate) {
        let links = props.paginate.links;

        Object.keys(links).forEach((key) => {

            let label = links[key].label;
            let disabled = (links[key].url == null) ? true : false;
            let active = links[key].active;

            if(links[key].active) {
                currentPage = label;
            }

            if(label == '&laquo; Previous') {
                label = '‹';
            }else if(label == 'Next &raquo;') {
                label = '›';
            }

            p.push({'label': label, 'active': active, 'disabled': disabled});
        });

        lastPage = props.paginate.last_page;

    }
    return p;
});

const emit = defineEmits(["changePage"]);

function changePage(p, d) {
    if(!d) {

        if(p == '‹') {

            if(currentPage > 1) {
                p = Number(currentPage) - 1;
            }else {
                return;
            }

        }else if(p == '›') {
            if(currentPage < lastPage) {
                p = Number(currentPage) + 1;
            }else{
                return;
            }
        }else if(p == currentPage) {
            return;
        }

        emit("changePage", p);
    }
}
</script>

<style scoped>
h6 {
    margin: 0 !important;
}
</style>

<style>
.table-responsive {
    border-radius: 8px;
}

.table {
    background: #fff;
    margin-bottom: 0px !important;
    border-radius: 8px;
}

thead {
    background: #fff;
    color: #595959;
}

.table_bordered {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.table_bordered tbody tr {
    vertical-align: middle;
    color: #595959;
}

.table_bordered tbody tr:last-child, .table_bordered tbody tr:last-child td {
    border-bottom: 0px !important;
}

tbody tr:hover, tbody tr:hover td {
    background: #f3f3f3 !important;
}

.table td, .table th {
  padding: 0.8rem 1rem !important;
  vertical-align: middle;
  color: #595959;
}

.table th {
    border-bottom: 1px #d7d7d7 solid;
}

tfoot tr, tfoot td {
    border: 0;
    font-weight: 600;
}

.pages {
    border-top: 1px solid #d7d7d7;
}

del {
    color: #909090;
}

.actions {
    width: 120px;
    text-align: right;
    white-space: nowrap;
}

.actions > span, .actions > a {
    padding: 9px 8px;
    color: #215eda;
    border-radius: 50px;
    font-size: 12px;
    margin-right: 2px;
    cursor: pointer !important;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
}

.actions > span:hover, .actions > a:hover {
    box-shadow: inset 0px 0px 50px rgb(0 0 0 / 20%);
}

.actions > span.mark_order_finished {
    visibility: hidden;
}

tr:hover span.mark_order_finished {
    visibility: visible;
    color: #a4a4a4;
}

tfoot:last-child tr:last-child td, tbody:last-child tr:last-child td {
    border-bottom: 0px !important;
}

span.mark_order_finished.marked {
    color: #215eda !important;
    visibility: visible;
}

/* navigation */
.page_item {
    border: 1px solid #d7d7d7;
    border-right: 0;
    padding: 8px 14px;
    color: #7e7e7e;
    display: inline-block;
    cursor: pointer;
    user-select: none;
}

.page_item:hover {
    background: #e9ecef;
}

.page_item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page_item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-right: 1px solid #dee2e6;
}

.page_item.active {
    background-color: #215eda;
    color: white !important;
}

.page_item.disabled {
    cursor: default !important;
    background: #fff !important;
}

table tr:last-child td:first-child {
    border-radius: 0px 0px 0px 8px;
}

table tr:last-child td:last-child {
    border-radius: 0px 0px 8px 0px;
}

.loading_component {
    width: 100%;
    display: flex;
    border-radius: 3px;
    height: 21px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
    background: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 100%);
    background-size: 800px 21px;
    animation: loading 1s linear infinite forwards;
    margin: 7px 0px;
}

@keyframes loading {
    0% {
        background-position-x: -800px;
    }
}

.no_top_radius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>