<template>
    <div class="box_content">
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th class="ps-3">Título</th>
                        <th class="ps-3"></th>
                    </tr>
                </thead>
                <tbody v-if="files.length">
                    <tr v-for="(file, key) in files" :key="key">
                        <td >
                            {{ file.title }}
                        </td>
                        <td class="actions">
                            <button @click="editFile(file.id)" type="button" class="icon_buttons icon_view">
                                 <font-awesome-icon icon="fas fa-pen-to-square" />
                            </button>
                            <button @click="openPopup(file.id)" type="button" class="icon_buttons icon_view">
                                <font-awesome-icon icon="fa-solid fa-trash-can" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!files.length" class="text-center p-4 mb-0 divisor_bottom">Nenhum resultado encontrado</h6>
        </div>
        <div style="text-align:center;" class="p-4">
            <BaseButton @click="createFile()" class="btn-primary btn-lg">
                Adicionar
            </BaseButton>
        </div>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover este arquivo?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteFile" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
    </BasePopup>
</template>

<script setup>
import { computed, shallowRef,ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter} from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { files }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const product_id = computed (function () {
    return route.params.id;
});


let fileToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null);
const isLoading = ref(false);

function editFile(id){
    globalStore.loader('show')
    productStore.tryFile(id).then( function(success){
        if(success){
            router.push('/products/' + product_id.value + '/files/' + id)
        }

        globalStore.loader('hide');
    })
}

function createFile(){
    router.push('/products/' + product_id.value + '/files/new')
}
async function deleteFile(){
    if(!isDeleting && !isLoading.value && fileToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isLoading.value = true;
        globalStore.loader('show');
        await productStore.tryDeleteFile(fileToDelete).then(function(success) {
            isDeleting = false;
            if(success){
                useGlobalStore().successMessage('Arquivo removido com sucesso');

                productStore.tryFiles().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    router.push('/products/' + product_id.value + '/files')
                });
                isLoading.value = false;
                confirmAction.value = null;
            }else {
                globalStore.loader('hide');
                isLoading.value = false;
                isDeleting = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function openPopup(id) {
    fileToDelete = id
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}
</script>

<style scoped>

.table_bordered {
    background: #fff;
    border-radius: 8px;
}

thead {
    background: #fff;
    color: #595959;
}

tbody{
    border-bottom: 1px #ddd solid ;
}

tbody td {
    padding: 12px 16px;
    vertical-align: middle;
    color: #595959;
}

tbody tr:last-of-type {
    border-bottom: 0px solid #fff !important;
}

tbody tr:hover {
    background: #f3f3f3 !important;
}
</style>