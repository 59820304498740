<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <div>
            <header>
                <font-awesome-icon icon="fa-solid fa-user-tie-hair" style="color: #215eda;"/>
                <span class="ms-2 align-middle" v-if="route.params.slug">Editar gerente</span>
                <span class="ms-2 align-middle" v-else>Novo gerente</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseInput
                            class="col-md-8 col-12"
                            label="Email *"
                            type="input"
                            id="email"
                            :page="route.name"
                            v-model="manager.email"
                            :disabled="route.params.slug ? true : false"
                        />
                        <BaseInput
                            class="col-md-4 col-12"
                            label="Comissão *"
                            type="number"
                            id="comission"
                            tag="%"
                            :page="route.name"
                            v-model="manager.comission"
                        />
                        <BaseSelect
                            v-if="route.params.slug "
                            class="col-12"
                            label="Status *"
                            id="status"
                            :items="statusList"
                            :page="route.name"
                            v-model="manager.status"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </div>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from '@/stores/global'
import { useStoreStore } from '@/stores/store';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { manager } = storeToRefs(storeStore)

const isLoaded = ref(false)
const isLoading = ref(false);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

const statusList = {
    1: 'Habilitado',
    2: 'Desabilitado',
}

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];

    if(route.name == 'storeManagerEdit'){
        if(storeStore.manager.id){
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            storeStore.tryManager(route.params.slug).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'storeManagerCreate'){
        storeStore.manager = {}
        globalStore.loader('hide')
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')

        if(route.name == 'storeManagerEdit'){

            let params = ['email', 'comission', 'status'];

            storeStore.tryUpdateManager(route.params.slug, params, route.name).then(function(success) {
                if(success){
                    storeStore.tryManagers().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/managers')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        } else if(route.name == 'storeManagerCreate'){

            let params = ['email', 'comission'];

            storeStore.tryCreateManager(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryManagers().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/managers')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })

        }
    }
}

</script>