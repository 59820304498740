import TheOrders from '@/views/orders/TheOrders';
import OrderView from '@/views/orders/show/OrderShow';
import OrderEdit from '@/views/orders/edit/OrderEdit';
import Editinformation from '@/views/orders/edit/OrderEditinformation';
import EditClient from '@/views/orders/edit/OrderEditClient';
import EditComissions from '@/views/orders/edit/OrderEditComission';
import EditRefund from '@/views/orders/edit/OrderEditRefund';
import EditChargeback from '@/views/orders/edit/OrderEditChargeback';
import EditFiles from '@/views/orders/edit/files/OrderFileEdit';
import FormFiles from '@/views/orders/edit/files/OrderFileForm';
import EditEvents from '@/views/orders/edit/OrderEditEvents';

export default [
    {
        path: '/orders',
        name: 'orders',
        component: TheOrders,
        meta: {
            requiresAuth: true,
            menuPage: 'orders',
            dontScrollUp: true
        },
        children: [
            {
                path: '/orders/:id_order/show',
                component: OrderView,
                meta: {
                    requiresAuth: true
                }
            }
        ],
    },
    {
        path: '/orders/:id_order/',
        component: OrderEdit,
        name: 'editOrder',
        meta: {
            requiresAuth: true,
            menuPage: 'orders'
        },
        props:{
            header: true,
            content: true,
        },
        children: [
            {
                path: '/orders/:id_order/',
                component: Editinformation,
                name: 'editInformation',
                children: [
                    {
                        path: '/orders/:id_order/client',
                        name: 'editClient',
                        component: EditClient
                    }
                ]
            },
            {
                path: '/orders/:id_order/tax-and-comissions',
                name: 'editComissions',
                component: EditComissions,
            },
            {
                path: '/orders/:id_order/refund',
                name: 'editRefund',
                component: EditRefund
            },
            {
                path: '/orders/:id_order/chargeback',
                component: EditChargeback,
                name: 'replyChargeback',
            },
            {
                path: '/orders/:id_order/files',
                name: 'editFiles',
                component: EditFiles,
                children: [
                    {
                        path: '/orders/:id_order/files/:file',
                        name: 'editFile',
                        component: FormFiles,
                    }
                ]
            },
            {
                path: '/orders/:id_order/events',
                name: 'editEvents',
                component: EditEvents,
            },
        ]
    }
];