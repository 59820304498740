<template>
    <div>
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <template v-if="isLoaded != false">
                <header>
                    <font-awesome-icon icon="fa-solid fa-file" style="color: #215eda;"/>
                    <span v-if="lesson.id"  class="ms-2 align-middle">Editar lição</span>
                    <span v-else class="ms-2 align-middle">Nova lição</span>
                </header>
                <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseSelect 
                            v-if="!props.moduleId"
                            class="col-12"
                            label="Selecione módulo"
                            id="id_module"
                            :items="modulesList"
                            v-model="lesson.id_module"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-12 mb-3"
                            label="Título *"
                            type="text"
                            id="title"
                            v-model="lesson.title"
                            :page="route.name"
                        />
                        <BaseInput
                            v-if="lesson.type == 'video'"
                            class="col-12 mb-3"
                            label="Vídeo *"
                            type="text"
                            id="content.video"
                            v-model="lesson.content.video"
                            :page="route.name"
                        />

                        <div v-if="lesson.type == 'quiz'" class="col-12">
                            <div id="content_quiz" class="mt-2 mb-3">
                                <h6 v-if="!lesson.content.questions.length" class="text-center p-4 mb-0">Nenhuma questão cadastrada</h6>

                                    <div v-for="(question, index_question) in lesson.content.questions" :key="index_question" class="question mb-3">
                                        <div class="p-3 pb-0">
                                            <h6 class="question_title mb-4">{{  index_question + 1 }} - Questão 
                                                <button @click="removeQuestion(index_question)" type="button" class="icon_buttons">
                                                    <font-awesome-icon icon="fa-solid fa-trash-can" />
                                                </button>
                                            </h6>
                                            <BaseInput
                                                class="col-12 mb-3"
                                                label="Pergunta"
                                                type="text"
                                                id="question"
                                                v-model="question.title"
                                                :page="route.name"
                                            />
                                        </div>
                                        <table class="table mt-1">
                                            <thead>
                                                <tr>
                                                    <th>Opção</th>
                                                    <th class="text-center">Correta</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(answer, index_answer) in question.answers" :key="index_answer">
                                                    <td>
                                                        <BaseInput
                                                            class="col-12 mb-3"
                                                            type="text"
                                                            id="answer.title"
                                                            v-model="answer.title"
                                                            :page="route.name"
                                                        />
                                                    </td>
                                                    <td class="actions text-center">
                                                        <BaseSwitch
                                                            v-model:checked="answer.correct"  
                                                            id="answer.correct"
                                                            :page="route.name" 
                                                        />
                                                    </td>
                                                    <td class="actions">
                                                        <button @click="removeAnswer(index_question, index_answer)" type="button" class="icon_buttons">
                                                            <font-awesome-icon icon="fa-solid fa-trash-can" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div class="text-left px-3 pt-3 divisor_top">
                                            <BaseButton @click="addAnswer(index_question)" class="btn-secondary mt-2 mb-4"><font-awesome-icon icon="fa-solid fa-plus"/></BaseButton>
                                        </div>
                                    </div>
                            </div>
                            <div class="text-center">
                                <BaseButton @click="addQuestion" class="btn-secondary btn-lg mt-2 mb-4">Adicionar questão</BaseButton>
                            </div>
                        </div>
                        
                        <BaseInput
                            class="col-12 mb-3"
                            label="Conteúdo"
                            textarea
                            id="content.text"
                            v-model="lesson.content.text"
                            :page="route.name"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
                </div>
            </template>
        </BasePopup>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from "vue-router"; 
import { useGlobalStore } from '@/stores/global';
import { useProductStore } from '@/stores/products';

const route = useRoute();
const router = useRouter();

const productStore = useProductStore();
const { lesson } = storeToRefs(productStore);

const globalStore = useGlobalStore();

const product_id = computed (function () {
    return route.params.id;
});

const isLoaded = ref(false)
const isLoading = ref(false);

const modulesList = ref({})

const props = defineProps({
    moduleId: {
    }
})

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(route.name == 'productCourseLessonsEdit'){
        if(productStore.lesson.id){
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            productStore.tryLesson(product_id.value, route.params.slug).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'productCourseLessonsCreate'){
        globalStore.cleanObject(productStore.lesson);
        globalStore.loader('hide')
        isLoaded.value = true

        productStore.lesson.type = route.params.slug;
        if(props.moduleId == null){
            for(let i = 0; i < productStore.course_content.length; i++){
                modulesList.value[productStore.course_content[i].id] = productStore.course_content[i].title 
            }
        }
    }
})

function addQuestion(){
    productStore.lesson.content.questions.push({
        title: "Teste",
        answers: [{
            title: null,
            correct: 0,
        }]
    });
}

function addAnswer(index_question){
    productStore.lesson.content.questions[index_question].answers.push({
        title: null,
        correct: 0,
    });
}

function removeQuestion(index_question){
    productStore.lesson.content.questions.splice(index_question, 1);
}

function removeAnswer(index_question, index_answer){
    productStore.lesson.content.questions[index_question].answers.splice(index_answer, 1);
}

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        previousPage = previousPage.substring(0, previousPage.lastIndexOf("/"))
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        const params = [
            'title', 'content', 'id_module', 'questions', 'type',
        ];
        if(route.params.slug != 'video' && route.params.slug != 'text' && route.params.slug != 'quiz'){
            productStore.tryUpdateLesson(product_id.value, params, productStore.lesson.id, route.name).then(function(success) {
                if(success){
                    useGlobalStore().successMessage('Lição atualizada com sucesso');
                    
                    productStore.tryCourseContent(product_id.value).then(function() {
                        globalStore.loader('hide');
                    });
                    isLoading.value = false;
                    router.push('/products/' + product_id.value + '/course');
                }else {
                    isLoading.value = false;
                    globalStore.loader('hide');
                }
            })
        } else {
            if(!props.moduleId){
                productStore.tryCreateLesson(product_id.value, params, route.name).then(function(success) {
                    if(success){
                        useGlobalStore().successMessage('Lição cadastrada com sucesso');

                        productStore.tryCourseContent(product_id.value).then(function() {
                            globalStore.loader('hide');
                        });
                        isLoading.value = false;
                        router.push('/products/' + product_id.value + '/course');
                    }else {
                        isLoading.value = false;
                        globalStore.loader('hide');
                    }
                }) 
            } else{
                productStore.lesson.id_module = props.moduleId

                productStore.tryCreateLesson(product_id.value, params, route.name).then(function(success) {
                    if(success){
                        useGlobalStore().successMessage('Lição cadastrada com sucesso');

                        productStore.tryCourseContent(product_id.value).then(function() {
                            globalStore.loader('hide');
                        });
                        isLoading.value = false;
                        router.push('/products/' + product_id.value + '/course');
                    }else {
                        isLoading.value = false;
                        globalStore.loader('hide');
                    }
                }) 
            }
        }
    }
}



</script>
<style scoped>


#content_quiz .question {
    border: 1px #ddd solid;
    border-radius: 8px;
    background-color: #f8f8f8;
}

#content_quiz table td,
#content_quiz table th {
    background-color: #f8f8f8;
}

#content_quiz .form-group {
    margin-bottom: 0 !important;
}

.question {
    border-bottom: 1px #ddd solid;
}

.question:last-child {
    border-bottom: 0;
}

.question_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>