<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
        </BaseHeader>

        <div class="box_content">
            <div class="p-4">
                <div class="row">
                    <div class="col">
                        <strong class="d-block">Produto</strong>
                        {{ testAb.product_title }}
                    </div>

                    <div class="col">
                        <strong class="d-block">Data de criação</strong>
                        {{ globalStore.formatDate(testAb.created_at) }}
                    </div>

                    <div class="col">
                        <strong class="d-block">Tempo de teste</strong>
                        {{ testAb.duration }} dias
                    </div>
                </div>
            </div>
        </div>

        <div class="box_content">
            <div id="options">
                <div class="option" v-for="(option, key) in testAb.options" :key="key">
                    <div class="row">
                        <div class="col">
                            <strong class="d-block">Opção {{ key + 1 }}</strong>
                            <span>{{ option.title }}</span>
                        </div>
                        <div class="col">
                            <strong class="d-block">Tempo médio</strong>
                            <span>{{ option.duration }}s</span>
                        </div>
                        <div class="col">
                            <strong class="d-block">Acessos</strong>
                            <span>{{ option.pageviews }}</span>
                        </div>
                        <div class="col">
                            <strong class="d-block">Pedidos</strong>
                            <span>{{ option.orders }}</span>
                        </div>
                        <div class="col">
                            <strong class="d-block">Compras</strong>
                            <span>{{ option.purchases }}</span>
                        </div>

                        <div class="col">
                            <strong class="d-block">Conversão</strong>
                            <span>{{ option.conversion }}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="testAb.chart" class="box_content mt-3">
            <ChartTest v-if="testAb.chart" :chart="testAb.chart"
                 ref="childMethod" :chartStoreLoaded="true" />
            <ChartTest v-else :chartStoreLodaded="false"/>
        </div>
    </div>
</template>
<script setup>
import ChartTest from '@/components/includes/stores/ChartTest.vue'
import { useGlobalStore } from "@/stores/global";
import { computed, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStoreStore } from '@/stores/store';
import { storeToRefs } from "pinia";

const title = { title: 'Relatório A/B', icon: 'fa-table-layout' };
const route = useRoute();
const router = useRouter();

const store_id = computed(function () {
    return route.params.id;
});

const previousPage = ref(null);
const globalStore = useGlobalStore();
const storeStore = useStoreStore();
const { testAb } = storeToRefs(storeStore);

onBeforeMount(async () => {
    if (!globalStore.hasPermissionTo('edit_store')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;

    if (!storeStore.store.id) {
        await storeStore.tryStore(store_id.value);
    }

    await storeStore.tryTestAb(route.params.slug);
});
</script>

<style scoped>
.option {
    border-bottom: 1px #ddd solid;
    padding: 20px;
}

.option:last-child {
    border-bottom: 0;
}
</style>