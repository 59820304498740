<template>
    <teleport to="body">
        <div class="dialog-container">
            <dialog open>
                <header class="mb-3">
                    <div class="close_button" @click="closeDialog()">Ok</div>
                    <div class="dialog_header">
                        <slot name="header"></slot>
                    </div>
                </header>
                <slot name="body"></slot>
            </dialog>
        </div>
    </teleport>
</template>

<script setup>
const emit = defineEmits(["emitCloseDialog"]);

function closeDialog() {
    emit('emitCloseDialog')

}
</script>

<style scoped>

.dialog-container {
    background-color: #fff;
    z-index: 99;
    top: 165px;
    right: 0;
    position: fixed;
    width: 350px;
    border: 1px #ddd solid;
    box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    border-radius: 12px;
    margin: 10px;
}

dialog {
    position: relative;
    width: 100%;
    padding: 1.25em;
    border: none;
    border-radius: 25px;
}

.close_button {
    position: relative;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    right: -4px;
    background: #215eda;
    border-radius: 20px;
    color: #fff;
    padding: 4px 20px;
    height: 30px;
    float: right;
    text-align: center;
    cursor: pointer;
}

.close_button:hover{
    color: #ffffff;
    background-color: #124abd;
}

.dialog_header {
    width: 100%;
    display: block !important;
    margin: 0;
    margin-bottom: 30px;
    font-size: 16px;
    color: #646464;
    font-weight: 600;
    padding: 5px;
}

@media (max-width: 768px) {
    span {
        display: none;
    }

    svg {
        margin: 0 !important;
    }

    .dialog-container{
        padding: 2.5em;
    }

    dialog{
        max-width: 100%;
    }

    .close_button{
        top: -50px;
    }

    .dialog_header {
        padding: 0;
    }
}

:deep(.back_arrow){
    margin-top: -10px!important;
    margin-left: -10px!important;
    margin-bottom: -7px;
}

:deep(.btn_w100_edit){
    border-radius: 4px!important;
    text-align: left;
    border: 1px solid #ddd!important;
    display: flex;
}

:deep(.btn_w100_edit svg){
    margin: auto 0 auto auto;
}

:deep(.btn_w100_edit:hover){
    background-color: #ddd;
}

@media screen and (max-width: 370px) {
    .dialog_header {
        padding-left: 40px;
        font-size: 14px !important;
    }
}

@media screen and (max-width: 330px) {
    header {
        height: auto;
    }

    .dialog_header {
        padding: 0;
        display: block;
        margin: 0 !important;
    }
}
</style>
