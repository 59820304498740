<template v-if="store.id">
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
            <div class="item action btn-group">
                <div v-if="route.name == 'storeInformations' || route.name == 'storePersonalization' || route.name == 'storeReferrals' || route.name == 'storeShippingMethods' || route.name == 'storeNotifications'" class="item action btn-group">
                    <baseButton @click="updateStore()" :disabled="isLoading" class="btn btn-primary w-100">Salvar</baseButton>
                </div>
            </div>
        </BaseHeader>
        <div class="page_content row" style="margin-top: 0px">
            <div class="nav_div col-xl-3 col-12 mx-0 mb-xl-0 mb-4">
                <div class="navigation_bar">
                    <ul class="nav nav-pills" role="tablist" aria-orientation="vertical">
                        <li>
                            <router-link :to="'/stores/' + store_id" :class="[route.path === '/stores/' + store_id ? 'active': '']">Informações</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/checkouts'" :class="[route.path === '/stores/'+ store_id + '/checkouts' ? 'active': '']">Checkouts <span class="badge ms-2">Novo</span></router-link>
                        </li>

                        <li>
                            <router-link :to="'/stores/' + store_id +  '/tests-ab'" :class="[route.path === '/stores/'+ store_id +'/tests-ab'? 'active': '']">Teste A/B <span class="badge ms-2">Novo</span></router-link>
                        </li>

                        <li style="display: none;">
                            <router-link :to="'/stores/'+ store_id + '/personalization'" :class="[route.path === '/stores/'+ store_id + '/personalization' ? 'active': '']">Personalização</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/integrations'" :class="[route.path === '/stores/'+ store_id + '/integrations' ? 'active': '']">Integrações</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/coupons'" :class="[route.path === '/stores/'+ store_id + '/coupons' ? 'active': '']">Cupons</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/pages'" :class="[route.path === '/stores/'+ store_id + '/pages' ? 'active': '']">Páginas</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/notifications'" :class="[route.path === '/stores/'+ store_id + '/notifications' ? 'active': '']">Notificações<span class="badge ms-2">Novo</span></router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/trackers'" :class="[route.path === '/stores/'+ store_id + '/trackers' ? 'active': '']">Rastreamento</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/payments'" :class="[route.path === '/stores/'+ store_id + '/payments' ? 'active': '']">Formas de pagamento</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/webhooks'" :class="[route.path === '/stores/'+ store_id + '/webhooks' ? 'active': '']">Webhooks</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/managers'"  :class="[route.path === '/stores/'+ store_id + '/managers' ? 'active': '']">Gerentes</router-link>
                        </li>
                        <li>
                            <router-link :to="'/stores/'+ store_id +'/referrals'" :class="[route.path === '/stores/'+ store_id + '/referrals' ? 'active': '']">Afiliados</router-link>
                        </li>
                        <li>
                            <router-link :to="''" type="button" class="btn-primary tab_always_open_main">Frete</router-link>

                            <ul>
                            <li>
                                <router-link :to="'/stores/'+ store_id +'/shipping/companies'" :class="[route.path === '/stores/'+ store_id +'/shipping/companies' ? 'active': '']" class="tab_always_open">Transportadoras </router-link>
                            </li>
                            <li>
                                <router-link :to="'/stores/'+ store_id +'/shipping/methods'"  :class="[route.path === '/stores/'+ store_id +'/shipping/methods' ? 'active': '']"   class="tab_always_open"> Métodos de envio </router-link>
                            </li>
                        </ul>
                        </li>
                       
                    </ul>
                </div>
            </div>
            <div class="col-xl-9 col-12 mb-4" v-if="store.id">
                <router-view></router-view>            
            </div>
        </div>
    </div>
</template>

<script setup>
const title = {title: 'Editar loja', icon: 'fa-store'};
import { useRoute, useRouter } from "vue-router"; 
import { ref, onBeforeMount, computed } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { useStoreStore } from '@/stores/store';
import { storeToRefs } from 'pinia';

const store_id = computed (function () {
    return route.params.id;
});

const route = useRoute();
const router = useRouter();

const previousPage = ref(null);

const storeStore = useStoreStore();
const { store } = storeToRefs(storeStore);

const globalStore = useGlobalStore();
const isLoading = ref(false);

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('edit_store')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;
    delete globalStore.errors['storeEdit'];

    globalStore.cleanObject(storeStore.store);

    globalStore.loader('show');
    await storeStore.tryStore(store_id.value).then(() => {
        globalStore.loader('hide')
        storeStore.tryTestsAb();
     });

    if(previousPage.value){
        if(previousPage.value.split('/').length > 3){
            previousPage.value = '/stores';
        }
    }
});

function updateStore() {
    if(isLoading.value) {
        return;
    }

    storeStore.store.status = 'published';

    isLoading.value = true;
    globalStore.loader('show');

    let params = {
        storeInformations: ['title', 'url', 'logotipo', 'email', 'email_support', 'color', 'soft_descriptor', 'warranty', 'phone', 'meta.sell_to_cnpj', 'status'],
        storeNotifications: ['meta.notifications',],
        storePersonalization: ['meta.background_color', 'meta.timer_text', 'meta.timer_text_color', 'meta.timer_background_color', 'meta.timer_text_button', 'meta.timer_color_text_button', 'meta.timer_color_button', 'meta.timer_time', 'meta.timer_numbers_background_color', 'meta.timer_numbers_text_color', 'meta.exit_popup_text_color', 'meta.exit_popup_background_color', 'meta.exit_popup_text_button', 'meta.exit_popup_color_text_button', 'meta.exit_popup_color_button'],
        storeReferrals: ['meta.cookie_type', 'meta.cookie_time', 'meta.referral_sensitive_information'],
        storeShippingMethods: ['meta.hide_shipping_methods'],
    }

    storeStore.tryUpdateStore(params, route.name).then(() => {
        globalStore.loader('hide');
        isLoading.value = false;
    });
}
</script>

<style scoped>

.shipping_main, .shipping_method{
    border-bottom: none!important;
}

.badge {
    background-color: #215eda;
    float: right;
    border-radius: 20px;
    margin-top: 2px;
    font-weight: 500;
}
</style>