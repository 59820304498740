<template>
    <div class="p-3">
        <div class="col-12">
            <div class="mx-auto" style="width: fit-content">
                <h2 class="">Termos de Uso</h2>
            </div>
            <div class="divisor_colored mt-2 mb-4 mx-auto"></div>
        </div>

        <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>
        Esta plataforma, cujo nome é <b>FULLSALE</b>, é de propriedade e mantida por 
        <b>FULLSALE LTDA.</b>,pessoa jurídica de direito privado inscrita no CNPJ/ME sob o n.º 41.953.551/0001-57, com sede na Avenida Luiz Boiteux Piazza, n.º 1302, Bairro Canasvieiras, Cidade Florianópolis, Estado Santa Catarina, CEP: 88056-000.
        </span></p>

        <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>
        <b><u>Este documento visa prestar informações sobre o modo de utilização da plataforma
        pelos Usuários e suas ferramentas, condições, nomenclaturas, direitos e deveres.</u></b>
        Alertamos que todo o texto deve ser lido com atenção e, caso você não concorde 
        com o conteúdo de nossos termos e/ou política de privacidade, não dê prosseguimento à navegação ou a utilização de nossos serviços.
        </span></p>

                <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height: 150%'><b>
        <span style='color:black'><u>1. DATA DE DISPONIBILIZAÇÃO DO TEXTO</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='color: black;margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%;' >
            1.1. A presente versão deste documento foi disponibilizada em: 04/12/2023.
        </p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height: 150%'><b><span style='color:black'><u>2. EXPLICAÇÃO DOS TERMOS TÉCNICOS OU EM LÍNGUA ESTRANGEIRA</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>2.1. Abaixo estão dispostos os significados das nomenclaturas técnicas e termos na língua inglesa.</span></p>
        <ul class="ps-5" style="color:black;">
            <li>Chargebacks: Cancelamento de uma compra on-line realizada através de cartão de crédito ou débito, na qual o consumidor alega desconhecer a cobrança ou parte dela.</li>
            <li>Login: É o processo que permite o acesso a um sistema informático, controlado por meio de identificação e autenticação do Usuário pelas credenciais fornecidas por esse mesmo internauta.</li>
            <li>Página de Checkout: Página em que ocorre o preenchimento de informações do Consumidor para finalização da compra e realização do pagamento, na qual pode também visualizar todos os itens incluídos na sua compra.</li>
            <li>Online: Termo da língua inglesa cujo significado literal é “em linha”. É habitualmente usado para designar que um determinado Usuário da internet ou de outra rede de computadores está conectado à rede.</li>
            <li>Vírus: Software malicioso que infecta sistemas e computadores, com objetivo de causar algum dano, faz cópias de si mesmo e tenta se espalhar para outros computadores, utilizando-se de diversos meios.</li>
        </ul>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>2.2. São os tipos de Usuários da plataforma:</span></p>
        <ul class="ps-5" style="color:black;">
            <li>Usuário Produtor: Aquele que é responsável pela loja/produto.</li>
            <li>Usuário Co-Produtor: Aquele que é responsável por dividir a produção de um determinado produto lançado pelo Produtor e, devido a isso, divide a comissão com o Produtor.</li>
            <li>Usuário Gerente: Aquele que é responsável por administrar os afiliados e suas vendas e por isso dividirá a comissão com esses. O gerente pode receber parte da comissão de todos afiliados ou somente de afiliados selecionados e, dependendo da configuração, poderá até receber comissões em vendas diretas do produtor, ou seja, sem afiliados.</li>
            <li>Usuário Afiliado: Aquele que é responsável por divulgar o produto do lojista/produtor e recebe comissão por isso.</li>
            <li>Usuário Consumidor: Aquele que realiza as compras dentro da plataforma do Produtor, do Co-Produtor e/ou do Afiliado.</li>
            <li>Estes Usuários, em conjunto, serão chamados apenas de Usuários.</li>
        </ul>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b>
        <span style='color:black'><u>3. SERVIÇOS</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>3.1. <b><u>O que somos:</u></b> A <b>FULLSALE</b> consiste, mas não se limita, em uma plataforma
        que tem a finalidade de facilitar as transações dentro de um e-commerce, ligando vendedores
        e compradores, e funcionando ainda como um checkout de pagamentos. </span></p>

        <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'><span style='color:black'>3.1.1. Através da nossa plataforma, o Usuário Produtor poderá realizar o cadastro, a venda e
        o gerenciamento de produtos através de dashboard e checkout para pagamentos, que podem ser realizados nas opções
        de pix, boleto e cartão de crédito. Além disso, o Usuário Co-Produtor compartilha, junto com o Usuário Produtor, 
        produto(s) para ser(em) vendido(s).</span></p>
        <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>3.1.2. Os Usuários Gerente e Afiliado realizam a venda desses produtos incluídos pelos Usuários Produtor e Co-Produtor.</span></p>

        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>3.2. Não exercemos atividade de instituição financeira, assim como também não somos prestadores de serviços 
        financeiros e/ou administradores de cartões de crédito.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'><span style='color:black'>3.3. Ao utilizar a <b>FULLSALE</b>, o Usuário deve compreender e aceitar que todas as suas ações e escolhas são livres 
        e não possuem nenhum tipo de influência ou ingerência da <b>FULLSALE</b>.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>3.4. Fica esclarecido que o presente serviço não estabelece entre as partes qualquer vínculo empregatício, societário
        ou associativo, permanecendo cada parte como única responsável por todas as suas respectivas despesas e encargos, sejam de natureza trabalhista, 
        previdenciária, fiscal, securitária, civil, penal ou de qualquer outra natureza ou espécie.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>3.5 Os produtos cadastrados pelo Usuário Produtor não serão exibidos no site da <b>FULLSALE</b>, como se estivessem em uma vitrine. 
        É de responsabilidade do Usuário Produtor a estruturação da sua landing page de vendas ou site de e-commerce.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>3.6. Após realizado o cadastro do Produto ou Serviço pelo Usuário Produtor ou pelo Usuário Co-Produtor, a <b>FULLSALE</b> realizará
        uma análise do produto ou serviço inserido, com o objetivo de verificar a compatibilidade de sua atividade com a política da empresa. Após essa análise, 
        a seu critério, a <b>FULLSALE</b> poderá aprovar ou reprovar o cadastro do produto ou serviço.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>4. CADASTRO DOS USUÁRIOS</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>4.1. Para o cadastro e utilização dos serviços da <b>FULLSALE</b> serão requeridas as seguintes informações: </span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>4.1.1. Dos Usuários Produtor, Co-Produtor, Gerente e Afiliado: </span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>4.1.1.1. No caso de ser Pessoa Física: Nome completo, CPF, Data de nascimento, Nome da mãe completo, 
                Profissão, E-mail, Número de Telefone e Número de Celular, Endereço completo, Dados Bancários, Chave Pix. </span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>4.1.1.2. No caso de ser Pessoa Jurídica: Nome completo do titular da conta, Razão Social da Pessoa Jurídica,
                Nome Fantasia da Pessoa Jurídica, Data de fundação da Pessoa Jurídica, CNPJ, Inscrição Estadual, E-mail, Número de Telefone e/ou Celular,
                Endereço completo, Dados Bancários, Chave Pix.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>4.1.2. Do Usuário Consumidor: Nome completo, CPF, endereço, telefone e e-mail. A depender da forma de pagamento, dados do cartão de crédito.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>4.2. Os Usuários Produtor, Co-Produtor, Gerente e Afiliado deverão anexar documentos como RG, CPF, Contrato Social ou Estatuto Social no caso 
        de ser Pessoa Jurídica, assim como Comprovante de Endereço, entre outros que a <b>FULLSALE</b> poderá solicitar. </span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>4.2.1. No processo de aprovação, caso se trate de Pessoa Jurídica, será analisado o quadro societário da sociedade e o perfil dos sócios.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'> 4.2.2. Caso os documentos não sejam anexados ao nosso sistema, o cadastro ficará pendente e não será concluído.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>4.2.3. Além disso, a <b>FULLSALE</b> reserva-se no direito de utilizar todos os meios válidos e possíveis para confirmar os dados fornecidos pelo 
            Produtor, Co-Produtor, Gerente ou Afiliado, bem como consultar bancos de dados mantidos por terceiros, como SPC e Serasa.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'><span style='color:black'>4.3. A Conta de Pagamento cadastrada pelos Usuários Produtor, Co-Produtor, Gerente ou Afiliado, para recebimento dos valores da venda, deverá ser
        uma conta bancária corrente ou poupança em titularidade da Pessoa Física ou Pessoa Jurídica cadastrada, pois a <b>FULLSALE</b> não admite o cadastro de conta bancária de titularidade de terceiros.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'><span style='color:black'>4.4. Conforme o artigo 4º da Circular n.º 3.461 do Banco Central do Brasil, a <b>FULLSALE</b> irá analisar se o Produtor, Co-Produtor, Gerente ou Afiliado ou seus sócios, diretores, administradores
        são consideradas “Pessoas Expostas Politicamente”.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>4.5. Cada Usuário determinará o seu login e senha de acesso, sendo de sua exclusiva responsabilidade a manutenção do sigilo 
        dessas informações. A <b>FULLSALE</b> não se responsabiliza pelas ações e danos que poderão ser causados pelo acesso irregular da conta de acesso por terceiros.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>4.5.1. No momento do cadastro, é encaminhada uma senha de acesso por e-mail ao Usuário, o qual orientamos que, ao entrar na plataforma, realize a troca dessa senha.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>4.6. É permitida a realização de apenas 1 (uma) conta por CPF ou CNPJ.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>4.7. O cadastro na plataforma é permitido somente a pessoas acima de 18 (dezoito) anos. Ao aceitar estes Termos, o Usuário declara ser plenamente capaz para exercer todos os atos da vida civil.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>4.7.1. Caso seja constatado um cadastro, como Produtor, Co-Produtor, Gerente e Afiliado, menor de 18 (dezoito) anos, a 
            <b>FULLSALE</b> providenciará o cancelamento e estorno das vendas em que esse participou e excluirá, imediatamente, o seu cadastro.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>4.8. O cadastro na plataforma é permitido somente a pessoas que sejam residentes no Brasil.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>4.9.  Os Usuários são responsáveis por fornecer informações verdadeiras, precisas e atualizadas.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>4.10. A <b>FULLSALE</b> poderá rescindir o contrato com os Usuários, suspender os seus serviços, recusar ou cancelar sem notificação 
        prévia a conta de acesso de um Usuário sempre que suspeitar que as informações fornecidas são falsas, incompletas, desatualizadas ou imprecisas ou, 
        ainda, nos casos indicados nas leis e regulamentos aplicáveis, nestes Termos de Uso ou em qualquer Política.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>4.10.1. A <b>FULLSALE</b> poderá, a seu exclusivo critério, solicitar a regularização da situação no caso de identificar irregularidade 
            no cadastro do Usuário Produtor, sendo que, nestes casos, o Usuário Produtor não poderá movimentar a sua conta até que a situação seja regularizada no prazo estipulado pela <b>FULLSALE</b>.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>5. PAGAMENTO PELO USO DA PLATAFORMA</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>5.1. A utilização de nossa plataforma é gratuita, devendo os Usuários Consumidores apenas efetuarem pagamento pelos produtos que adquirirem, 
        o qual será pago através da plataforma <a href="https://pagar.me/" target="_blank">Pagar.me</a>, que é integrada a nossa plataforma.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>5.2 Para o Usuário Produtor, contudo, serão devidos valores em cima das vendas que forem realizadas através da plataforma, os quais serão retidos dessas vendas.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>5.2.1. Cada Usuário Produtor deverá pagar 5,89% (cinco vírgula oitenta e nove por cento) + R$ 0,99 (noventa e nove centavos) em cima de cada venda realizada.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>5.2.2. Só será cobrada essa taxa se houver vendas. Caso não haja venda, não há cobrança.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>5.2.3. Em saques será cobrado uma taxa de R$ 3,98 (três reais e noventa e oito centavos).</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>5.2.4. Em compras parceladas, será incluída uma taxa de 2,99% (dois vírgula noventa e nove por cento) ao mês. Essa cobrança por padrão está inclusa na compra do 
            Usuário Consumidor, porém há a possibilidade do Usuário Produtor assumir essa taxa, e, neste caso, será descontado dele.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>5.2.5. Em compras parceladas, caso exista interesse em realizar a antecipação dos recebíveis, será cobrada uma taxa de 1.79% (um vírgula setenta e nove por cento) ao mês sobre o valor da parcela</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>5.3. Em casos de fraude, prejuízos ou outros problemas relacionados, o Usuário Produtor não realizar novas vendas em até 30 (trinta) dias, poderemos exigir 
        um depósito referente ao valor do prejuízo, o qual deverá ser realizado diretamente em conta bancária corrente de titularidade da plataforma.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height: 150%'><b><span style='color:black'><u>6. REEMBOLSO E CANCELAMENTO PELO USUÁRIO </u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>6.1. <b>Direito de arrependimento:</b> A <b>FULLSALE</b> respeita todos os direitos de seus Usuários, em especial o direito de arrependimento das compras feitas pela internet no prazo de 07 (sete) dias, 
        contados após o recebimento do produto, conforme disposto no Código de Defesa do Consumidor. Após a manifestação de arrependimento, será feito o reembolso de todos os valores eventualmente pagos pelo Usuário.</span></p>
        <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>6.1.1. O produto, nestes casos, deverá ser encaminhado ao Usuário Produtor responsável pela venda, conforme Política de Vendas, sendo que o frete é de responsabilidade da empresa responsável 
            pela comercialização do produto.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>6.2. Para cancelar os serviços da plataforma o Usuário deverá encaminhar a solicitação de cancelamento para o e-mail contato@fullsale.com.br.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>6.3. Em caso de cancelamento requerido pelo Usuário Produtor, serão devidos os valores referentes às compras realizadas até o momento, os quais serão retidos antes do repasse final.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>6.4. <b><u>Trocas e devoluções:</u></b> É de responsabilidade do Usuário Produtor estabelecer regras de trocas e devoluções de seus produtos, assim como prazos de garantia desses, informando previamente ao Usuário Consumidor e de acordo com a legislação aplicável e estes Termos de Uso.</span></p>
        <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>6.4.1 <b>Vícios</b>: Ainda, o Usuário Consumidor deverá realizar a troca ou outra ação legal (conserto ou devolução dos valores), em caso de vícios no produto vendido por ele, estas trocas 
            deverão respeitar a legislação aplicável para o caso.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>6.4.2. Eventuais reembolsos serão realizados de acordo com o estipulado pelo Usuário Produtor, sendo que, caso o prazo ultrapasse os 07 (sete) dias previstos no Código de Defesa do Consumidor, 
            deverá ser observada as condições impostas para o Usuário Produtor nestes casos. Ainda, reembolsos serão realizados pelo Usuário Produtor.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>7. RESPONSABILIDADE DAS PARTES</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>7.1.<b><u>Responsabilidade da FULLSALE:</u></b></span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>7.1.1. Realizar os serviços conforme o descrito nestes Termos de Uso;</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>7.1.2. Responsabilizar-se pelo funcionamento da plataforma e pelas correções que eventualmente sejam necessárias;</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>7.1.3. Informar qualquer alteração dos serviços aos Usuários, por meio de comunicados simples na plataforma.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>7.2.<b><u>Responsabilidade dos Usuários:</u></b></span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>7.2.1. Utilizar a plataforma conforme os critérios de utilização definidos pela <b>FULLSALE</b>, sem alterar a sua programação, 
            quebrar senhas ou realizar procedimentos que venham causar prejuízos a <b>FULLSALE</b> e aos demais Usuários;</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>7.2.2. Responsabilizar-se para todos os efeitos, inclusive jurídicos, pelo teor das informações que introduzir e pelos compromissos que assumir na plataforma;</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>7.2.3. Respeitar integralmente estes Termos de Uso, Políticas de Privacidade, legislação vigente e contratos entre as partes.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>7.3. <b><u>Ainda, os Usuários Vendedores são responsáveis por:</u></b></span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>7.3.1. Respeitar a legislação aplicável à negociação realizada por intermédio da plataforma, como o Código de Defesa ao Consumidor e a Lei Geral de Proteção de Dados.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>7.3.2. Realizar a entrega dos produtos no prazo estabelecido previamente e tomando medidas para a proteção do produto.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>8. ISENÇÃO DE RESPONSABILIDADE DA FULLSALE</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>8.1. <u>A <b>FULLSALE não se responsabiliza por:</b></u></span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.1. Eventual indisponibilidade da plataforma a qual não tenha dado causa.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.2. Condutas dos Usuários e pelo descumprimento de suas obrigações.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.3. Inserção de informações falsas pelos Usuários.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.4. Danos que o Usuário possa experimentar por ações exclusivas de terceiros, bem como falhas na conexão de rede e interações maliciosas como vírus.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.5. Danos que o Usuário possa ter em decorrência do mau uso da plataforma em desconformidade com estes Termos, com a Política de Privacidade, com a lei ou ordens judiciais.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.6. Casos fortuitos ou de força maior.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.7. Pelas condutas ilícitas realizadas por Usuários durante o uso da plataforma (ex: fraudes).</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.8. Eventuais problemas nos sites do Produtor, Co-Produtor, Gerente e Afiliado.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.9. Quaisquer produtos ou serviços ofertados pelo Produtor, Co-Produtor, Gerente e Afiliado.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>8.1.10. Eventuais chargebacks por denúncias de compras fraudulentas.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>8.1.10.1. Nestes casos, ficará a critério do Banco aceitar o chargeback, ainda que o Vendedor  a entrega 
                do produto ou a prestação do serviço ou ainda, a participação do Comprador na especificação do produto/serviço.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>9. REGRAS DE CONDUTA E PROIBIÇÕES</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>9.1. Os Usuários <u><b>não</b></u> podem:</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.1.1. Lesar direitos da <b>FULLSALE</b>, dos operadores da plataforma, de outros Usuários, de terceiros ou agir sob qualquer meio ou forma que possa contribuir com tal violação;</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.1.2. Executar atos que limitem ou impeçam a utilização da plataforma ou acessar ilicitamente a <b>FULLSALE</b>;</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.1.3. Utilizar a ferramenta para praticar ações ilícitas e difundir mensagens não relacionadas com a plataforma ou com suas finalidades, incluindo mensagens com conteúdo impróprio;</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.1.4. Inserir dados que sejam falsos, desatualizados ou incompletos;</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.1.5. Responsabilizar a <b>FULLSALE</b> por condutas de Usuários que estejam cadastrados na plataforma.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.1.6. Efetuar cadastro em nome de outra pessoa física ou de pessoa jurídica que não represente.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.1.7. Transferir sua conta à terceiros.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.1.8. Desenvolver atividades ou comercializar bens e/ou serviços não autorizados por lei ou em desacordo com estes Termos, incluindo, mas não se limitando a:</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.1. Ofertar serviços de acompanhantes, serviços sexuais, agências de acompanhantes ou casas de massagem, ou que promovam a prostituição.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.2. Assinatura de serviços adultos, bate-papos para adultos ou de conteúdo pornográfico em qualquer meio, por exemplo, fotos ou vídeos, físicos ou digitais.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.3. Produtos que promovam e/ou façam alusão a pedofilia.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.4. Armas de fogo, munições ou produtos similares, bem como armas brancas como facões, machados, entre outros.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.5. Quaisquer drogas ilícitas, incluindo produtos, serviços ou ferramentas destinadas à produção de drogas, parafernália para drogas, substâncias que imitam drogas ilícitas e/ou outros produtos psicoativos.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.6. Produtos que permitam a decodificação serial ou sinal do satélite, telefones, cartões ou programação de cartões eletrônicos, incluindo dispositivos/serviços/software de espionagem e modchips.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'
                ><span style='color:black'>9.1.8.7. Bloqueadores de sinal ou dispositivos projetados para bloquear ou interferir em dispositivos ou sinais de comunicação pessoal.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.8. Venda de medicamentos proibidos ou restritos nos termos da legislação brasileira.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.9. A comercialização de suplementos a serem entregues em outros países.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.10. Jogos de azar, loterias, bingo, apostas ou similares.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.11. Captar ou arrecadar dinheiro, venda em pirâmide.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.12. Produtos ou serviços que promovam ódio, violência, discriminação, terrorismo, assédio ou abuso.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.13. Listas contendo dados pessoais.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.14. Quaisquer outros produtos e serviços ilegais, incluindo, sem limitação, aqueles que infrinjam normas de propriedade intelectual.</span></p>
                <p class='MsoListParagraph ps-5' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
                <span style='color:black'>9.1.8.15. Produtos falsificados ou réplicas.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>9.2. A <b>FULLSALE</b> poderá, a seu exclusivo critério, bloquear, restringir, desabilitar ou impedir o acesso de qualquer Usuário à plataforma sempre 
        que for detectada uma conduta inadequada, sem necessidade de aviso prévio ou direito a qualquer tipo de compensação.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.2.1. Neste caso, os Usuários Produtor, Co-Produtor, Gerente ou Afiliado deverão solicitar imediatamente a retirada de eventual saldo existente em sua Conta junto a FULLSALE.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.2.2. A <b>FULLSALE</b> poderá, a seu exclusivo critério, rescindir imotivadamente o contrato de prestação dos serviços, mediante notificação expressa e por escrito ao Usuário, com 30 (trinta) dias de antecedência.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>9.2.3. A não observância do que dispõe o item 9.1.8. implicará em cancelamento imediato da conta do Usuário, sem qualquer direito a indenização, compensação ou pagamento em favor do Produtor, 
            Co-Produtor, Gerente ou Afiliado, com a perda dos valores nas contas depositadas, a título de multa, sem prejuízo ainda da responsabilidade do Produtor, Co-Produtor, Gerente ou Afiliado, por eventuais perdas 
            e danos causados.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'><u>9.3. O Usuário poderá denunciar condutas ilícitas por parte dos Usuários Produtores, venda de produtos ilícitos e proibidos por meio dos canais de contato descritos na cláusula 14 destes Termos.</u></span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height: 150%'><b><span style='color:black'><u>10. PROPRIEDADE INTELECTUAL</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>10.1. A titularidade e os direitos relativos à plataforma pertencem exclusivamente à <b>FULLSALE LTDA.</b> O acesso à plataforma e a sua regular utilização pelo Usuário não lhe conferem 
            qualquer direito ou prerrogativa sobre propriedade intelectual ou outro conteúdo nela inserido.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>10.2 Todo o conteúdo da plataforma, incluindo nome, marca, domínio, programas, bases de dados, arquivos, textos, desenhos, fotos, layouts, cabeçalhos e demais elementos, foi criado, desenvolvido 
        ou cedido à <b>FULLSALE LTDA.</b>, sendo, portanto, de propriedade exclusiva da <b>FULLSALE LTDA.</b> ou a ela licenciado e encontra-se protegido pelas leis brasileiras e tratados internacionais que versam sobre direitos de 
        propriedade intelectual.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>10.3. São proibidas: a exploração, cessão, imitação, cópia, plágio, aplicação de engenharia reversa, tentativa de invasão (hackear), armazenamento, alteração, modificação de características, ampliação, 
        venda, locação, doação, alienação, transferência, reprodução, integral ou parcial, de qualquer conteúdo da plataforma da <b>FULLSALE LTDA.</b></span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>10.4. A pessoa que violar as proibições contidas na legislação sobre propriedade intelectual e nestes Termos será responsabilizada, civil e criminalmente, pelas infrações cometidas, além de poder ser penalizado na plataforma.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>11. TRATAMENTO DE DADOS PESSOAIS, PRIVACIDADE E SEGURANÇA</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>11.1. A <b>FULLSALE</b> dispõe de uma política específica para regular a coleta, guarda e tratamento de dados pessoais, bem como a sua segurança: 
        <a href="https://fullsale.com.br/politica-de-privacidade" target="_blank">Políticas de Privacidade</a>. Essas políticas específicas integram inseparavelmente estes Termos, ressaltando-se que os dados 
        de utilização da plataforma serão arquivados nos termos da legislação em vigor.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height: 150%'><b><span style='color:black'><u>12. DESDOBRAMENTOS DO ACESSO À PLATAFORMA</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>12.1. Apesar dos melhores esforços da <b>FULLSALE</b> para fornecer as melhores tecnologias para manter a conexão e sincronização online e acesso seguro aos Usuários, em virtude 
        de dificuldades técnicas, aplicações de internet ou problemas de transmissão, é possível ocorrer cópias inexatas ou incompletas das informações contidas na plataforma. Além disso, vírus de computador
        ou outros programas danosos também poderão ser baixados inadvertidamente da plataforma.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>12.1.1. A <b>FULLSALE</b> recomenda a instalação de antivírus ou protetores adequados.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>12.2. A <b>FULLSALE</b> se reserva o direito de unilateralmente modificar a plataforma, bem como a configuração, a apresentação, o desenho, o conteúdo, as funcionalidades, 
        as ferramentas ou qualquer outro elemento, inclusive o seu cancelamento.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>12.3. Nossa plataforma pode conter links de sites/plataformas de terceiros. Ao seguir um link, o Usuário deve verificar as políticas de privacidade desses 
        sites/plataformas, pois não aceitamos qualquer responsabilidade ou obrigação por essas políticas. Por favor, verifique essas políticas antes de compartilhar quaisquer dados pessoais com esses sites.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>13. ALTERAÇÕES DOS TERMOS E CONDIÇÕES DE USO</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>13.1. A <b>FULLSALE</b> poderá unilateralmente adicionar e/ou modificar qualquer cláusula contida nestes Termos de Uso. A versão atualizada valerá para o uso da plataforma 
        realizado a partir de sua publicação. A continuidade de acesso ou utilização da plataforma, depois da divulgação, confirmará a vigência dos novos Termos de Uso pelos Usuários.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>13.2. Caso a mudança efetuada necessite de consentimento do Usuário, será apresentada a opção de aceitar de forma livre, inequívoca e informada o novo texto ou de recusá-lo.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>13.3. Caso o Usuário não esteja de acordo com a alteração, poderá não fornecer consentimento para atos específicos ou poderá rescindir totalmente seu vínculo com a <b>FULLSALE</b>. 
        No entanto, essa rescisão não eximirá o Usuário de cumprir com todas as obrigações assumidas sob as versões precedentes dos Termos de Uso.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>14. LEI APLICÁVEL E FORO DE ELEIÇÃO</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>14.1. A plataforma é controlada e administrada na cidade de Florianópolis, Estado de Santa Catarina, Brasil, podendo ser acessada por qualquer dispositivo conectado à Internet, 
        independentemente de sua localização geográfica.</span></p>
            <p class='MsoListParagraph ps-4' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
            <span style='color:black'>14.1.1. As pessoas que acessam ou usam a <b>FULLSALE</b> a partir de outros países o fazem por conta própria e são responsáveis pelo cumprimento das leis regionais/nacionais.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>14.2. O Usuário concorda que a legislação aplicável para fins destes Termos e Condições de Uso e das Políticas de Privacidade será a vigente na República Federativa do Brasil.</span></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom: 6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>14.3. A <b>FULLSALE</b> e os Usuários concordam que o Foro de Florianópolis, Estado de Santa Catarina, Brasil, será o único competente para dirimir qualquer questão ou controvérsia 
        oriunda ou resultante do uso da plataforma, renunciando expressamente qualquer outro, por mais privilegiado que seja ou venha a ser.</span></p>

        <p style='margin-bottom:6.0pt; margin-top: 10px;text-align:justify;line-height:150%'><b><span style='color:black'><u>15. CONTATO</u></span></b></p>
        <p class='MsoListParagraph ps-3' style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-align:justify;text-indent:0cm;line-height:150%'>
        <span style='color:black'>15.1. A <b>FULLSALE</b> disponibiliza os seguintes canais para receber todas as comunicações que os Usuários desejarem fazer: pelo e-mail contato@fullsale.com.br.</span></p>
    
<div class="row mt-4">
        <div class="col-md-9 col-12">
            <BaseSwitch
                label="Concordo que li os termos e estou de acordo."
                id="user_terms_agreement"
                v-model:checked="agree_terms"
            />
        </div>
        <div class="col-md-3 col-12">
            <BaseButton
                v-if="agree_terms == 0"
                class="btn-primary w-100"
                disabled
                >Aceitar
            </BaseButton>
            <BaseButton
                v-else
                class="btn-primary w-100"
                @click="confirmAgreeTerms()"
                >Aceitar
            </BaseButton>
        </div>
    </div>
</div>
</template>

<script setup>
import { ref } from 'vue';

const agree_terms = ref(0)

const emit = defineEmits(["emitConfirmAction"]);

function confirmAgreeTerms(){
    emit('emitConfirmAction')
}

</script>

<style scoped>
.divisor_colored {
    width: 150px;
    height: 7px;
    background-color: #20cfcb;
    border-radius: 10px
}
</style>