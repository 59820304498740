<template>
    <BaseTable v-if="globalStore.storesList" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="globalStore.storesList.length" :multirows="true">
        <template v-slot:thead>
            <th class="thead" width="90%">Lojas</th>
            <th class="switch_th"><BaseSwitch @click="switchAllValues()" id="switch_all" :page="page"/></th>
        </template>
        <template v-slot:tbody>
            <tr v-for="(store, key) in globalStore.storesList" :key="key">
                <td width="90%">{{ store.title }}</td>
                <td class="actions">
                    <BaseSwitch @click="switchValue(store.id)" :id="'store_' + store.id" class="switch" :page="page" v-model="initialSwitchValue"/>
                </td>
            </tr>
        </template>
    </BaseTable>
</template>
    
<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const tableColumns = ['Loja'];

const initialSwitchValue = 'off'

var stores = ([])

const isLoading = ref(true);

const emit = defineEmits(['emitStoreAcessValues'])

const props = defineProps({
    pageParams:{
        default: null,
    },
})

const page = ref('createtStoreAcess')

onBeforeMount(() =>{
    if(globalStore.storesList.length < 0){
        globalStore.getStoresList().then(() =>{
            isLoading.value = false;
        })
    } else {
        isLoading.value = false;
    }
})

onMounted(() =>{
    const getAllSwitches = document.querySelectorAll('.switch input');
    stores = []
    for(let index = 0; index < getAllSwitches.length; index++){
        getAllSwitches[index].checked = false
        let splitSwitchId = getAllSwitches[index].id.split('_');
        for(let i = 0; i < props.pageParams.length; i++){
            let getThisStore = document.getElementById('store_' + splitSwitchId[1])
            if(splitSwitchId[1] == props.pageParams[i]){
                getThisStore.checked = true
                stores.push(parseInt(splitSwitchId[1]))
            }
        }
    }
    emit('emitStoreAcessValues', stores)
})

function switchAllValues(){
    const getSwitchAll = document.getElementById('switch_all');
    const getAllSwitches = document.querySelectorAll('.switch input');
    if(getSwitchAll.checked == false){
        for(let i = 0; i < getAllSwitches.length; i++){
            getAllSwitches[i].checked = false;
            stores = [];
        }
    } else {
        for(let i = 0; i < getAllSwitches.length; i++){
            getAllSwitches[i].checked = true;
            let splitSwitchId = getAllSwitches[i].id.split('_');
            stores.push(parseInt(splitSwitchId[1]));

        }
    }
    emit('emitStoreAcessValues', stores)
}

function switchValue(id){
    const getSwitch = document.getElementById("store_" + id)
    if(getSwitch.checked == false){
        for (let index = 0; index < globalStore.storesList.length; index++){
            while (index < globalStore.storesList.length) {
                if(stores[index] == id){
                    stores.splice(index, 1);
                } else {
                    index++;
                }
            } 
        }
    } else {
        stores.push(id)
    }
    emit('emitStoreAcessValues', stores)
}

let paginate = {
    first_page: 1,
    from: 1,
    last_page: 1,
}
    
</script>
    
<style scoped>

:deep(.form-group){
    margin: 0px!important;
}
    
.thead{
    font-weight: bold;
}

:deep(.form-group){
    margin: 0px!important;
}
    
table th{
    border-bottom: 1px solid #d7d7d7;
    padding: 0.6rem 1rem!important;
}

table td{
    padding: 0.5rem 1rem!important;
}

.actions .switch, th .switch{
    height: 25px;
    width: 45px;
}

.switch_th{
    padding-right: 0px!important;
    padding-left: 7px!important;
}

</style>