<template>
    <div v-if="isLoaded" class="container px-3 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage"/>
        <div class="box_content">
            <div class="divisor_bottom">
                <div class="p-4">
                    <div class="form-group mb-0">
						<p v-if="support.id" class="mb-1"><strong>{{ support.user }}</strong></p>
                        <p v-else class="mb-1"><strong>...</strong></p>
						<p v-if="support.id" class="mb-0">{{  globalStore.formatDate(support.created_at) }}</p>
                        <p v-else class="mb-0">...</p>
					</div>
                </div>
            </div>
            <div class="support_content p-4">
                <div class="mb-0" v-if="support.id">
                    {{support.content}}
                    <div style="width: 100px" class="custom-file" v-if="support.attachment" >
						<a target="_blank" :href="support.attachment">
                            <div class="background" :style="'background: url(' + support.attachment + ')'"></div>
						</a>
					</div>
                </div>
                <div class="mb-0" v-else>
                    ...
                </div>
            </div>
        </div>
        <div v-for="(reply, key) in support.replies" :reply="reply" :key="key" :class="'box_content ' + [reply.type_user == 'operator' ? 'reply' : '']">
            <div class="divisor_bottom">
                <div class="p-4">
                    <div class="form-group mb-0">
                        <p class="mb-1"><strong>{{ reply.user }}</strong></p>
                        <p class="mb-0 reply_date">{{ globalStore.formatDate(reply.reply_date)}}</p>
                    </div>
                </div>
            </div>
            <div v-if="reply.content" class="p-4">
                <div  class="col-md-12">
                   {{ reply.content }}
                    <div style="width: 100px" class="custom-file" v-if="reply.attachment" >
						<a target="_blank" :href="reply.attachment">
                            <div class="background" :style="'background: url('+ reply.attachment +')'"></div>
						</a>
					</div>
                </div>
            </div>
        </div>
        <div class="box_content p-4">
            <form @submit.prevent="">
                <div class="row">
                    <BaseInput
                        class="col-12"
                        id="content"
                        textarea
                        label="Mensagem *"
                        :page="route.name"
                        v-model="reply.content"
                    />
                    <BaseFileUpload
                        class="col-12"
                        id="attachment"
                        label="Imagem"
                        :page="route.name"
                        v-model="reply.attachment"
                        ref="fileUploadRef"
                    />
                </div>
                <BaseButton class="btn-primary btn-lg mt-3" @click="submit" :disabled="isLoading">Responder</BaseButton>
            </form>
        </div>
    </div>
    
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '@/stores/global';
import { useSupportStore } from '@/stores/support';
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();

const support_id = computed (function () {
    return route.params.id;
});

const previousPage = ref(null)

const title = ref({ title: `Suporte #0 ()`})
const isLoaded = ref(false)

const globalStore = useGlobalStore();

const supportStore = useSupportStore();
const { support, reply } = storeToRefs(supportStore);

const fileUploadRef = ref(null);

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('support')) {
        return router.push('/403');
    }

    globalStore.cleanObject(supportStore.support);

    globalStore.loader('show');

    previousPage.value = router.options.history.state.back;
    supportStore.trySupport(support_id.value).then(() => {
        title.value = { title: `Suporte #${route.params.id}  (${supportStore.support.title})` };
        isLoaded.value = true;
        globalStore.loader('hide');
    });	
});

const isLoading = ref(false);

function submit(){
    if(isLoading.value) {
        return;
    }

    let params = ['content', 'attachment'];

    isLoading.value = true;
    globalStore.loader('show');
    supportStore.tryUpdateSupport(support_id.value, params, route.name).then(function(success) {
        isLoading.value = false;
        globalStore.loader('hide');

        if(success){
            if(supportStore.reply.attachment){
                fileUploadRef.value.removeImage();
            }
            globalStore.cleanObject(supportStore.reply);
            supportStore.trySupport(support_id.value);
        }
    })
}

</script>
<style scoped>

strong{
    font-weight: 600;
}


.reply{
    border: 2px #215eda solid;
}

.reply p{
    color: #215eda;
}

.custom-file .background {
    width: 100%;
    height: 100px;
    border-radius: 4px;
    background-position: center !important;
    background-size: cover !important;
    margin-top: 20px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
    border: 2px #fff solid;
}

.support_content{
    white-space: pre-wrap;
}

</style>