<template>
    <BaseTable :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="receivables.length"  @changePage="changePage">
        <tr v-for="(receivable, key) in withdrawalStore.receivables" :key="key">
            <td>{{ globalStore.formatDateSimple(receivable.available_date) }}</td>
            <td>{{ globalStore.formatMoney(receivable.amount) }}</td>
            <td><BaseBadget :text="receivable.status.name" :color="receivable.status.color" /></td>
            <td class="actions"></td>
        </tr>
    </BaseTable>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { useWithdrawalStore } from '@/stores/withdrawal';
const withdrawalStore = useWithdrawalStore();
const { receivables, paginate } = storeToRefs(withdrawalStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const tableColumns = ['Data', 'Valor', 'Status'];

const isLoading = ref(false);

import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('wallet')) {
        return router.push('/403');
    }

    if(withdrawalStore.anticipations.length == undefined){
        isLoading.value = true;
    }
    
    if(route.query.page) {

        Object.keys(withdrawalStore.receivables_filters).forEach((key) => {
            let currentThing = route.query[key];
            withdrawalStore.receivables_filters[key] = '';
            withdrawalStore.receivables_filters[key] = currentThing;

            let param = route.query[key];
            withdrawalStore.receivables_filters[key] = (param == undefined) ? null : param;
        });
    }

    loadReceivables().then(() => {
        isLoading.value = false;
    });
});

async function loadReceivables() {
    await withdrawalStore.tryReceivables();
    globalStore.loader('hide');
}

let isChangingPage = false;
async function changePage(p) {
    if(isChangingPage) return;
    withdrawalStore.receivables_filters.page = p;
    globalStore.loader('show');
    isChangingPage = true;
    loadReceivables().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}
</script>