<template>
    <BaseTable v-if="modules" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :multirows="true" :qty="modules.length">
        <template v-slot:thead>
            <th class="thead" width="90%">Permissões</th>
            <th class="switch_th"><BaseSwitch @click="switchAllValues()" id="switch_all" :page="page"/></th>
        </template>
        <template v-slot:tbody>
            <template v-for="(permission_module, key) in modules" :key="key">
                <tr class="module">
                    <td>{{ permission_module.name }}</td>
                    <td class="actions">
                        <BaseSwitch @click="switchValue(permission_module.id)" :id="'module_' + permission_module.id" class="switch" :page="page"/>
                    </td>
                </tr>
                <tr class="sub_permission" v-for="(subpermission, id) in permission_module.sub_permissions" :key="id">
                    <td> <font-awesome-icon icon="fa-solid fa-turn-down-right" class="pe-2"/> {{ subpermission.name }}</td>
                    <td class="actions">
                        <BaseSwitch @click="switchValueSub(subpermission.id)" :id="'subpermission_' + subpermission.id" :class="'switch related_' + permission_module.id" :page="page"/>
                    </td>
                </tr>
            </template>
            <tr class="module">
                <td>App</td>
                <td class="actions">
                    <BaseSwitch class="switch" @input="setAppPermission" v-model:checked="appParams.login"/>
                </td>
            </tr>
            <tr class="sub_permission">
                <td> <font-awesome-icon icon="fa-solid fa-turn-down-right" class="pe-2"/>Informações da home</td>
                <td class="actions">
                    <BaseSwitch class="switch" @input="setAppPermission" v-model:checked="appParams.home_informations"/>
                </td>
            </tr>
            <tr class="sub_permission">
                <td> <font-awesome-icon icon="fa-solid fa-turn-down-right" class="pe-2"/>Pedidos</td>
                <td class="actions">
                    <BaseSwitch class="switch" @input="setAppPermission" v-model:checked="appParams.orders"/>
                </td>
            </tr>
            <tr class="sub_permission">
                <td> <font-awesome-icon icon="fa-solid fa-turn-down-right" class="pe-2"/>Validar ingressos</td>
                <td class="actions">
                    <BaseSwitch class="switch" @input="setAppPermission" v-model:checked="appParams.validate_tickets"/>
                </td>
            </tr>
        </template>
    </BaseTable>
</template>

<script setup>

import { onBeforeMount, onMounted, ref } from 'vue'
import { useUsersStore } from '@/stores/users';
import { storeToRefs } from 'pinia'

const usersStore = useUsersStore();
const { modules } = storeToRefs(usersStore)

const tableColumns = ['Permissões'];

const page = ref('createPermissions')

const props = defineProps({
    pageParams:{
        default: null,
    },

    appPageParams:{
        default: null,
    }
})

const appParams = ref({
    login: 0,
    home_informations:  0,
    orders:  0,
    validate_tickets: 0,
})

const modulesEnabled = ref([])

const isLoading = ref(true);

const emit = defineEmits(['emitPermissionsValues', 'emitAppValues'])

onBeforeMount(() =>{
    isLoading.value = false
})

onMounted(() =>{
    for(let i = 0; i < usersStore.modules.length; i++){
        const getRelateds = document.querySelectorAll(".related_" + usersStore.modules[i].id + ' input')
        for(let index = 0; index < getRelateds.length; index++){
            getRelateds[index].classList.add('disabled_switch')
            getRelateds[index].setAttribute('disabled', '')
        }
        for(let index = 0; index < props.pageParams.length; index++){
            if(usersStore.modules[i].id == props.pageParams[index]){
                document.getElementById('module_' + usersStore.modules[i].id).checked = true;
                modulesEnabled.value.push(parseInt(usersStore.modules[i].id));
                for(let indexer = 0; indexer < getRelateds.length; indexer++){
                    getRelateds[indexer].classList.remove('disabled_switch')
                    getRelateds[indexer].removeAttribute('disabled', '')
                } 
            }
            for(let indexer = 0; indexer < usersStore.modules[i].sub_permissions.length; indexer++){
                if(usersStore.modules[i].sub_permissions[indexer].id == props.pageParams[index]){
                    document.getElementById('subpermission_' + usersStore.modules[i].sub_permissions[indexer].id).checked = true
                    modulesEnabled.value.push(parseInt(usersStore.modules[i].sub_permissions[indexer].id));
                }
            }
        }
    }
   appParams.value = {
        login: props.appPageParams.login,
        home_informations:  props.appPageParams.home_informations,
        orders:  props.appPageParams.orders,
        validate_tickets:  props.appPageParams.validate_tickets,
    }
    emit('emitPermissionsValues', modulesEnabled.value)
    emit('emitAppValues', appParams.value)
})

function switchAllValues(){
    const getSwitchAll = document.getElementById('switch_all');
    const getAllSwitches = document.querySelectorAll('.switch input');
    if(getSwitchAll.checked == false){
        for(let i = 0; i < getAllSwitches.length; i++){
            getAllSwitches[i].checked = false;
            modulesEnabled.value.splice(0, modulesEnabled.value.length)
        }
        formatAppPermissions('allFalse')
    } else {
        for(let i = 0; i < getAllSwitches.length; i++){
            getAllSwitches[i].checked = true;
            let splitSwitchId = getAllSwitches[i].id.split('_');
            modulesEnabled.value.push(splitSwitchId[1]);
            getAllSwitches[i].classList.remove('disabled_switch');
            getAllSwitches[i].removeAttribute('disabled', '')
        }
        formatAppPermissions('allTrue');
    }
    emit('emitPermissionsValues', modulesEnabled.value)
    emit('emitAppValues', appParams.value)
}

function switchValue(id){
    const getSwitch = document.getElementById("module_" + id)
    const getRelateds = document.querySelectorAll(".related_" + id + ' input')
    if(getSwitch.checked == false){
        for (let index = 0; index < modulesEnabled.value.length; index++){
            while (index < modulesEnabled.value.length) {
                if(modulesEnabled.value[index] == id){
                    modulesEnabled.value.splice(index, 1);
                    for(let i = 0; i < getRelateds.length; i++){
                        getRelateds[i].classList.add('disabled_switch')
                        getRelateds[i].setAttribute('disabled', '')
                    } 
                    index++
                } else {
                    index++;
                }
            }
        }
    } else {
        for(let i = 0; i < getRelateds.length; i++){
            getRelateds[i].classList.remove('disabled_switch')
            getRelateds[i].removeAttribute('disabled', '')
        } 
        modulesEnabled.value.push(id)
    }
    emit('emitPermissionsValues', modulesEnabled.value)
}

function switchValueSub(id){
    const getSwitch = document.getElementById("subpermission_" + id)
    if(getSwitch.checked == false){
        for (let index = 0; index < modulesEnabled.value.length; index++){
            if(modulesEnabled.value[index] == id){
                modulesEnabled.value.splice(index, 1);   
            }
        }
    } else {
        modulesEnabled.value.push(id)
    }
    emit('emitPermissionsValues', modulesEnabled.value)
}

function formatAppPermissions(option){
    if(option){
        if(option === 'allFalse'){
            for(let key in appParams.value){
                appParams.value[key] = 0;
            }
        } else {
            for(let key in appParams.value){
                appParams.value[key] = 1;
            }
        }
    }
}

function setAppPermission(){
    emit('emitAppValues', appParams.value)
}

let paginate = {
    first_page: 1,
    from: 1,
    last_page: 1,
}

</script>

<style scoped>
.thead{
    font-weight: bold;
}

:deep(.form-group){
    margin: 0px!important;
}
    
table th{
    border-bottom: 1px solid #d7d7d7;
    padding: 0.6rem 1rem!important;
}

table .module, table .module td{
    border-top: 1px solid #d7d7d7;
    border-bottom: 0px;
}

table .sub_permission, table .sub_permission td{
    border: 0px!important;
}

table td{
    padding: 0.5rem 1rem!important;
}

.actions .switch, th .switch{
    width: 45px;
}

.switch_th{
    padding-right: 0px!important;
    padding-left: 7px!important;
}
</style>'