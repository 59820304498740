<template>
    <svg
        viewBox="0 0 687.5 136"
        style="enable-background: new 0 0 687.5 136"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    class="st0"
                    d="M47.4,117.4h11.5c5.1,0,8.9,3.9,8.9,9c0,5.2-3.8,9-8.9,9H47.4c-4.7,0-8.9-3.9-8.9-9
                    C38.5,121.2,42.8,117.4,47.4,117.4z"
                />
                <path
                    class="st1"
                    d="M191.6,43.1v21.7h-23v70.6h-21.7V64.8h-13.2l0.1-21.6H147v0.1v-5.1c0-26.8,14-37.8,37-37.8h7.7v21.3h-6
                    c-10.6,0-17,3.8-17,17.9v3.6L191.6,43.1L191.6,43.1z M270.1,133.3v-9.6c-6.8,7.1-17,11.6-27.6,11.6c-21.7,0-36.6-15-36.6-37.8
                    V43.1H228v49.8c0,15,8.1,22,19.6,22c12.8,0,22.5-9.1,22.5-23.3V43.1h21.7v92.4h-21.7V133.3z M305.5,135.4V16.8h21.7v118.6H305.5
                    L305.5,135.4z M341.2,135.4V0.4h21.7v135H341.2z M411,62.4c-8.9,0-13.4,3.3-13.4,6.9c0,4.9,8.9,6.9,18.3,8.9
                    c17.5,4.1,33.3,10.6,33.3,29.7c0,15.9-15.9,27.6-37,27.6c-15,0-28-5.3-38.6-15l12.2-14.6c6.9,6.1,17.1,10.6,26.8,10.6
                    c8.9,0,15.9-3.7,15.9-8.5c0-7.3-11.4-9.8-17.9-11.4c-14.6-4.1-34.1-8.5-34.1-27.2c0-15,14.6-26,34.5-26c13.8,0,26.8,5.3,35.4,13
                    L435,70.6C428.5,64.8,419.5,62.4,411,62.4L411,62.4z M528.6,122.5c-5.7,6.9-16.3,13-27.2,13c-25.2,0-43.9-20.7-43.9-46.3
                    c0-25.2,18.7-45.9,43.9-45.9c11,0,21.5,6.1,27.2,13V43.1h21.1v92.4h-21.1 M503.4,64.4c-13.8,0-25.2,11-25.2,24.8
                    s11.4,25.2,25.2,25.2s25.2-11.4,25.2-25.2S517.2,64.4,503.4,64.4z M565.4,135.4V0.4h21.7v135H565.4z M686.3,99h-66.2
                    c3.7,11,13.8,17.5,26.8,17.5c8.5,0,16.7-2.8,21.9-8.9l13.4,13.4c-8.5,9.3-21.1,14.6-35.8,14.6c-26.4,0-47.1-19.9-47.1-46.3
                    c0-26,19.5-45.9,45.5-45.9c24.8,0,42.3,18.3,42.3,44.7C687.1,91.6,687,95,686.3,99L686.3,99z M644.5,62
                    c-12.2,0-22.8,8.1-24.4,20.3h47.1C665.6,70.1,656.2,62,644.5,62L644.5,62z"
                />
                <path
                    class="st0"
                    d="M18.1,96.6L1.5,57.9c-0.3-0.8-0.6-1.7-0.6-2.5c-0.1-0.3-0.1-0.7-0.1-1v-1.2c0-4.6,3.7-8.4,8.3-8.4h98.1
                    c4.6,0,8.3,3.8,8.3,8.4v1.2c0,4.6-3.7,8.4-8.3,8.4H23.1l12,28.1h42.7c4.6,0,8.3,3.8,8.3,8.4v1.2c0,4.6-3.7,8.4-8.3,8.4H28.9
                    C22.3,108.5,20.6,102.1,18.1,96.6L18.1,96.6z"
                />
            </g>
        </g>
    </svg>
</template>
    
<style scoped>
    .st0 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #20cfcb;
    }
    .st1 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #1c56c6;
    }
</style>