<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
            <div class="item action btn-group">
                <BaseButton @click="createNew()" class="btn btn-primary w-100">Novo</BaseButton>
            </div>
        </BaseHeader>
        <BaseFilter @search="search" :initialSearch="initialSearch">
        </BaseFilter>
        <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="supports.length"  @changePage="changePage">
            <tr v-for="(support, key) in supportStore.supports" :key="key">
                <td width="10">{{ support.id }}</td>
                <td>{{ support.title }}</td>
                <td><BaseBadget :text="support.status.name" :color="support.status.color" /></td>
                <td>{{ globalStore.formatDate(support.updated_at) }}</td>
                <td class="actions">
                    <router-link :to="'/support/' + support.id" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Visualizar"><font-awesome-icon icon="fas fa-eye" /></router-link>
                </td>
            </tr>
        </BaseTable>
    </div>
</template>
<script setup>
const title = {title: 'Suporte', icon: 'fa-comment-lines'};
import { ref, onBeforeMount, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useSupportStore } from '@/stores/support';
import BaseFilter from '@/components/ui/BaseFilter.vue';

const supportStore = useSupportStore();
const { supports, paginate } = storeToRefs(supportStore);
const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const tableColumns = ['ID', 'Assunto', 'Status', 'Data de atualização'];

const isLoading = ref(false);
const params = ref({
    'search': null,
    'page': null
});

const route = useRoute();
const router = useRouter();

function createNew(){
    router.push('/support/create/new');
}

let initialSearch = null;

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('support')) {
        return router.push('/403');
    }

    if(supportStore.supports.length == undefined){
        isLoading.value = true;
    }

    if(route.query.search || route.query.page) {

        Object.keys(params.value).forEach((key) => {
            if(key == 'search') {
                initialSearch = route.query.search;
            }
            let param = route.query[key];
            params.value[key] = (param == undefined) ? null : param;
        });
    }

    loadSupport().then(() => {
        isLoading.value = false;
    });
});

async function loadSupport() {

    let usedParams = {};
    Object.keys(params.value).forEach((key) => {
        if(params.value[key] != null && params.value[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: params.value[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    await supportStore.trySupports(params.value)
    globalStore.loader('hide');
}

function search(r) {
    params.value.search = r;
    params.value.page = null;
    loadSupport();
}

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    params.value.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadSupport().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        params.value.page = null;
        loadSupport().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});
</script>