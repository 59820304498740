<template>
    <div class="box_content">
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, key) in webhooks" :key="key">
                        <td>{{ row.title }}</td>
                        <td class="actions">
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editWebhook(row.id)"><font-awesome-icon icon="fas fa-edit" /></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(row.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!webhooks.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
        </div>
        <!-- add row table quando dados de cupon à receber-->
        <div style="text-align:center;" class="p-4 divisor_top">
            <router-link :to="'/stores/' + store_id + '/webhooks/new'"> 
                <BaseButton class="btn-primary btn-lg">
                    Adicionar
                </BaseButton>
            </router-link>
        </div>
    </div>
    <router-view :productsList="productsList"></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover este webhook?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteWebhook" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref, shallowRef } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from "vue-router";
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { webhooks } = storeToRefs(storeStore);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

let webhookToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null)
const isLoading = ref(false);

const productsList = ref({
    0: 'Todos'
})

onBeforeMount(() =>{
    storeStore.tryStoreProducts(store_id.value).then(function(success) {
        if(success == true){
            for(let index = 0; index < storeStore.products.length; index++){
                if(storeStore.products[index].tag === null){
                    productsList.value[storeStore.products[index].id] = storeStore.products[index].id + ' - ' + storeStore.products[index].title;
                } else {
                    productsList.value[storeStore.products[index].id] = {title: storeStore.products[index].id + ' - ' + storeStore.products[index].title, tag: storeStore.products[index].tag}
                }
            }
        }
    })
})

async function deleteWebhook(){
    if(!isDeleting && !isLoading.value && webhookToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await storeStore.tryDeleteWebhook(webhookToDelete).then(function(success) {

            if(success){
                storeStore.tryWebhooks().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    confirmAction.value = null;
                    isLoading.value = false;
                    router.push('/stores/' + store_id.value + '/webhooks')
                });
            }else {
                isLoading.value = false;
                globalStore.loader('hide');
                isDeleting = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function editWebhook(id){
    globalStore.loader('show')
    storeStore.tryWebhook(id, store_id.value).then(() =>{
        router.push('/stores/' + store_id.value + '/webhooks/' + id);
        globalStore.loader('hide')
    })
}

function openPopup(id) {
    webhookToDelete = id
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

</script>