<template v-if="isLoaded === true">
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage">
            <router-link to="" class="item action btn-group">
                <baseButton  class="btn btn-primary w-100" @click="updateCampaign" :disabled="isLoading"> Salvar</baseButton>
            </router-link>
    </BaseHeader>
        <div class="box_content mt-2">
            <nav>
                <div class="nav" id="nav-tab" role="tablist">
                    <router-link :to="'/campaigns/'+ campaign_id" :class="[route.path === '/campaigns/'+ campaign_id ? 'active': '']">
                        Informações
                    </router-link>
                    <router-link :to="'/campaigns/' + campaign_id +  '/trackers'" :class="[route.path === '/campaigns/'+ campaign_id +'/trackers'? 'active': '']">
                        Rastreamento
                    </router-link>
                </div>
            </nav>
        </div>
        <router-view v-slot="{ Component }" v-if="campaignStore.campaign.id">
            <component v-if="route.path === '/campaigns/' + campaign_id" :destinationItems="destinationItems" ref="childMethod"  :is="Component"/>
            <component v-else ref="childMethod"  :is="Component"/>
        </router-view>
    </div>
</template>

<script setup>
const title = { title: "Editar campanha", icon: "fa-bullhorn" };
import { ref, onBeforeMount, computed } from 'vue'
import { useRoute, useRouter} from "vue-router";
import { useGlobalStore } from '@/stores/global'
import { useStoreStore } from '@/stores/store';
import { useCampaignStore } from '@/stores/campaign'

const route = useRoute();
const router = useRouter();

const page = 'updateCampaignInfo';

const campaign_id = computed (function () {
    return route.params.id;
});

const destinationItems= ref({
    pages: {},
    products: {},
})

const globalStore = useGlobalStore();

const campaignStore = useCampaignStore();

const storeStores = useStoreStore();

const isLoading = ref(false);
const isLoaded = ref(false);
const childMethod = ref(null);

onBeforeMount(() =>{
    if(!globalStore.hasPermissionTo('edit_campaign')) {
        return router.push('/403');
    }
    if(!globalStore.hasPermissionTo('stores')) {
        return router.push('/403');
    }
    previousPage.value = router.options.history.state.back;

    globalStore.cleanObject(campaignStore.campaign);

    storeStores.tryStores().then(()=>{
        globalStore.loader('hide'); 
        campaignStore.tryCampaign(campaign_id.value).then(() =>{
            isLoaded.value = true;
            let newCampaignBackUrl = '/campaigns/create/store=' + campaignStore.campaign.id_store
            if(router.options.history.state.back === newCampaignBackUrl){
                previousPage.value = '/campaigns'
            }    
        })

    });
    if(isLoading.value) {
        return;
    }
    globalStore.loader('show');
});

const previousPage = ref(null)

async function updateCampaign() {
    if(!isLoading.value){
        globalStore.loader('show');
        isLoading.value = true;
        let params = {
            campaignEditInformations: ['title', 'id_destination', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'src', 'type_destination']
        }

        campaignStore.tryUpdateCampaign(params, page).then(function(success) {
            globalStore.loader('hide');

            if(success){
                router.push('/campaigns/' + campaignStore.campaign.id);
                isLoading.value = false;
            } else {
                isLoading.value = false;
            }
        })
        globalStore.loader('hide'); 
    }
} 
</script>