<template>
  <div class="p-4 pt-0">
    <h5 class="text-center mb-4 p-4">Com qual tipo de conta deseja continuar?</h5>
    <div class="row" id="list_box">
      <div class="col mb-3">
        <router-link to="/account/completion/informations?type=J">
          <div class="item p-5">
            <font-awesome-icon icon="fa-regular fa-briefcase" />

            <h6 class="mt-4">Pessoa Jurídica</h6>
            <label>Cadastro com CNPJ</label>

            <BaseButton class="mt-4 btn-primary">Continuar</BaseButton>
          </div>
        </router-link>
      </div>

      <div class="col mb-3">
        <router-link to="/account/completion/informations?type=F">
          <div class="item p-5">
            <font-awesome-icon icon="fa-regular fa-user" />

            <h6 class="mt-4">Pessoa Física</h6>
            <label>Cadastro com CPF</label>

            <BaseButton class="mt-4 btn-primary">Continuar</BaseButton>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
#list_box a {
  text-decoration: none !important;
  color: #505050 !important;
  display: block;
}

#list_box .item {
  width: 100%;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  text-align: center;
  background: #fbfbfb;
  border-radius: 6px;
  cursor: pointer;
}

#list_box .item:hover {
  background: #fff;
}

#list_box .item h6 {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 5px;
}

#list_box .item label {
  display: block;
  text-align: center;
  opacity: 0.6;
}

#list_box .item.active {
  box-shadow: inset 0px 0px 0px 2px #215eda;
  border-color: #215eda;
  color: #fff;
  background: #215eda;
}

#list_box .item button {
  min-width: 200px;
  margin-top: 20px;
}

#list_box .item.active button {
  background: #1043a9;
}

#list_box .item svg {
  font-size: 25px;
}
</style>