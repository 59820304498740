<template>
        <div class="table-responsive box_content">
            <table class="table">
                <thead class="divisor_bottom">
                    <th>Descrição</th>
                    <th>Tipo</th>
                </thead>
                <tbody v-if="trackers.length">
                    <tr v-for="(tracker, key) in trackers" :key="key">
                        <td width="20">{{ tracker.title }}</td>
                        <td>{{ tracker.type_name }}</td>
                        <td class="actions">
                            <span  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editTracking(tracker.id)"><font-awesome-icon icon="fas fa-edit" /></span>
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(tracker.id)"><font-awesome-icon icon="fas fa-trash-can" /></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!trackers.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
        </div>
    <div class="box_content_add">
        <div style="text-align:center;" class="p-4">
            <div @click="createTracker()"> 
                <BaseButton class="btn-primary btn-lg">
                    Adicionar
                </BaseButton>
            </div>
        </div>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
                <div class="popup">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover esse rastreamento?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteTracker" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
    </BasePopup>
</template>
    
<script setup>
import { computed, shallowRef, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute();
const router = useRouter();

const campaign_id = computed (function () {
    return route.params.id;
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useCampaignStore } from '@/stores/campaign';
const campaignStore = useCampaignStore();
const { trackers } = storeToRefs(campaignStore);

let trackerToDelete = null;
const confirmAction = shallowRef(null);
const excludeTextConfirm = ref(null)
const isLoading = ref(false);

let isDeleting = false;
async function deleteTracker() {
    if(!isDeleting && !isLoading.value && trackerToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await campaignStore.tryDeleteTracker(trackerToDelete).then(function(success) {
            globalStore.loader('hide');
            isDeleting = false;
            confirmAction.value = null;
            excludeTextConfirm.value = null;
            if(success){
                campaignStore.tryTrackers(campaign_id.value);
                isLoading.value = false;
            } else {
                isLoading.value = false;
            }
        }); 
    }
}

function createTracker(){
    router.push('/campaigns/' + campaign_id.value + '/trackers/new')
}

function editTracking(id){
    globalStore.loader('show');
    campaignStore.tryTracker(id).then(() =>{
        router.push('/campaigns/' + campaign_id.value + '/trackers/' + id)
        globalStore.loader('hide');
    })
}

function openPopup(tracker_id) {
    trackerToDelete = tracker_id
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}
</script>
    
<style scoped>

.box_content{
    margin-bottom: 0px;
    border-radius: 8px 8px 0px 0px;
}

.box_content_add{
    margin: 0;
    background: #fff;
    border-bottom: 1px #ddd solid;
    border-left: 1px #ddd solid;
    border-right: 1px #ddd solid;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 25px;
}
</style>