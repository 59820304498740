import TheUsers from '@/views/users/TheUsers';
import UsersCreate from '@/views/users/create/UserCreate';
import UsersCreateAccessData from '@/views/users/create/UserCreateAccessData';
import UsersCreateStoreAccess from '@/views/users/create/UserCreateStoreAccess';
import UsersCreatePermissions from '@/views/users/create/UserCreatePermission';
import UsersEdit from '@/views/users/edit/UserEdit';
import UsersEditAcessData from '@/views/users/edit/UserEditAccessData';
import UsersEditStoreAcess from '@/views/users/edit/UserEditStoreAccess';
import UsersEditPermissions from '@/views/users/edit/UserEditPermission';

export default [
    {
        name: 'users',
        path: '/users',
        component: TheUsers,
        meta: {
            requiresAuth: true,
            menuPage: 'users'
        }
    },

    {
        name: 'userCreate',
        path: '/users/create',
        component: UsersCreate,
        meta: {
            requiresAuth: true,
            menuPage: 'users',
        },
        children: [
            {
                name: 'userCreateAccessData',
                path: '/users/create',
                component: UsersCreateAccessData,
            },

            {
                name: 'userCreateStoreAccess',
                path: '/users/create/store-access',
                component: UsersCreateStoreAccess,
            },

            {
                name: 'userCreatePermissions',
                path: '/users/create/permissions',
                component: UsersCreatePermissions,
            }
        ]
    },
    {
        name: 'userEdit',
        path: '/users',
        component: UsersEdit,
        meta: {
            requiresAuth: true,
            menuPage: 'users'
        },
        children: [
            {
                name: 'userEditAccessData',
                path: '/users/:id/',
                component: UsersEditAcessData,
            },
            {
                name: 'userEditStoreAccess',
                path: '/users/:id/store-access/',
                component: UsersEditStoreAcess,
            },
            {
                name: 'userEditPermissions',
                path: '/users/:id/permissions/',
                component: UsersEditPermissions,
            },
        ]
    },
];