<template>
    <div>
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <div>
                <header>
                    <font-awesome-icon icon="fa-solid fa-shipping-fast" style="color: #215eda;"/>
                    <span v-if="route.name == 'storeShippingMethodEdit'" class="ms-2 align-middle">Editar método de envio</span>
                    <span v-else class="ms-2 align-middle">Novo método de envio</span>
                </header>
                <div class="content">
                    <form @submit.prevent="">
                        <div class="row">
                            <BaseInput
                                class="col-md-6 col-12"
                                label="Nome *"
                                type="input"
                                id="title"
                                tooltip="O nome que será exibido no checkout"
                                v-model="shipping_method.title"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-md-6 col-12"
                                label="Tipo de frete"
                                type="input"
                                id="type_shipping"
                                tooltip="PAC, Sedex 10, Standard..."
                                v-model="shipping_method.type_shipping"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-md-12 col-12"
                                label="Transportadora"
                                :items="listCompanies"
                                placeholder="Selecione"
                                type="input"
                                id="id_company"
                                v-model="shipping_method.id_company"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-md-4 col-12"
                                label="Status *"
                                :items="listStatus"
                                placeholder="Selecione"
                                type="input"
                                id="status"
                                v-model="shipping_method.status"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-md-4 col-12"
                                label="Padrão *"
                                :items="listDefault"
                                placeholder="Selecione"
                                type="input"
                                id="default"
                                v-model="shipping_method.default"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-md-4 col-12"
                                label="Integração"
                                :items="listIntegrations"
                                type="input"
                                id="integration"
                                v-model="shipping_method.integration"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-md-6 col-12"
                                label="Valor"
                                type="text"
                                tag="R$"
                                id="price"
                                v-model="shipping_method.price"
                                :page="route.name"
                                mask="9.99#,##"
                                :tokens="'9:[0-9]:repeated'"
                                :reversed="true"
                                maxlength="10"
                            />
                            <BaseInput
                                class="col-md-6 col-12"
                                label="Estimativa"
                                type="input"
                                id="estimated"
                                v-model="shipping_method.estimated"
                                :page="route.name"
                            />
                        </div>
                        <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                            Salvar
                        </BaseButton>
                    </form>
                </div>
            </div>
        </BasePopup>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from '@/stores/global'
import { useStoreStore } from '@/stores/store';

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false);
const isLoading = ref(false);

const globalStore = useGlobalStore();

const storeStore = useStoreStore();
const { shipping_method } = storeToRefs(storeStore);

const store_id = computed (function () {
    return route.params.id;
});

const listDefault = ref({
    0: 'Não',
    1: 'Sim',
})

const listStatus = ref({
    1: 'Habilitado',
    0: 'Desabilitado',
})

const listCompanies = ref({
    0: 'Nenhuma'
})

const listIntegrations = ref({})

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];

    for(let i = 0; i < storeStore.shipping_companies.length; i++){
        listCompanies.value[storeStore.shipping_companies[i].id] = storeStore.shipping_companies[i].title
    }

    for(let i = 0; i < storeStore.integrations.length; i++){
        listIntegrations.value[storeStore.integrations[i].slug] = storeStore.integrations[i].title
    }
    
    if(route.name == 'storeShippingMethodEdit'){
        if(storeStore.shipping_method.id){
            globalStore.loader('hide')
            isLoaded.value = true;
        } else {
            storeStore.tryShippingMethod(route.params.slug).then(() =>{
                globalStore.loader('hide')
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'storeShippingMethodCreate'){
        globalStore.cleanObject(storeStore.shipping_method);
        globalStore.loader('hide')
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
    globalStore.loader('show')
        if(route.name == 'storeShippingMethodEdit'){

            let params = ['title', 'status', 'price', 'estimated', 'default', 'id_company', 'type_shipping', 'integration'];

            storeStore.tryUpdateShippingMethod(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryShippingMethods().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/shipping/methods')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
        else if(route.name == 'storeShippingMethodCreate') {

            let params = ['title', 'status', 'price', 'estimated', 'default', 'id_company', 'id_store', 'type_shipping', 'integration'];

            storeStore.tryCreateShippingMethod(params, route.name).then(function(success) {
                if(success){
                    storeStore.tryShippingMethods().then(function() {
                        globalStore.loader('hide');
                        router.push('/stores/' + store_id.value + '/shipping/methods')
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    }
}
</script>