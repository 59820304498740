import TheCampaigns from '@/views/campaigns/TheCampaigns';
import CampaignCreate from '@/views/campaigns/create/CampaignCreate';
import CampaignEdit from '@/views/campaigns/edit/CampaignEdit';
import CampaignEditInformations from '@/views/campaigns/edit/informations/CampaignEditInformations.vue';
import CampaignEditTrackers from '@/views/campaigns/edit/trackers/CampaignEditTrackers.vue';
import CampaignTrackingDialog from '@/views/campaigns/edit/trackers/CampaignTrackingDialog';

export default [
    {
        path: '/campaigns',
        name: 'campaigns',
        component: TheCampaigns,
        meta: {
            requiresAuth: true,
            menuPage: 'campaigns'
        }
    },

    {
        name: 'campaignCreateDefinestore',
        path: '/campaigns/create',
        component: CampaignCreate,
        meta: {
            requiresAuth: true,
            menuPage: 'campaigns'
        }
    },

    {
        name: 'campaignCreate',
        path: '/campaigns/create/store=:id',
        component: CampaignCreate,
        meta: {
            requiresAuth: true,
            menuPage: 'campaigns'
        }
    },

    {
        name: 'campaignEdit',
        path: '/campaigns/:id',
        component: CampaignEdit,

        meta: {
            requiresAuth: true,
            menuPage: 'campaigns',
        },

        children: [
            {
                name: 'campaignEditInformations',
                path: '/campaigns/:id',
                component: CampaignEditInformations,
            },
            {
                path: '/campaigns/:id/trackers',
                component: CampaignEditTrackers,
                children: [
                    {
                        name: 'campaignTrackerCreate',
                        path: '/campaigns/:id/trackers/new',
                        props: true,
                        component: CampaignTrackingDialog,
                    },
                    {
                        name: 'campaignTrackerEdit',
                        path: '/campaigns/:id/trackers/:slug',
                        props: true,
                        component: CampaignTrackingDialog,
                    }
                ]
            }
        ]
    }
];