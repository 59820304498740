<template v-if="reportStore.recommendation">        
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="{title: 'Recomendado: ' + reportStore.recommendation}" removeSearchBar :redirectBack="previousPage">
            <div id="DayPicker" class=" col-md-5 col-12  metrics_amount_picker preset_picker">
                <BaseDatePicker 
                    @updateInfos="updateReportInfos()"
                    @clearDayPicker="clearReportInfos()"
                    ref="childMethodClear"
                    placeholder="Hoje"
                    :hideInputIcon="false"
                    style="margin-top: 0px !important"
                    range
                    multi
                    v-model="params.date_range"
                    :storeLoaded="reportStoreLoaded"
                    :loadedDayPicker="true"
                    enablePreset
                />
            </div>
        </BaseHeader>
        <div class="box_content">
            <div class="p-4 pb-1">
                <h2>
                    <font-awesome-icon icon="fa-heart-rate" class="icon"/>
                    Métricas
                </h2>
                <div class="box_spacing_metrics p-4" v-if="reportStore.metrics[0]">
                    <div class="item_metrics">
                        <BaseInfoGroup
                            svg_icon="fas fa-shopping-cart"
                            title="Vendas"
                            :info_value="reportStore.metrics[0].sells"
                        />
                    </div>
                    <div class="item_metrics">
                        <BaseInfoGroup
                            svg_icon="fas fa-chart-simple"
                            title="Total"
                            :info_value="reportStore.metrics[0].amount"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="box_content p-4 d-flex">
            <BaseMetricsBox>
                <h2 class="metrics_title">
                    <font-awesome-icon icon="fas fa-chart-mixed" />
                    Vendas
                </h2>
                <div class="header_actions sales_datepicker">
                    <BaseDatePicker
                        v-if="reportStoreLoaded == true"
                        id="SecondSalesDatepicker"
                        :month=true
                        :hideInputIcon="false"
                        style="margin-top: 0px !important;margin-bottom: 16px;" 
                        circle_background="1AB4D7"
                        class="me-2"
                        v-model="reportStore.chart[0].last_month.date"
                        @formatMonthEvent="formatMonth('last_month')"
                        @loadLastInfos="loadChartClear('thisMonth')"
                        :storeLoaded="reportStoreLoaded"
                        :loadedLastMonthPicker="true"
                        :secondLoadChart="getChartLoaded"
                    />
                    <BaseDatePicker
                        v-if="reportStoreLoaded == true"
                        id="FirstSalesDatepicker"
                        :month=true
                        :hideInputIcon="false"
                        style="margin-top: 0px !important;margin-bottom: 16px;"
                        circle_background="1A75D7"
                        class="me-0"
                        v-model="reportStore.chart[0].this_month.date"
                        @formatMonthEvent="formatMonth('this_month')"
                        @loadThisInfos="loadChartClear('lastMonth')"
                        :storeLoaded="reportStoreLoaded"
                        :loadedThisMonthPicker="true"
                        :secondLoadChart="getChartLoaded"
                    />
                </div>
                <LineChart :chart="reportStore.chart" :chart_params="chartparams" ref="childMethod" :chartStoreLoaded="reportStoreLoaded"/>
            </BaseMetricsBox>
        </div>
    </div>
</template>

<script setup>
import LineChart from '@/components/includes/reports/LineChart.vue';

import { ref, onBeforeMount, onMounted, computed } from 'vue'

import { useGlobalStore} from '@/stores/global';
const globalStore = useGlobalStore()

import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

import { useReportStore } from '@/stores/report';
const reportStore = useReportStore();

const recommendation_id = computed (function () {
    return route.params.id;
});

const params = ref({
    date_range: null,
    chart_informations: null,
});

const chartparams = ref ({
    chart_this_month: null,
    chart_last_month: null,
})

const infoparams = ref({
    date_start: null,
    date_end: null,
})

const today = new Date();

const reportStoreLoaded = ref(false)
const previousPage = ref(null)
const childMethod = ref(null);
const childMethodClear = ref(null);
const getChartLoaded = ref(false);
const todayDate = ref(null);
var initialFilters = null;

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('report_recommendations')) {
        return router.push('/403');
    }

    globalStore.loader('show')

    if(route.query){
        params.value = route.query
    }

    if(route.query.date_start && route.query.date_end){
        params.value.date_range = route.query.date_start + '@' + route.query.date_end
    }

    previousPage.value = router.options.history.state.back;
    setTodayDate()

    if(!reportStore.recommendation){
        await reportStore.tryRecommendation(recommendation_id.value);
        await reportStore.tryRecommendationMetrics(recommendation_id.value,  params.value);
        await reportStore.tryRecommendationChart(recommendation_id.value,  params.value).then(() =>{
            reportStoreLoaded.value = true;
        })
        
        globalStore.loader('show')
    } else{
        reportStoreLoaded.value = true;
        globalStore.loader('show')
    }

    updateReportInfos()
    globalStore.loader('hide')
})

onMounted(() =>{
    if(initialFilters){
        childMethodClear.value.resizeDayPicker();
        initialFilters.date_range = null
    }
})

function updateReportInfos(){
    if(params.value.date_range){
        globalStore.loader('show')
        infoparams.value.date_start = params.value.date_range[0]
        infoparams.value.date_end = params.value.date_range[1]
        let getInput = document.querySelector('.metrics_amount_picker');
        getInput.setAttribute("style","width:fit-content;");

        childMethodClear.value.resizeDayPicker();

        router.replace({query: infoparams.value})
        reportStore.tryRecommendationMetrics(route.params.id, infoparams.value).then(() =>{
            globalStore.loader('hide')
        })
    }
}

function clearReportInfos(){
    globalStore.loader('show')
    params.value.date_range = null;
    infoparams.value.date_start = null;
    infoparams.value.date_end = null;

    document.querySelector('.metrics_amount_picker').setAttribute("style","width:120px;");

    reportStore.tryRecommendationMetrics(route.params.id, infoparams.value).then(() => {
        globalStore.loader('hide');
    })

    var inputDayPicker = document.querySelector(".metrics_amount_picker .dp__input_wrap");
    inputDayPicker.style.width = 100 + '%';
    router.replace('');
}

function setTodayDate(){
    let getFullYear = today.getFullYear();
    let getMonth = today.getMonth() +1;
    let getDate = today.getDate();

    if(getDate <= 9){
        infoparams.value.date_start = getFullYear + '-' + getMonth + '-0' + getDate
        infoparams.value.date_end = getFullYear + '-' + getMonth + '-0' + getDate
        todayDate.value = getFullYear + '-' + getMonth + '-0' + getDate
        if(getMonth <= 9){
            infoparams.value.date_start = getFullYear + '-0' + getMonth + '-0' + getDate
            infoparams.value.date_end = getFullYear + '-0' + getMonth + '-0' + getDate
            todayDate.value = getFullYear + '-0' + getMonth + '-0' + getDate
        }
    }

    if(getMonth <= 9){
        infoparams.value.date_start = getFullYear + '-0' + getMonth + '-' + getDate
        infoparams.value.date_end = getFullYear + '-0' + getMonth + '-' + getDate
        todayDate.value = getFullYear + '-0' + getMonth + '-' + getDate
        if(getDate <= 9){
            infoparams.value.date_start = getFullYear + '-0' + getMonth + '-0' + getDate
            infoparams.value.date_end = getFullYear + '-0' + getMonth + '-0' + getDate
        }
    }
}


function formatMonth(selected_month){
    setTimeout(() => {
        let selectedMonthSplitted = null;
        if(selected_month == 'this_month'){
            selectedMonthSplitted =  reportStore.chart[0].this_month.date.split('-');
        } else {
            selectedMonthSplitted = reportStore.chart[0].last_month.date.split('-');
        }

        if (selectedMonthSplitted[1].length <= 2) {
            let selectedMonthFormated = selectedMonthSplitted[1] + '-' + selectedMonthSplitted[0];
            if(selected_month == 'this_month'){
                chartparams.value.chart_this_month =  selectedMonthFormated;
            } else {
                chartparams.value.chart_last_month = selectedMonthFormated;
            }
            loadChartReport();
        } else {
            loadChartReport();
        }
    }, 10)
}

async function loadChartReport() {
    let usedParams = {};
    Object.keys(chartparams.value).forEach((key) => {
        if(chartparams.value[key] != null && chartparams.value[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: chartparams.value[key]
            };
            usedParams =  currentParams;
        }
    });
    router.replace({query: usedParams});

    childMethod.value.updateChart();
}   

async function loadChartClear(monthPicker){
    if(monthPicker === 'thisMonth'){
        chartparams.value.chart_this_month = '12-2032';
    } else if(monthPicker === 'lastMonth'){
        chartparams.value.chart_this_month = '12-2032';
    }

    childMethod.value.updateChartClear(monthPicker);
}
</script>


<style scoped>

#DayPicker :deep(#size-calibration){
    position: absolute;
}

#DayPicker :deep(.dp__input){
    border-radius: 10px;
}

.metrics_amount_picker:deep(input::placeholder) {
    font-family: Poppins;
    font-size: 14px;
    padding-left: 30px;
    position: absolute;
    margin-top: 1px;
    font-weight: 500;
}

h2{
    display: inline-block;
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}

.icon{
    margin-right: 10px;
    color:#215eda;
}

:deep(.dot){
    vertical-align: middle!important;
}

.box_spacing_metrics {
    display: flex;
    justify-content: space-between;
}

.metrics_amount_picker{
    width: 120px;
}

.header_actions{
    float: right;
    margin-top: -38px;
    background: transparent;
    white-space: nowrap;
}

.sales_datepicker {
    display: flex;
    margin-right: 20px;
}

.sales_datepicker :deep(.circle) {
    position: relative;
    top: 36px;
    right: -30px;
    z-index: 9;
}

#FirstSalesDatepicker, #SecondSalesDatepicker {
    width: min-content
}

.sales_datepicker :deep(.dp__input_wrap) {
    padding: 6px 0px 6px 20px;
}

.sales_datepicker:deep(input){
    border-radius: 10px;
    width: 100%;
    border: 1px solid #d7d7d7;
    padding-left: 30px;
}

.sales_datepicker:deep(.dp__input_icon){
    display: none;
}

@media screen and (max-width: 1200px) {
    .box_spacing_metrics {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));
    }

    .item_metrics {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }
}

@media screen and (max-width: 992px) {


    .box_spacing_infos .row {
        height: auto !important;
    }

    .metrics_amount_picker,
    .metrics_amount_picker :deep(.dp__input_wrap),
    .metrics_amount_picker :deep(.dp__input) {
        width: 100% !important;
        text-align: center;
    }

    .metrics_amount_picker:deep(input::placeholder) {
        padding-left: 10px;
        position: absolute;
        margin-top: 1px;
        font-weight: 500;
    }

    .metrics_amount_picker :deep(.dp__input_icon) {
        left: 90% !important;
    }

    .header_actions {
        width: 100%;
        margin-top: 15px;
    }

    .sales_datepicker {
        margin-top: -10px;
        margin-bottom: 15px;
    }

    .main_title_icon{
        height: 400px;
    }

    .sales_datepicker :deep(.dp__main > div:first-child) {
        width: fit-content;
    }

    .sales_datepicker :deep(.dp__input) {
        width: 100%;
    }

}

@media screen and (max-width: 576px) {

    .main_title_icon{
        height: 450px;
    }

    .header_sales{
        height: 225px;
    }

    .item_metrics {
        width: 100%;
    }

    .sales_datepicker {
        display: inline-block;
    }
}

</style>