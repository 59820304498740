<template>
    <div class="container px-2 px-lg-4">
        <BaseTable v-if="withdrawalStore.withdrawal.order_canceled" :columns="['ID', 'Valor']" :noActions="true"  :qty="withdrawalStore.withdrawal.order_canceled.length">
            <tr v-for="(order, key) in withdrawalStore.withdrawal.order_canceled" :key="key">
                <td width="50%">
                    <a :href="order.url" target="_blank">
                        {{ order.id_order }}
                        <font-awesome-icon :icon="['far', 'square-arrow-up-right']" />
                    </a>
                    <b v-if="order.installment">{{ order.installment }}º Parcela</b>
                </td>
                <td>
                    {{ globalStore.formatMoney(order.amount) }}
                </td>
            </tr>
        </BaseTable>
    </div>
</template>
    
<script setup>
import { useWithdrawalStore } from '@/stores/withdrawal'
const withdrawalStore = useWithdrawalStore();
 
import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
</script>
    
<style scoped>
a:hover{
    text-decoration: underline!important;
}

b {
    display: inline-block;
    margin-left: 15px;
}
</style>