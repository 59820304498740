<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <template v-if="isLoaded != false">        
            <header>
                <font-awesome-icon icon="fa-regular fa-boxes-stacked" style="color: #215eda;"/>
                <span v-if="route.name == 'productKitEdit'"  class="ms-2 align-middle">Editar kit</span>
                <span v-else class="ms-2 align-middle">Novo item do kit</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseSelect
                            class="col-12"
                            id="id_product_kit"
                            label="Produto"
                            :items="productsList"
                            aria-placeholder="Selecione"
                            placeholder="Selecione"
                            :page="route.name"
                            v-model="kit.id_product_kit"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </template>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter} from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { kit }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const productsList = ref({})
const isLoaded = ref(false)
const isLoading = ref(false);

const product_id = computed (function () {
    return route.params.id;
});

onBeforeMount(() =>{
    if(productStore.product.id){
        for(let i = 0; i < productStore.productslist.length; i++){
            if(productStore.productslist[i].tag === null){
                productsList.value[productStore.productslist[i].id] = productStore.productslist[i].id + ' - ' + productStore.productslist[i].title;
            } else {
                productsList.value[productStore.productslist[i].id] = {title: productStore.productslist[i].id + ' - ' + productStore.productslist[i].title, tag: productStore.productslist[i].tag};
            }
        }
    }
    if(route.name == 'productKitEdit'){
        if(productStore.kit.id == undefined || productStore.kit.id == null){
            globalStore.loader('show')
            productStore.tryKit(route.params.slug).then(() =>{
                isLoaded.value = true;
                globalStore.loader('hide')
            })
        } else {
            isLoaded.value = true
        }
    } else if(route.name == 'productKitCreate') {
        globalStore.cleanObject(productStore.kit);
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(route.name == 'productKitEdit'){
            let params = ['id_product_kit'];

            productStore.tryUpdateKit(params, route.name, productStore.kit.id).then(function(success) {
                if(success){
                    useGlobalStore().successMessage('Kit atualizado com sucesso');

                    productStore.tryKits().then(function(success){
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/kits')
                        } else{
                            globalStore.loader('hide');
                        }
                    })
                }
                isLoading.value = false;
            });
        } else if(route.name == 'productKitCreate') {
            let params = ['id_product_kit'];

            productStore.tryCreateKit(params, route.name).then(function(success){
                if(success){
                    useGlobalStore().successMessage('Item adicionado ao kit com sucesso');

                    productStore.tryKits().then(function(success) {
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/kits')
                        } else {
                            globalStore.loader('hide');
                        }
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    }
}

</script>