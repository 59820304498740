<template>
    <form @submit.prevent="resetPassword">
        <BaseInput
            label="E-mail"
            type="email"
            id="email"
            v-model="inputs.email"
            :page="page"
            login
            required
        />

        <BaseInput
            label="Nova senha"
            type="password"
            id="password"
            v-model="inputs.password"
            :page="page"
            login
            required
        />

        <BaseInput
            label="Repita a nova senha"
            type="password"
            id="password_confirmation"
            v-model="inputs.password_confirmation"
            :page="page"
            login
            required
        />

        <BaseButton
            class="mt-4 btn-primary w-100"
            :loading="isLoading"
            :disabled="isLoading"
            >Redefinir senha
        </BaseButton>
    </form>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
    code: {
        required: true
    }
});

import { useAuthStore } from '@/stores/auth';
const auth = useAuthStore();

import { useGlobalStore } from '@/stores/global';
const global = useGlobalStore();

const page = ref('forgotPasswordCode');

const inputs = ref({
    email: '',
    password: '',
    password_confirmation: '',
    recaptcha_token: ''
});

const isLoading = ref(false);

async function resetPassword() {
    isLoading.value = false;

    let errors = false;

    if(!inputs.value.password) {
        global.localErrors('password', 'Campo obrigatório', page.value);
        errors = true;
    }

    if (inputs.value.password.length < 5) {
        global.localErrors('password', 'Senha deve conter pelo menos 6 caracteres', page.value);
        errors = true;
    }

    if(!inputs.value.password_confirmation) {
        global.localErrors('password_confirmation', 'Campo obrigatório', page.value);
        errors = true;
    }

    if(inputs.value.password != inputs.value.password_confirmation) {
        global.localErrors('password', 'As senhas não conferem', page.value);
        errors = true;
    }

    if(!errors) {
        // eslint-disable-next-line
        grecaptcha.ready(() => {
            isLoading.value = true;
            // eslint-disable-next-line
            grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {  
                inputs.value.recaptcha_token = token;
                const params = inputs.value;
                auth.tryResetPassword(page.value, params, props.code).then(() =>{
                    isLoading.value = false;
                });
            });
        });
    }
}
</script>
