<template>
    <h2>
        <font-awesome-icon icon="fa-solid fa-badge-dollar" style="color: #215eda"/>
        <span class="ms-2">Aceitar solicitação - Pedido #{{ props.refund.id }}</span>
    </h2>
    <p>Você está concordando no reembolso de <b>{{ props.refund.client.name }}</b>, no valor de {{ global.formatMoney(props.refund.total) }}.</p>
    <BaseSwitch
        id="get_product_back"
        label="Solicitar devolução do produto"
        v-model:checked="params.get_product_back"
        :page="route.name"
    />
    <p class="alert" v-if="params.get_product_back">Ao aceitar o reembolso você concorda que o frete da devolução do produto é de sua responsabilidade. Conforme descrito nos nossos <b><a target="_blank" href="https://fullsale.com.br/termos-de-uso">Termos de uso</a></b>.</p>
    <BaseInput
        v-if="params.get_product_back"
        id="transport_code"
        class="col-12"
        label="Código de devolução do produto:"
        :page="route.name"
        v-model="params.transport_code"
    />
    <BaseInput
        class="col-12"
        label="Detalhes e informações para o(a) cliente:"
        :sub_label="(params.get_product_back) ? 'Por favor informe com detalhes como realizar o procedimento para devolução do produto.' : ''"
        textarea
        :page="route.name"
        id="text"
        v-model="params.text"
    />
    <BaseFileUpload
        class="col-12"
        label="Imagens"
        id="images"
        :page="route.name"
        multiple
        tooltip_text="Selecione no máximo 3 imagens."
        v-model="params.images"
        accept=".jpg, .jpeg, .png"
    />
    <BaseButton :disabled="isLoading || disableSubmitButton" class="mt-2 btn-primary w-100" @click="submit"> {{ (params.get_product_back) ? 'Solicitar devolução' : 'Aceitar reembolso' }}</BaseButton>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useGlobalStore } from '@/stores/global';
const global = useGlobalStore();

import { useRoute } from "vue-router";
const route = useRoute();

onBeforeMount(() => {
    delete global.errors[route.name];
});

const props = defineProps({
    refund: {
        required: true,
    }
});

const emit = defineEmits(["closePopup"]);

const params = ref({
    get_product_back: 0,
    transport_code: '',
    text: '',
    images: null,
});

import { useRefundStore } from '@/stores/refund';
const refundStore = useRefundStore();

const disableSubmitButton = ref(false);

const isLoading = ref(false);
function submit() {
    if(!isLoading.value){
        global.loader('show');
        isLoading.value  = true;
        disableSubmitButton.value = true;
        refundStore.tryAcceptRefund(route.name, props.refund.id, params.value).then(function (success){
            global.loader('hide');
            if(success){
                emit('closePopup');
            }else {
                disableSubmitButton.value = false;
            }
            isLoading.value = false;
        });
    }
}
</script>

<style scoped>
h2 {
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}
</style>