<template>
    <div>
        <div class="container customize_item" @click.self="openDialogCustomization('checkout', true)" v-bind:style="[background_image_preview ? { backgroundImage: 'url(' + background_image_preview + ')' } : { backgroundImage: 'url(' + checkout.customization.background_image + ')' }]">
    
        <div v-if="open_exit_popup == true" id="exit_popup" style="display: block;" @click.self="closeExitPopup">
            <div class="content customize_item" @click.self="openSubmenu('exit_intent_popup', true)">
                <div class="close">x</div>
                Você ganhou <span class="amount customize_item" @click="openDialogCustomization('exit_popup_discount', true)"><strong>50</strong>%</span> de desconto
                <button class="customize_item" @click="openDialogCustomization('exit_popup_button', true)">{{ checkout.customization.exit_popup_button_text }}</button>
            </div>
        </div>
        
        <div v-if="checkout.customization.enable_timer == 1" id="countdown_wrapper" class="customize_item"
            @click.self="openSubmenu('timer', true)">
            <div class="row" @click.self="openSubmenu('timer', true)">
                <div class="col customize_item" @click="openDialogCustomization('timer_text', true)">
                    <h5>{{ checkout.customization.timer_text }}</h5>
                </div>
                <div class="col customize_item" @click="openDialogCustomization('timer_numbers', true)">
                    <div id="countdown" data-time="10">
                        <span>
                            <strong>{{ timerHours }}</strong>
                            Horas
                        </span>
                        <span>
                            <strong>{{ timerMinutes }}</strong>
                            Minutos
                        </span>
                        <span>
                            <strong>00</strong>
                            Segundos
                        </span>
                    </div>
                </div>
                <div class="col" @click.self="openSubmenu('timer', true)">
                    <a v-if="checkout.customization.enable_timer_sell_button == 1" @click="openDialogCustomization('timer_button', true)" class="btn customize_item" id="order_now">
                        {{ checkout.customization.timer_text_button }}
                    </a>
                </div>
            </div>
        </div>

        <div v-if="checkout.customization.header_logotipo == 1" id="header" class="customize_item" @click.self="openSubmenu('header', true)">
                <div class="row" @click.self="openSubmenu('header', true)">
                    <div class="col" @click.self="openSubmenu('header', true)">
                        <img v-if="header_logotipo_preview" :src="header_logotipo_preview" id="logotipo" class="customize_item"  @click.self="openDialogCustomization('logotipo', true)"/>
                        <img v-else-if="checkout.customization.logotipo" :src="checkout.customization.logotipo" id="logotipo" class="customize_item"  @click.self="openDialogCustomization('logotipo', true)"/>
                        <img v-else :src="store.logotipo" class="customize_item " id="logotipo"  @click.self="openDialogCustomization('logotipo', true)">
                    </div>

                    <div id="header_contact_info" v-if="checkout.customization.show_email_support_on_header == 1" class="col header_contact customize_item" @click="openDialogCustomization('enable_header_contact_info', true)">
                        <svg fill="#494949" width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"></path></svg>
                        <p>
                            <span>Dúvidas?</span>{{ store.email }}
                        </p>
                    </div>
                    
                    <div id="header_secure_info" class="col secure customize_item"  @click="openDialogCustomization('header_secure', true)">
                            <svg fill="#10933a" width="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve"><g><path d="M95.6,490h298.7c21.4,0,38.8-17.4,38.8-38.8V237.1c0-21.4-17.4-38.8-38.8-38.8h-1v-51.2C393.3,66,327.3,0,246.2,0S99.1,66,99.1,147.1v51.2h-3.4c-21.4,0-38.8,17.4-38.8,38.8v214.1C56.8,472.6,74.2,490,95.6,490z M163.4,147.1c0-45.6,37.1-82.8,82.8-82.8c45.6,0,82.8,37.1,82.8,82.8v51.2H163.4V147.1z"></path></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                            <p>
                                <span>Pagamento</span>100% seguro
                            </p>
                    </div>
                </div>
        </div>
        
        <div id="checkout">
            <div class="row">
                <div v-if="checkout.customization.enable_banner_cover == '1'" :class="[checkout.customization.show_email_support_on_checkout == 1 ? 'mb-0' : 'mb-3'] + ' col-12'">
                    <header v-if="props.device == 'desktop'" class="customize_item" @click="openDialogCustomization('cover_desktop', true)">
                        <img v-if="cover_desktop_preview" :src="cover_desktop_preview" />
                        <img v-else-if="checkout.customization.cover_desktop" :src="checkout.customization.cover_desktop" />
                        <img v-else-if="checkout.product_demo.cover_desktop" :src="checkout.product_demo.cover_desktop" />
                        <span v-else></span>
                    </header>
                    <header v-else-if="props.device == 'mobile'" class="customize_item" @click="openDialogCustomization('cover_mobile', true)">
                        <img v-if="cover_mobile_preview" :src="cover_mobile_preview" />
                        <img v-else-if="checkout.customization.cover_mobile" :src="checkout.customization.cover_mobile" />
                        <img v-else-if="checkout.product_demo.cover_mobile" :src="checkout.product_demo.cover_mobile" />
                        <span v-else></span>
                    </header>
                </div>
            <div class="col-12" v-if="checkout.customization.show_email_support_on_checkout == 1">
                <div v-if="checkout.customization.show_email_support_on_checkout == 1" :class="[checkout.customization.enable_banner_cover == 1 ? 'mb-2 mb-4 banner' : 'mb-4 mt-3'] + ' seller_email'">Vendedor: {{
                store.title }} - {{ store.email }}</div>
            </div>
            <div :class="checkout.customization.enable_banner_right == 0 && checkout.customization.enable_summary == 0 && props.device != 'mobile' ? 'col-12 mt-md-0' : 'col-md-8 col-12 mt-md-0 mt-3'" :style="[checkout.customization.enable_banner_right == 0 && checkout.customization.enable_summary == 0 && props.device !== 'mobile' ? 'display: flex' : '']" @click.self="openDialogCustomization('checkout', true)">
                <div v-if="checkout.customization.enable_banner_content == 1" id="banner_content" class="customize_item" @click="openDialogCustomization('banner_content', true)">
                    <img v-if="banner_content_preview" :src="banner_content_preview" />
                    <img v-else-if="checkout.customization.banner_content" :src="checkout.customization.banner_content" />
                    <img v-else-if="checkout.product_demo.banner_content" :src="checkout.product_demo.banner_content" />
                    <span v-else></span>
                </div>
                <div id="registration_data" :class="[checkout.customization.enable_banner_right == 0 && checkout.customization.enable_summary == 0 && props.device != 'mobile' ? 'col-6' : ''] + ' box'">
                    <div class="form_checkout">
                        <div id="checkout_row">
                            <h2 class="form_heading">Dados Cadastrais</h2>
                            <div id="form_fields">
                                <div class="spacing">

                                    <div class="row" id="row_name">
                                        <div class="col-12">
                                            <label>Nome completo <span>*</span></label>
                                            <input autocomplete="off" placeholder="Digite seu nome completo" type="text"
                                                name="name" id="name" value="">
                                        </div>
                                    </div>

                                    <div class="row" id="row_email">
                                        <div class="col-12 col-md-6 mt-3">
                                            <label>E-mail <span>*</span></label>
                                            <input autocomplete="off" placeholder="Digite seu melhor e-mail" type="email"
                                                name="email" id="email" value="">
                                            <small class="email_sugestion"></small>

                                        </div>

                                        <div class="col-12 col-md-6 mt-3">
                                            <label>Celular com DDD <span>*</span></label>
                                            <input autocomplete="off" type="tel" name="phone" id="phone" value=""
                                                maxlength="15">
                                        </div>

                                    </div>


                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <label>CEP <span>*</span></label>
                                            <input autocomplete="off" type="tel" class="customize_item" name="postcode"
                                                id="postcode" value="" :placeholder="checkout.customization.postcode_placeholder" maxlength="9" @click="openDialogCustomization('postcode', true)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div id="shipping_methods">
                            <div class="divisor"></div>
                            <div class="spacing">
                                <h3 class="mt-0 mb-0">Escolha a opção de envio</h3>
                            </div>

                            <div class="shipping" id="shipping_method">
                                <h4 style="text-align: center;">Nenhuma opção de envio disponível</h4>
                            </div>
                            <input class="shipping_input" type="hidden" name="shipping_method" value="">
                        </div>
                    </div>

                </div>


                <div id="checkout_payment" :class="[checkout.customization.enable_banner_right == 0 && checkout.customization.enable_summary == 0 && props.device != 'mobile' ? 'col-6 ps-4' : 'mt-4']">
                <div class="box">
                    <div class="form_checkout">
                        <h2 class="form_heading">Pagamento</h2>
                        <div id="payment">
                            <ul id="payment_methods" class="customize_item" @click="openSubmenu('payment_methods', true)">
                                <li v-if="checkout.customization.credit_card_status == 1" :class="storeStore.checkout.customization.credit_card_ordenation == 1 ? 'active' : '' + ' credit_card_display'" data-crip-key="" data-id="151" data-type="credit-card">
                                    <div class="select_item">
                                        <div></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                        viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                        <path
                                            d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z">
                                        </path>
                                    </svg>
                                    Crédito
                                    <div v-if="storeStore.checkout.customization.credit_card_discount > 0 && storeStore.checkout.customization.credit_card_discount <= 100" class="checkout_payment_discount">{{ storeStore.checkout.customization.credit_card_discount }}% OFF</div>
                                </li>
                                <li v-if="checkout.customization.pix_status == 1" :class="storeStore.checkout.customization.pix_ordenation == 1 ? 'active' : '' + ' pix_display'" data-crip-key="" data-id="150" data-type="pix">
                                    <div class="select_item">
                                        <div></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                        viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                        <path
                                            d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z">
                                        </path>
                                    </svg> Pix
                                    <div v-if="storeStore.checkout.customization.pix_discount > 0 && storeStore.checkout.customization.pix_discount <= 100" class="checkout_payment_discount">{{ storeStore.checkout.customization.pix_discount }}% OFF</div>
                                </li>
                                <li v-if="checkout.customization.boleto_status == 1" :class="[storeStore.checkout.customization.boleto_ordenation == 1 ? 'active border_right ' : ''] + [storeStore.checkout.customization.boleto_ordenation == 2 ? ' border_right ' : ''] + ' boleto_display'" data-crip-key="" data-id="149" data-type="boleto">
                                    <div class="select_item">
                                        <div></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                        viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                        <path class="fa-primary"
                                            d="M64 56c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V192H64V56zm0 264h64V456c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24V320zm96 0h32V464c0 8.8-7.2 16-16 16s-16-7.2-16-16V320zm64 0h64V456c0 13.3-10.7 24-24 24H248c-13.3 0-24-10.7-24-24V320zm64-128H224V56c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V192zm32 128h64V456c0 13.3-10.7 24-24 24H344c-13.3 0-24-10.7-24-24V320zm64-128H320V56c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V192zm64 128h32V464c0 8.8-7.2 16-16 16s-16-7.2-16-16V320zm32-128H448V48c0-8.8 7.2-16 16-16s16 7.2 16 16V192zm32 128h64V456c0 13.3-10.7 24-24 24H536c-13.3 0-24-10.7-24-24V320zm64-128H512V56c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V192zm-384 0H160V48c0-8.8 7.2-16 16-16s16 7.2 16 16V192z">
                                        </path>
                                        <path class="fa-secondary"
                                            d="M0 256c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24z">
                                        </path>
                                    </svg> Boleto
                                    <div v-if="storeStore.checkout.customization.boleto_discount > 0 && storeStore.checkout.customization.boleto_discount <= 100" class="checkout_payment_discount">{{ storeStore.checkout.customization.boleto_discount }}% OFF</div>
                                </li>
                            </ul>

                            <div class="spacing">

                                <div class="payment_box" data-type="credit-card" style="display: block;">
                                    <div id="one_card">
                                        <div class="card_item" id="card_one">

                                            <div class="row">
                                                <div class="col-12 mt-3">
                                                    <label>Número do cartão <span>*</span></label>
                                                    <input autocomplete="off" placeholder="Digite somente números" type="tel"
                                                        name="one_card_number" id="one_card_number">
                                                </div>

                                                <div class="col-md-6 col-8 mt-3">
                                                    <label>Validade (MM/AA) <span>*</span></label>
                                                    <div class="row flex" id="one_card_expiry_date_group">
                                                        <div class="col-6">

                                                            <select name="one_card_expiry_month" id="one_card_expiry_month">
                                                                <option value="" style="display: none;">Mês</option>
                                                                <option value="01">01</option>
                                                                <option value="02">02</option>
                                                                <option value="03">03</option>
                                                                <option value="04">04</option>
                                                                <option value="05">05</option>
                                                                <option value="06">06</option>
                                                                <option value="07">07</option>
                                                                <option value="08">08</option>
                                                                <option value="09">09</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                            </select>
                                                        </div>

                                                        <div class="col-6">
                                                            <select name="one_card_expiry_year" id="one_card_expiry_year">
                                                                <option value="" style="display: none;">Ano</option>
                                                                <option value="23">23</option>
                                                                <option value="24">24</option>
                                                                <option value="25">25</option>
                                                                <option value="26">26</option>
                                                                <option value="27">27</option>
                                                                <option value="28">28</option>
                                                                <option value="29">29</option>
                                                                <option value="30">30</option>
                                                                <option value="31">31</option>
                                                                <option value="32">32</option>
                                                                <option value="33">33</option>
                                                                <option value="34">34</option>
                                                                <option value="35">35</option>
                                                                <option value="36">36</option>
                                                                <option value="37">37</option>
                                                                <option value="38">38</option>
                                                                <option value="39">39</option>
                                                                <option value="40">40</option>
                                                                <option value="41">41</option>
                                                                <option value="42">42</option>
                                                                <option value="43">43</option>
                                                            </select>
                                                        </div>

                                                        <input type="hidden" id="one_card_expiry">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-4 mt-3" id="cvv_column">
                                                    <label>CVV <span>*</span></label>
                                                    <input autocomplete="off" type="tel" name="one_card_cvc" id="one_card_cvc">
                                                </div>

                                                <div class="col-12 mt-3">
                                                    <label>Nome impresso no cartão <span>*</span></label>
                                                    <input autocomplete="off" placeholder="Seu nome impresso no cartão"
                                                        type="text" name="one_card_name" id="one_card_name">
                                                </div>

                                                <div class="col-12 mt-3">
                                                    <label>CPF do titular do cartão <span>*</span></label>
                                                    <input autocomplete="off" type="tel" name="cpf_credit_card"
                                                        id="cpf_credit_card" class="cpf" value=""
                                                        placeholder="Para emissão da nota fiscal" maxlength="14">
                                                </div>

                                                <div class=" col-12  mt-3">
                                                    <label>Parcelas <span>*</span></label>
                                                    <select name="one_card_installments" id="one_card_installments">
                                                        <option value="12" selected="">12x de {{ globalStore.formatMoney(checkout.product_demo.price / 12) }}</option>
                                                    </select>
                                                </div>
                                                <div v-if="storeStore.checkout.customization.pix_ordenation == 1 && storeStore.checkout.customization.pix_status == 1" class="pix_warn mt-4">
                                                    <h6>Pagamento via Pix: </h6>
                                                    <ul>
                                                        <li>Faça seu pagamento em poucos segundos sem custos adicionais</li>
                                                        <li>Para pagar basta abrir o aplicativo do seu banco, procurar pelo PIX e escanear o QRcode Seguro</li>
                                                        <li>O pagamento via PIX foi desenvolvido pelo Banco Central para facilitar suas compras</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div id="link_coupon">
                                    <a>Possui cupom de desconto?</a>
                                </div>
                                <div id="order_coupon" class="row">
                                    <div class="col-6 ps-0">
                                        <input 
                                            autocomplete="off" type="text" name="coupon"
                                            id="coupon" class="col-6" value=""
                                            placeholder="Código do cupom"
                                        >
                                    </div>
                                    <button @click="openDialogCustomization('coupon_code', true)" type="text" class="col-6 place_holder btn btn-default customize_item">
                                        <span>
                                            {{ checkout.customization.coupon_button_text }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="spacing mt-0">
                                <div id="order_dump" class="mb-4">
                                    <h3 class="mt-0 customize_item" @click="openDialogCustomization('order_bump_title', true)">{{
                                        checkout.customization.order_bump_title }}
                                    </h3>
                                    <ul>
                                        <li class="customize_item" @click.self="openDialogCustomization('order_bump', true)">
                                            <div class="row">
                                                <div style="width: auto;" class="col col-md-12 bump_image" @click="openDialogCustomization('order_bump', true)">
                                                    <img height="90" :src="checkout.product_demo.image">
                                                </div>
                                                <div class="col">
                                                    <label class="customize_item" @click="openDialogCustomization('order_bump_label', true)" for="product_bump_O78LZWdzHT93V6PVyXJD">
                                                        <input class="products_dump_input" type="checkbox" :checked="checkout.customization.order_bump_selected == 1 ? true : false">
                                                        <div class="select_box_item">
                                                            <font-awesome-icon class="check_icon" :icon="['fas', 'check']"/>
                                                        </div>
                                                        <div>{{ extractVariables(checkout.customization.order_bump_box_title) }} - Por apenas {{ globalStore.formatMoney(checkout.product_demo.price)}}</div>
                                                    </label>

                                                    <p @click="openDialogCustomization('order_bump', true)">{{ extractVariables(checkout.customization.order_bump_description) }}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <button type="text" class="place_holder btn_default customize_item"
                                    @click="openDialogCustomization('order_button', true)"><span>{{
                                        checkout.customization.order_button_text }}</span>
                                    <div class="loader"><strong></strong></div>
                                    <div class="progress"></div>
                                </button>

                                <div class="secure">
                                    <svg fill="#10933a" width="15" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490 490"
                                        style="enable-background:new 0 0 490 490;" xml:space="preserve">
                                        <g>
                                            <path
                                                d="M95.6,490h298.7c21.4,0,38.8-17.4,38.8-38.8V237.1c0-21.4-17.4-38.8-38.8-38.8h-1v-51.2C393.3,66,327.3,0,246.2,0S99.1,66,99.1,147.1v51.2h-3.4c-21.4,0-38.8,17.4-38.8,38.8v214.1C56.8,472.6,74.2,490,95.6,490z M163.4,147.1c0-45.6,37.1-82.8,82.8-82.8c45.6,0,82.8,37.1,82.8,82.8v51.2H163.4V147.1z">
                                            </path>
                                        </g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                    </svg>
                                    Pagamento 100% seguro, processado com criptografia 128 bits.
                                </div>

                                <div class="disclaimer_box">
                                    <b>Precisa de ajuda?</b> <a class="email_account" href="#">Entre em contato</a>
                                    <p class="mt">Estou ciente de que a Fullsale está processando este pedido a serviço de
                                        {{ account.name }} e concordo com os <a target="_blank"
                                            href="https://fullsale.com.br/termos-de-compra">Termos de Compra</a> e confirmo que
                                        li e entendi a <a target="_blank"
                                            href="https://fullsale.com.br/politica-de-privacidade">Política de Privacidade</a>.
                                    </p>
                                    <p class="mt">*OBS.: Taxa de 2,99% a.m.</p>
                                </div>

                            </div>


                        </div>
                    </div>
            </div>

                </div>
            </div>

            <div v-if="checkout.customization.enable_banner_right == 1 || checkout.customization.enable_summary == 1" class="col-12 col-md-4  mt-md-0 mt-3" @click.self="openDialogCustomization('checkout', true)">
                <div v-if="checkout.customization.enable_banner_right == 1" id="banner_right" class="customize_item" @click="openDialogCustomization('banner_right', true)">
                    <img v-if="banner_preview" :src="banner_preview" />
                    <img v-else-if="checkout.customization.banner" :src="checkout.customization.banner" />
                    <img v-else-if="checkout.product_demo.banner" :src="checkout.product_demo.banner" />
                    <span v-else></span>
                </div>

                <div id="checkout_summary" v-if="checkout.customization.enable_summary == 1">
                    <div class="box">
                        <div class="form_checkout">
                            <div >
                                <h2 class="form_heading">Resumo da compra</h2>

                                <div class="spacing pt-0">
                                    <div class="spacing">
                                        <div class="row">
                                            <div class="col-12 product_image">
                                                <img height="45px" :src="checkout.product_demo.image">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row divisor_bottom">
                                        <div class="col-8">
                                            {{ checkout.product_demo.title }}
                                        </div>
                                        <div class="col-4 align_right" id="checkout_shipping_cost">{{ globalStore.formatMoney(checkout.product_demo.price) }}</div>
                                    </div>

                                    <div class="row green_text divisor_bottom" id="checkout_discount_container" >
                                        <div class="col">
                                            Desconto
                                        </div>
                                            <div class="col align_right" id="checkout_discount_amount">- R$&nbsp;0,00</div>
                                    </div>
                                    <div class="row green_text pb-0 divisor_bottom">
                                        <div class="col">
                                            Total
                                        </div>
                                        <div class="col align_right" id="checkout_total">12x de {{ globalStore.formatMoney(checkout.product_demo.price / 12) }}</div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="checkout.customization.enable_notify == 1" id="popup_orders">
                <div id="popup_orders_close"><svg width="12px" height="12px" viewBox="0 0 329.26933 329"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0">
                        </path>
                    </svg></div>
                <div id="popup_orders_image"><img :src="checkout.product_demo.image"></div>
                <div id="popup_orders_text">Aline de Curitiba comprou<strong>{{ checkout.product_demo.title }}</strong>
                    <span>Poucos minutos atrás</span>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
    <BaseDragDrop :initialTop="'160px'" :startRight="true" :dragFromHeader="true">
        <template v-slot:header="{ onmousedown }">
            <div id="menuCustomHeader" class="menu_custom_header box_content"  @mousedown="onmousedown">
                <div class=" m-3">
                    <font-awesome-icon v-if="customization_type == 'home'" icon="fa-solid fa-table-layout" style="color: #215eda;" class="me-1 customization_icon"/>
                    <font-awesome-icon class="back_arrow me-1" v-else icon="fa-solid fa-arrow-left" style="color: #215eda;"  @click="customizationPrevious()"/>
                    <h6 v-if="customization_type == 'home'" class="ms-2">Personalização</h6>
                    <h6 v-else-if="customization_type == 'submenu'">{{ props.menuTitles[submenu_selected] }}</h6>
                    <h6 v-else>{{ props.menuTitles[customization_type] }}</h6>
                    <div class="minimize_icon" @click="emitMinimizeMenu"><font-awesome-icon :icon="['fas', 'bars']" /></div>
                </div>
            </div>
        </template>
        <template v-slot:content>
            <div id="menuCustomBox" class="menu_custom box_content">
                <div class="menu_list" v-if="customization_type == 'home'">
                    <ul class="main_nav">
                        <li class="nav_item" @click="openDialogCustomization('checkout', false)">Geral</li>
                        <li class="nav_item" @click="openSubmenu('timer')">Timer</li>
                        <li class="nav_item" @click="openSubmenu('header')">Header</li>
                        <li class="nav_item" @click="openDialogCustomization([props.device == 'desktop' ? 'cover_desktop' : 'cover_mobile'], false)">Banner</li>
                        <li class="nav_item" @click="openDialogCustomization('banner_content', false)">Banner formulário</li>
                        <li class="nav_item" @click="openDialogCustomization('banner_right', false)">Banner lateral</li>
                        <li class="nav_item" @click="openSubmenu('form')">Formulário</li>
                        <li class="nav_item" @click="openSubmenu('payment')">Pagamento</li>
                        <li class="nav_item" @click="openSubmenu('coupon')">Cupom</li>
                        <li class="nav_item" @click="openSubmenu('order_bump_sub')">Order bump</li>
                        <li class="nav_item" @click="openSubmenu('exit_intent_popup')">Exit-intent popup</li>
                        <li class="nav_item" @click="openDialogCustomization('back_redirect')">Back Redirect</li>
                    </ul>
                </div>
                <div class="menu_list" v-if="customization_type == 'submenu'">
                        <ul class="main_nav">
                            <li v-for="config in subMenuComponents[submenu_selected]" :class="!config.inputComponent ? 'nav_item' : 'customization_config'" v :key="config.id" @click="config.inputComponent ? '' : config.dialog_customization ? openDialogCustomization(config.dialog_customization, false) : openSubmenu(config.dialog_submenu)">
                                {{ config.title }}
                                <div v-if="config.inputComponent" class="customization_config px-3" >
                                    <div  :key="config.id" style='display: block;'>
                                        <component
                                            class="col"
                                            v-if="!config.display_if || (config.display_if && config.display_if.split(':')[1] == customization.customization[config.display_if.split(':')[0]])"
                                            :is="config.component" :label="config.label"
                                            v-model="checkout.customization[config.model]"
                                            v-model:checked="checkout.customization[config.model]"
                                            :id="config.id"
                                            :tooltip="config.tooltip"
                                            :format="config.format"
                                            :type="config.type"
                                            :min="config.min"
                                            :max="config.max"
                                            :accept="config.accept"
                                            v-bind="config.props"
                                            @base64="config.onBase64"
                                            :showBadget="config.showBadget"
                                            :badgetColor= "config.badgetColor"
                                            :badgetText="config.badgetText"
                                            :tag="config.tag"
                                        />
                                        <p class="info" v-if="config.id === 'cover_desktop' || config.id === 'cover_mobile' || config.id === 'banner' || config.id === 'banner_content'">* Ao selecionar um banner, ele substituirá a imagem cadastrada no produto.</p>
                                    </div>
                                </div>
                            </li>
                        <div class="me-2 ms-auto open_exit_intent" v-if="submenu_selected == 'exit_intent_popup'">
                            <BaseButton  @click="openExitPopup" class="btn btn-secondary" id="btn_show_exit_popup" style="margin-top: 10px; margin-bottom: 10px;">Abrir</baseButton>
                        </div>
                    </ul>
                </div>
                <div class="customization_config px-3" v-else>
                    <div v-for="config in customizationComponents[customization_type]" :key="config.id" style='display: block;'>
                        <component
                            class="col"
                            v-if="!config.display_if || (config.display_if && config.display_if.split(':')[1] == customization.customization[config.display_if.split(':')[0]])"
                            :is="config.component" :label="config.label"
                            v-model="checkout.customization[config.model]"
                            v-model:checked="checkout.customization[config.model]"
                            :id="config.id"
                            :tooltip="config.tooltip"
                            :format="config.format"
                            :type="config.type"
                            :min="config.min"
                            :max="config.max"
                            :accept="config.accept"
                            v-bind="config.props"
                            @base64="config.onBase64"
                            :showBadget="config.showBadget"
                            :badgetColor= "config.badgetColor"
                            :badgetText="config.badgetText"
                            :tag="config.tag"
                        />
                        <p class="info" v-if="config.id === 'cover_desktop' || config.id === 'cover_mobile' || config.id === 'banner' || config.id === 'banner_content'">* Ao selecionar um banner, ele substituirá a imagem cadastrada no produto.</p>
                    </div>
                </div>
            </div>
        </template> 
    </BaseDragDrop>
</template>

<script setup>
import { useStoreStore } from '@/stores/store';
import { storeToRefs } from 'pinia';
import { ref, onMounted, watch } from 'vue';
import { useGlobalStore } from "@/stores/global";
import { useAccountStore } from '@/stores/account';

const props = defineProps({
    device: {
        required: false,
        default: 'desktop'
    },
    previewObj: {
        required: true,
        default: null,
    },
    menuTitles: {
        required: true,
        default: null,
    }
});

const globalStore = useGlobalStore();
const accountStore = useAccountStore();
const storeStore = useStoreStore();
const { store, checkout } = storeToRefs(storeStore);
const { account } = storeToRefs(accountStore)

const timerMinutes = ref('00');
const timerHours = ref('00');

const emit = defineEmits(['emitPreviewFile', 'emitMinimizeMenu']);

const customization_type = ref('home');
const submenu_selected = ref(null);
const previous_menu_selected = ref(null)
const open_exit_popup = ref(false);

onMounted(() =>{
    if(storeStore.checkout.customization.timer_time){
        addToTimer();
    }
    watch(storeStore.checkout.customization ,function () {
        addToTimer()
    })

    if(props.previewObj !== null){
        for(let key in props.previewObj){
            if(key === 'cover_desktop_preview'){
                cover_desktop_preview.value = props.previewObj.cover_desktop_preview
            } else if(key === 'cover_mobile_preview'){
                cover_mobile_preview.value = props.previewObj.cover_mobile_preview;
            } else if(key === 'banner_preview'){
                banner_preview.value = props.previewObj.banner_preview;
            } else if(key === 'banner_content_preview'){
                banner_content_preview.value = props.previewObj.banner_content_preview;
            } else if(key === 'header_logotipo_preview'){
                header_logotipo_preview.value = props.previewObj.header_logotipo_preview;
            } 
        }
    }
});

const customizationComponents = {
    cover_desktop: [
        {
            component: 'BaseFileUpload',
            label: 'Banner superior',
            model: 'cover_desktop',
            tooltip: 'Desktop',
            id: 'cover_desktop',
            showBadget: true,
            badgetColor: "#6f6f6f", 
            badgetText: "Desktop",
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: coverDesktop,
            props: {
                short: true,
            }
        },
    ],
    cover_mobile:[
        {
            component: 'BaseFileUpload',
            label: 'Banner superior',
            model: 'cover_mobile',
            id: 'cover_mobile',
            showBadget: true ,
            badgetColor: "#6f6f6f", 
            badgetText: "Mobile",
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: coverMobile,
            props: {
                short: true,
            }
        },
    ],
    banner_right:[
        {
            component: 'BaseFileUpload',
            label: 'Banner lateral',
            model: 'banner',
            id: 'banner',
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: bannerRight,
            props: {
                short: true,
            }
        },
    ],
    banner_content:[
        {
            component: 'BaseFileUpload',
            label: 'Banner do formulário',
            model: 'banner_content',
            id: 'banner_content_input',
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: bannerContent,
            props: {
                short: true,
            }
        },
    ],
    checkout:[
        {
            component: 'BaseFileUpload',
            label: 'Imagem de fundo',
            model: 'background_image',
            id: 'background_image',
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: backgroundImage,
            props: {
                short: true,
            }
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'background_color',
            id: 'background_color',
        },
        {
            component: 'BaseSwitch',
            label: 'Banner superior',
            tooltip: 'Exibe o banner superior',
            model: 'enable_banner_cover',
            id: 'enable_banner_cover',
        },
        {
            component: 'BaseSwitch',
            label: 'Banner Lateral',
            tooltip: 'Exibe o banner lateral',
            model: 'enable_banner_right',
            id: 'enable_banner_right',
        },
        {
            component: 'BaseSwitch',
            label: 'Banner do formulário',
            tooltip: 'Exibe banner do formulário',
            model: 'enable_banner_content',
            id: 'enable_banner_content',
        },
        {
            component: 'BaseSwitch',
            label: 'Resumo da compra',
            model: 'enable_summary',
            id: 'enable_summary',
            tooltip: 'Exibe o resumo da compra no checkout contendo informações do produto',
        },
        {
            component: 'BaseSwitch',
            label: 'Timer de Escassez',
            model: 'enable_timer',
            id: 'enable_timer',
            tooltip: 'Exibe temporizador de escassez do produto no topo do checkout',
        },
        {
            component: 'BaseSwitch',
            label: 'Cabeçalho',
            tooltip: 'Exibe o cabeçalho',
            model: 'header_logotipo',
            id: 'header_logotipo',
        },
        {
            component: 'BaseSwitch',
            label: 'Notificação de Escassez',
            model: 'enable_notify',
            id: 'enable_notify',
            tooltip: 'Exibe notificações de pedidos recentes',
        },
        {
            component: 'BaseSwitch',
            label: 'Contato da loja',
            model: 'show_email_support_on_checkout',
            id: 'show_email_support_on_checkout',
        },
    ],
    logotipo: [
        {
            component: 'BaseFileUpload',
            label: 'Logotipo',
            model: 'logotipo',
            id: 'header_logotipo_preview',
            accept: 'jpg, .jpeg, .png, .gif, .bmp, .webp',
            onBase64: logotipoHeader,
            props: {
                short: true,
            }
        },
        {
            component: 'BaseInputRange',
            label: 'Tamanho',
            model: 'logotipo_size',
            type: 'range',
            min: '100',
            max: '400',
            format: 'px',
            id: 'logotipo_size',
        },
    ],
    enable_header_contact_info:[
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto e ícone',
            model: 'header_contact_color',
            id: 'header_contact_color',
        },
    ],
    header_secure:[
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto e ícone',
            model: 'header_secure_color',
            id: 'header_secure_color',
        },
    ],
    timer_numbers:[
        {
            component: 'BaseInput',
            label: 'Duração em minutos',
            model: 'timer_time',
            id: 'timer_time'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'timer_numbers_background_color',
            id: 'timer_numbers_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor dos números',
            model: 'timer_numbers_text_color',
            id: 'timer_numbers_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor da legenda',
            model: 'timer_numbers_legend_color',
            id: 'timer_numbers_legend_color',
        },
    ],
    timer_text:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'timer_text',
            id: 'timer_text'
        },        
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'timer_text_color',
            id: 'timer_text_color',
        },
    ],
    timer_button:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'timer_text_button',
            id: 'timer_text_button'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'timer_button_color',
            id: 'timer_button_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'timer_text_button_color',
            id: 'timer_text_button_color',
        },
    ],
    coupon_code:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'coupon_button_text',
            id: 'coupon_button_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'coupon_button_text_color',
            id: 'coupon_button_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'coupon_button_background_color',
            id: 'coupon_button_background_color',
        },
    ],
    coupon_code_hover:[
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto ao passar o mouse',
            model: 'coupon_button_text_hover_color',
            id: 'coupon_button_text_hover_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão ao passar o mouse',
            model: 'coupon_button_background_hover_color',
            id: 'coupon_button_background_hover_color',
        },
    ],
    order_bump_title:[
        {
            component: 'BaseInput',
            label: 'Título',
            model: 'order_bump_title',
            id: 'order_bump_title'
        },
    ],
    order_bump:[
        {
            component: 'BaseInput',
            label: 'Descrição',
            model: 'order_bump_description',
            id: 'order_bump_description'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'order_bump_background_color',
            id: 'order_bump_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'order_bump_text_color',
            id: 'order_bump_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor da borda',
            model: 'order_bump_border_color',
            id: 'order_bump_border_color',
        },   
    ],
    order_bump_label:[
        {
            component: 'BaseInput',
            label: 'Título',
            model: 'order_bump_box_title',
            id: 'order_bump_box_title'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'order_bump_label_background_color',
            id: 'order_bump_label_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'order_bump_label_text_color',
            id: 'order_bump_label_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor da sombra',
            model: 'order_bump_label_shadow_color',
            id: 'order_bump_label_shadow_color',
        },
        {
            component: 'BaseSwitch',
            label: 'Order bump selecionado',
            tooltip: 'Define o order bump selecionado como padrão no checkout',
            model: 'order_bump_selected',
            id: 'order_bump_selected',
        },
    ],
    order_button:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'order_button_text',
            id: 'order_button_text'
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'order_button_background_color',
            id: 'order_button_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão ao passar o mouse',
            model: 'order_button_background_hover_color',
            id: 'order_button_background_hover_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'order_button_text_color',
            id: 'order_button_text_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto ao passar o mouse',
            model: 'order_button_text_hover_color',
            id: 'order_button_text_hover_color',
        },
    ],
    popup_orders:[
        {
            component: 'BaseSelect',
            label: 'Formato',
            model: 'popup_orders_type',
            id: 'popup_orders_type',
            props: {
                items: {
                    'time': 'Compras baseado em período',
                    'customer': 'Compradores recentes'
                },
            }
        },
        {
            component: 'BaseSelect',
            label: 'Período',
            model: 'popup_orders_periods',
            id: 'popup_orders_periods',
            props: {
                items: {
                    'now': 'Neste momento',
                    'last_30_min': 'Nos últimos 30 minutos',
                    'last_hour': 'Na última hora',
                    'day': 'Hoje',
                    'week': 'Esta semana',
                },
            },
            display_if: 'popup_orders_type:time',
        },
        {
            component: 'BaseInput',
            label: 'Quantidade mínima',
            model: 'popup_orders_qty_min',
            id: 'popup_orders_qty_min',
            display_if: 'popup_orders_type:time',
        },
    ],
    credit_card_method:[
        {
            component: 'BaseInput',
            label: 'Desconto cartão de crédito',
            model: 'credit_card_discount',
            id: 'credit_card_discount',
            tag: '%',
        },
        {
            component: 'BaseSelect',
            label: 'Status',
            model:  'credit_card_status',
            id: 'credit_card_status',
            props: {
                items: {
                    0: 'Desabilitado',
                    1: 'Habilitado'
                },
            }
        },
        {
            component: 'BaseSelect',
            label: 'Ordem no checkout',
            model: 'credit_card_ordenation',
            id: 'credit_card_ordenation',
            props: {
                items: {
                    1: '1°',
                    2: '2º',
                    3: '3º',
                },
            }
        },
    ],
    pix_method:[
        {
            component: 'BaseInput',
            label: 'Desconto pix',
            model: 'pix_discount',
            id: 'pix_discount',
            tag: '%',
        },
        {
            component: 'BaseSelect',
            label: 'Status',
            model: 'pix_status',
            id: 'pix_status',
            props: {
                items: {
                    0: 'Desabilitado',
                    1: 'Habilitado'
                },
            }
        },
        {
            component: 'BaseSelect',
            label: 'Ordem no checkout',
            model: 'pix_ordenation',
            id: 'pix_ordenation',
            props: {
                items: {
                    1: '1°',
                    2: '2º',
                    3: '3º',
                },
            }
        },
    ],
    boleto_method:[
        {
            component: 'BaseInput',
            label: 'Desconto boleto',
            model: 'boleto_discount',
            id: 'boleto_discount',
            tag: '%',
        },
        {
            component: 'BaseSelect',
            label: 'Status',
            model: 'boleto_status',
            id: 'boleto_status',
            props: {
                items: {
                    0: 'Desabilitado',
                    1: 'Habilitado'
                },
            }
        },
        {
            component: 'BaseSelect',
            label: 'Ordem no checkout',
            model: 'boleto_ordenation',
            id: 'boleto_ordenation',
            props: {
                items: {
                    1: '1°',
                    2: '2º',
                    3: '3º',
                },
            }
        },
    ],
    exit_popup_button:[
        {
            component: 'BaseInput',
            label: 'Texto',
            model: 'exit_popup_button_text',
            id: 'exit_popup_button_text',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do botão',
            model: 'exit_popup_button_background_color',
            id: 'exit_popup_button_background_color',
        },
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'exit_popup_button_text_color',
            id: 'exit_popup_button_text_color',
        },
        
    ],
    exit_popup_discount:[
        {
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'exit_popup_discount_text_color',
            id: 'exit_popup_discount_text_color',
        },
    ],
    postcode:[
        {
            component: 'BaseInput',
            label: 'Placeholder',
            model: 'postcode_placeholder',
            id: 'postcode_placeholder',
        },
    ],
    back_redirect:[
        {
            component: 'BaseInput',
            label: 'URL',
            model: 'back_redirect_url',
            id: 'back_redirect_url',
        },    
        {
            component: 'BaseSwitch',
            label: 'Ativar back redirect',
            model: 'back_redirect_enable',
            id: 'back_redirect_enable',
        }, 
    ],  
}

const subMenuComponents = {
    timer:[
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'timer_background_color',
            id: 'timer_background_color',
        },
        {
            inputComponent: true,
            component: 'BaseSwitch',
            label: 'Botão de compra',
            model: 'enable_timer_sell_button',
            id: 'enable_timer_sell_button',
        },
        {
            title: 'Texto',
            dialog_customization: 'timer_text'
        },
        {
            title: 'Tempo',
            dialog_customization: 'timer_numbers',
        },
        {
            title: 'Botão de compre agora',
            dialog_customization: 'timer_button',
        }
    ],
    header:[
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'header_background_color',
            id: 'header_background_color',
        },
        {
            inputComponent: true,
            component: 'BaseSwitch',
            label: 'Contato da loja',
            model: 'show_email_support_on_header',
            id: 'show_email_support_on_header',
        },
        {
            title: 'Logotipo',
            dialog_customization: 'logotipo',
        },
        {
            title: 'Contato',
            dialog_customization: 'enable_header_contact_info',
        },
        {
            title: 'Ícone segurança',
            dialog_customization: 'header_secure',
        },
    ],
    coupon:[
        {
            title: 'Botão',
            dialog_customization: 'coupon_code',
        },
        {
            title: 'Botão ao passar o mouse',
            dialog_customization: 'coupon_code_hover',
        }
    ],
    order_bump_sub:[
        {
            title: 'Título',
            dialog_customization: 'order_bump_title',
        },
        {
            title: 'Rótulo',
            dialog_customization: 'order_bump_label',
        },
        {
            title: 'Descrição',
            dialog_customization: 'order_bump',
        },
    ],
    payment:[
        {
            title: 'Formas de pagamento',
            dialog_submenu: 'payment_methods',
        },
        {
            title: 'Botão de compra',
            dialog_customization: 'order_button',
        },
    ],
    payment_methods:[
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor de seleção',
            model: 'payment_methods_selected_color',
            id: 'payment_methods_selected_color',
        },
        {
            title: 'Cartão de crédito',
            dialog_customization: 'credit_card_method',
        },
        {
            title: 'Pix',
            dialog_customization: 'pix_method',
        },
        {
            title: 'Boleto',
            dialog_customization: 'boleto_method',
        },
    ],
    form:[
        {
            title: 'Placeholder CEP',
            dialog_customization: 'postcode',
        },
    ],
    exit_intent_popup:[
        {   
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor do fundo',
            model: 'exit_popup_background_color',
            id: 'exit_popup_background_color',
        },
        {
            inputComponent: true,
            component: 'BaseColorPicker',
            label: 'Cor do texto',
            model: 'exit_popup_text_color',
            id: 'exit_popup_text_color',
        },
        {
            inputComponent: true,
            component: 'BaseInput',
            label: 'Cupom',
            model: 'exit_popup_coupon',
            id: 'exit_popup_coupon',
        },
        {
            inputComponent: true,
            component: 'BaseSwitch',
            label: 'Habilitar exit-intent popup',
            model: 'enable_exit_popup',
            id: 'enable_exit_popup',
        },
        {
            title: 'Cor do texto de desconto',
            dialog_customization: 'exit_popup_discount',
        },
        {
            title: 'Botão',
            dialog_customization: 'exit_popup_button',
        }
    ],
}

function addZero(r) {
    return (Number(r) < 10) ? '0' + r  : r;
}

function addToTimer(){
    if(storeStore.checkout.customization.timer_time > 59){
        let getValueInHours = storeStore.checkout.customization.timer_time / 60;
        if(Number.isInteger(getValueInHours)){
            timerHours.value = addZero(getValueInHours)
            timerMinutes.value = '00'
        }else{
            let splitHour = getValueInHours.toString().split('.');
            
            timerHours.value = addZero(splitHour[0]);
            timerMinutes.value =  addZero(Math.round((getValueInHours - splitHour[0]) * 60));
        }
    } else if(storeStore.checkout.customization.timer_time <= 59) {
        timerHours.value = '00';
        timerMinutes.value = addZero(storeStore.checkout.customization.timer_time);
    }
}


function openDialogCustomization(type, layout_click) {
    if(layout_click === true){
        previous_menu_selected.value = 'home';
        let menuHeader = document.getElementById('menuCustomHeader');
        let menuList = document.getElementById('menuCustomBox');
        if(menuList.classList.contains('minimized') || menuHeader.classList.contains('minimized')){
            menuHeader.classList.remove('minimized');
            menuHeader.style.marginLeft = 0;
            menuList.classList.remove('minimized');
        } 
    } else if(type != null){
        previous_menu_selected.value = customization_type.value
    }else{
        previous_menu_selected.value = 'home'
    }
    customization_type.value = type;
}

function openSubmenu(type) {
    previous_menu_selected.value = 'home';
    customization_type.value = 'submenu';
    submenu_selected.value = type
}

function customizationPrevious() {
    if(customization_type.value == 'submenu' && previous_menu_selected.value != 'home'){
        customization_type.value = 'home';
        previous_menu_selected.value = customization_type.value;
    }else if (previous_menu_selected.value == customization_type.value){
        customization_type.value = 'home';
        previous_menu_selected.value = customization_type.value;
    } else {
        customization_type.value = previous_menu_selected.value;
        previous_menu_selected.value = customization_type.value;
    }
}

function openExitPopup() {
    open_exit_popup.value = true;
}

function closeExitPopup(){
    open_exit_popup.value = false;
}


const background_image_preview = ref(null);
function backgroundImage(value) {
    background_image_preview.value = value;
}

const cover_desktop_preview = ref(null);
function coverDesktop(value) {
    cover_desktop_preview.value = value;
    let valuesObj = {
        'cover_desktop_preview': cover_desktop_preview.value,
    }
    emit('emitPreviewFile', valuesObj)
}

const cover_mobile_preview = ref(null);
function coverMobile(value) {
    cover_mobile_preview.value = value;
    let valuesObj = {
        'cover_mobile_preview': cover_mobile_preview.value,
    }
    emit('emitPreviewFile', valuesObj)
}

const banner_preview = ref(null);
function bannerRight(value) {
    banner_preview.value = value;
    let valuesObj = {
        'banner_preview': banner_preview.value,
    }
    emit('emitPreviewFile', valuesObj)
}

const banner_content_preview = ref(null);
function bannerContent(value) {
    banner_content_preview.value = value;
    let valuesObj = {
        'banner_content_preview': banner_content_preview.value,
    }
    emit('emitPreviewFile', valuesObj)
}
const previousOrdenations = ref([storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation])

watch(() => [storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation] , (e) => {
    for(let i = 0; i < e.length; i++){
        if(e[i] != previousOrdenations.value[i]){
            for(let index = 0; index < previousOrdenations.value.length;index++){
                if(previousOrdenations.value[index] == e[index]){
                    if(e[i] == previousOrdenations.value[index]){
                        if(index == 0){
                            storeStore.checkout.customization.credit_card_ordenation = previousOrdenations.value[i];
                            previousOrdenations.value = [storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation]
                            return;
                        } else if(index == 1){
                            storeStore.checkout.customization.pix_ordenation = previousOrdenations.value[i];
                            previousOrdenations.value = [storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation]
                            return;
                        } else if(index == 2){
                            storeStore.checkout.customization.boleto_ordenation = previousOrdenations.value[i];
                            previousOrdenations.value = [storeStore.checkout.customization.credit_card_ordenation, storeStore.checkout.customization.pix_ordenation, storeStore.checkout.customization.boleto_ordenation]
                            return;
                        }
                    }
                }
            }
        }
    }
});

const header_logotipo_preview = ref(null);
function logotipoHeader(value){
    header_logotipo_preview.value = value;
    let valuesObj = {
        'header_logotipo_preview': header_logotipo_preview.value,
    }
    emit('emitPreviewFile', valuesObj)
}

var index_variables = 0;
function extractVariables(string){
    if (storeStore.checkout.id) {
        if(string == null || string == undefined){
            if(index_variables == 0){
                string = '{produto:titulo}'
                string = string.replace('{produto:titulo}', storeStore.checkout.product_demo.title);
                index_variables++
            } else if(index_variables == 1){
                string = '{produto:descricao}'
                string = string.replace('{produto:descricao}', storeStore.checkout.product_demo.description );
                index_variables++
            } 
            return;
        }
        string = string.replace('{produto:titulo}', storeStore.checkout.product_demo.title);
        string = string.replace('{produto:descricao}', storeStore.checkout.product_demo.description);
    }

    return string;
}

function emitMinimizeMenu(){
    emit('emitMinimizeMenu');
}

</script>

<style scoped src="@/assets/css/checkouts/v1/checkout_v1.css"></style>
<style scoped src="@/assets/css/checkouts/v1/checkout_v1_mobile.css"></style>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700;900&display=swap");

.mobile #countdown_wrapper {
    margin-bottom: 0;
    max-width: 500px !important;
}

.form_heading{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    background: #f7f7f7;
    padding: 10px;
    border-bottom: 1px #dedede solid;
    border-radius: 8px 8px 0px 0px;
}

.container {
    background-color: #fff;
    border: 1px #ccc solid;
    border-radius: 8px;
    padding: 0;
    padding-bottom: 40px;
    font-family: 'Source Sans Pro';
}

#checkout {
    max-width: 1000px;
    margin: 0 auto;
}

.form-control {
    border: 0;
}

.product_image {
    max-width: 200px;
    width: auto !important;
    margin: 0 auto;
    display: block;
    border: none;
}

.product_image img {
    height: 45px;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

#header {
    padding: 10px 15px;
    background-color: v-bind('checkout.customization.header_background_color');
}

#header .row {
    max-width: 1000px;
    margin: 0 auto;
    align-items: center;
}

#checkout header {
    background-color: #d2d2d2;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0;
}

#checkout header span {
    min-height: 180px;
    display: block;
}

#header{
    background-color: v-bind('checkout.customization.header_background_color');
}

#logotipo {
    width: v-bind(checkout.customization.logotipo_size + 'px');
}

#header_contact_info, .header_contact  svg{
    color: v-bind('checkout.customization.header_contact_color');
    fill: v-bind('checkout.customization.header_contact_color')!important;
}

#header_secure_info{
	line-height: 14px!important;
    margin-top: 0;
    display: flex;
    text-align: left;
}


#header_secure_info, #header_secure_info svg{
    color: v-bind('checkout.customization.header_secure_color');
    fill: v-bind('checkout.customization.header_secure_color')!important;
}


#secure_summary{
    text-align: end;
    margin-bottom: 15px;
}
.form_checkout label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600 !important;
}

.bump_image span {
    background-color: #000000;
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 4px
}

.box {
    margin-top: 15px
}

#popup_orders {
    position: absolute;
    right: 20px;
    bottom: 20px;
    opacity: 1;
}
.container {
    background-color: v-bind('checkout.customization.background_color');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

#countdown_wrapper {
    background-color: v-bind('checkout.customization.timer_background_color');
}

#countdown_wrapper h5 {
    color: v-bind('checkout.customization.timer_text_color');
}

#countdown_wrapper span {
    color: v-bind('checkout.customization.timer_numbers_legend_color');
}

#countdown_wrapper #countdown strong {
    background-color: v-bind('checkout.customization.timer_numbers_background_color');
    color: v-bind('checkout.customization.timer_numbers_text_color');
}

#countdown_wrapper .btn {
    background-color: v-bind('checkout.customization.timer_button_color');
    color: v-bind('checkout.customization.timer_text_button_color');
}

#exit_popup .content,
#exit_popup .content .percent {
    background-color: v-bind('checkout.customization.exit_popup_background_color');
    color: v-bind('checkout.customization.exit_popup_text_color');
}

#exit_popup .content button {
    background-color: v-bind('checkout.customization.exit_popup_button_background_color');
    color: v-bind('checkout.customization.exit_popup_button_text_color');
}

#exit_popup .content .amount {
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
    color: v-bind('checkout.customization.exit_popup_discount_text_color');
}

#registration_data{
    height: fit-content;
}

#order_coupon{
    margin-right: 0px!important;
    margin-left: 0px!important;
}

#order_coupon button{
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    color: v-bind('checkout.customization.coupon_button_text_color');
    background-color: v-bind('checkout.customization.coupon_button_background_color');
}

#order_coupon button:hover{
    color: v-bind('checkout.customization.coupon_button_text_hover_color');
    background-color: v-bind('checkout.customization.coupon_button_background_hover_color');
}

#order_dump ul li {
    background-color: v-bind('checkout.customization.order_bump_background_color');
    border-color: v-bind('checkout.customization.order_bump_border_color');
}

#order_dump ul li p {
    color: v-bind('checkout.customization.order_bump_text_color');
}

#order_dump ul li label {
    background-color: v-bind('checkout.customization.order_bump_label_background_color');
    color: v-bind('checkout.customization.order_bump_label_text_color');
    box-shadow: 2px 3px 0 v-bind('checkout.customization.order_bump_label_shadow_color');
}

#order_dump ul li div {
    color: v-bind('checkout.customization.order_bump_label_text_color');
}
.btn_default {
    background-color: v-bind('checkout.customization.order_button_background_color');
    color: v-bind('checkout.customization.order_button_text_color');
}

.btn_default:hover{
    background-color: v-bind('checkout.customization.order_button_background_hover_color');
    color: v-bind('checkout.customization.order_button_text_hover_color');
}

#payment_methods li.active {
    color: v-bind('checkout.customization.payment_methods_selected_color');
    box-shadow: inset 0px 4px 0px -1px v-bind('checkout.customization.payment_methods_selected_color');
}

#payment_methods li.active svg {
    fill: v-bind('checkout.customization.payment_methods_selected_color');
}


#btn_show_exit_popup {
    height: 30px;
    padding: 4px;
    top: 2px;
    position: relative;
}

#exit_popup {
    z-index: 4;
    position: absolute;
}
#exit_popup .content {
    position: fixed;
    margin: -100px -210px;
}

.seller_email {
    margin-bottom: 0;
}

#checkout_products {
	font-size: 15px;
    margin-bottom: 30px;
    border: 1px #ccc solid;
    border-radius: 6px;
}

#checkout_products .product_image {
	flex: 0;
	padding-right: 0;
}

#checkout_total {
	font-weight: 600;
}

.divisor {
    border-top: 1px #ccc solid;
}

.bump_image {
	text-align: center;
}

#banner_right img {
    width: 100%;
}

#banner_right span {
    background-color: #d2d2d2;
    min-height: 120px;
    display: block;
}

#banner_content img {
    width: 100%;
}

#banner_content span {
    background-color: #d2d2d2;
    min-height: 120px;
    display: block;
}

#header{
    background-color: v-bind('checkout.customization.header_background_color');
}

#header_contact_info, .seller_email  svg{
    color: v-bind('checkout.customization.header_contact_color');
    fill: v-bind('checkout.customization.header_contact_color')!important;
}

#header_secure_info, #header_secure_info svg{
    color: v-bind('checkout.customization.header_secure_color');
    fill: v-bind('checkout.customization.header_secure_color')!important;
}

#logotipo {
    width: v-bind(checkout.customization.logotipo_size + 'px');
}
.secure{
    margin-top: 0;
}

</style>