<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar>
            <div v-if="accountStore.account.is_editable" class="item action btn-group">
                <baseButton @click="submit" class="btn-primary w-100"> Salvar </baseButton>
            </div>
        </BaseHeader>
        <div class="box_content">
            <nav>
                <div class="nav" id="nav-tab" role="tablist">
                    <router-link to="/account" :class="[route.path === '/account' ? 'active' : '']">
                        Dados cadastrais
                    </router-link>
                    <router-link to="/account/address" :class="[route.path === '/account/address' ? 'active' : '']">
                        Endereço
                    </router-link>
                    <router-link to="/account/bank-info" :class="[route.path === '/account/bank-info' ? 'active' : '']">
                        Dados bancários
                    </router-link>
                    <router-link to="/account/documents" :class="[route.path === '/account/documents' ? 'active' : '']">
                        Documentos
                    </router-link>
                    <router-link v-if="accountStore.account.status.slug === 'validated'" to="/account/taxes" :class="[route.path === '/account/taxes' ? 'active' : '']">
                        Taxas e prazos
                    </router-link>
                    <router-link v-if="accountStore.account.status.slug === 'validated'" to="/account/api" :class="[route.path === '/account/api' ? 'active' : '']">
                        API
                    </router-link>
                </div>
            </nav>
        </div>
        <div class="alert alert-warning mb-4" role="alert" v-if="accountStore.account.message">{{ accountStore.account.message }}</div>
        <router-view></router-view>
    </div>
</template>
<script setup>
const title = { title: "Minha conta ", icon: "fa-user" };
import { onBeforeMount } from "vue";

import { useRoute } from "vue-router";
const route = useRoute();

import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

import { useAccountStore } from '@/stores/account'
const accountStore = useAccountStore();

import { useGlobalStore } from '@/stores/global'
const globalStore = useGlobalStore();


onBeforeMount(() => {
    globalStore.loader('show')
    userStore.tryUser();
    accountStore.tryAccount().then(() => {
        globalStore.loader('hide')
    })
})

function submit() {
    globalStore.loader('show')

    let params = {
        accountInfo: ['social_reason', 'name', 'birth_date', 'document', 'state_registration', 'phone', 'cellphone', 'mothers_name', 'occupation'],
        accountAddress: ['postcode', 'address', 'address_number', 'neighborhood', 'complement', 'city', 'state'],
        accountBank: ['bank_account_code', 'bank_account_type', 'bank_account_agency', 'bank_account_number', 'bank_account_number_digit', 'pix_type', 'pix_key'],
        accountDocuments: ['file_cnpj_card', 'file_social_contract', 'file_proof_address', 'file_proof_bank', 'file_document_front', 'file_document_back']
    };

    accountStore.tryUpdateAccount(params, route.name).then(function (success) {
        globalStore.loader('hide');

        if (success) {
            useGlobalStore().successMessage('Os dados foram enviados para análise');
        }
    })
}
</script>

<style scoped>
.main_title_icon {
    height: 82px;
}

.active {
    background-color: white !important;
}

.divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

@media screen and (max-width: 992px) {
    .main_title_icon {
        width: 150px;
        margin: 0 auto;
    }
}
</style>