<template>
    <aside id="navbar" @mouseover="toggleSidebar()" @mouseout="toggleSidebar()">
        <TopMenu/>
        <MiddleMenu @reloadSameRoute="onReloadSameRoute"/>
        <BottomMenu/>
    </aside>
</template>

<script setup>
import TopMenu from '@/components/includes/menu/TopMenu';
import MiddleMenu from '@/components/includes/menu/MiddleMenu';
import BottomMenu from '@/components/includes/menu/BottomMenu';
import { onMounted } from 'vue';

let collapsed = false;

const emit = defineEmits(['reloadSameRoute'])

onMounted(() => {
    const app = document.getElementById("app");
    app.classList.add("menu-collapsed");
    setTimeout(function(){
        toggleSidebar();
    }, 200);

    let getNavbar = document.getElementById("navbar")
    let getFooterBtn = document.querySelector("#footer_nav button")

    if(getFooterBtn){
        getFooterBtn.style.fontSize = '0px'

        getNavbar.addEventListener('transitionend', () =>{
            if(collapsed){ 
                document.getElementById('stores_select_dropdown').classList.remove('show')
                getFooterBtn.style.fontSize = '0px'
            } else {
                setTimeout(() =>{
                    getFooterBtn.style.fontSize = '14px'
                }, 100)
            }
            
        })
    }
});

function toggleSidebar() {
    const navbar = document.getElementById("navbar");
    const app = document.getElementById("app");
    
    if(collapsed){
        if(navbar) {
            navbar.style.width = "255px";
        }
        if(app) {
            app.classList.remove("menu-collapsed");
        }
        collapsed = false;
    }else {
        const getNavbarStyle = document.getElementById("navbar");
        if(getNavbarStyle.style.height !== "100%"){
            if(navbar) {
                navbar.style.width = "70px";
            }

            if(app) {
                app.classList.add("menu-collapsed");
            }
        }
        collapsed = true;
    }

}

function onReloadSameRoute(){
    emit('reloadSameRoute')
}
</script>

<style scoped>
#navbar {
    white-space: nowrap;
    transition: 0.3s;
}

#app.menu-collapsed #navbar {
    transition: 0.3s;
}

aside {
    background: white;
    border-right: 1px #ddd solid;
    width: 70px;
    position: fixed;
    top: 0;
    z-index: 101;
}

@media screen and (min-width: 993px) {
    aside {
        height: 100% !important;
    }
}

@media screen and (max-width: 992px) {
    aside {
        width: 100% !important;
        height: 65px;
        overflow: hidden;
        border: none;
        border-bottom: 1px #ddd solid;
        border-right: 1px #ddd solid;
    }
}
</style>