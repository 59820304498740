<template>
  <div class="box">
    <div class="p-4">
      <h5>Olá, {{ auth.name }}.</h5>
      <p class="mb-0">Precisamos de algumas informações para continuar com o seu cadastro.</p>
    </div>

    <div class="divisor_top"></div>

    <div class="p-4">

      <div class="row" v-if="completion.type == 'J'">

        <BaseInput
          class="col-md-12"
          label="Razão social"
          type="text"
          id="social_reason"
          maxlength="150"
          v-model="completion.social_reason"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Nome fantasia"
          type="text" 
          id="name" 
          maxlength="150"
          v-model="completion.name" 
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Data de fundação"
          type="text"
          id="birth_date"
          maxlength="10"
          :mask="'##/##/####'"
          v-model="completion.birth_date"
          :page="route.name"
          />

          <BaseInput
          class="col-md-6"
          label="CNPJ"
          type="text"
          id="document"
          maxlength="20"
          :mask="'##.###.###/####-##'"
          v-model="completion.document"
          :page="route.name"
          />

          <BaseInput
          class="col-md-6"
          label="Inscrição estadual"
          type="text"
          id="state_registration"
          maxlength="50"
          v-model="completion.state_registration"
          :page="route.name"
          />

          <BaseInput
          class="col-md-6"
          label="Telefone"
          type="text"
          id="phone"
          v-model="completion.phone"
          mask="['(##) ####-####', '(##) #####-####']"
          :page="route.name"
          />

          <BaseInput
          class="col-md-6"
          label="Celular"
          type="text"
          id="cellphone"
          v-model="completion.cellphone"
          mask="['(##) ####-####', '(##) #####-####']"
          :page="route.name"
          />
      </div>

      <div class="row" v-else-if="completion.type == 'F'">

        <BaseInput
          class="col-md-12"
          label="Nome completo"
          type="text"
          id="name"
          maxlength="150"
          v-model="completion.name"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Nome completo da mãe"
          type="text"
          id="mothers_name"
          maxlength="150"
          v-model="completion.mothers_name"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="CPF"
          type="text"
          id="document"
          maxlength="20"
          :mask="'###.###.###-##'"
          v-model="completion.document"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Data de nascimento"
          type="text" id="birth_date"
          maxlength="10"
          :mask="'##/##/####'"
          v-model="completion.birth_date"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Profissão / Ramo de Atividade"
          type="text"
          id="occupation"
          maxlength="50"
          v-model="completion.occupation"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Telefone"
          type="text"
          id="phone"
          v-model="completion.phone"
          mask="['(##) ####-####', '(##) #####-####']"
          :page="route.name"
        />

        <BaseInput
          class="col-md-6"
          label="Celular"
          type="text"
          id="cellphone"
          v-model="completion.cellphone"
          mask="['(##) ####-####', '(##) #####-####']"
          :page="route.name"
        />

      </div>
    </div>
  </div>

  <div class="space"></div>

  <div class="actions">
    <div class="container d-flex justify-content-between">
      <router-link to="/account/completion">
        <BaseButton class="btn-white btn-lg"><font-awesome-icon class="mx-2" icon="fa-solid fa-arrow-left-long" /> Voltar
        </BaseButton>
      </router-link>
      <router-link to="/account/completion/address">
        <BaseButton class="btn-primary btn-lg">Continuar <font-awesome-icon class="mx-2"
            icon="fa-solid fa-arrow-right-long" /></BaseButton>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import { useAccountStore } from '@/stores/account'
import { storeToRefs } from 'pinia';
import { useRoute } from "vue-router";
import { onBeforeMount } from 'vue';

const route = useRoute();

const auth = useAuthStore();
const accountStore = useAccountStore();
const { completion } = storeToRefs(accountStore);

onBeforeMount(() => {
  if (route.query.type) {
    accountStore.completion.type = route.query.type;
  } else if (!accountStore.completion.type) {
    accountStore.completion.type = 'J';
  }
})
</script>