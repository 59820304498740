<template v-if="isLoaded === true">
    <div>
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose" :displayGrid="'display:grid;'">
        <div>
            <header>
            <font-awesome-icon icon="fa-solid  fa-bullhorn" style="color: #215eda;"/>
            <span v-if="route.name == 'campaignTrackerEdit'" class="ms-2 align-middle">Editar Rastreador</span>
            <span v-else class="ms-2 align-middle">Novo Rastreador</span>
            </header>
            <div class="content">
            <form @submit.prevent="">
                    <div class="row">
                        <BaseSelect
                            class="col-12"
                            id="type"
                            :items="trackerTypes"
                            label="Tipo *"
                            :required="false"
                            v-model="tracker.type"
                        />
                        <BaseSelect
                            v-if="tracker.type == 'pixel'"
                            class="col-12"
                            id="tracking_type"
                            :items="pixelType"
                            label="Tipo de rastreio"
                            :required="false"
                            v-model="tracker.content.tracking_type"
                        />
                        <BaseInput
                            class="col-12"
                            label="Descrição *"
                            id="title"
                            :required="false"
                            v-model="tracker.title"
                            :page="route.name"
                        />
                        <BaseInput
                            class="col-12"
                            label="ID *"
                            id="content.code"
                            :required="false"
                            v-model="tracker.content.code"
                            :page="route.name"
                        />
                        <BaseSelect
                            class="col-md-9 col-12"
                            id="content.amount_type"
                            :items="buyValues"
                            label="Valor da compra"
                            v-model="tracker.content.amount_type"
                            :page="route.name"
                        />
                        <BaseInput
                            v-if="tracker.content.amount_type === 'custom_amount'"
                            class="col-md-3 col-12"
                            type="text"
                            label="Valor personalizado"
                            id="content.buy_custom_value"
                            tag="R$"
                            :page="route.name"
                            v-model="tracker.content.amount"
                            mask="9.99#,##"
                            :tokens="'9:[0-9]:repeated'"
                            :reversed="true"
                            maxlength="10"
                        />
                        <div v-if="tracker.type == 'pixel' && tracker.content.tracking_type == 'api'">
                            <BaseInput
                                class="col-12"
                                label="Token de acesso"
                                id="accesstoken"
                                :required="false"
                                v-model="tracker.content.access_token"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-12"
                                label="Código de teste"
                                id="testoken"
                                :required="false"
                                v-model="tracker.content.test_code"
                                :page="route.name"
                            />
                        </div>
                        <BaseInput 
                            v-if="tracker.type == 'google_ads'"
                            class="col-12"
                            id="content.label"
                            label="Rótulo de conversão"
                            :required="false"
                            v-model="tracker.content.label"
                        />
                        <BaseInput 
                            v-if="tracker.type == 'pixel' && tracker.content.tracking_type == 'code'"
                            class="col-12"
                            id="content.domain"
                            label="Domínio"
                            placeholder="pixel.seudominio.com.br"
                            :required="false"
                            v-model="tracker.content.domain"
                        />
                        <BaseSelect
                            class="col-12"
                            id="content.track_in"
                            :items="trackerActions"
                            label="Executar em *"
                            :required="false"
                            v-model="tracker.content.track_in"
                            :page="route.name" 
                        />
                        <div v-if="tracker.type == 'google_ads' || tracker.type == 'outbrain'">
                            <label>Executar conversão nas formas de pagamento</label>
                            <BaseSwitch
                                v-model:checked="tracker.content.track_boleto"  
                                label="Boleto gerado"
                                id="boleto"
                                :page="route.name" 
                            />
                            <BaseSwitch
                                v-model:checked="tracker.content.track_pix" 
                                label="Pix gerado"
                                id="pix"
                                :page="route.name" 
                            />
                            <BaseSwitch
                                v-model:checked="tracker.content.track_credit_card"
                                label="Cartão de crédito"
                                id="credit_card"
                                :page="route.name"
                            />
                        </div>
                        <div v-if="tracker.type == 'pixel' && tracker.content.tracking_type">
                            <label>Executar conversão nas formas de pagamento</label>
                            <BaseSwitch
                                v-model:checked="tracker.content.track_boleto"  
                                label="Boleto gerado"
                                id="boleto"
                                :page="route.name" 
                            />
                            <BaseSwitch
                                v-if="tracker.content.tracking_type == 'api'"
                                v-model:checked="tracker.content.track_boleto_paid"  
                                label="Boleto pago"
                                id="boletopaid"
                                :page="route.name" 
                            />
                            <BaseSwitch
                                v-model:checked="tracker.content.track_pix" 
                                label="Pix gerado"
                                id="pix"
                                :page="route.name" 
                            />
                            <BaseSwitch
                                v-model:checked="tracker.content.track_pix_paid" 
                                label="Pix pago"
                                id="pixpaid"
                                :page="route.name" 
                            />
                            <BaseSwitch
                                v-model:checked="tracker.content.track_credit_card"
                                label="Cartão de crédito"
                                id="credit_card"
                                :page="route.name"
                            />
                        </div>
                    </div>
                    <BaseButton @click="submit" class="mt-4 btn-primary w-100" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
                </div>
            </div>
        </BasePopup>
        </div>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '@/stores/global';
import { useCampaignStore } from '@/stores/campaign';

const route = useRoute();
const router = useRouter();

let previousPage = router.options.history.state.back;
const campaign_id = computed (function () {
    return route.params.id;
});

const campaignStore = useCampaignStore();
const { tracker } = storeToRefs(campaignStore);

const globalStore = useGlobalStore();

const isLoading = ref(false);
const isLoaded = ref(false);

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}
const trackerTypes = ref({
    'google_analytics': 'Google Analytics',
    'google_tag_manager': 'Google Tag Manager',
    'google_ads': 'Google ADS',
    'pixel': 'Facebook Pixel',
    'taboola': 'Taboola',
    'outbrain': 'Outbrain',
    'tiktok': 'TikTok',
    'microsoft_clarity': 'Microsoft Clarity',

})

const trackerActions = ref({
   'all': 'Todas as páginas',
   'checkout': 'Somente na página de checkout',
   'order': 'Somente na página de obrigado',
})

const pixelType = ref({
    'api' : 'API',
    'code' : 'Código',
})

const buyValues = ref({
    'transaction_amount' : 'Enviar valor da transação',
    'comission_amount' : 'Enviar valor da comissão',
    'custom_amount' : 'Enviar valor personalizado',
})

onBeforeMount(() => {
    delete globalStore.errors[route.name];

    if(route.name === 'campaignTrackerEdit'){
        if(campaignStore.tracker.id == undefined || campaignStore.tracker.id == null){
            globalStore.loader('show')
            campaignStore.tryTracker(route.params.slug).then(() =>{
                isLoaded.value = true
                globalStore.loader('hide')
            })
        } else {
            isLoaded.value = true
        }
    } else if(route.name == 'campaignTrackerCreate') {
        globalStore.cleanObject(campaignStore.tracker);
        isLoaded.value = true
    }
});

async function submit() {
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show')
        if(route.name == 'campaignTrackerEdit'){

            let params = ['type', 'content', 'title'];

            campaignStore.tryUpdateTracker(params, route.name).then(function(success) {
                globalStore.loader('hide');

                if(success){
                    campaignStore.tryTrackers(campaign_id.value);
                    router.push('/campaigns/' + campaign_id.value + '/trackers')
                    globalStore.loader('hide')
                    isLoading.value = false;
                }else{
                    globalStore.loader('hide')
                    isLoading.value = false;
                }
            })
        }
        else if(route.name == 'campaignTrackerCreate'){
            let params = ['type', 'content', 'title'];
            campaignStore.tryCreateTracker(campaign_id.value, params, route.name).then(function(success) {
                globalStore.loader('hide');

                if(success){
                    campaignStore.tryTrackers(campaign_id.value);
                    router.push('/campaigns/' + campaign_id.value + '/trackers')
                    globalStore.loader('hide')
                    isLoading.value = false;
                } else{
                    globalStore.loader('hide')
                    isLoading.value = false;
                }
            })
        }
    }
}   


</script>

<style scoped>

header{
    width: 100%;
    display: block!important;
    margin: 0;
    padding-top: 6px;
    font-size: 16px;
    color: #646464;
    font-weight: 600;
}

</style>