import TheRefunds from '@/views/refunds/TheRefunds';
import TheRefundEdit from '@/views/refunds/RefundEdit';

export default [
    {
        path: '/refunds',
        name: 'refunds',
        component: TheRefunds,
        meta: {
            requiresAuth: true,
            menuPage: 'refunds'
        },
    },
    {
        path: '/refunds/:id',
        name: 'refund-edit',
        component: TheRefundEdit,
        props: true,
        meta: {
            requiresAuth: true,
            menuPage: 'refunds'
        }
    }
];