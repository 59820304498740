import TheInsights from '@/views/reports/insights/TheInsights'
import TheReferralReport from '@/views/reports/referrals/TheReferralsReport';
import TheProductorsReport from '@/views/reports/productors/TheProductorsReport';
import TheProductsReport from '@/views/reports/products/TheProductsReport';
import ViewReferralReport from '@/views/reports/referrals/ReportReferralShow';
import ThePaymentMethodsReport from '@/views/reports/payment-methods/ThePaymentMethodsReport';
import TheConversionsReport from '@/views/reports/conversions/TheConversionsReport';
import TheRecommendationsReport from '@/views/reports/recommendations/TheRecommendationsReport';
import ViewRecommendationReport from '@/views/reports/recommendations/ReportRecommendationShow';
import TheTestsAb from '@/views/reports/tests-ab/TheTestsAb';

export default [
    {
        path: '/reports/insights',
        name: 'reports-insights',
        component: TheInsights,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-insights'
        }
    },
    {
        path: '/reports/referrals',
        name: 'reports-referrals',
        component: TheReferralReport,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-referrals'
        }
    },

    {
        path: '/reports/referrals/:id',
        name: 'view-referral-report',
        component: ViewReferralReport,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-referrals',
        }
    },

    {
        path: '/reports/productors',
        name: 'reports-productors',
        component: TheProductorsReport,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-productors'
        }
    },


    {
        path: '/reports/products',
        name: 'reports-products',
        component: TheProductsReport,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-products'
        }
    },

    {
        path: '/reports/payment-methods',
        name: 'payment-methods',
        component: ThePaymentMethodsReport,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-payment-methods'
        }
    },

    {
        path: '/reports/conversions',
        name: 'conversions',
        component: TheConversionsReport,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-conversions'
        }
    },

    {
        path: '/reports/recommendations',
        name: 'recommendations',
        component: TheRecommendationsReport,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-recommendations'
        }
    },

    {
        path: '/reports/recommendations/:id',
        name: 'view-recommendations-report',
        component: ViewRecommendationReport,
        meta: {
            requiresAuth: true,
            menuPage: 'reports-recommendations'
        }
    },
    {
        path: '/reports/tests-ab',
        name: 'tests_ab',
        component: TheTestsAb,
        meta: {
            requiresAuth: true,
            menuPage: 'tests-ab',
        },
    }
];