<template>
    <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <template v-if="isLoaded != false">
            <header>
                <font-awesome-icon icon="fa-solid fa-ticket" style="color: #215eda;"/>
                <span v-if="route.name == 'productLoteEdit'"  class="ms-2 align-middle">Editar lote</span>
                <span v-else class="ms-2 align-middle">Novo lote</span>
            </header>
            <div class="content">
                <form @submit.prevent="">
                    <div class="row">
                        <BaseInput
                            class="col-12 mb-3"
                            label="Título *"
                            type="text"
                            id="name"
                            v-model="lote.name"
                            :page="route.name"
                        />

                        <BaseInput
                            class="col-12 mb-3"
                            label="Valor *"
                            id="price"
                            v-model="lote.price"
                            :page="route.name"
                            mask="9.99#,##"
                            :tokens="'9:[0-9]:repeated'"
                            :reversed="true"
                            maxlength="10"
                        />

                        <BaseInput
                            class="col-12 mb-3"
                            label="Quantidade *"
                            type="number"
                            id="amount"
                            v-model="lote.amount"
                            :page="route.name"
                        />

                        <BaseDatePicker
                            id="date_range"
                            class="col-12 mb-5"
                            label="Período do lote *"
                            :page="route.name"
                            v-model="lote.date_range"
                            range
                            multi
                            :close_autoapply="false"
                        />
                    </div>
                    <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </form>
            </div>
        </template>
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter} from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { lote }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const isLoaded = ref(false);
const isLoading = ref(false);

const product_id = computed (function () {
    return route.params.id;
});

onBeforeMount(() =>{
    if(route.name == 'productLoteEdit'){
        if(productStore.lote.length == undefined){
            globalStore.loader('show')
            productStore.tryLote(route.params.slug).then(() =>{
                productStore.lote.date_range = productStore.lote.date_range[0] + '@' + productStore.lote.date_range[1]
                isLoaded.value = true
                globalStore.loader('hide')
            })
        } else {
            isLoaded.value = true
        }
    } else if(route.name == 'productLoteCreate') {
        globalStore.cleanObject(productStore.lote);
        isLoaded.value = true
    }
})

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show');
        if(route.name == 'productLoteEdit'){

            let params = ['price', 'name', 'date_range', 'amount'];

            productStore.tryUpdateLote(params, route.name, productStore.lote.id).then(function(success) {
                if(success){
                    useGlobalStore().successMessage('Lote atualizado com sucesso');

                    productStore.tryLotes().then(function(success){
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/lotes')
                        } else{
                            globalStore.loader('hide');
                        }
                    })
                }
                isLoading.value = false;
            });
        } else if(route.name == 'productLoteCreate') {
            let params = ['price', 'name', 'date_range', 'amount'];

            productStore.tryCreateLote(params, route.name).then(function(success){
                if(success){
                    useGlobalStore().successMessage('Lote criado com sucesso');

                    productStore.tryLotes().then(function(success) {
                        if(success){
                            globalStore.loader('hide');
                            router.push('/products/' + product_id.value + '/lotes')
                        } else {
                            globalStore.loader('hide');
                        }
                    });
                }else {
                    globalStore.loader('hide');
                }
                isLoading.value = false;
            })
        }
    }
}

</script>