<template>
    <div v-if="isLoaded != false" class="box_content">
        <nav>
            <div v-if="integrations.length" class="nav" id="nav-tab" role="tablist">
                <router-link v-if="hasIntegration.hasMautic" :to="'/products/'+ product_id +'/events/mautic'" :class="[route.path === '/products/'+ product_id +'/events/mautic' ? 'active': '']">
                    Mautic
                </router-link>
                <router-link v-if="hasIntegration.hasLeadlovers" :to="'/products/'+ product_id +'/events/leadlovers'" :class="[route.path === '/products/'+ product_id +'/events/leadlovers' ? 'active': '']">
                    Leadlovers
                </router-link>
                <router-link v-if="hasIntegration.hasMex" :to="'/products/'+ product_id +'/events/mex'" :class="[route.path === '/products/'+ product_id +'/events/mex' ? 'active': '']">
                    Mex
                </router-link>
            </div>
        </nav>
        <div v-if="!integrations.length" class="text-center p-4">
            <h6  class="message">Nenhuma integração encontrada</h6>
        </div>
    </div>
    <div>
        <router-view></router-view>
    </div>

</template>
<script setup>
import { computed, onBeforeMount, ref } from 'vue' 
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from "vue-router"; 
import { useProductStore } from '@/stores/products';

const route = useRoute();
const router = useRouter();

const productStore = useProductStore();
const { integrations } = storeToRefs(productStore);

const isLoaded = ref(false)

const hasIntegration = ref({
    hasMautic: false,
    hasMex: false,
    hasLeadlovers: false,
})

const product_id = computed (function () {
    return route.params.id;
});

onBeforeMount(() =>{
    for(let i = 0; i < productStore.integrations.length; i++){
        if(productStore.integrations[i].slug_integration == 'mautic'){
            productStore.tryEventsMautic();
            hasIntegration.value.hasMautic = true
            router.push('/products/' + product_id.value + '/events/' + productStore.integrations[0].slug_integration)
        } 
        if (productStore.integrations[i].slug_integration == 'mex'){
            productStore.tryEventsMex().then
            hasIntegration.value.hasMex = true
            router.push('/products/' + product_id.value + '/events/' + productStore.integrations[0].slug_integration)
        } 
        if (productStore.integrations[i].slug_integration == 'leadlovers'){
            productStore.tryEventsLeadlovers();
            hasIntegration.value.hasLeadlovers = true
            router.push('/products/' + product_id.value + '/events/' + productStore.integrations[0].slug_integration)
        }
        isLoaded.value = true;
    }
})

</script>

<style scoped>
.main_title_icon {
    height: 82px;
}

.active{
    background-color: white!important;
}

.divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

:deep(.form-group) {
    height: 70px;
}


:deep(.table_bordered){
    background: #fff;
    border-radius: 8px;
}

:deep(thead) {
    background: #fff;
    color: #595959;
}

:deep(tbody){
    border-bottom: 1px #ddd solid ;
}

:deep(tbody td) {
    padding: 12px 16px;
    vertical-align: middle;
    color: #595959;
}

tbody tr:last-of-type {
    border-bottom: 0px solid #fff !important;
}

tbody tr:hover {
    background: #f3f3f3 !important;
}

:deep(.icon_buttons) {
    padding-top: 3px;
    color: #215eda !important;
    border-radius: 50px;
    text-decoration: none;
    margin-left: 2px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    border: none;
    background-color: transparent;
}

:deep(.icon_buttons svg){
    width: 15px;
    height: 13px;
}

:deep(.icon_buttons:hover) {
    box-shadow: inset 0px 0px 50px rgb(0 0 0 / 20%);
}


/* navigation */

:deep(.page_item){
    background-color: white;
    border: 1px solid #d7d7d7;
    padding: 5px 11px;
}

.active {
    border-color: #215eda;
    background-color: #215eda;
    color: white !important;
}

@media screen and (max-width: 992px) {
    .main_title_icon {
        width: 150px;
        margin: 0 auto;
    }
}
</style>