<template>
    <div class="box_content" v-if="store.meta.cookie_type">
        <div class="row p-4">
            <BaseSelect
                class="col-md-6 col-12"
                label="Modelo de comissão"
                type="text"
                id="meta.cookie_type"
                :required="false"
                placeholder="selecione"
                :items="{'last': 'Último click', 'first': 'Primeiro click'}"
                v-model="store.meta.cookie_type"
                :page="route.name"
            />
            <BaseInput
                class="col-md-6 col-12"
                label="Tempo de duração da indicação em dias* "
                type="tel"
                id="meta.cookie_time"
                tooltip_text="Tempo de duração do cookie que gera venda de afiliado."
                v-model="store.meta.cookie_time"
                :page="route.name"
            />
        </div>
        <hr/>
        <div class="p-4">
            <BaseSwitch
                id="meta.referral_sensitive_information"
                label='Ocultar informações sensíveis dos compradores para afiliados'
                :page="route.name" 
                v-model:checked="store.meta.referral_sensitive_information" 
                class="sensitive"
            />
        </div>
        <div class="divisor_top divisor_bottom">
            <div class="table-responsive">
                <table class="table mb-0">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Comissão</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <template v-if="referralslist.length > 0|| referralsinvites.length > 0">
                        <tbody>
                            <tr v-for="(referral, key) in referralslist" :key="key">
                                <td>{{ referral.code }}</td>
                                <td>
                                    {{ referral.name }}
                                    <small v-if="referral.manager" class="d-block">Gerente: {{ referral.manager }}</small>
                                </td>
                                <td>{{ referral.comission }}%</td>
                                <td>{{ referral.status_name }}</td>
                                <td class="actions">
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editReferral(referral.id, row)"><font-awesome-icon icon="fas fa-edit" /></span>
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(referral.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                                </td>
                            </tr>
                            <tr v-for="(row, key) in referralsinvites" :key="key">
                                <td>{{ row.code }}</td>
                                <td>{{ row.email }}</td>
                                <td>{{ row.comission }}%</td>
                                <td>{{ row.status }}</td>
                                <td class="actions">
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopupInvite(row.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
                <h6 v-if="referralslist.length == 0 && referralsinvites.length == 0" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>
            </div>
        </div>
        <div style="text-align: center" class="p-4">
            <router-link :to="'/stores/' + store_id + '/referrals/new'">
                <BaseButton class="btn-primary btn-lg"> Adicionar </BaseButton>
            </router-link>
        </div>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover este afiliado?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteReferral" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { computed, shallowRef, ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { store, referralslist, referralsinvites } = storeToRefs(storeStore);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

let referralToDelete = null;
let inviteToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef();
const excludeTextConfirm = ref(null);
const isLoading = ref(false);

function editReferral(id){
    globalStore.loader('show');
    storeStore.tryReferral(id).then(() =>{
        router.push('/stores/' + store_id.value + '/referrals/' + id);
        globalStore.loader('hide');
    })
}

async function deleteReferral(){
    if(!isDeleting && !isLoading.value && referralToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await storeStore.tryDeleteReferral(store_id.value, referralToDelete).then(function(success) {
            if(success){
                useGlobalStore().successMessage('Afiliado removido com sucesso');
            }
            storeStore.tryReferrals().then(function() {
                globalStore.loader('hide');
                router.push('/stores/' + store_id.value + '/referrals')
            });
            isDeleting = false;
            confirmAction.value = null;
            excludeTextConfirm.value = null;
            isLoading.value = false;
        })

    } else if(!isDeleting && inviteToDelete){
        isDeleting = true;
        globalStore.loader('show');
        await storeStore.tryDeleteReferralInvite(store_id.value, inviteToDelete).then(function(success) {
            if(success){
                useGlobalStore().successMessage('Convite removido com sucesso');
            }
            storeStore.tryReferrals().then(function() {
                globalStore.loader('hide');
                router.push('/stores/' + store_id.value + '/referrals')
            });
            isDeleting = false;
            confirmAction.value = null;
            isLoading.value = false;
            excludeTextConfirm.value = null;
        }); 
        
    }
}

function openPopup(id) {
    referralToDelete = id
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function openPopupInvite(id) {
    inviteToDelete = id
    confirmAction.value = true
    excludeTextConfirm.value = null;
}


function clickToClose() {
    confirmAction.value = null;
}


</script>

<style scoped>
.sensitive {
    margin: 0 !important;
}
hr {
    margin: 0 !important;
}
</style>
