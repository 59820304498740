<template>
    <div>
        <input type="range" :class="'range ' + [error ? 'error' : '']" @input="updateInput" :id="props.id"
        :value="Number(props.modelValue)" :max="props.max - 1"
        :style="'background: linear-gradient(to right, #215eda 0%, #215eda ' + background + '%, #e6e4e4 ' + background + '%, #e6e4e4 100%)'"
        />
        <span v-if="!!error" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
    </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue';

import { useGlobalStore } from '@/stores/global';
const global = useGlobalStore();

const props = defineProps({
    modelValue: {
        required: false,
        default: 0
    },
    max: {
        required: false,
        default: 0
    },
    id: {
        required: true
    },
    page: {
        required: true
    }
});

const background = ref(0);
const emit = defineEmits(["update:modelValue"]);

function updateInput(event) {
    emit("update:modelValue", Number(event.target.value));

    if(global.errors[props.page] && global.errors[props.page][props.id]) {
        delete global.errors[props.page][props.id];
    }
}

watchEffect(() => {
    background.value = (props.modelValue) ? (props.modelValue * 100) / (props.max - 1) : 0;
    setTimeout(() => {
        document.getElementById(props.id).value = props.modelValue;
    }, 5);
})

const error = computed(function () {
    if(global.errors[props.page] && global.errors[props.page][props.id]) {
        return global.errors[props.page][props.id];
    }
    return null;
});
</script>

<style scoped>
/*Chrome*/
.range {
    border-radius: 8px;
    height: 7px;
    width: 100%;
    outline: none;
    transition: background 500ms ease-in;
    -webkit-appearance: none;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;   
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 17px;
    height: 17px;
    background: #4279e5;
    border-radius: 50%;
    border: 0.5px solid transparent;
}

.range::-webkit-slider-thumb:active {
    background: #cad6ef;
    transition: 50ms ease-out;
}

.range:focus::-webkit-slider-thumb {
    border-color: white;
    outline: #cad6ef solid 3px;
    transition: 50ms ease-out;
}

/*Firefox*/
.range::-moz-range-thumb {
    -webkit-appearance: none;
    width: 17px;
    height: 17px;
    background: #4279e5;
    border-radius: 50%;
    border: 0.5px solid transparent;
}

.range::-moz-range-thumb:active {
    background: #cad6ef;
    transition: 50ms ease-out;
}

.range:focus::-moz-range-thumb {
    border-color: white;
    outline: #cad6ef solid 3px;
    transition: 50ms ease-out;
}

.error {
    background: #ff6d6d !important;
}
.error::-webkit-slider-thumb {
  background: #d60e0e;
  -webkit-appearance: none;
  cursor: pointer;
}
.error::-moz-range-thumb {
  background: #d60e0e;
  cursor: pointer;
}
.error::-ms-thumb {
  background: #d60e0e;
  cursor: pointer;
}
</style>