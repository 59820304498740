<template>
  <div class="box_content pt-1">
    <div class="tab-content">
      <div class="tab-pane show active p-4">
        <div class="row" v-if="accountStore.account.type == 'J'">

          <BaseInput class="col-md-6" label="Razão social" type="text" id="social_reason" maxlength="150"
            v-model="accountStore.account.social_reason" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Nome fantasia" type="text" id="name" maxlength="150"
            v-model="accountStore.account.name" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-4" label="Data de fundação" type="text" id="birth_date" maxlength="10"
            :mask="'##/##/####'" v-model="accountStore.account.birth_date" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-4" label="CNPJ" type="text" id="document" maxlength="20" :mask="'##.###.###/####-##'"
            v-model="accountStore.account.document" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-4" label="Inscrição estadual" type="text" id="state_registration" maxlength="50"
            v-model="accountStore.account.state_registration" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Telefone" type="text" id="phone" v-model="accountStore.account.phone"
            mask="['(##) ####-####', '(##) #####-####']" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Celular" type="text" id="cellphone" v-model="accountStore.account.cellphone"
            mask="['(##) ####-####', '(##) #####-####']" :page="route.name" :disabled="!accountStore.account.is_editable" />
        </div>

        <div class="row" v-else-if="accountStore.account.type == 'F'">

          <BaseInput class="col-md-12" label="Nome completo" type="text" id="name" maxlength="150"
            v-model="accountStore.account.name" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Nome completo da mãe" type="text" id="mothers_name" maxlength="150"
            v-model="accountStore.account.mothers_name" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="CPF" type="text" id="document" maxlength="20" :mask="'###.###.###-##'"
            v-model="accountStore.account.document" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Data de nascimento" type="text" id="birth_date" maxlength="10"
            :mask="'##/##/####'" v-model="accountStore.account.birth_date" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Profissão / Ramo de Atividade" type="text" id="occupation" maxlength="50"
            v-model="accountStore.account.occupation" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Telefone" type="text" id="phone" v-model="accountStore.account.phone"
            mask="['(##) ####-####', '(##) #####-####']" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6" label="Celular" type="text" id="cellphone" v-model="accountStore.account.cellphone"
            mask="['(##) ####-####', '(##) #####-####']" :page="route.name" :disabled="!accountStore.account.is_editable" />

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
import { useAccountStore } from '@/stores/account'
import { useRoute } from "vue-router";
import { onBeforeMount } from 'vue';

const globalStore = useGlobalStore();
const accountStore = useAccountStore();

const route = useRoute();

onBeforeMount(() =>{
  accountStore.account.birth_date = globalStore.formatDate(accountStore.account.birth_date);
})
</script>