<template>
  <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
    <div  v-if="integrations">
        <header>
            <font-awesome-icon icon="fa-solid fa-puzzle-piece" style="color: #215eda;"/>
            <span class="ms-2 align-middle">{{ integration.title }}</span>
        </header>
        <div class="content">
            <form   @submit.prevent="" class="row">
                <template v-if="route.params.slug != 'notazz'">                 
                    <div v-for="(field, key) in integration.content" :class="field.class" :key="key">
                        <BaseInput
                            v-if="field.type == 'input'"
                            :label="field.title"
                            :id="'content.' + field.name"
                            :required="false"
                            v-model="field.value"
                            :page="route.name"
                        />
                        <BaseSelect
                            v-if="field.type == 'select'"
                            :label="field.title"
                            :items="field.items"
                            :id="'content.' + field.name"
                            :required="false"
                            v-model="field.value"
                            :page="route.name"
                        />
                        <BaseSwitch 
                            v-if="field.type == 'checkbox'" 
                            :label="field.title"
                            :id="'content.' + field.name"
                            v-model:checked="field.value"
                            :page="route.name"
                        />
                    </div>
                    </template>
                    <div v-else>
                        <BaseInput
                            :class="integration.content[0].class"
                            :label="integration.content[0].title"
                            :id="'content.' +  integration.content[0].name"
                            :required="false"
                            v-model="integration.content[0].value"
                            :page="route.name"
                        />
                        <BaseSelect
                            :class="integration.content[1].class"
                            :label="integration.content[1].title"
                            :items="integration.content[1].items"
                            :id="'content.' + integration.content[1].name"
                            :required="false"
                            v-model="integration.content[1].value"
                            :page="route.name"
                        />
                        <BaseInput
                            v-if="integration.content[1].value == 'nfs_e'"
                            :class="integration.content[2].class"
                            :label="integration.content[2].title"
                            :id="'content.' +  integration.content[2].name"
                            :required="false"
                            v-model="integration.content[2].value"
                            :page="route.name"
                        />
                    </div>
                <BaseSwitch id="ActivateSwitch" label="Ativar" v-model:checked="integration.status"/>
                <div class="col-12">
                    <BaseButton @click="submit" class="btn-primary w-100 mt-3" :disabled="isLoading">
                        Salvar
                    </BaseButton>
                </div>
            </form>
        </div>
    </div>
  </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from "@/stores/global";
import { useStoreStore } from "@/stores/store";
import { storeToRefs } from "pinia";

const route = useRoute();
const router = useRouter();
const globalStore = useGlobalStore();
const storeStore = useStoreStore();
const { integration, integrations } = storeToRefs(storeStore);

const store_id = computed(function () {
  return route.params.id;
});

const isLoading = ref(false);

onBeforeMount(() => {
  delete globalStore.errors[route.name];
});

let previousPage = router.options.history.state.back;

function clickToClose() {
  if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit() {
  if(!isLoading.value){
    isLoading.value = true;
    globalStore.loader("show");
    let params = {
      content: {},
      status: storeStore.integration.status,
    };
    for (let i = 0; i < storeStore.integration.content.length; i++) {
      params.content[storeStore.integration.content[i].name] =
        storeStore.integration.content[i].value;
    }

    storeStore
      .tryUpdateIntegration(store_id.value, params, route.name)
      .then(function (success) {
        if (success) {
          storeStore.tryIntegrations().then(() => {
            globalStore.loader("hide");
            router.push("/stores/" + store_id.value + "/integrations");
          });
        } else {
          globalStore.loader("hide");
        }
        isLoading.value = false;
      });

      if(route.params.slug === 'mautic' && storeStore.integration.status === 1){
        window.open('//api-dashboard.fullsale.com.br/postbacks/mautic/auth?id_store=' + store_id.value, '_blank')
      }
  }
}
</script>