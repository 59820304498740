import TheAbandonedCarts from '@/views/recovery/abandonedcarts/TheAbandonedCarts';
import EditAbandonedCart from '@/views/recovery/abandonedcarts/edit/RecoveryAbandonedCartEdit';
import RecoveryAbandonedCartEditInformations from '@/views/recovery/abandonedcarts/edit/RecoveryAbandonedCartEditInformations'
import RecoveryAbandonedCartEditTimeline from '@/views/recovery/abandonedcarts/edit/RecoveryAbandonedCartEditTimeline';

import TheRefusedOrders from '@/views/recovery/refusedorders/TheRefusedOrders';
import RefusedView from '@/views/recovery/refusedorders/show/RecoveryRefusedOrderShow'

export default [
    {
        path: '/recovery/abandoned-carts',
        name: 'abandoned-carts',
        component: TheAbandonedCarts,
        meta: {
            requiresAuth: true,
            menuPage: 'abandoned-carts'
        }
    },

    {
        path: '/recovery/abandoned-carts/:id/',
        name: 'abandoned-carts-edit',
        component: EditAbandonedCart,
        meta: {
            requiresAuth: true,
            menuPage: 'abandoned-carts'
        },
        children: [
            {
                path: '/recovery/abandoned-carts/:id/',
                component: RecoveryAbandonedCartEditInformations,
            },
            {
                path: '/recovery/abandoned-carts/:id/timeline/',
                component: RecoveryAbandonedCartEditTimeline,
            },
        ],
    },

    {
        path: '/recovery/orders-refused',
        name: 'orders-refused',
        component: TheRefusedOrders,
        meta: {
            requiresAuth: true,
            menuPage: 'orders-refused'
        },
        children: [
            {
                path: '/recovery/orders-refused/view/:id',
                component: RefusedView,
            },
        ]
    },
];