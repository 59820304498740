import TheWithdrawal from '@/views/withdrawals/TheWithdrawal';
import TheWithdrawals from '@/views/withdrawals/TheWithdrawals';
import TheAnticipations from '@/views/withdrawals/TheAnticipations';
import TheReceivables from '@/views/withdrawals/TheReceivables';
import WithdrawalsView from '@/views/withdrawals/show/WithdrawalShow';
import WithdrawalsViewInfo from '@/views/withdrawals/show/WithdrawalShowInfo';
import WithdrawalsViewOrders from '@/views/withdrawals/show/WithdrawalShowOrders';
import WithdrawalShowDebts from '@/views/withdrawals/show/WithdrawalShowDebts';
import WithdrawalRequest from '@/views/withdrawals/WithdrawalRequest';


export default [
    {
        path: '/withdrawals',
        name: 'withdrawals',
        component: TheWithdrawal,
        meta: {
            requiresAuth: true,
            menuPage: 'withdrawals'
        },
        children:[
            {
                name: 'witdhrawalWithdrawals',
                path: '/withdrawals',
                component: TheWithdrawals,
            },

            {
                name: 'withdrawalAnticipations',
                path: '/withdrawals/anticipations',
                component: TheAnticipations,
            },
            {
                name: 'withdrawalReceivables',
                path: '/withdrawals/receivables',
                component: TheReceivables,
            },
        ]
    },
    {
        path: '/withdrawals/request',
        name: 'withdrawals-request',
        component: WithdrawalRequest,
        meta: {
            requiresAuth: true,
            menuPage: 'withdrawals'
        }
    },
    {
        path: '/withdrawals/:id',
        name: 'withdrawals-view',
        component: WithdrawalsView,
        meta: {
            requiresAuth: true,
            menuPage: 'withdrawals'
        },
        children: [
            {
                path: '/withdrawals/:id',
                name: 'withdrawals-view-info',
                component: WithdrawalsViewInfo,
            },
            {
                path: '/withdrawals/:id/orders',
                name: 'withdrawals-view-orders',
                component: WithdrawalsViewOrders,
            },
            {
                path: '/withdrawals/:id/debts',
                name: 'withdrawals-view-debts',
                component: WithdrawalShowDebts,
            },
        ]
    },


];