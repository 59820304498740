<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Produto</th>
                    <th>Qtd</th>
                    <th>Valor</th>
                    <th class="row_right">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(product, key) in props.products" :key="key">
                    <td>
                        {{ product.title }}
                        <BaseBadget small class="ms-1" v-if="product.type != 'primary'" icon="fa-arrow-up" iconType="fas" color="#28a745" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="globalStore.capitalizeText(product.type)" />
                    </td>
                    <td>{{ product.qty }}</td>

                    <td v-if="product.price_promotional">
                        <del class="d-block">{{ globalStore.formatMoney(product.price) }}</del> 
                        {{ globalStore.formatMoney(product.price_promotional) }}
                    </td>
                    <td v-else>{{ globalStore.formatMoney(product.price) }}</td>

                    <td v-if="product.total">{{ globalStore.formatMoney(product.total) }}</td>
                    <td v-else>{{ globalStore.formatMoney(product.total) }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="3">Subtotal</td>
                    <td class="row_right">{{ globalStore.formatMoney(props.table_values.subtotal) }}</td>
                </tr>
                <tr v-if="props.table_values.shipping">
                    <td colspan="3">Entrega ({{ props.table_values.shipping.title }})</td>
                    <td class="row_right">{{ globalStore.formatMoney(props.table_values.shipping.cost) }}</td>
                </tr>
                <tr>
                    <td colspan="3">
                        Descontos
                        <span v-if="props.table_values.discounts.discount_payment" class="d-block fw-light">Método de pagamento: {{ globalStore.formatMoney(props.table_values.discounts.discount_payment) }}</span>
                        <span v-if="props.table_values.discounts.coupon_code" class="d-block fw-light">Cupom ({{ props.table_values.discounts.coupon_code }}) : {{ globalStore.formatMoney(props.table_values.discounts.discount_coupon) }}</span>
                    </td>
                    <td class="row_right">{{ globalStore.formatMoney(props.table_values.discounts.total) }}</td>
                </tr>
                <tr>
                    <td colspan="3">Total
                    </td><td class="row_right">{{ globalStore.formatMoney(props.table_values.total) }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script setup>
const props = defineProps({
    products: {
        required: true,
        default: null
    },
    table_values: {
        required: true,
        default: null
    }
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
</script>