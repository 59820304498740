<template>
     <BaseHeader :title="title" removeSearchBar></BaseHeader>
    <div class="container px-2 px-lg-4">
        <div class="box_content p-4">
            <h6 style="font-weight: 600; font-size: 1rem">
                Ganhe com indicações na plataforma!
            </h6>
            <p class="m-0 p-0">
                Amplie suas oportunidades com nosso programa de indicações! Use seu link exclusivo para convidar novos produtores para a nossa plataforma. Para cada produtor que se registrar e realizar uma venda através do seu link, você receberá uma comissão de 1%. É uma ótima forma de aumentar seus ganhos enquanto ajuda a comunidade a crescer. Comece a compartilhar e ganhar hoje mesmo!
            </p>
            <div class="col-12 mt-4">
                <div class="input_copy">
                    <input id="content" disabled v-model="total.url">
                    <button @click="copyURL(total.url)" id="copy_button" class="btn">Copiar url</button>
                </div>
            </div>
        </div>
        <div class="box_content">
            <div class="table-responsive table_bordered">
                <table class="table">
                    <thead class="divisor_bottom">
                        <th class="thead">Indicados</th>
                        <th class="thead">
                            Vendas 
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Número de vendas realizadas pelos indicados" class="ms-1"><font-awesome-icon icon="fas fa-circle-info"/></span>
                        </th>
                        <th class="thead">Comissão
                            <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Comissão recebida pelas vendas dos indicados" class="ms-1"><font-awesome-icon icon="fas fa-circle-info"/></span>
                        </th>
                        <th class="thead"></th>
                    </thead>
                    <tbody v-if="recommendations">
                        <template v-for="(recommendation, key) in recommendations" :key="key">
                            <tr class="module">
                                <td>{{ recommendation.name }}</td>
                                <td>{{ recommendation.sales }}  </td>
                                <td>{{ globalStore.formatMoney(recommendation.comission) }}</td>
                                <td></td>
                                <td class="actions">
                                    <a :href="'/reports/referrals/' + recommendation.code" @click.prevent="viewRecomendationReport(recommendation.code, recommendation.name)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Visualizar">
                                        <font-awesome-icon icon="fas fa-eye" />
                                    </a>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <h6 v-if="!recommendations.length" class="text-center p-4 mb-0 ">Nenhum resultado encontrado</h6>
            </div>
        </div>
    </div>
</template>
<script setup>
const title = {title: 'Relatório: Minhas indicações', icon: 'fa-chart-simple'};

import { ref, onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';

import { useReportStore } from '@/stores/report';
const reportStore = useReportStore();
const { recommendations, total } = storeToRefs(reportStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

const isLoading = ref(true);

import { useRouter } from 'vue-router';
const router = useRouter();

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('report_recommendations')) {
        return router.push('/403');
    }
    
    loadRecommendations().then(() => {
        isLoading.value = false;
    });
});

let loadDefaultStore = false;
watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        reportStore.recommendations_rep_filters.page = null;
        globalStore.loader('show');
        loadRecommendations().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

async function loadRecommendations() {
    await reportStore.tryRecommendations();
    globalStore.loader('hide');
}

function copyURL(url) {
    navigator.clipboard.writeText(url);
    var button = document.getElementById('copy_button');
    button.innerHTML = 'Copiado';
    button.style.backgroundColor = '#28a745';
    setTimeout(() => {
        button.innerHTML = 'Copiar código';
        button.style.backgroundColor = '#000';
    }, 1000);
}

async function viewRecomendationReport(id, name){
    globalStore.loader('show');
    reportStore.recommendation = name;
    await reportStore.tryRecommendationMetrics(id);
    await reportStore.tryRecommendationChart(id);
    router.push('/reports/recommendations/' + id);
}

</script>
<style scoped>
.input_copy{
    border: 1px #ddd solid;
    padding: 4px 10px;
    height: 40px;
    padding-right: 140px;
    font-size: 18px;
    text-align: left;
    border-radius: 40px;
    position: relative;
    width: 100%;
}

.input_copy input{
    border: 0;
    width: 100%;
    font-size: 14px;
    background-color: white;
}

.input_copy button {
    background-color: #000;
    color: #fff !important;
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 5px 15px 5px 15px;
    border-radius: 25px;
    font-size: 14px;
    width: 132px;
    text-align: center;
}

.input_copy button:hover{
    background-color: #505050;
}

.input_copy input:focus {
    outline: none;
}

</style>