<template>
    <div :class="'mb-3 ' + [!login ? 'form-group' : ''] + [(type == 'password' || tag) && !textarea ? ' position-relative' : '']">
        <label v-if="label" :for="id" :class="[login ? 'login-label' : '']+ ' ' + [error && !login ? 'danger-label' : '']">
            {{ label }}
            <span 
                v-if="tooltip" data-bs-toggle="tooltip" 
                data-bs-placement="top" data-bs-animation="true" 
                v-tooltip :title="tooltip" class="ms-1">
                <font-awesome-icon icon="fas fa-circle-info" />
            </span>
        </label>

        <p  v-if="sub_label" class="sub_label">{{ sub_label }}</p>
        <span v-if="tag && !textarea" class="input-group-text">{{ tag }}</span>
        
        <div class="position-relative">
            <input
                v-if="!textarea" 
                :id="id"
                :type="currentType"
                :class="'form-control base-input ' + [login ? 'login' : ''] + ' ' + [error && !login ? 'danger-border' : '' ] + ' ' + [tag && !textarea ? 'base-input-group' : ''] + ' ' + [type == 'password' ? 'eye-spacing' : '']"
                :required="!!required"
                :value="modelValue"
                @input="updateInput"
                @focus="focusedInput"
                @blur="bluredInput"
                :maxlength="maxlength"
                :disabled="disabled == 1"
                v-maska
                :data-maska="mask"
                :data-maska-tokens="tokens"
                :data-maska-reversed="reversed"
                :placeholder="placeholder"
            />

            <textarea v-else
                :id="id"
                :class="'form-control base-input textarea ' + [login ? 'login' : ''] + ' ' + [error && !login ? 'danger-border' : '']"
                :required="!!required"
                :value="modelValue"
                @input="updateInput"
                rows="4"
                :disabled="disabled == 1"
            ></textarea>

            <span class="eye" v-if="type == 'password'" @click="clickEye">
                <font-awesome-icon v-if="currentType == 'password'" icon="fas fa-eye-slash" />
                <font-awesome-icon v-else icon="fas fa-eye" />
            </span>

            <span v-if="!!error && login" class="invalid-feedback error_login" :class="[error ? 'd-block' : '']" role="alert"><strong>{{ error }}</strong></span>

            <span v-if="!!error && !login" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
        </div>
    </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global';
import { vMaska } from "maska"

export default {
    emits: ["update:modelValue", "focusedInput", 'bluredInput'],
    directives: { maska: vMaska },
    data() {
        return {
            global: useGlobalStore(),
            currentType: this.$props.type,
            pageProp: this.$props.page,
        }
    },

    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        
        sub_label: {
            type: String,
            required: false,
            default: null,
        },  

        id: {
            type: String,
            required: true,
        },

        type: {
            type: String,
            required: false,
            default: 'text'
        },

        required: {
            type: Boolean,
            required: false,
            default: null,
        },

        textarea: {
            type: Boolean,
            required: false,
            default: false,
        },

        modelValue: {
        },

        page: {
            type: String,
            required: false,
        },

        login: {
            type: Boolean,
            required: false,
            default: false,
        },

        maxlength: {
            required: false,
            default:null,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        mask: {
            required: false,
            default:null,
        },

        tokens: {
            required: false,
            default: null,
        },
        
        reversed: {
            type: Boolean,
            default: false,
            required: false,
        },

        placeholder:{
            required:false,
            default:null,
        },

        tooltip:{
            required:false,
        },

        tag:{
            required: false,
            default: null,
        },

        eye: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    methods: {
        updateInput(event) {
            this.$emit("update:modelValue", event.target.value);

            if(event.isTrusted == true && this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },
        clickEye() {
            if(this.$props.type == this.currentType && this.currentType == 'password') {
                this.currentType = 'text';
            }else {
                this.currentType = 'password';
            }
        },
        focusedInput(){
            this.$emit("focusedInput")
        },

        bluredInput(){
            this.$emit("bluredInput")
        }
    },
    watch: {
        pageProp (value){
            this.global.fields_pages[this.$props.id] = value;
        }
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>

<style scoped>
label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: #545454;
}

.login-label {
    min-height: 21px;
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
}

.base-input {
    border-radius: 6px;
    box-sizing: border-box;
    height: 39px;
    padding: 8px 15px;
    box-shadow: none;
    border: 1px solid #d7d7d7;
    font-size: 14px;
    color: #495057;
}

.base-input:focus {
    outline: 0;
    border: 2px solid #215eda !important;
    box-shadow: inset 0 0 0 0px #215eda, 0 0 0 4px rgb(16 114 235 / 16%), inset 0 0 0 1px #215eda !important;
}

.form-group .base-input:focus,  .base-input-group:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 8%) !important;
    border: 1px solid #b8b8b8 !important;
    outline: none !important;
}

.login {
    border: 2px solid #d7d7d7;
}

.login:focus {
    outline: none !important;
    box-shadow: none;
    border: 2px solid #d7d7d7;
}

.error_login {
    font-size: 11px;
    margin-bottom: 15px;
}

.danger-border {
    border-color: #fd6d6d;
    box-shadow: 0px 0px 0px 3px #ffe7e7 !important
}

.danger-label {
    color: #d60e0e;
}

.base-input-group {
    padding: 8px 15px 8px 45px;
}

.sub_label{
    font-size: 13px;
    font-weight: 400;
    color: #545454;
    margin-top: -10px;
    margin-bottom: 4px;
}

.input-group-text {
    position: absolute;
    height: 37px;
    background: #ffffff;
    border-radius: 20px 0 0 20px !important;
    font-size: 13px;
    z-index: 1;
    padding: 8px 0px;
    width: 35px;
    justify-content: center;
    margin-left: 1px;
    margin-top: 1px;
    border: 0;
    border-right: 1px #ddd solid;
}

.textarea {
    height: auto;
}

.eye {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: absolute;
    top: 9px;
    right: 14px;
    color: #d7d7d7;
    cursor: pointer;
}

.eye:hover {
    color: #545454;
}

.eye-spacing {
    padding: 8px 40px 8px 15px;
}

.danger-border::-webkit-input-placeholder {
  color: #d60e0e;
}

.danger-border:-moz-placeholder{
  color: #d60e0e;
}

.danger-border::-moz-placeholder{
  color: #d60e0e;
}

.danger-border:-ms-input-placeholder {
  color: #d60e0e;
}
</style>

