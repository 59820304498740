<template>
    <template v-if="order.id">
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <template v-if="order.id">
                <header>
                    <span @click="click" class="me-2 align-middle">Pedido #{{ order.id }}</span>
                </header>
                <div class="content">
                <div class="row">
                    <BaseInput
                        :class="[order.client.cpf ? 'col-md-6' : 'col-12']"
                        label="Nome *"
                        id="name"
                        :page="route.name"
                        v-model="order.client.name"
                    />
                    <BaseInput
                        class="col-md-6"
                        label="E-mail *"
                        id="email"
                        :page="route.name"
                        v-model="order.client.email"
                    />
                    <BaseInput
                        class="col-md-6"
                        label="Telefone *"
                        id="phone"
                        :page="route.name"
                        mask="['(##) ####-####', '(##) #####-####']"
                        v-model="order.client.phone"
                    />
                    <BaseInput
                        v-if="order.client.cpf"
                        class="col-md-6"
                        label="CPF/CNPJ *"
                        id="cpf"
                        :page="route.name"
                        mask="['###.###.###-##', '##.###.###/####-##']"
                        v-model="order.client.cpf"
                    />
                    <template v-if="order.client.address || order.client.postcode">
                    <BaseInput
                        class="col-md-4 loadCep"
                        label="CEP *"
                        id="postcode"
                        :page="route.name"
                        :mask="'#####-###'"
                        v-model="order.client.postcode"
                        @input="debounceCep"
                    />
                    <BaseInput
                        class="col-md-8 loadCep"
                        label="Endereço *"
                        id="address"
                        :page="route.name"
                        v-model="order.client.address"
                    />
                    <BaseInput
                        class="col-md-4"
                        label="Número *"
                        id="address_number"
                        :page="route.name"
                        type="tel"
                        v-model="order.client.address_number"
                    />
                    <BaseInput
                        class="col-md-8 loadCep"
                        label="Bairro *"
                        id="neighborhood"
                        :page="route.name"
                        v-model="order.client.neighborhood"
                    />
                    <BaseInput
                        class="col-md-12"
                        label="Complemento *"
                        id="complement"
                        :page="route.name"
                        v-model="order.client.complement"
                    />
                    <BaseInput
                        class="col-md-6 loadCep"
                        label="Cidade *"
                        id="city"
                        :page="route.name"
                        v-model="order.client.city"
                    />
                    <BaseSelect
                        class="col-md-6 loadCep"
                        label="Estado *"
                        :required="true"
                        placeholder="Selecione"
                        id="state"
                        :page="route.name"
                        :items="global.statesList()"
                        v-model="order.client.state"
                    />
                    </template>
                    <div class="mt-3 form-group col-12">
                        <BaseButton :loading="isLoading" @click="updateClient" class="btn-primary w-100" :disabled="isLoading">Salvar</BaseButton>
                    </div>
                    </div>
                </div>
            </template>
        </BasePopup>
    </template>    
</template>

<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from 'pinia'
import { useOrderStore } from '@/stores/order';
import { useRoute, useRouter } from 'vue-router'; 

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const router = useRouter();
const route = useRoute();
let previousPage = router.options.history.state.back;

import { useGlobalStore } from "@/stores/global";
const global = useGlobalStore();

onMounted(() => {
    delete global.errors[route.name];
});

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

let debounce = null;
function debounceCep(event) {
    clearTimeout(debounce);
    if(event.target.value.length == 9) {
        debounce = setTimeout(() => {
            global.getAddressByCep(event.target.value).then((r) => {
                orderStore.order.client.address = r.address;
                orderStore.order.client.neighborhood = r.neighborhood;
                orderStore.order.client.city = r.city;
                orderStore.order.client.state = r.state;
            });
        }, 700);
    }
}

const isLoading = ref(false);
function updateClient() {
    if(!isLoading.value){
        let params = ['name', 'email', 'phone', 'cpf', 'postcode', 'address', 'address_number', 'neighborhood', 'complement', 'city', 'state']
        isLoading.value = true;
        orderStore.tryUpdateClient(route.name, orderStore.order.id, params).then((r) => {
            isLoading.value = false;
            if(r) {
                router.push('/orders/' + orderStore.order.id);
            }
        });
    }
}
</script>

<style scoped>

header{
    width: 100%;
    display: block!important;
    margin: 0;
    padding-top: 6px;
    font-size: 16px;
    color: #646464;
    font-weight: 600;
}
.base-title .align {
    display: flex;
    align-items: center;
    min-height: 28px;
    max-width: 1350px;
    margin: 0 auto;
}

.base-title .space-between {
    justify-content: space-between;
}

.title_div{
    display: inline-block;
    font-size: 18px;
    margin-top: 0;
    color: #414951;
    font-weight: 600;
}

.title_div svg {
    width: 21px;
    margin-left: 0px;
    margin-right: 9px!important;
    fill: #215eda;
    color: #215eda
}

.btn_save{
    background: #215eda;
    color: #fff !important;
    border-radius: 25px;
    width: 104px;
    height: 37px;
}

.btn_save:focus {
    box-shadow: 0 0 0 0.2rem rgb(138 179 224 / 50%) !important;
}

.btn_save:hover {
    background: #2750a3;
}

.btn_save:active {
    background-color: #0069d9!important;
    border-color: #0062cc!important;
}

.content_division{
    border-bottom: 1px solid #d7d7d7;
}

strong{
    font-weight: 600;
}

:deep(.base-select){
    background-color: white!important;
}

@media screen and (max-width: 992px){

    .container:first-of-type{
        margin-top: 0px;
        padding-top: -10px!important;
    }

    .p-4 {
        padding: 1.2rem!important;
    }

    .base-title {
        text-align: center;
        justify-content:right!important;
    }

    .title_div{
        float: right;
    }

    .btn-group{
        display: inline;
    }
}

</style>