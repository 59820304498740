import { createRouter, createWebHistory } from 'vue-router'

import auth from './auth';
import TheHome from '@/views/home/TheHome';
import orders from './orders';
import stores from './stores';
import products from './products';
import refunds from './refunds';
import campaigns from './campaigns';
import withdrawals from './withdrawals';
import support from './support';
import recovery from './recovery';
import coproductors from './coproductors';
import reports from './reports';
import users from './users';
import account from './account';

import TheNotFound from '@/views/errors/TheNotFound';
import TheForbidden from '@/views/errors/TheForbidden';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...auth,
        {
            path: '/',
            name: 'home',
            component: TheHome,
            meta: {
                requiresAuth: true,
                menuPage: 'home'
            }
        },
        ...orders,
        ...stores,
        ...products,
        ...refunds,
        ...campaigns,
        ...withdrawals,
        ...support,
        ...recovery,
        ...coproductors,
        ...reports,
        ...users,
        ...account,
        {
            path: '/403',
            component: TheForbidden,
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: '/:notFound(.*)',
            component: TheNotFound,
            meta: {
                requiresAuth: false
            }
        },
    ],
})

export default router;