<template>
    <div class="row">
        <div @click="editIntegration(integration.slug, integration.id, key)" v-for="(integration, key) in integrations" :key="key" :to="'/stores/' + store_id + '/integrations/' + integration.title" class="col-md col-sm-12 col-xl-4 mb-4">
            <div class="inte-box p-4">
                <h6>{{ integration.title }}</h6>
                <p class="mb-4">{{ integration.category }}</p>
                <BaseButton :class="'w-100 ' + buttonColor(integration.status, integration.state)">
                    <template v-if="integration.status == 1">
                        <span v-if="integration.state == 'authenticated'">Ativada <font-awesome-icon icon="fas fa-check" /></span>
                        <span v-else>Configurar <font-awesome-icon icon="fas fa-exclamation-triangle" /></span>
                    </template>
                    <span v-else>Ativar</span>
                </BaseButton>
            </div>
        </div> 
    </div>
    <router-view></router-view>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from '@/stores/global';
import { useStoreStore } from '@/stores/store';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { integrations } = storeToRefs(storeStore);

const globalStore = useGlobalStore();

const store_id = computed(function () {
    return route.params.id;
});

function buttonColor(status, state) {
    if(status == 1 && state == 'error_authentication') {
        return 'btn-danger';
    }else if(status == 1) {
        return 'btn-primary';
    }else {
        return 'btn-white';
    }
}

function editIntegration(slug){
    globalStore.loader('show')
    storeStore.tryIntegration(slug).then(() =>{
        router.push('/stores/' + store_id.value + '/integrations/' + slug)
        globalStore.loader('hide')
    })
}

</script>
<style scoped>
.inte-box{
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
}

.inte-box:hover{
    background-color: #fdfdfd;
}

h6 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 5px;
    color: #414951;
}

p {
    color: #595959;
    opacity: 0.5;
}
</style>