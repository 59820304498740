<template>
    <span :class="'badget ' + [small ? 'small' : ''] + ' text-center'" :style="{ backgroundColor: color }"><font-awesome-icon preserveAspectRatio="none" v-if="icon != null" :icon="iconType + ' ' + icon" /><span v-if="text">{{ text }}</span></span>
</template>

<script>
export default {
    props: {
        color: {
            required: true,
        },
        iconType: {
            required:false,
            default: 'far'
        },
        icon: {
            required: false,
            default: null
        },
        text: {
            required: false,
            default: null,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    }
};
</script>

<style scoped>
.badget {
    padding: 2px 8px;
    font-size: 12px;
    background-color: #ccc;
    color: #fff;
    border-radius: 25px;
    white-space: nowrap;
    font-weight: 500;
}

svg {
    margin-right: 4px;
}
svg:last-child {
    margin: 0;
}

.small {
    padding: 1px 5px !important;
    font-size: 10px !important;
}
</style>