<template>
    <div class="row">
        <div class="col-xl-8 col-12">
            <div class="box_content p-4">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <p class="mb-3">
                            <strong class="d-block">Nome</strong>
                                {{ cart.client.name}}
                        </p>
                    </div>
                    <div class="col-md-6 col-12">
                        <p class="mb-3">
                            <strong class="d-block">Telefone</strong>
                            {{ cart.client.phone }}
                        </p>
                    </div>
                    <div class="col-md-6 col-12">
                        <p class="mb-3">
                            <strong class="d-block">E-mail</strong>
                            {{ cart.client.email }}
                        </p>
                    </div>
                    <div class="col-md-6 col-12">
                        <p class="mb-3">
                            <strong class="d-block">Data</strong>
                            {{ globalStore.formatDate(cart.created_at) }}
                        </p>
                    </div>
                    <div v-if="cart.checkout.city" class="col-md-6 col-12">
                        <p class="mb-3">
                            <strong class="d-block">Cidade</strong>
                            {{ cart.checkout.city }}
                        </p>
                    </div>
                    <div v-if="cart.checkout.state" class="col-md-6 col-12">
                        <p class="mb-3">
                            <strong class="d-block">Estado</strong>
                            {{ cart.checkout.state }}
                        </p>
                    </div>
                    <div v-if="utmsList.length > 0" class="col-12">
                        <strong class="d-block mb-2">UTM:</strong>
                        <p v-for="(utm, i) in utmsList" :key="i"><b>{{ utm.utm_title + ": "}}</b> {{ utm.utm_value }} </p>
                    </div>
                </div>
            </div>
            <div class="box_content">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Produto</th>
                                <th>Quantidade</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, key) in cart.products" :key="key">
                                <td>
                                    {{ product.id }}
                                </td>
                                <td>{{ product.title }}</td>
                                <td>{{ product.qty }}</td>
                                <td>{{ globalStore.formatMoney(product.total) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-12">
            <div class="box_content p-4">
                <h6 class="mb-3">Alterar status</h6>
                <BaseSelect
                    id="status"
                    :items="cartStatusList"
                    v-model="cartStore.cart.status.id"
                    :page="route.name"
                />
                <h6 class="mt-5 mb-2">Anotações</h6>
                <div class="mt-3 mb-4">
                    <ul v-if="cart.notes" id="order_notes">
                        <li v-for="(note, key) in cart.notes" :key="key" :class="cart.notes.length === 1 ? 'single_note': ''">
                            <p class="m-0" v-html="note.text"></p>
                            <span>{{ globalStore.formatDateLong(note.date) }}</span>
                        </li>
                    </ul>
                    <ul v-else id="order_notes">
                        <li><div class="loading_component"></div></li>
                        <li><div class="loading_component"></div></li>
                    </ul>
                </div>
                <BaseInput
                    id="note"
                    textarea
                    v-model="cartStore.cart.note"
                    :page="route.name"
                />
                <p>Lembrete</p>
                <BaseDatePicker placeholder="" id="reminder" class="mb-2" v-model="cartStore.cart.reminder" :page="route.name"/>
            </div>
        </div>
    </div>

</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia'
const route = useRoute();
const router = useRouter();

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

import { useCartStore } from '@/stores/cart';
const cartStore = useCartStore();
const { cart } = storeToRefs(cartStore);


const cartStatusList = ref({
    0: 'Abandonado',
    1: 'Pedido realizado',
    2: 'Convertido',
    3: 'Pendente',
    4: 'Não convertido',
})

const utmsList = ref([]);

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('abandoned_carts')) {
        return router.push('/403');
    }

    for(let key in cartStore.cart.utms){
        if(cartStore.cart.utms[key] !== null){
            utmsList.value.push({utm_title: key, utm_value: cartStore.cart.utms[key]})
        }
    }
})

</script>

<style scoped>
#order_notes {
  padding: 5px;
  margin-bottom: 0;
}

#order_notes li {
  list-style: none;
  padding-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

#order_notes li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

#order_notes li span {
  display: block;
  font-size: 12px;
  color: #a0a0a0;
}

#order_notes li:before {
  content: '';
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  border-radius: 25px;
  position: absolute;
  margin-top: 4px;
  left: -2px;
  border: 3px #205ed9 solid;
  z-index: 1;
}

#order_notes li:after {
  content: '';
  width: 4px;
  height: 100%;
  background-color: #205ed9;
  position: absolute;
  margin-top: 0px;
  top: -16px;
  left: 3px;
}

#order_notes li:first-child:after {
  margin-top: 22px;
}

#order_notes li:last-child:after {
  margin-top: -6px;
}


#order_notes .single_note:after{
    display: none!important;
}

strong{
    font-weight: 600;
}

.top_icon_margin{
    margin-left: 13px;
}

.top_icon {
    fill: #215eda;
    color: #215eda;
}

</style>