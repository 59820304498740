<template>
    <div class=" mt-2">
        <BaseTable :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="affiliations.length"  @changePage="changePage" :initialSearch="initialSearch" :noActions="true">
            <tr v-for="(affiliation, key) in coproductorStore.affiliations" :key="key">
                <td width="40%">{{ affiliation.title }}</td>
                <td><BaseStoreDot :title="affiliation.store.title" :color="affiliation.store.color" /></td>
                <td>
                    <template v-if="affiliation.comission_fixed">
                        {{ globalStore.formatMoney(affiliation.comission) }}
                    </template>
                    <template v-else>
                        {{ affiliation.comission }}%
                    </template>
                </td>
            </tr>
        </BaseTable>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';

import { useCoproductorStore } from '@/stores/coproductor';
const coproductorStore = useCoproductorStore();
const { affiliations, paginate } = storeToRefs(coproductorStore);
const tableColumns = ['Produto', 'Loja', 'Comissão'];

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();
const { defaultStore } = storeToRefs(globalStore);

import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();

const isLoading = ref(true);
const params = ref({
    'search': null,
    'page': null
});

let initialSearch = null;

onBeforeMount(() => {

if(route.query.search || route.query.page) {

    Object.keys(params.value).forEach((key) => {
        if(key == 'search') {
            initialSearch = route.query.search;
        }
        let param = route.query[key];
        params.value[key] = (param == undefined) ? null : param;
    });
    }

    loadReferrals().then(() => {
        isLoading.value = false;
    });
});

function search(r) {
    params.value.search = r;
    params.value.page = null;
    loadReferrals();
}

let isChangingPage = false;
async function changePage(page) {
    if(isChangingPage) return;
    params.value.page = page;
    globalStore.loader('show');
    isChangingPage = true;
    loadReferrals().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}

let loadDefaultStore = false;
    watch(defaultStore, function () {
    if(!loadDefaultStore) {
        loadDefaultStore = true;
        params.value.page = null;
        globalStore.loader('show');
        loadReferrals().then(()=> {
            globalStore.loader('hide');
            loadDefaultStore = false;
        });
    }
});

async function loadReferrals() {
    let usedParams = {};
    Object.keys(params.value).forEach((key) => {
        if(params.value[key] != null && params.value[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: params.value[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    await coproductorStore.tryReferrals(params.value);
    globalStore.loader('hide');
}

defineExpose({
    search
})

</script>

<style scoped>

</style>