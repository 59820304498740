<template>
    <div v-if="activeGateway" class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar :redirectBack="previousPage"/>
        <div v-if="withdrawalStore.exceeded_withdrawal === true" class="alert alert-dark" role="alert">
            O limite mensal de R$1.900,00 para pessoa física foi atingido.
        </div>
        <div class="page_content row">
            <div class="nav_div col-md-4 col-12 mx-0 mb-lg-0 mb-4">
                <div class="navigation_bar nav flex-column"  role="tablist" aria-orientation="vertical">
                    <h6>Valores disponíveis</h6>
                    <a v-for="(gateway, key) in withdrawalStore.request" :key="key" :class="[activeGateway == key ? 'active' : '']" @click="selectGateWay(key)">
                        <div class="method_icon">
                            <img :alt="gateway.name" :src="require(`@/assets/images/logo-${key}.svg`)">
                        </div>
                        <div class="method_text">
                            <strong class="d-block">{{ gateway.name }}</strong>
                            {{ globalStore.formatMoney(gateway.total) }}
                        </div>
                        <div class="dot_fill"></div>
                    </a>
                </div>
            </div>
            <div class="forms_div col-md-8 col-12">
                <div class="box_content">
                    <div class="p-4">
                        <h6>Selecione o valor desejado para saque</h6>
                        <p>A seleção de valores é formada pelo somatório de compras realizadas.</p>
                        <BaseRange :max="withdrawalStore.request[activeGateway].items.length" :id="activeGateway" v-model="rangeValue" page="withdrawal"/>
                        <h6 class="mt-1">{{ globalStore.formatMoney(withdrawalStore.request[activeGateway].items[rangeValue]) }}</h6>
                        <span v-if="negativeAmountSelected" class="negative_amount">*Valores negativos se referem a débitos na plataforma por cancelamentos e/ou chargebacks.</span>
                        <p>Taxa de saque: {{ globalStore.formatMoney(withdrawalStore.request[activeGateway].fees) }}</p>
                        <BaseButton class="btn-primary w-100" :disabled="!canWithdrawal || withdrawalStore.exceeded_withdrawal === true" @click="openPopup">Continuar</BaseButton>

                        <BasePopup v-if="showPopup" hideCloseButton closeType="stayOnPage" width="500" @clickToClose="closePopup">
                            <div class="content popup text-center">
                                <h2>Atenção</h2>
                                <h3>Deseja realizar o saque de <b>{{ globalStore.formatMoney(withdrawalStore.request[activeGateway].items[rangeValue]) }}</b>?</h3>
                                <p class="mt-3 mb-0">{{ withdrawalStore.request[activeGateway].information['time'] }}</p>
                                <b>Procure no extrato a descrição: "{{ withdrawalStore.request[activeGateway].information['invoice_name'] }}"</b>
                                <div class="mt-4">
                                    <BaseButton class="btn-secondary m-1" @click="closePopup">Não</BaseButton>
                                    <BaseButton class="btn-success m-1" @click="requestWithdrawal" :disabled="isLoading">Confirmar saque</BaseButton>
                                </div>
                            </div>
                        </BasePopup>
                    </div>            
                </div>    

                <div v-if="activeGateway === 'pagarme'" class="forms_div col-12 mb-4">
                    <div class="box_content">
                        <div class="p-4">
                            <h6>Antecipação</h6>
                            <p>O valor antecipável é formado por 60% do valor das vendas.</p>
                            <p class="mb-0"><b>Valor disponível pra antecipar</b></p>
                            <h6>{{ globalStore.formatMoney(withdrawalStore.request[activeGateway].anticipation) }}</h6>
                            <BaseButton v-if="withdrawalStore.request[activeGateway].can_anticipate" @click="showAnticipationPopup()" class="btn-primary btn-lg w-100 mt-3" :disabled="withdrawalStore.exceeded_withdrawal === true">Solicitar</BaseButton>
                            <p class="m-0" v-else data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip :title="withdrawalStore.request[activeGateway].anticipation > 0 ? 'já existe uma solicitação de antecipação em andamento.' : ''">
                                <BaseButton class="btn-primary btn-lg w-100 mt-3" disabled>Solicitar</BaseButton>
                            </p>
                        </div>            
                    </div>    
                    <BasePopup v-if="showAnticipationRequest" closeType="stayOnPage" width="540" @clickToClose="closeAnticipationPopup()">
                        <div class="anticipation_popup">
                            <header>
                                <font-awesome-icon icon="fa-solid fa-wallet" style="color: #215eda;"/>
                                <span class="ms-2 align-middle">Solicitar antecipação</span>
                            </header>
                            <div class="content">
                                <form @submit.prevent="">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>Valor disponível</label>
                                            <p>{{ globalStore.formatMoney(withdrawalStore.request[activeGateway].anticipation) }}</p>
                                        </div>
                                        <BaseInput
                                            class="col-12 mb-3"
                                            label="Valor desejado"
                                            type="text"
                                            id="amount"
                                            :tag="'R$'"
                                            :mask="'9.99#,##'"
                                            :tokens="'9:[0-9]:repeated'"
                                            :reversed="true"
                                            v-model="amount_anticipation"
                                            :page="route.name"
                                        />
                                    </div>

                                    <p>
                                        Pedidos de antecipação são respondidos em até 24 horas com suas respectivas taxas conforme os <a href="https://fullsale.com.br/termos-de-uso" target="_blank">termos de uso</a> podendo ser aprovado ou rejeitado após análise do time de compliance.
                                    </p>
                                    <p>
                                        Antecipações para o mesmo dia útil precisam ser solicitadas antes das 10:30 da manhã.
                                    </p>
                                    <baseButton @click="simulateAnticipation(amount_anticipation)" :class="'btn btn-primary w-100 mt-2'">Simular antecipação</baseButton>
                                    <h6 v-if="hasSimulated" class="mb-3 mt-4">Resultado da simulação</h6>
                                    <span v-if="hasSimulated && withdrawalStore.simulation_result.anticipation_amount"><b>Valor a antecipar</b></span>
                                    <p v-if="hasSimulated && withdrawalStore.simulation_result.anticipation_amount">{{ globalStore.formatMoney(withdrawalStore.simulation_result.anticipation_amount) }}</p>
                                    <span v-if="hasSimulated && withdrawalStore.simulation_result.anticipation_fee"><b>Taxa de antecipação</b></span>
                                    <p v-if="hasSimulated && withdrawalStore.simulation_result.anticipation_fee">{{ globalStore.formatMoney(withdrawalStore.simulation_result.anticipation_fee) }}</p>
                                    <BaseButton v-if="hasSimulated" @click="requestAnticipation" class="btn btn-primary w-100 mt-2" :disabled="isLoading" >
                                        Solicitar antecipação
                                    </BaseButton>
                                </form>
                            </div>
                        </div>
                    </BasePopup>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const title = {title: 'Solicitar saque'};
import { ref, onBeforeMount, watch, computed } from "vue";
import BasePopup from "@/components/ui/BasePopup.vue";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

import { useGlobalStore } from '@/stores/global'
const globalStore = useGlobalStore();

import { useWithdrawalStore } from '@/stores/withdrawal'
const withdrawalStore = useWithdrawalStore();

const previousPage = ref(null);
const activeGateway = ref(null);
const amount_anticipation = ref(null);
const last_amount_anticipation = ref(null);
const isLoading = ref(false);
const hasSimulated = ref(false)
const rangeValue = ref(0);

onBeforeMount(async () => {
    if(!globalStore.hasPermissionTo('request_withdrawal')) {
        return router.push('/403');
    }

    hasSimulated.value = false;
    previousPage.value = router.options.history.state.back;
    globalStore.loader('show');
    await withdrawalStore.tryWalletWithdrawal();
    globalStore.loader('hide');

    activeGateway.value = Object.keys(withdrawalStore.request)[0];
    rangeValue.value = withdrawalStore.request[activeGateway.value].start_at;
})

function selectGateWay(key){
    activeGateway.value = key;
}

watch(activeGateway, function (e) {
    rangeValue.value = withdrawalStore.request[e].start_at;
});

let debounceAnticipation = null;
watch(amount_anticipation, function(){
    clearTimeout(debounceAnticipation);
    debounceAnticipation = setTimeout(() => {
        if(amount_anticipation.value !== last_amount_anticipation.value){
            last_amount_anticipation.value = amount_anticipation.value;
            hasSimulated.value = false;
        }
    }, 500)
})

const negativeAmountSelected = computed(function () {
    return (withdrawalStore.request[activeGateway.value].items[rangeValue.value] < 0) ? true : false;
});

const canWithdrawal = computed(function () {
    let cur_value = withdrawalStore.request[activeGateway.value].items[rangeValue.value];
    return (cur_value > 0 && cur_value >= withdrawalStore.request[activeGateway.value].fees) ? true : false;
});

const showPopup = ref(false);

function openPopup() {
    showPopup.value = true;
}

function closePopup() {
    showPopup.value = false;
}

const showAnticipationRequest = ref(false);

function requestWithdrawal() {
    if(canWithdrawal.value && withdrawalStore.exceeded_withdrawal === false && !isLoading.value) {
        isLoading.value = true;
        globalStore.loader('show');
        withdrawalStore.tryRequestWithdrawal(activeGateway.value, withdrawalStore.request[activeGateway.value].items[rangeValue.value], 'requestWithdrawal').then(() =>{
            isLoading.value = false;
        })
    }
}

function simulateAnticipation(amount){
    globalStore.loader('show');
    if(amount){
        last_amount_anticipation.value = amount;
        withdrawalStore.trySimulateAnticipation(amount, route.name).then( function(success){
            if(success){
                globalStore.loader('hide');
                hasSimulated.value = true;
            } else{
                globalStore.loader('hide');
            }
        })
    } else{
        globalStore.loader('hide'); 
        return;
    }
}

function requestAnticipation(){
    globalStore.loader('show');

    if(!isLoading.value){
        isLoading.value = true
        withdrawalStore.tryRequestAnticipation(route.name).then(function(response){
        if(response){
            router.push('/support/' + response.id);
            globalStore.loader('hide')
            isLoading.value = false
        } else{
            globalStore.loader('hide')
            isLoading.value = true
            return;
        }
    })
    }
}

function showAnticipationPopup(){
    delete globalStore.errors[route.name];
    showAnticipationRequest.value = true;
}

function closeAnticipationPopup(){
    showAnticipationRequest.value = false;
}

</script>

<style scoped>
.navigation_bar h6{
    padding-top: 18px;
    padding-left: 18px;
    padding-right: 20px;
}

.navigation_bar a{
    border-bottom: 0px;
    padding: 13px 20px 13px 18px;
}

.method_icon{
    font-size: 18px;
    color: #ababab;
    border-radius: 50px;
    margin-left: 0;
    background-color: #fff;
    border: 1px #ccc solid;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    padding: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    float: left;
}

.method_icon > img{
    width: 18px;
}

.active .method_icon > img{
    filter: brightness(0) invert(1);
}
.method_text{
    font-weight: 400;
    float: left;
}

.method_text strong{
    font-weight: 600;
}

.dot_fill{
    position: relative;
    border: 2px #ccc solid;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    float: right;
}

.active{
    background-color: white!important;
    border-color: white!important;
    box-shadow: none;
}
.active .method_icon,
.active .dot_fill{
    background-color: #215eda;
    fill: white;
    color: white;
    border-color: #215eda;

}

.active .method_text{
    color: #215eda;
}

.negative_amount {
    color: #d60e0e;
    font-size: 12px;
    font-weight: 400;
    margin-top: -5px;
    margin-bottom: 10px;
    display: block;
}

.popup .btn-success {
    min-width: 180px!important;
}

.popup .btn-secondary {
    min-width: 100px !important;
    background-color: #aaa!important;
    color: #fff!important;
}

.popup h2 {
    font-size: 20px;
    font-weight: 500;
}

.popup h3 {
    font-size: 18px;
    font-weight: 300;
}
.popup h3 b {
    font-weight: 600;
}

.popup > b {
    font-size: 14px;
    font-weight: 500;
    color: #7e7e7e;
}

.red {
    color: #d60e0e;
}
</style>