<template>
  <div class="box_content pt-1">
    <div class="tab-content">
      <div class="tab-pane active bank_data p-4" id="nav-contact">
        <div class="row">

          <BaseSelect class="col-md-6" label="Banco" type="text" id="bank_account_code" :items="banksList"
            v-model="accountStore.account.bank_account_code" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseSelect class="col-md-6" label="Tipo de conta" type="text" id="bank_account_type" maxlength="150"
            :items="accountTypes" v-model="accountStore.account.bank_account_type" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-4" label="Agência" type="text" id="bank_account_agency" maxlength="8"
            v-model="accountStore.account.bank_account_agency" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-4" label="Conta" type="text" id="bank_account_number" maxlength="10"
            v-model="accountStore.account.bank_account_number" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-4" label="Dígito da conta" type="text" id="bank_account_number_digit" maxlength="1"
            v-model="accountStore.account.bank_account_number_digit" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseSelect class="col-md-6 col-12" label="Tipo de Chave Pix" type="text" id="pix_type" :items="pixKeyTypes"
            v-model="accountStore.account.pix_type" :page="route.name" :disabled="!accountStore.account.is_editable" />

          <BaseInput class="col-md-6 col-12" label="Chave Pix" type="text" id="pix_key"
            v-model="accountStore.account.pix_key" :page="route.name" :disabled="!accountStore.account.is_editable" />

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';

import { useAccountStore } from '@/stores/account'
import { useRoute } from "vue-router";

const accountStore = useAccountStore();
const route = useRoute();

const accountTypes = {
  C: 'Corrente',
  P: 'Poupança',
};

const pixKeyTypes = {
  CNPJ: 'CNPJ',
  CPF: 'CPF',
  TELEFONE: 'TELEFONE',
  EMAIL: 'E-MAIL',
  CHAVE_ALEATORIA: 'CHAVE ALEATORIA',
};

const banksList = ref({})

onBeforeMount(() => {
  for (let i = 0; i < accountStore.all_banks.length; i++) {
    banksList.value[parseInt(accountStore.all_banks[i].code)] = accountStore.all_banks[i].name
  }
})

</script>

<style scoped>
.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

:deep(.form-group) {
  height: 70px;
}

@media screen and (max-width: 992px) {
  .main_title_icon {
    width: 150px;
    margin: 0 auto;
  }
}
</style>