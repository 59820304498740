<template>
    <div class="box_content p-3">
        <BaseSwitch
            id="meta[hide_shipping_methods]"
            style="width: fit-content"
            label="Ocultar seleção de frete no checkout"
            v-model:checked="store.meta.hide_shipping_methods"
            :page="route.name"
        />
        <p>
            <span>Caso essa opção esteja ativa, o frete padrão será definido em todas as compras da loja.</span></p>
    </div>
    <div class="box_content">
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>Método de envio</th>
                        <th>Transportadora</th>
                        <th>Padrão</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <template v-if="shipping_methods">
                    <tbody>
                        <tr v-for="(shipping_method, key) in shipping_methods" :key="key">
                            <td>{{ shipping_method.title }}</td>
                            <td>{{ shipping_method.company_title }}</td>
                            <td v-html="[shipping_method.default === 0 ? 'Não' : 'Sim']"></td>
                            <td>{{ shipping_method.status_name }}</td>
                            <td class="actions">
                                <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar" @click="editMethod(shipping_method.id)"><font-awesome-icon icon="fas fa-edit" /></span>
                                <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover" @click="openPopup(shipping_method.id)"><font-awesome-icon icon="fas fa-trash-alt" /></span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </table>
            <h6 v-if="!shipping_methods.length" class="text-center p-4 mb-0">Nenhum resultado encontrado</h6>    
        </div>
        <div style="text-align:center;" class="p-4 divisor_top">
            <router-link :to="'/stores/' + store_id + '/shipping/methods/new'"> 
                <BaseButton class="btn-primary btn-lg">
                    Adicionar
                </BaseButton>
            </router-link>
        </div>
    </div>
    <router-view :method="propsParams"></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover este método de envio?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteMethod" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { computed, onBeforeMount, ref, shallowRef} from 'vue'
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from "vue-router";
import { useGlobalStore } from "@/stores/global";
import { useStoreStore } from '@/stores/store';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { store, shipping_methods } = storeToRefs(storeStore);

const globalStore = useGlobalStore();

const store_id = computed (function () {
    return route.params.id;
});

const propsParams = ref({
    email: '',
    comission: '',
    status: '',
    select: '',
})

let methodToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null)
const isLoading = ref(false);

onBeforeMount(() =>{
    if(storeStore.store.meta.hide_shipping_methods != 1 || storeStore.store.meta.hide_shipping_methods != '1'){
        storeStore.store.meta.hide_shipping_methods = 0
    } else {
        storeStore.store.meta.hide_shipping_methods = 1
    }
})

async function deleteMethod(){
    if(!isDeleting && !isLoading.value && methodToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isLoading.value = true;
        globalStore.loader('show');
        await storeStore.tryDeleteShippingMethod(methodToDelete).then(function(success) {
            isDeleting = false;
            if(success){
                storeStore.tryShippingMethods().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    router.push('/stores/' + store_id.value + '/shipping/methods');
                    confirmAction.value = null;
                    isLoading.value = false;
                });
            }else {
                globalStore.loader('hide');
                isDeleting = false;
                isLoading.value = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function editMethod(id){
    globalStore.loader('show')
    storeStore.tryShippingMethod(id).then(() =>{
        setTimeout(() =>{
            router.push('/stores/' + store_id.value + '/shipping/methods/' + id).then(() =>{
                globalStore.loader('hide')
            })
        }, 100)
    })
}

function openPopup(id) {
    methodToDelete = id
    confirmAction.value = true;
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}
</script>