<template>
    <div>

    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useCoproductorStore } from '@/stores/coproductor';

const coproductorStore = useCoproductorStore();

const router = useRouter();
const route = useRoute();

onBeforeMount(() =>{
    coproductorStore.tryCoproductorAccept(route.params.code).then(() => {
        router.push('/coproductors')
    })
})

</script>

<style scoped>
</style>