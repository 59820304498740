<template>
    <div>
        <BasePopup closeType="stayOnPage" width="700" @clickToClose="clickToClose">
            <div v-if="isLoaded != false">
                <header>
                    <font-awesome-icon icon="fa-solid fa-badge-percent" style="color: #215eda;"/>
                    <span v-if="route.name == 'storeCouponEdit'"  class="ms-2 align-middle">Editar Cupom</span>
                    <span v-else class="ms-2 align-middle">Novo Cupom</span>
                </header>
                <div class="content">
                    <form @submit.prevent="">
                        <div class="row">
                            <BaseInput
                                class="col-12 mb-3"
                                label="Código *"
                                type="text"
                                id="code"
                                v-model="coupon.code"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-6 mb-3"
                                label="Tipo *"
                                type="text"
                                id="type"
                                :items="selectItems"
                                v-model="coupon.type"
                                :page="route.name"
                                placeholder="Selecione"
                            />
                            <BaseInput
                                class="col-6 mb-3"
                                label="Desconto *"
                                type="text"
                                id="amount"
                                :tag="coupon.type == 'percentage' ? '%' : 'R$'"
                                :mask="coupon.type != 'percentage' ?  '9.99#,##' : `['#', '#.#', '#.##', '##.##', '###.##']`"
                                :tokens="coupon.type != 'percentage' ? '9:[0-9]:repeated' : null"
                                :reversed="coupon.type == 'percentage' ? false : true"
                                v-model="coupon.amount"
                                maxlength="10"
                                :page="route.name"
                            />
                            <BaseDatePicker
                                class="col-6 mb-3"
                                style="margin-top: 0px!important"
                                label="Expiração *"
                                type="text"
                                id="expiration_date"
                                v-model="coupon.expiration_date"
                                :page="route.name"
                            />
                            <BaseInput
                                class="col-6 mb-3"
                                label="Limite de utilização *"
                                type="number"
                                id="limit_utilization"
                                v-model="coupon.limit_utilization"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-12 mb-3"
                                label="Produto"
                                type="text"
                                id="id_product"
                                :items="props.productsList"
                                v-model="coupon.id_product"
                                :page="route.name"
                            />
                            <BaseSelect
                                class="col-md-12"
                                label="Afiliado"
                                id="referral"
                                :items="referralList"
                                v-model="coupon.id_referral"
                                :page="route.name"
                            />
                        </div>
                        <BaseButton @click="submit" class="btn-primary w-100 mt-4" :disabled="isLoading">
                            Salvar
                        </BaseButton>
                    </form>
                </div>
            </div>
        </BasePopup>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useRoute, useRouter } from "vue-router"; 
import { useGlobalStore } from '@/stores/global'
import { useStoreStore } from '@/stores/store';
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();

const globalStore = useGlobalStore();

const storeStore = useStoreStore();
const { coupon } = storeToRefs(storeStore);

const isLoaded = ref(false);
const isLoading = ref(false);

const store_id = computed (function () {
    return route.params.id;
});

const props = defineProps({
    productsList:{
        type: Object,
    }
})

const selectItems = {
    percentage: 'Porcentagem',
    money: 'Valor',
}

const referralList = ref({
    0: 'Todos'
})

onBeforeMount(() =>{
    globalStore.loader('show')
    delete globalStore.errors[route.name];
    
    if(route.name == 'storeCouponEdit'){
        if(storeStore.coupon.id){
            globalStore.loader('hide');
            getreferralList();
            isLoaded.value = true;
        } else {
            storeStore.tryCoupon(route.params.slug, store_id.value).then(() =>{
                globalStore.loader('hide');
                getreferralList();
                isLoaded.value = true;
            })
        }
    } else if(route.name == 'storeCouponCreate'){
        globalStore.cleanObject(storeStore.coupon);
        globalStore.loader('hide');
        getreferralList();
        isLoaded.value = true
    }
})

watch(() => storeStore.coupon.amount, () => {
    if(storeStore.coupon.type === 'percentage'){
        let floatCouponValue = parseFloat(storeStore.coupon.amount).toFixed(2)
        if(floatCouponValue > 100.00){
            storeStore.coupon.amount = '100.00'
        }
    }
});

function getreferralList(){
    for(let i = 0; i < storeStore.referralslist.length; i++){
        if(storeStore.referralslist[i].status_name == "Habilitado" && storeStore.referralslist[i].status == 1) {
            referralList.value[storeStore.referralslist[i].id] = storeStore.referralslist[i].name;
        }
    }
}

let previousPage = router.options.history.state.back;

function clickToClose() {
    if(previousPage !== null){
        router.push(previousPage)
    } else{
        previousPage = route.path.substring(0, route.path.lastIndexOf("/"));
        router.push(previousPage)
    }
}

function submit(){
    if(!isLoading.value){
        isLoading.value = true;
        globalStore.loader('show');
        let params = ['code', 'type', 'amount', 'limit_utilization', 'expiration_date', 'id_product', 'id_referral'];

        if(route.name == 'storeCouponEdit'){
            storeStore.tryUpdateCoupon(route.params.slug, params, route.name).then(function(success) {
                response(success);
            });

        }else if(route.name == 'storeCouponCreate') {
            storeStore.tryCreateCoupon(params, route.name).then(function(success) {
                response(success);
            })
        }
    }
}

function response(r) {
    if(r){
        storeStore.tryCoupons().then(function() {
            globalStore.loader('hide');
            router.push('/stores/' + store_id.value + '/coupons')
        });
    }else {
        globalStore.loader('hide');
    }
    isLoading.value = false;
}

</script>
<style scoped>


#switch_button{
    margin-top: 15px;
}

</style>