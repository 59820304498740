<template>
  <div class="box_content">
    <div class="tab-content">
      <div class="tab-pane active bank_data p-4" id="nav-contact">
        <div class="row">

          <h6 class="mb-3">Suas taxas</h6>
          <div class="col-md-12">
            <p>Cartão de crédito: {{ accountStore.account.payments_meta.pagarme.percent_comission_credit_card }}%</p>
            <p>Pix: {{ accountStore.account.payments_meta.pagarme.percent_comission_pix }}%</p>
            <p>Boleto: {{ accountStore.account.payments_meta.pagarme.percent_comission_boleto }}%</p>
          </div>
          <h6 class="mt-2 mb-3">Prazos de recebimento</h6>
          <div class="col-md-12">
            <p>Cartão de crédito: D+{{ accountStore.account.payments_meta.pagarme.available_days_card_card }}</p>
            <p>Pix: D+{{ accountStore.account.payments_meta.pagarme.available_days_pix }}</p>
            <p>Boleto: D+{{ accountStore.account.payments_meta.pagarme.available_days_boleto }}</p>
          </div>
            <p v-if="accountStore.account.type === 'F'">Limite de saque: R$ 1.900,00 por mês.</p>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { onBeforeMount, ref } from 'vue';
import { useAccountStore } from '@/stores/account';
import { useRouter } from "vue-router";

const router = useRouter();

const accountStore = useAccountStore();

const banksList = ref({})

onBeforeMount(() => {
  if(accountStore.account.status.slug !== 'validated'){
    router.push('/account')
  }
  for (let i = 0; i < accountStore.all_banks.length; i++) {
    banksList.value[parseInt(accountStore.all_banks[i].code)] = accountStore.all_banks[i].name
  }
})

</script>

<style scoped>
.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

:deep(.form-group) {
  height: 70px;
}

@media screen and (max-width: 992px) {
  .main_title_icon {
    width: 150px;
    margin: 0 auto;
  }
}
</style>