<template >
        <div class="box_content">
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th class="ps-3">Autor</th>
                        <th class="ps-3">Comentário</th>
                        <th class="ps-3">Origem</th>
                        <th class="ps-3"></th>
                    </tr>
                </thead>
                <tbody v-if="course_comments.length">
                    <tr v-for="(comment, key) in course_comments" :key="key">
                        <td >
                            {{ globalStore.getFirstName(comment.name) }}
                        </td>
                        <td >
                            {{ globalStore.truncate(comment.message, 20) }}
                        </td>
                        <td>
                            {{ comment.origin }}
                        </td>
                        <td class="actions">
                            <button @click="approveComment(comment.id)" v-if="comment.status == 'pending'" type="button" class="icon_buttons" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Aprovar">
                                 <font-awesome-icon icon="fas fa-check" />
                            </button>
                            <button @click="editComment(comment.id)" type="button" class="icon_buttons" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Responder">
                                 <font-awesome-icon icon="fas fa-comment" />
                            </button>
                            <button @click="openPopup(comment.id)" type="button" class="icon_buttons"  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover">
                                <font-awesome-icon icon="fa-solid fa-trash-can" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!course_comments.length" class="text-center p-4 mb-0 divisor_bottom">Nenhum resultado encontrado</h6>
        </div>
    </div>

    <router-view :moduleId="module_id" @emitComentToDelete="setEmitedComentDelete"></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="700" @clickToClose="clickToClose">
        <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover este comentário?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteComment" style="width:150px" class="btn-success ms-2" :disabled="excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
    </BasePopup>
</template>

<script setup>
import { computed, shallowRef, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';
import { useGlobalStore } from '@/stores/global';
import { useRoute, useRouter } from 'vue-router';

const globalStore = useGlobalStore();

const productStore = useProductStore();
const { course_comments } = storeToRefs(productStore);

const router = useRouter();

let isDeleting = false;
let commentToDelete = null;
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null)

const route = useRoute();

const product_id = computed(function () {
    return route.params.id;
});

function editComment(id){
    globalStore.loader('show')
    productStore.tryCourseComment(product_id.value, id).then( function(success){
        if(success){
            router.push('/products/' + product_id.value + '/course/comments/' + id)
        } 

        globalStore.loader('hide');
    })
}

async function deleteComment() {
    if(!route.params.slug){
        if(!isDeleting && commentToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
            isDeleting = true;
            globalStore.loader('show');
            await productStore.tryDeleteCourseComment(commentToDelete).then(() =>{
                useGlobalStore().successMessage('Comentário removido com sucesso');
                productStore.tryCourseComments(product_id.value).then(() =>{
                    globalStore.loader('hide');
                    isDeleting = false;
                    confirmAction.value = null;
                })
            })
        }
    } else {
        for(let index = 0; index < productStore.course_comment.replies.length; index++){
            if(productStore.course_comment.replies[index].id === commentToDelete){
                productStore.course_comment.replies.splice(index, 1);
            }
        }
    }
    excludeTextConfirm.value = null;
}

function approveComment(comment_id){
    globalStore.loader('show');
    productStore.tryApproveCourseComment(comment_id).then(function(success) {
        if(success){
            useGlobalStore().successMessage('Comentário aprovado com sucesso');

            productStore.tryCourseComments(product_id.value).then(() =>{
                isDeleting = false;
            })
        }

        globalStore.loader('hide');

    });
}

function setEmitedComentDelete(answerComentToDelete){
    commentToDelete = answerComentToDelete;
}

function openPopup(comment_id) {
    if(!route.params.slug){
        commentToDelete = comment_id;
        confirmAction.value = true;
        excludeTextConfirm.value = null;
    }
}

function clickToClose() {
    confirmAction.value = null;
}


</script>

<style scoped>
.first_content td{
    padding-left: 30px!important;
}

tr{
    border-bottom: 1px solid #d7d7d7;
}

.module_item td{
    padding-left: 50px !important;
    border-bottom: 0px;
}

/* navigation */

.page_item {
    background-color: white;
    border: 1px solid #d7d7d7;
    padding: 5px 11px;
}

.move {
    width: 100px;
    border-left: 1px #ddd solid;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.module_item td.move {
    padding-left: 10px !important;
}

table tbody tr:last-child.module_item td {
    border-bottom: 1px #ddd solid !important;
}
</style>