<template>
    <div class="box_content p-4">
        <h6>Campos personalizados no checkout</h6>
        <BaseInput 
            class="col-12 mb-3 mt-4"
            label="Título *"
            type="text"
            id="personalized_fields_title"
            :required="true"
            v-model="product.personalized_fields_title"
            :page="route.name"
        />
        <BaseSwitch  
            label="Exibir campos personalizados no checkout"
            id="show_personalized_fields_on_checkout"
            v-model:checked="product.show_personalized_fields_on_checkout"
            :page="route.name" 
        />
    </div>
    <div class="box_content">
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th class="ps-3">Título</th>
                        <th class="ps-3">Tipo</th>
                        <th class="ps-3"></th>
                    </tr>
                </thead>
                <tbody v-if="personalized_fields.length">
                    <tr v-for="(row, key) in personalized_fields" :key="key">
                        <td>
                            {{ row.title }}
                        </td>
                        <td >
                            {{ typeList[row.type] }}
                        </td>
                        <td class="actions">
                            <button @click="editField(row.id)" type="button" class="icon_buttons icon_view">
                                <font-awesome-icon icon="fas fa-pen-to-square" />
                            </button>
                            <button @click="openPopup(row.id)" type="button" class="icon_buttons icon_view">
                                <font-awesome-icon icon="fa-solid fa-trash-can" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 v-if="!personalized_fields.length" class="text-center p-4 mb-0 divisor_bottom">Nenhum resultado encontrado</h6>
        </div>
        <div style="text-align:center;" class="p-4">
            <BaseButton @click="createField" class="btn-primary btn-lg">
                Adicionar
            </BaseButton>
        </div>
    </div>
    <router-view></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
                <div class="popup ">
                    <p class="title">Atenção</p>
                    <p class="text">Deseja remover esse produto?</p>
                    <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                    <BaseInput
                        label=''
                        type="text"
                        id="excludeTextConfirm"
                        v-model="excludeTextConfirm"
                        :page="route.name"
                        class="col-12"
                    />
                    <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                    <baseButton @click="deleteField" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
                </div>
            </div> 
    </BasePopup>
</template>

<script setup>
import { computed, shallowRef, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter} from 'vue-router'
import { useProductStore } from "@/stores/products";
import { useGlobalStore } from '@/stores/global';

const productStore = useProductStore();
const { personalized_fields, product }  = storeToRefs(productStore); 
const globalStore = useGlobalStore();

const route = useRoute();
const router = useRouter();

const product_id = computed (function () {
    return route.params.id;
});

const typeList = ref({
    'text': 'Texto',
    'date': 'Data',
    'hour': 'Horario',
    'cpf': 'CPF',
    'cnpj': 'CNPJ',
    'rg': 'RG',
    'email': 'E-mail',
})

let fieldToDelete = null;
let isDeleting = false;
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null)
const isLoading = ref(false);

function editField(id){
    globalStore.loader('show')
    productStore.tryField(id).then(function(success){
        if(success){
            router.push('/products/' + product_id.value + '/custom-fields/' + id)
            globalStore.loader('hide')
        } else{
            globalStore.loader('hide')
        }
    })
}


function createField(){
    router.push('/products/' + product_id.value + '/custom-fields/new')
}

async function deleteField(){
    if(!isDeleting && !isLoading.value && fieldToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isLoading.value = true;
        globalStore.loader('show');
        await productStore.tryDeleteField(fieldToDelete).then(function(success) {
            isDeleting = false;
            if(success){
                productStore.tryFields().then(function() {
                    globalStore.loader('hide');
                    isDeleting = false;
                    confirmAction.value = null;
                    isLoading.value = false;
                    router.push('/products/' + product_id.value + '/custom-fields')
                });
            }else {
                isLoading.value = false;
                globalStore.loader('hide');
                isDeleting = false;
            }
            excludeTextConfirm.value = null;
        }); 
    }
}

function openPopup(id) {
    fieldToDelete = id;
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}


</script>

<style scoped>

.table_bordered {
    background: #fff;
    border-radius: 8px;
}

thead {
    background: #fff;
    color: #595959;
}

tbody{
    border-bottom: 1px #ddd solid ;
}

tbody td {
    padding: 12px 16px;
    vertical-align: middle;
    color: #595959;
}

tbody tr:last-of-type {
    border-bottom: 0px solid #fff !important;
}

tbody tr:hover {
    background: #f3f3f3 !important;
}
</style>