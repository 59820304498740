<template v-if="auth.inInitialLoading == false">
    <div v-if="route.meta.blankPage !== true && route.meta.requiresAuth == true">
        <TheMenu @reloadSameRoute="onReloadSameRoute"/>
        <TheHeader @isHidingSensitive="hideSensitive"/>
        <div id="container">
            <router-view :key="reloadComponent"></router-view>
        </div>
        <BasePopup v-if="accountStore.account.terms !== undefined" :termsAndConditions="true" closeType="stayOnPage" width="800" hideCloseButton>
            <component :is="agreeTermsContent" @emitConfirmAction="confirmAgreeTerms" :style="'overflow: scroll;height: ' + termsHeight +'px;'"/>
        </BasePopup>
    </div>
    <router-view v-else></router-view>
    <div id="global_loader"><div></div></div>
</template>

<script setup>
import { watch, ref } from 'vue';
import TheMenu from '@/components/includes/menu/TheMenu';
import TheHeader from '@/components/includes/header/TheHeader';
import { useRoute } from 'vue-router';
import { useAccountStore } from '@/stores/account';
import { useAuthStore } from '@/stores/auth';
import AgreeTermsContent from '@/components/includes/termsconditions/AgreeTermsContent'
import { useGlobalStore } from './stores/global';

const route = useRoute();
const agreeTermsContent = AgreeTermsContent;

const accountStore = useAccountStore();
const authStore = useAuthStore();
const globalStore = useGlobalStore();

const reloadComponent = ref(0)

const termsHeight = ref(window.screen.height - (window.screen.height / 100 * 20))
const pastWindowHeight = ref(window.screen.height);

watch(
    () => authStore.isLogged, () =>{
        if(authStore.isLogged == true){
            accountStore.trySensitiveInfo().then(function(success){
            if(success){
                if(accountStore.user_preferences.sensitive_information == 1){
                    hideSensitive(true);
                } else if(accountStore.user_preferences.sensitive_information == 0){
                    hideSensitive(false);
                }
            }
        })
        }
    }
)

let debounceNotification = null;

watch(route, () => {
    if(route.meta.requiresAuth == true){
        clearTimeout(debounceNotification)

        debounceNotification = setTimeout(() => {
            if(route.meta.requiresAuth == true){
                accountStore.tryAccountNotifications();
            } else{
                return;
            }
        }, 10000)   
    }
})

function hideSensitive(value){
    const root = document.querySelector(':root');

    if(value === true){
        root.style.setProperty('--sensitive_color', '#eee')
        root.style.setProperty('--sensitive_bgcolor',  '#eee')
        root.style.setProperty('--sensitive_radius', '3px',)
        root.style.setProperty('--sensitive_display', 'flex')
        root.style.setProperty('--sensitive_none_property', 'none')
    } else {
        root.style.removeProperty('--sensitive_color', '#eee')
        root.style.removeProperty('--sensitive_bgcolor',  '#eee')
        root.style.removeProperty('--sensitive_radius', '3px',)
        root.style.removeProperty('--sensitive_display', 'flex')
        root.style.removeProperty('--sensitive_none_property', 'none')
    }
}

function confirmAgreeTerms(){
    globalStore.loader('show')
    accountStore.account.terms = 1;
    let params = {
        accountInfo: ['terms'],
    }
    
    accountStore.tryUpdateAccount(params, route.name).then(() =>{
        globalStore.loader('hide')
    })
}

window.addEventListener('resize', adjustTermsHeight)

function adjustTermsHeight(){
    if(pastWindowHeight.value === window.screen.height){
        return;
    } else{
        termsHeight.value = window.screen.height - (window.screen.height / 100 * 20)
        pastWindowHeight.value = window.screen.height;
    }
}

function onReloadSameRoute(){
    reloadComponent.value += 1;
}

</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

@font-face{
    font-family: 'fa-solid-900';
    src: local('fa-solid-900'),
    url('@/components/assets/fontawesome/fa-solid-900.ttf')
}

body {
    font-family: "Poppins", "Roboto", sans-serif !important;
    font-size: 14px !important;
    color: #595959 !important;
    font-weight: 400 !important;
    scrollbar-color: #ccc rgb(0 0 0 / 0%);
    scrollbar-width: thin !important;
}

html, body, #app {
    height: 100%;
    background: #f7f7f7
}

:root{
    --loaderFontSize: 0px;
    --sensitive_color: ;
    --sensitive_bgcolor: ;
    --sensitive_radius: ;
    --sensitive_display: ;
    --sensitive_none_property: ;
}

.form-control {
    color: #495057;
    font-family: "Poppins", "Roboto", sans-serif;
}

a {
    text-decoration: none !important;
    color: #215eda;
}

#global_loader {
    display: none;
    z-index: 9999999;
    width: 35px;
    height: 35px;
    position: fixed;
    left: 50%;
    border-radius: 50px;
    margin-top: 15px;
    margin-left: -20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    bottom: 30px;
}

#global_loader div {
    display: inline-block;
    z-index: 2000;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    border: solid 3px transparent;
    border-top-color: #3cb6cc;
    border-left-color: #3cb6cc;
    border-radius: 100%;
    padding: 10px;
    -webkit-animation: pace-spinner 500ms linear infinite;
    -moz-animation: pace-spinner 500ms linear infinite;
    -ms-animation: pace-spinner 500ms linear infinite;
    -o-animation: pace-spinner 500ms linear infinite;
    animation: pace-spinner 500ms linear infinite;
}

@-webkit-keyframes pace-spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes pace-spinner {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes pace-spinner {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes pace-spinner {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes pace-spinner {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(130deg);
        border-top-color: #215eda;
        border-left-color: #215eda;
    }
    100% {
        transform: rotate(360deg);
        border-top-color: #3cb6cc;
        border-left-color: #3cb6cc;
    }
}

.row_right {
    width: 40px;
    white-space: nowrap;
}

.sensitive_information {
    color: var(--sensitive_color) !important;
    background: var(--sensitive_bgcolor) !important;
    border-radius: var(--sensitive_radius) !important;
    display: var(--sensitive_display);
    -webkit-touch-callout: var(--sensitive_none_property);
    -webkit-user-select:  var(--sensitive_none_property);
    -khtml-user-select:  var(--sensitive_none_property);
    -moz-user-select:  var(--sensitive_none_property);
    -ms-user-select:  var(--sensitive_none_property);
    user-select:  var(--sensitive_none_property);
}

#container {
    padding: 70px 0 20px 70px;
    width: 100%;
}

.box_content {
    margin: 0 auto;
    background: #fff;
    border: 1px #ddd solid;
    border-radius: 8px;
    margin-bottom: 25px;
}

#nav-tab {
    overflow: hidden;
}

#nav-tab > a {
    display: inline-block !important;
    padding: 17px 20px 20px 20px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    color: #767676 !important;
    background: transparent;
    border: none;
    border-top: 3px transparent solid;
    margin-right: 4px;
}

#nav-tab > a:hover {
    color: #414951 !important;
}

#nav-tab > a.active {
    border: none;
    border-top: 3px #215eda solid;
    color: #414951 !important;
}

#nav-tab > a:last-child {
    margin-right: 0px;
}

.divisor_bottom {
    border-bottom: 1px #ddd solid;
}

.divisor_top {
    border-top: 1px #ddd solid !important;
}

.dropdown-toggle:active,
.dropdown-toggle:focus {
    box-shadow: inset 0 0 0 0px #215eda, 0 0 0 4px rgb(16 114 235 / 16%),
        inset 0 0 0 1px #215eda !important;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    font-size: 14px !important;
    padding: 8px 15px !important;
    cursor: pointer !important;
    color: #595959 !important;
    border: none !important;
    width: 100% !important;
    text-decoration: none !important;
}

.dropdown-item:hover, .dropdown-item:focus, dropdown-item:active {
    background-color: #f8f9fa !important;
}

.table>:not(caption)>*>* {
    color: #595959 !important;
}

.box {
    border: 1px solid #d7d7d7;
    border-radius: 8px;
    color: #545454;
}

.box p strong {
    font-weight: 600;
    font-size: 15px;
}

h6, h2{
    color: #595959!important;
}

b, strong{
    font-weight: 600!important;
}

.box:last-child {
    margin-bottom: 0 !important;
}

.navigation_bar {
  background: #fff;
  border: 1px #ddd solid;
  border-radius: 8px;
  text-decoration: none;
  overflow: hidden;
  position: sticky;
  top: 157px;
}

body.scrolling .navigation_bar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.navigation_bar ul{
    flex-direction: column;
    padding-left: 0;
}

.navigation_bar a, .navigation_bar ul li a {
  padding: 15px 20px 15px 18px;
  border-bottom: 1px #ddd solid;
  border-radius: 0px !important;
  font-weight: 600;
  cursor: pointer;
  color: #767676 !important;
  border-left: 2px solid transparent;
  width: 100%;
  display: block;
}

.navigation_bar a, .navigation_bar ul li:last-child a{
    border-bottom: 0;
}

.navigation_bar ul li ul a {
    border-bottom: 0 !important;
}

.navigation_bar a:hover, .navigation_bar ul li a:hover {
  color: #414951 !important;
}

.navigation_bar .active, .navigation_bar ul li a .active {
  color: #414951 !important;
  background-color: #f8f8f8 !important;
  border-left: 3px #215eda solid;
  width: 100%;
  padding-left: 18px;
}

.navigation_bar .tab_always_open_main {
    border-bottom: 0 !important;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: #dfdfdf;
    border-radius: 25px;
    visibility: hidden;
}

:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

.btn-filter {
    white-space: nowrap;
}

.dropdown-menu {
    box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
    border-color: #ddd !important;
    color: #595959;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropdown-toggle::after {
    content: '';
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    margin-left: 6px !important;
    font-family: 'fa-solid-900'!important;
    font-weight: 900;
    content: "\f078" !important;
    font-size: 12px!important;
    vertical-align: middle!important;
    margin-top: -1px;
}

.dropup .dropdown-toggle::after {
    content: '';
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    font-family: 'fa-solid-900'!important;
    font-weight: 900;
    content: "\f077" !important;
    font-size: 13px!important;
}

.actions .dropdown-toggle::after {
    display: none !important;
}

.filter_container {
    overflow: auto;
    width: 360px;
    height: auto;
    max-height: 550px;
    scrollbar-color: #ccc rgb(0 0 0 / 0%);
    scrollbar-width: thin !important;
}

.filter_container .collapse {
    border-bottom: 1px #e4e4e4 solid;
    padding: 5px 20px 10px 20px;
}

.filter_container ul.collapse .collapse {
    border: 0;
}

.filter_container .opened_collapse, .closed_collapse {
    display: none;
}

.filter_container .collapse_title a[aria-expanded="false"] .closed_collapse {
    display: inline-block!important;
}

.filter_container .collapse_title a[aria-expanded="true"]> .opened_collapse {
    display: inline-block!important;
}

.filter_container .expand_button[aria-expanded="true"]{
    display: none;
}

.filter_container .collapse{
    margin-bottom: 0px!important;
}

.filter_container .collapse_title {
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter_container .collapse_title a {
    font-weight: 700;
    padding: 0px 20px;
    font-size: 14px;
    color: #595959!important;
}

.filter_container .collapse_item .form-check {
    margin-bottom: 10px;
}

.filter_container .collapse_items .collapse_item label {
    font-size: 14px;
}

.filter_container .collapse_items .collapse_item input:checked {
    background-color: #215EDA;
}

.filter_container .collapse_items .collapse_item .collapse_title svg {
    width: 14px;
    height: 16px;
    font-weight: 900;
    font-size: 16px;
    color: #707070!important;
    margin-right: 13px;
}

.filter_container .form-check input, .form-check label {
    cursor: pointer;
}

.filter_container .form-check-label{
    width: 92%;
}

.filter_container .filter_only{
    display: none;
    border-radius: 25px;
    padding: 1px 5px;
    float: right;
    white-space: nowrap;
    font-size: 12px;
    cursor: pointer;
    color: #606060;
}

.filter_container .form-check:hover .filter_only{
    display: inline-block;
}

.filter_container .filter_only:hover{
    background-color: #e6e6e6;
}

.filter_container .expand_button {
    color: #215EDA;
    font-size: 13px;
    font-weight: 600;
    background-color: #fff;
    border: none;
    margin-left: 28px;
}

.filter_container .expand_button:hover {
    background-color: #f7f7f7;
}

.filter_container .form-check:last-child {
    margin-block: 0 !important;
}

.filter_container .form-check .form-check-input {
    margin-top: 3px !important;
}

.form-check-input {
    border: 1px #ccc solid !important;
}

.sp-original-input-container .sp-add-on .sp-colorize {
    box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.2);
}

#completion .container {
  max-width: 700px !important;
}

#completion .box {
  background-color: #fff;
  margin-bottom: 200px;
}

#completion .space {
  height: 100px;
}

#completion .actions {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px #ccc solid;
  width: 100%;
  left: 0;
  padding: 20px;
}

.danger-border .otp-input {
    border-color: #fd6d6d !important;
    color: #fd6d6d !important;
}

.opt-box {
    width: 100%;
    margin: 0;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    padding: 0 !important;
}
.otp-input {
    height: 40px;
    border-radius: 8px;
    border: 2px #bfbfbf solid;
    text-align: center;
    width: 100%;
    color: #215eda;
    font-weight: 500;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
    border-color: #215eda;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.opt-box > div > div {
    margin-right: 6px;
}

.opt-box > div > div:last-child {
    margin-right: 0px;
}

#terms_input label {
    font-size: 12px;
}

#terms_input #terms {
    margin-top: 5px;
    border-color: #c8c8c8;
}

.icon_buttons {
    padding-top: 3px;
    color: #215eda !important;
    border-radius: 50px;
    text-decoration: none;
    margin-left: 2px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    border: none;
    background-color: transparent;
}

.icon_buttons svg{
    width: 15px;
    height: 13px;
}

.icon_buttons:hover {
    box-shadow: inset 0px 0px 50px rgb(0 0 0 / 20%);
}

.product_image {
    width: 35px;
    height: 35px;
    background-position: center !important;
    border-radius: 4px;
    display: inline-block;
    margin-right: 20px;
    border: 1px #d7d7d7 solid;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
}

.alert-warning {
    border-color: #d7c179 !important;
}

p.info {
    font-size: 12px;
    color: #505050;
}

@media screen and (max-width: 540px) {
    .filter_container {
       width: 300px;
    }
}

@media (min-width: 576px){
    .container, .container-md, .container-sm {
        max-width: 1400px !important;
    }
}

@media (min-width: 576px){
    .container, .container-sm {
        max-width: 540px;
    }
}

@media screen and (max-width: 768px){
    
    .navigation_bar ul{
        display: flex!important;
        flex-direction: row!important;
        overflow: auto;
        flex-wrap: nowrap!important;
    }
    
    .navigation_bar ul li a{
        width:max-content!important;
    }
}

@media  screen and (max-width: 992px) {
    #container{
        padding: 60px 0.5rem 0px 0.5rem!important;
    }

    #global_loader {
        top: -2px;
        right: 75px;
        left: inherit;
    }
}

@media (min-width: 1200px){
    .tab_always_open:before{
        content: '';
        display: inline-block;
        width: 8px;
        height: 4px;
        background: #215eda;
        border-radius: 25px;
        top: -2px;
        left: -5px;
        position: relative;
        margin-left: 8px;
        margin-right: 10px;
    }
}

@media (max-width: 1199px){
    .navigation_bar ul {
        overflow: scroll;
        display: block !important;
        white-space: nowrap;
    }

    .navigation_bar ul li {
        display: inline-block;
    }

    .navigation_bar .active {
        border-left: 0;
        box-shadow: inset 0px 2px 0px #215eda;
        background-color: #fff !important;
    }

    .navigation_bar a, .navigation_bar ul li a {
        border-bottom: 0;
    }

    .navigation_bar ul li ul {
        display: inline-block !important;
        overflow: inherit;
    }

    .navigation_bar .tab_always_open_main {
        display: none;
    }
}
.alert {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border: 1px solid #ffeeba !important;
}
.alert b {
    font-weight: 500 !important;
}

.customize_item {
    cursor: pointer;
}

.customize_item:hover:not(.container) {
    box-shadow: 0 0 0px 5px rgb(89 158 196 / 50%) !important;
    z-index: 9;
    position: relative;
}

.container.customize_item:hover:not(:has(*:hover)) {
    box-shadow: 0 0 0px 5px rgb(89 158 196 / 50%) !important;
    z-index: 9;
    position: relative;
}
</style>
