import TheMyAccount from '@/views/account/TheMyAccount';
import TheAccessData from '@/views/account/TheAccessData';

import MyAccountInfo from '@/views/account/myaccount/MyAccountInfo';
import MyAccountAddress from '@/views/account/myaccount/MyAccountAddress';
import MyAccountBank from '@/views/account/myaccount/MyAccountBank';
import MyAccountDocuments from '@/views/account/myaccount/MyAccountDocuments';
import MyAccountTaxes from '@/views/account/myaccount/MyAccountTaxes'
import MyAccountAPI from '@/views/account/myaccount/MyAccountAPI'

import AccountCompletion from '@/views/account/completion/AccountCompletion';
import AccountCompletionType from '@/views/account/completion/AccountCompletionType';
import AccountCompletionInformations from '@/views/account/completion/AccountCompletionInformations';
import AccountCompletionAddress from '@/views/account/completion/AccountCompletionAddress';
import AccountCompletionBank from '@/views/account/completion/AccountCompletionBank';
import AccountCompletionDocuments from '@/views/account/completion/AccountCompletionDocuments';

import TheAccountClassification from '@/views/account/TheAccountClassification';
import TheAccountMessage from '@/views/account/TheAccountMessage';

export default [
    {
        path: '/account',
        name: 'account',
        component: TheMyAccount,
        meta: {
            requiresAuth: true,
            menuPage: 'account'
        },
        children: [
            {
                name: 'accountInfo',
                path: '/account',
                component: MyAccountInfo,
                meta: {
                    requiresAuth: true,
                    menuPage: 'account'
                }
            },
            {
                name: 'accountAddress',
                path: '/account/address',
                component: MyAccountAddress,
                meta: {
                    requiresAuth: true,
                    menuPage: 'account'
                }
            },
            {
                name: 'accountBankInfo',
                path: '/account/bank-info',
                component: MyAccountBank,
                meta: {
                    requiresAuth: true,
                    menuPage: 'account'
                }
            },
            {
                name: 'accountDocuments',
                path: '/account/documents',
                component: MyAccountDocuments,
                meta: {
                    requiresAuth: true,
                    menuPage: 'account'
                }
            },
            {
                name: 'accountTaxes',
                path: '/account/taxes',
                component: MyAccountTaxes,
                meta: {
                    requiresAuth: true,
                    menuPage: 'account'
                }
            },
            {
                name: 'accountAPI',
                path: '/account/api',
                component: MyAccountAPI,
                meta: {
                    requiresAuth: true,
                    menuPage: 'account'
                }
            },
        ],
    },
    {
        path: '/account/user',
        name: 'userinfo',
        component: TheAccessData,
        meta: {
            requiresAuth: true,
            menuPage: 'user'
        }
    },
    {
        path: '/account/completion',
        name: 'accountCompletion',
        component: AccountCompletion,
        meta: {
            requiresAuth: true,
            blankPage: true,
            menuPage: 'user'
        },
        children: [
            {
                path: '/account/completion',
                name: 'accountCompletionType',
                component: AccountCompletionType,
                meta: {
                    requiresAuth: true,
                    blankPage: true,
                    menuPage: 'user'
                },
            },
            {
                path: '/account/completion/informations',
                name: 'accountCompletionInformations',
                component: AccountCompletionInformations,
                meta: {
                    requiresAuth: true,
                    blankPage: true,
                    menuPage: 'user'
                },
            },
            {
                path: '/account/completion/address',
                name: 'accountCompletionAddress',
                component: AccountCompletionAddress,
                meta: {
                    requiresAuth: true,
                    blankPage: true,
                    menuPage: 'user'
                },
            },
            {
                path: '/account/completion/bank',
                name: 'accountCompletionBank',
                component: AccountCompletionBank,
                meta: {
                    requiresAuth: true,
                    blankPage: true,
                    menuPage: 'user'
                },
            },
            {
                path: '/account/completion/documents',
                name: 'accountCompletionDocuments',
                component: AccountCompletionDocuments,
                meta: {
                    requiresAuth: true,
                    blankPage: true,
                    menuPage: 'user'
                },
            },
        ]
    },
    {
        path: '/account/classification',
        name: 'classification',
        component: TheAccountClassification,
        meta: {
            requiresAuth: true,
            menuPage: 'user'
        }
    },
    {
        path: '/account/messages/:id',
        name: 'messages',
        component: TheAccountMessage,
        meta: {
            requiresAuth: true,
            menuPage: 'user'
        }
    },

];