import TheSupport from '@/views/support/TheSupport';
import SupportCreate from '@/views/support/create/SupportCreate';
import SupportView from '@/views/support/show/SupportShow';

export default [
    {
        path: '/support',
        name: 'support',
        component: TheSupport,
        meta: {
            requiresAuth: true,
            menuPage: 'support'
        }
    },

    {
        path: '/support/create/new',
        name: 'support-create',
        component: SupportCreate,
        meta: {
            requiresAuth: true,
            menuPage: 'support'
        }
    },

    {
        path: '/support/:id',
        name: 'support-view',
        component: SupportView,
        meta: {
            requiresAuth: true,
            menuPage: 'support',
        }
    }
];