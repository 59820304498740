<template>
    <div class="box_content">
        <div class="p-4">
            <form>
                <div class="row">
                    <BaseInput
                        class="col-12"
                        id="name"
                        label="Nome *"
                        type="text"
                        v-model="userdata.name"
                        @input="setValue"
                        :page="route.name"
                    />
                    <BaseInput
                        class="col-md-6 col-12"
                        id="email"
                        label="E-mail *"
                        type="text"
                        @input="setValue"
                        v-model="userdata.email"
                        :page="route.name"
                    />
                    <BaseInput
                        class="col-md-6 col-12"
                        id="password"
                        label="Senha *"
                        type="password"
                        @input="setValue"
                        v-model="userdata.password"
                        :page="route.name"
                    />
                </div>
            </form>
        </div>
    </div>
</template>
    
<script setup>
import { onBeforeMount, onMounted , ref } from 'vue';
import { useRoute } from "vue-router";

const emit = defineEmits(['emitUserValues'])

const props = defineProps({
    pageParamsName:{
        default: null,
    },

    pageParamsEmail :{
        default: null,
    },

    pageParamsPassword :{
        default: null,
    },
})

const userdata = ref({
    name: '',
    email: '',
    password: null,
})

const route = useRoute();

onBeforeMount (() =>{
    setTimeout(() =>{
        emit('emitUserValues', userdata)
    })
})

onMounted (() =>{
    userdata.value.name = props.pageParamsName
    userdata.value.email = props.pageParamsEmail
    userdata.value.password = props.pageParamsPassword
    setValue()
})

function setValue(){
    emit('emitUserValues', userdata)
}
    
</script>
    
<style scoped>
    
:deep(.eye){
    display: none;
}
:deep(.eye){
   padding: 0px;
}

</style>