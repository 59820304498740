<template>
    <div id="DragableDiv" :onmousedown="props.dragFromHeader === false ? dragMouseDown : ''">
      <slot name="header" :onmousedown="props.dragFromHeader === true ? dragMouseDown : ''">

      </slot>
      <slot name="content">

        </slot>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const clientX = ref(undefined);
const clientY = ref(undefined);
const movementX = ref(0);
const movementY = ref(0);
const draggableDiv = ref(null);

const props = defineProps({
  dragFromHeader:{
    default: false,
  },
  initialTop:{
    required: false,
  },
  initialLeft: {
    required: false,
  },
  startRight: {
    default: false,
    required: false,
  },
  startBottom: {
    default: false,
    required: false,
  },
})

onMounted(() =>{
  draggableDiv.value = document.getElementById('DragableDiv')
  alignDiv() 
})

function dragMouseDown(event){
    if(props.dragFromHeader === false){
      event.preventDefault()
    }
      clientX.value = event.clientX
      clientY.value = event.clientY
      document.onmousemove = elementDrag
      document.onmouseup = closeDragElement
}

function elementDrag(event){
      event.preventDefault()
      movementX.value = clientX.value - event.clientX
      movementY.value = clientY.value - event.clientY
      clientX.value= event.clientX
      clientY.value = event.clientY
      draggableDiv.value.style.top = (draggableDiv.value.offsetTop - movementY.value) + 'px'
      draggableDiv.value.style.left = (draggableDiv.value.offsetLeft - movementX.value) + 'px'

      if(parseInt(draggableDiv.value.style.left.split('px')[0]) + draggableDiv.value.offsetWidth >= (window.innerWidth - 9)){
        draggableDiv.value.style.left = (window.innerWidth - draggableDiv.value.offsetWidth - 10) + 'px';
      }

      if(parseInt(draggableDiv.value.style.left.split('px')[0] - 5) < 0){
        draggableDiv.value.style.left = '5px';
      }

      if(draggableDiv.value.style.top.split('px')[0] < 0){
        draggableDiv.value.style.top = 0;
      }

      if(parseInt(draggableDiv.value.style.top.split('px')[0]) + draggableDiv.value.offsetHeight >= (window.innerHeight + 15)){
        draggableDiv.value.style.top = (window.innerHeight - draggableDiv.value.offsetHeight + 15) + 'px';
      }
      
}

function closeDragElement (){
    document.onmouseup = null
    document.onmousemove = null
}

function alignDiv(){
  if(props.initialTop){
    draggableDiv.value.style.top = props.initialTop;
  }

  if(props.initialLeft){
    draggableDiv.value.style.left = props.initialLeft;
  }

  if(props.startRight){
    draggableDiv.value.style.left = (window.innerWidth - draggableDiv.value.offsetWidth - 10) + 'px';
  }

  if(props.startBottom){
    draggableDiv.value.style.top = (window.innerHeight - draggableDiv.value.offsetHeight + 10) + 'px';
  }
}

window.addEventListener('resize', () => {
  alignDiv()
})

</script>

<style scoped>

#DragableDiv{
    position: fixed;
    z-index: 999;
}

#DragableDiv :deep(#menuCustomHeader){
  cursor: pointer;
}

</style>