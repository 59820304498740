<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title" removeSearchBar redirectBack="/stores">
            <div class="item action btn-group">
                <div class="item action btn-group">
                    <baseButton :disabled="isLoading" class="btn btn-primary w-100" @click="submit('completed')" style="border-top-right-radius: 0!important; border-bottom-right-radius: 0!important;">Salvar</baseButton>
                    <button class="btn btn-primary dropdown-toggle save_draft" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="background-color: #215EDA;"></button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li class="dropdown-item"  @click="submit('draft')"><a>Salvar como rascunho</a></li>
                    </ul>
                </div>
            </div>
        </BaseHeader>
        <div class="box_content p-4">
            <form @submit.prevent="">
                <div class="row">
                    <BaseInput
                        class="col-md-8 col-12"
                        label="Nome da Loja *"
                        type="text"
                        id="title"
                        v-model="store.title"
                        :page="route.name"
                    />
                    <BaseColorPicker
                        class="col-md-4 col-12"
                        label="Cor da Loja"
                        id="color"
                        v-model="store.color"
                        :page="route.name"
                    />
                    <BaseFileUpload
                        class="col-12"
                        label="Logotipo da loja *"
                        id="logotipo"
                        v-model="store.logotipo"
                        :page="route.name"
                        recommended_size="200 x 100"
                        :preview="true"
                    />
                    <BaseInput
                        class="col-12"
                        label="URL do site da loja *"
                        type="text"
                        id="url"
                        v-model="store.url"
                        :page="route.name"
                    />
                    <BaseInput
                        class="col-12"
                        label="Telefone *"
                        type="tel"
                        id="phone"
                        v-model="store.phone"
                        :page="route.name"
                        mask="['(##) ####-####', '(##) #####-####']"
                    />
                    <BaseInput
                        class="col-12"
                        label="Email de contato *"
                        type="text"
                        id="email_support"
                        v-model="store.email_support"
                        :page="route.name"
                    />
                    <BaseInput
                        class="col-12"
                        label="Email para receber notificações *"
                        type="text"
                        id="email"
                        v-model="store.email"
                        :page="route.name"
                    />
                    <div class="col-md-6 col-12">
                        <BaseInput
                            label="Soft descriptor"
                            id="soft_descriptor"
                            v-model="store.soft_descriptor"
                            tooltip_text="Nome da loja que aparecerá na fatura do cartão. Campo alfanumérico, permitindo também: - *"
                            :page="route.name"
                        />
                        <small class="show_descriptor mb-3">Nome na fatura será: Fullsale*{{ store.soft_descriptor }}</small>
                    </div>
                    <BaseSelect
                        class="col-md-6 col-12"
                        label="Garantia de produtos* "
                        :items="all_warranties"
                        :disabled="(all_warranties == null) ? true : false"
                        id="warranty"
                        v-model="store.warranty"
                        placeholder="Selecione"
                        :page="route.name"
                    />
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
const title = {title: 'Nova loja', icon: 'fa-store'};
import { useRoute, useRouter } from "vue-router";
import { ref, onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useStoreStore } from '@/stores/store';
import { useGlobalStore } from '@/stores/global';

const route = useRoute();
const router = useRouter();

const storeStore = useStoreStore();
const { all_warranties, store } = storeToRefs(storeStore);

const globalStore = useGlobalStore();

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('new_store')) {
        return router.push('/403');
    }
    delete globalStore.errors[route.name];

    globalStore.cleanObject(storeStore.store);

    storeStore.store.color = '#215eda';
    
    storeStore.tryGetWarranty();
});

watch(() => storeStore.store.title, (e) => {
    if(e){
        storeStore.store.soft_descriptor = e.replace(/[^A-Za-z0-9*-]/gi, '*').substring(0, 13);
    }
});

const isLoading = ref(false);

async function submit(value) {
    isLoading.value = true;
    globalStore.loader('show');

    let params = ['title', 'url', 'logotipo', 'email', 'email_support', 'color', 'soft_descriptor', 'warranty', 'phone', 'status'];


    if(value === 'completed'){
        storeStore.store.status = 'published';
    } else if(value === 'draft'){
        storeStore.store.status = 'draft';
    }

    storeStore.tryCreateStore(params, route.name).then(function(success) {
        isLoading.value = false;
        globalStore.loader('hide');

        if(success){
            router.push('/stores');
        }
    })
}
</script>

<style scoped>

.save_draft{
    border: 0px;
    border-left: 1px #2750a3 solid;;
}

.save_draft:hover{
    background-color: #2750a3 !important;
}

.dropdown-toggle:empty::after{
    margin: auto!important;
    font-size: 11px!important;
}

@media screen and (max-width: 992px){
    .base-title .item, .base-title .btn-group {
        width: fit-content!important;
    }
}


@media screen and (max-width: 768px){
    .base-title .action {
        min-width: 100%;
    }
}

</style>