<template>
    <div v-if="order.files" class="box_content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Título</th>
                        <th class="row_right"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(file, key) in order.files" :key="key">
                        <td>{{ file.title }}</td>
                        <td class="row_right actions">
                            <span @click="edit(file.id)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Editar"><font-awesome-icon icon="fas fa-pen-to-square" /></span>
                            <span @click="openPopup(file.id)" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-animation="true" v-tooltip title="Remover"><font-awesome-icon icon="fas fa-trash-can" /></span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h6 v-if="!order.files || order.files.length < 1" class="mb-0 pt-4 pb-4 text-center">Nenhum resultado encontrado</h6>

        </div>
        <div v-if="order.files.length < 20" class="text-center p-4">
            <baseButton link :to="'/orders/' + order.id + '/files/new'" class="btn-primary btn-lg">Adicionar</baseButton>
        </div>
    </div>
    <router-view v-if="order.files"></router-view>
    <BasePopup v-if="confirmAction" closeType="stayOnPage" width="400" @clickToClose="clickToClose">
        <div class="confirm_action">
            <div class="popup ">
                <p class="title">Atenção</p>
                <p class="text">Deseja remover esse registro?</p>
                <h6>Escreva a palavra "EXCLUIR" para confirmar.</h6>
                <BaseInput
                    label=''
                    type="text"
                    id="excludeTextConfirm"
                    v-model="excludeTextConfirm"
                    :page="route.name"
                    class="col-12"
                />
                <baseButton @click="clickToClose" style="width:110px" class="btn-secondary me-2">Cancelar</baseButton>
                <baseButton @click="deleteFile" style="width:150px" class="btn-success ms-2" :disabled="isLoading ? true : excludeTextConfirm == 'EXCLUIR' || excludeTextConfirm == 'excluir'  ? false : true">Sim, remover</baseButton>
            </div>
        </div> 
    </BasePopup>
</template>

<script setup>
import { shallowRef, ref} from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global';
import { useOrderStore } from '@/stores/order';
import { useRouter, useRoute } from "vue-router";

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const globalStore = useGlobalStore();

const router = useRouter();
const route = useRoute();

let fileToDelete = null;
const confirmAction = shallowRef(null)
const excludeTextConfirm = ref(null);
const isLoading = ref (false);

let isDeleting = false;
async function deleteFile() {
    if(!isDeleting && !isLoading.value && fileToDelete && excludeTextConfirm.value == 'EXCLUIR' || excludeTextConfirm.value == 'excluir') {
        isDeleting = true;
        isLoading.value = true;
        globalStore.loader('show');
        await orderStore.tryDeleteFile(order.value.id, fileToDelete).then(() => {
            useGlobalStore().successMessage('Arquivo removido com sucesso');

            globalStore.loader('hide');
            isDeleting = false;
            confirmAction.value = null;
            excludeTextConfirm.value = null;
            isLoading.value = false;
        }); 
    }
}

function edit(id) {
    router.push('/orders/' + order.value.id + '/files/' + id);
}

function openPopup(file) {
    fileToDelete = file
    confirmAction.value = true
    excludeTextConfirm.value = null;
}

function clickToClose() {
    confirmAction.value = null;
}

</script>

<style scoped>

table {
    border-bottom: 1px solid #d7d7d7;
}

.table-responsive {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

</style>