<template>
    <form @submit.prevent="submit">
        <BaseInput
            label="E-mail"
            type="email"
            id="email"
            v-model="login.email"
            :required="true"
            :page="page"
            login
        />
        <BaseInput
            class="mb-2"
            label="Senha"
            type="password"
            id="password"
            v-model="login.password"
            :required="true"
            :page="page"
            login
        />
        <div class="text-end">
            <router-link to="forgot-password"
                >Esqueceu sua senha?</router-link
            >
        </div>
        <BaseButton
            class="mt-4 btn-primary w-100"
            :loading="isLoading"
            :disabled="isLoading"
            >Entrar
        </BaseButton>

        <p class="text-center mt-5 spacer"><span>ou</span></p>

        <BaseButton class="mt-2 btn-secondary w-100" to="register" link>
            Cadastre-se
        </BaseButton>
    </form>
</template>
<script setup>
import { ref, onMounted } from "vue";

import { useAuthStore } from '@/stores/auth';
const authStore = useAuthStore();

import { useGlobalStore } from '@/stores/global';
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
const globalStore = useGlobalStore();

const { login } = storeToRefs(authStore);

const page = ref("login");

const isLoading = ref(false);
const route = useRoute();

const emit = defineEmits(["code_required"]);

async function submit() {
    isLoading.value = false;

    if (authStore.login.password === "" || authStore.login.password.length < 5) {
        globalStore.localErrors("password", "Senha deve conter pelo menos 6 caracteres", page.value);
        return;
    }

    // eslint-disable-next-line
    grecaptcha.ready(() => {
        isLoading.value = true;
        // eslint-disable-next-line
        grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {
            authStore.login.recaptcha_token = token;
            authStore.tryLogin(route.name).then((r) => {
                isLoading.value = false;
                if(r == 'code_required') {
                    emit("code_required");
                }
            });
        });
    });
}

onMounted(() => {
    delete globalStore.errors[page.value];
});
</script>
<style scoped>
.spacer {
    border-top: 1px #ccc solid;
}


.spacer span {
    background-color: #fff;
    top: -12px;
    display: inline-block;
    position: relative;
    padding: 0px 20px;
}

@media screen and (max-width: 1100px){
    .spacer span {
        background-color: #f7f7f7;
    }
}
</style>