<template>
    <div class="mb-3 form-group">
        <label :for="inputId + '-color'" :class="[error ? 'danger-label' : '']">
            {{ label }}
        </label>
        <input class="form-control base-input" :id="inputId + '-color'" />

        <span v-if="!!error" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
    </div>
</template>

<script setup>
import { onMounted, computed, onBeforeUnmount, watch } from "vue";
import Spectrum from "spectrum-vanilla";

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: null,
    },

    id: {
        type: String,
        required: true,
    },

    page: {
        type: String,
        required: false,
    },

    modelValue: {},
});

const emit = defineEmits(["update:modelValue"]);

let spectrum = null;

onMounted(() => {
    spectrum = Spectrum.getInstance("#" + inputId.value + "-color", {
        preferredFormat: "hex3",
        showButtons: false,
        color: props.modelValue,
        showAlpha: false,
        showInput: true,
    });

    spectrum.on('move', function(e) {
        emit("update:modelValue", e.target.value);
        if(globalStore.errors[props.page] && globalStore.errors[props.page][props.id]) {
            delete globalStore.errors[props.page][props.id];
        }
    });
});

watch(() => props.modelValue, (e) => {
    if((e) && (spectrum)) {
        spectrum.set(e);
    }
});

onBeforeUnmount(() => {
    if(spectrum) {
        spectrum.destroy();
    }
});

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const inputId = computed(function () {
    let newId = props.id;
    return newId.replace(/[^a-z0-9]/gi, '-');
});

const error = computed(function () {
    if(globalStore.errors[props.page] && globalStore.errors[props.page][props.id]) {
        return globalStore.errors[props.page][props.id];
    }
    return null;
});
</script>

<style>
@import "spectrum-vanilla/dist/spectrum.min.css";

.sp-original-input-container {
    width: 100%;
}
input.spectrum {
    border-radius: 6px !important;
    box-sizing: border-box !important;
    height: 39px !important;
    padding: 8px 15px !important;
    box-shadow: none !important;
    border: 1px solid #d7d7d7 !important;
    font-size: 14px !important;
    color: #495057 !important;
    padding-left: 36px !important;
    width: 100% !important;
}

input.spectrum:focus {
    outline: 0 !important;
    border: 2px solid #215eda !important;
    box-shadow: inset 0 0 0 0px #215eda, 0 0 0 4px rgb(16 114 235 / 16%), inset 0 0 0 1px #215eda !important;
}

.form-group input.spectrum:focus {
    border-color: #b6b6b6 !important;
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 11%) !important;
    border: 1px solid #d7d7d7 !important;
    outline: none !important;
}

.sp-colorize-container {
    width: 16px !important;
    height: 16px !important;
    border-radius: 60px !important;
    border: 0 !important;
    position: absolute !important;
    top: 11px !important;
    left: 12px !important;
    border-top-right-radius: 60px !important;
    border-bottom-right-radius: 60px !important;
}

.sp-original-input-container .sp-add-on {
    border-top-right-radius: 60px !important;
    border-bottom-right-radius: 60px !important;
}

.sp-original-input-container .sp-add-on .sp-colorize {
    border-radius: 60px !important;
}
</style>

<style scoped>
.color_box {
    position: relative;
}

label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: #545454;
}
</style>