import { defineStore } from "pinia";
import qs from "qs";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';
import { ref } from 'vue'

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

const paramsredirect = ref({
    'page': null
});

export const useUsersStore = defineStore('users', {

    state: () => ({
        users: {},
        paginate: {},
        user: {},
        app_permissions: {},
        modules: [],
        stores: {},
        permissions: {},
        users_filters:{
            'search': null,
            'page': null
        },
    }),

    actions: {

        async tryUsers(params = null) {
            try {
                const response = await Api.get('users', {params, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "brackets" });
                }});

                this.users = response.data.data;
                this.paginate = response.data.meta;
                this.permissions = response.data.permissions
                this.app_permissions = response.data.app_permissions
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUser(id){
            try{
                const response = await Api.get('users/' + id)
                this.user = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryModules(){
            try{
                const response = await Api.get('users/modules')
                this.modules = response.data.data;
            } catch(error){
                useGlobalStore().tryIncomingErrors(error);
                return false; 
            }
        },

        async tryUserApp(id){
            try{
                const response = await Api.get('users/' + id + '/app')
                this.userapps = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryCreateUser(params, page){
              const formData = new FormData();
              for (let key in params) {
                if(params[key]) {
                    if(Array.isArray(params[key])){
                        params[key].forEach(v => formData.append(`${key}[]`, v))
                    } else if(typeof params[key] === 'object'){
                        for(let k in params[key]){
                            formData.append('modules_app' + '['+ k + ']', params[key][k])
                        }
                    } else {
                        formData.append(key, params[key]);
                    }
                }
              }
            try{
                    await Api.post('users/', formData).then(() =>{
                    useGlobalStore().successMessage('Usuário criado com sucesso');
                    paramsredirect.value.page = this.paginate.last_page
                    this.tryUsers(paramsredirect.value).then(() =>{
                        let lastUser = this.users.length - 1;
                        this.tryUser(this.users[lastUser].id).then(() =>{
                            setTimeout(() =>{
                                this.router.push('/users/' + this.user.id)
                            }, 250);
                        })
                    })
                })
               
                return true;
            }
            catch (error) {
                this.router.push('/users/create')
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },
        
        async tryUpdateUser(id, params, page){
            const formData = new FormData();
            for (let key in params) {
              if(params[key]) {
                  if(Array.isArray(params[key])){
                      params[key].forEach(v => formData.append(`${key}[]`, v))
                  } else if(typeof params[key] === 'object'){
                    for(let k in params[key]){
                        formData.append('modules_app' + '['+ k + ']', params[key][k])
                    }
                  } else {
                      formData.append(key, params[key]);
                  }
              }
            }
            formData.append('_method', 'put');

            try {
                await Api.post('users/' + id, formData);
                useGlobalStore().successMessage('Usuário atualizado com sucesso');
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryDeleteUser(user_id) {
            try {
                await Api.delete('users/' + user_id);
                useGlobalStore().successMessage('Usuário removido com sucesso');
            }catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
    }
});