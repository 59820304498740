import { defineStore } from "pinia";
import Api from "@/api";

import { useGlobalStore } from '@/stores/global';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useUserStore = defineStore('user', {

    state: () => ({
        user: {},
    }),

    actions: {

        async tryUser(){
            try {
                const response = await Api.get('/account/user');
                this.user = response.data.data; 
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryUpdateUser(page, params){
            try {
                let formData = useGlobalStore().getOnlyParams(params, this.user);
                formData['_method'] = 'put';

                await Api.post('/account/user/', formData);
                useGlobalStore().successMessage('Dados de acesso atualizados com sucesso');
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

    }
});