<template>
    <div class="container px-2 px-lg-4">
        <BaseHeader :title="title">
            <div v-if="globalStore.hasPermissionTo('request_withdrawal')" class="btn-group">
                <BaseButton link to="/withdrawals/request" class="btn btn-primary w-100 px-4">Solicitar saque</BaseButton>
            </div>
        </BaseHeader>
        <div class="box_content mt-2">
            <nav>
                <div class="nav" id="nav-tab" role="tablist">
                    <router-link to="/withdrawals" :class="[route.name === 'witdhrawalWithdrawals' ? 'active' : '']" @click="goToWithdrawals">Saques</router-link>
                    <router-link to="/withdrawals/anticipations" :class="[route.name === 'withdrawalAnticipations' ? 'active' : '']" @click="goToAnticipations">Antecipações</router-link>
                    <router-link to="/withdrawals/receivables" :class="[route.name === 'withdrawalReceivables' ? 'active' : '']" @click="goToReceivables">Agenda de recebíveis</router-link>
                </div>
            </nav>
        </div>
        <router-view></router-view>
    </div>
</template>
<script setup>
const title = {title: 'Carteira', icon: 'fa-wallet'};
import { ref, onBeforeMount } from 'vue';

import { useWithdrawalStore } from '@/stores/withdrawal';
const withdrawalStore = useWithdrawalStore();

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const isLoading = ref(false);

import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();


onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('wallet')) {
        return router.push('/403');
    }

    if(withdrawalStore.withdrawals.length == undefined){
        isLoading.value = true;
    }
});

function goToWithdrawals(){
    withdrawalStore.filters = {page: 0,};
    withdrawalStore.tryWithdrawals().then(() =>{
        router.push('/withdrawals/')
    })
}

function goToAnticipations(){
    withdrawalStore.filters = {page: 0,};
    withdrawalStore.tryAnticipations().then(() =>{
        router.push('/withdrawals/anticipations')
    })
}

function goToReceivables(){
    withdrawalStore.receivables_filters = {page: 0,};
    router.push('/withdrawals/receivables')
}


</script>

<style scoped>

.info_group{
    cursor: pointer;
}

.info_group:hover{
    background-color: #f7f7f7;
}

.active {
    background-color: #ececec;
}

.navigation_bar ul{
    flex-direction:row!important;
}

</style>