<template>
    <div class="box_content">
        <div class="p-4">
            <h6 >Definições de cobrança de juros</h6>
            <BaseSwitch
                id="meta[]" 
                class="mt-4" 
                label="Assumir juros de parcelamento"
                :page="page"
                v-model:checked="product.assume_fees"
            />
            <br>
            <p>
                Assumir o juros possibilita que o cliente compre parcelado sem juros, sendo o juros pago por você.<br>
                Também será assumido o juros de produtos secundários adicionados no momento da compra pelo cliente, como bump e upsell, caso estejam disponíveis.
            </p>
        </div>
        <div class="table-responsive table_bordered">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th class="ps-3">Parcela</th>
                        <th class="ps-3">Valor da parcela sem juros</th>
                        <th class="ps-3">Total para o cliente</th> 
                        <th class="ps-3">Total com juros descontado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(installment, key) in productStore.installments" :key="key">
                        <td><span>{{installment.number}}x</span></td>
                        <td><span>{{ installment.installment_client}}</span></td>
                        <td><span>{{ installment.amount_client}}</span></td>
                        <td><span>{{ installment.amount_productor}}</span></td>
                    </tr>
                   
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
const page = 'productEdit';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/products';
const productStore = useProductStore();
const { product } = storeToRefs(productStore);  
</script>

<style scoped>
.box_content .p-4 {
    padding: 19px 24px 19px 24px!important;
}
</style>