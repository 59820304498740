<template>
    <apexchart
        id="chart"
        width="100%"
        height="180"
        type="bar"
        :options="options"
        :series="series"
    ></apexchart>
</template>
  
  <script setup>
import { onMounted, ref } from "vue";

const props = defineProps({
    graphic_data: {
        required: false,
        default: null,
    }
})

const options = ref({
    chart: {
        id: "chart",
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
        parentHeightOffset: 0,
    },
    plotOptions: {
    bar: {
        borderRadius: 2,
        borderRadiusApplication: 'around',
        borderRadiusWhenStacked: 'last',
        borderWidth: 0,
    }
 },
    grid: {
        show: false,
    },
    xaxis: {
        labels: {
            style: {
                colors: "#8f8f8f",
                fontSize: "11px",
            },
        },
        axisBorder: {
            show: false,
        },
    },
    yaxis: {
        show: false,
        labels: {
            formatter: function (value) {
                return Math.abs(value) > 999
                    ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) +
                          "k"
                    : Math.sign(value) * Math.abs(value);
            },
            style: {
                colors: ["#8f8f8f"],
                fontSize: "11px",
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        x: {
            formatter: function (value) {
                return value;
            },
        },
        y: {
            formatter: function (value) {
                return value;
            },
        },
    },
    legend: {
        show: false,
    },
    fill: {
        type: "color",
    },
    stroke: {
        width: 3
    },
    colors: ["#215eda"],
});

const series = ref([
    {
        name: "Vendas",
        data: [
            {x: '00h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '01h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '02h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '03h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '04h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '05h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '06h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '07h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '08h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '09h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '10h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '11h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '12h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '13h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '14h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '15h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '16h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '17h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '18h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '19h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '20h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '21h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '22h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            {x: '23h', y: 0, fillColor: '#215eda', strokeColor: '#215eda'},
            ]
    },
]);

onMounted(async () => {
    if(props.graphic_data !== null){
        for(let i = 0; i < props.graphic_data.length; i++){
            series.value[0].data[i].y = props.graphic_data[i];
        }
    }
});

function updateChart() {
    if(props.graphic_data !== null){
        for(let i = 0; i < props.graphic_data.length; i++){
            series.value[0].data[i].y = props.graphic_data[i];
        }
    }
}

defineExpose({
    updateChart,
});
</script>

<style scoped>
#chart {
    padding: 0px 20px 20px 20px;
}
</style>