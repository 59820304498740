import { defineStore } from "pinia";

import axios from 'axios';
import Api from "@/api";

import { useToast, POSITION, TYPE } from "vue-toastification";
const toast = useToast();

import { useAuthStore } from '@/stores/auth';

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useGlobalStore = defineStore('global', {

    state: () => ({
        errors: {},
        fields_pages: {},
        showToast: true,
        defaultStore: {},
        storesList: {},
        referralsList: {},
    }),

    actions: {
        loader(r) {
            document.getElementById("global_loader").style.display = (r == 'show') ? 'block' : 'none';
        },

        infoMessage(response, timeout = 6000) {
            toast(
                response,
                { position: POSITION.BOTTOM_RIGHT, type: TYPE.INFO, timeout: timeout }
            );
        },

        successMessage(response, timeout = 4000) {
            toast(
                response,
                { position: POSITION.BOTTOM_RIGHT, type: TYPE.SUCCESS, timeout: timeout  }
            );
        },

        warningMessage(response, timeout = 4000) {
            toast(
                response,
                { position: POSITION.BOTTOM_RIGHT, type: TYPE.WARNING, timeout: timeout  }
            );
        },

        errorMessage(response, timeout = 4000) {
            toast(
                response,
                { position: POSITION.BOTTOM_RIGHT, type: TYPE.ERROR, timeout: timeout  }
            );
        },

        async tryIncomingErrors(error, page = null, params = null) {
            if(!error) {
                useAuthStore().tryLogout();
            }

            let status = error.response.status;
            if(status == 422) {

                if(error.response.data.status && error.response.data.status == 'error' && error.response.data.message){
                    this.errorMessage(error.response.data.message);
                }else {
                    let errors = error.response.data.errors;

                    if(params){
                        let page_redirect = null;
                        let index = 0;

                        for (var error_key in errors) {
                            for (let param_object_key in params) {
                                
                                for (let param_key in params[param_object_key]) {

                                    let error_key_compare = error_key;
                                    if(error_key.includes('.')){
                                        let new_error_key_list = error_key.split(".");
                                        error_key_compare = new_error_key_list[0];
                                    }

                                    if(params[param_object_key][param_key] == error_key_compare){

                                        if(index == 0){
                                            page_redirect = param_object_key;
                                        }

                                        index++;

                                        const currentErrors = {
                                            ...this.errors[param_object_key],
                                            [error_key]: errors[error_key][0]
                                        };
                                        this.errors[param_object_key] = currentErrors;
                                    }
                                }
                            }
        
                        }

                        if(page_redirect){
                            this.router.push({ name: page_redirect });
                            page = page_redirect;
                        }
                    }
                    else {
                        for (var key in errors) {
                            const currentErrors = {
                                ...this.errors[page],
                                [key]: errors[key][0]
                            };
                            this.errors[page] = currentErrors;
                        }
                    }
                }

            }else if(status == 419 || status == 401) {
                if(sessionStorage.getItem('sessionMessage') === null) {
                    this.warningMessage('Sua sessão expirou');
                    sessionStorage.setItem('sessionMessage', true);
                }
                useAuthStore().tryLogout();
                this.router.go()
            }else if (status == 404) {
                this.router.push("/404")
            }else if (status == 403) {
                this.router.push("/403")
            }else if (status == 429) {
                if(this.showToast) {
                    this.showToast = false;
                    this.warningMessage('Muitas requisições, por favor tente novamente mais tarde.');
                    setTimeout(() => {
                        this.showToast = true;
                    }, 6000);
                }
            }else {
                this.router.push("/");
                let s = (status) ? '('+status+')' : '';
                this.errorMessage('Ocorreu um problema com a requisição'+s+', tente novamente.');
            }
        },

        getPageNameParam(error, params) {
            let status = error.response.status;
            let page_name = null;
    
            if(status == 422) {
                let errors = error.response.data.errors;
                for (var error_key in errors) {
    
                    for (let param_object_key in params) {
                        for (let param_key in params[param_object_key]) {
                            if(params[param_object_key][param_key] == error_key){
                                page_name = param_object_key;

                                return page_name;
                            }
                        }
                    }

                }
            }

            return page_name;
        },

        localErrors(error, text, page) {
            const currentErrors = {
                ...this.errors[page],
                [error]: text
            };
            this.errors[page] = currentErrors;
        },

        addZero(r) {
            return (Number(r) < 10) ? '0' + r  : r;
        },

        formatDateSimple(value){
            if(!value)  { return }
            const getDate =  value.substring(0, 10);
            const dateParts =  getDate.split('-');                
            return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        },

        formatDate(value){
            if(!value)  { return }
            const dateObject = new Date(value);
            const dateToday = new Date();

            let getHour =  dateObject.getHours();
            let getMinutes =  dateObject.getMinutes();

            if((getHour + getHour) <= 18){
                getHour = '0' + getHour
            }

            if((getMinutes + getMinutes) <= 18){
                getMinutes = '0' + getMinutes
            }

            if ( dateObject.getDate() === dateToday.getDate() && dateObject.getMonth() === dateToday.getMonth() && dateObject.getYear() === dateToday.getYear()){
                return `Hoje às  ${getHour}:${getMinutes}`
            }  else if( dateObject.getDate() === dateToday.getDate() - 1 && dateObject.getMonth() === dateToday.getMonth() && dateObject.getYear() === dateToday.getYear()){
                return `Ontem às  ${getHour}:${getMinutes}`
            }

            const getDate =  value.substring(0, 10);
            const dateParts =  getDate.split('-');

                
            return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]} às  ${(getHour)}:${(getMinutes)}` ;
        },

        capitalizeText(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },

        formatDescriptDate(value){
            if(!value)  { return }
            let dateObject =  new Date(value);
            dateObject = new Date(dateObject.setHours(dateObject.getHours() + 3));
            const getDay =  dateObject.getDate();
            const getMonth =  dateObject.toLocaleString('default', { month: 'long' });
            const getYear =  dateObject.getFullYear();
                
            return `${getDay} de ${getMonth} de ${getYear}`;
        },  

        formatDateLong(value){
            if(!value)  { return }
            const dateObject =  new Date(value);

            const getDay =  dateObject.getDate();
            const getMonth =  dateObject.toLocaleString('default', { month: 'long' });
            const getYear =  dateObject.getFullYear();

            const getHour = this.addZero(dateObject.getHours());
            const getMinutes =  this.addZero(dateObject.getMinutes());
                
            return `${getDay} de ${getMonth} de ${getYear} às ${getHour}:${getMinutes}` ;
        },   
        
        formatMoney(value){
            if(value === null || value === undefined)  { return }
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        },

        formatSeconds(value) {
            if(!value)  { return }
            var sec_num = parseInt(value, 10);
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;
            return [hours,minutes,seconds].map(v => v < 10 ? "0" + v : v).join(":");
        },

        getFirstLetterName(n) {
            const userFirstLetterGet = n.split(" ").map(function (s) { return s.charAt(0); }).join('');
            return userFirstLetterGet[0];
        },

        getFirstName(name) {
            const first_name = name.split(" ")[0];
            return first_name;
        },
        
        formatPostcode(value){
            if(!value) { return }
            var postcode = value.match(/.{1,5}/g) ?? [];
            return `${postcode[0]}-${postcode[1]}`
        },

        truncate(source, size) {
            return source.length > size ? source.slice(0, size - 1) + "…" : source;
        },

        async getAddressByCep(cep) {
            let c = cep.replace(/D/g, "");
            try {
                const response = await axios.get('https://viacep.com.br/ws/' + c + '/json');

                return {
                    address: response.data.logradouro,
                    neighborhood: response.data.bairro,
                    city: response.data.localidade,
                    state: response.data.uf
                }
            } catch (error) {
                this.tryIncomingErrors(error);
                return false;
            }
        },

        async getDefaultStore() {
            try {
                const response = await Api.get("account/default-store");
                this.defaultStore = response.data.data;
                await this.getStoresList();
            } catch (error) {
                this.tryIncomingErrors(error);
                return false;
            }
        },

        async getStoresList() {
            try {
                const response = await Api.get('account/stores');
                this.storesList = response.data.data;
            } catch (error) {
                this.tryIncomingErrors(error);
                return false;
            }
        },

        async getReferralsList() {
            try {
                const response = await Api.get('account/referrals');
                this.referralsList = response.data.data;
            } catch (error) {
                this.tryIncomingErrors(error);
                return false;
            }
        },

        async setDefaultStore(id) {
            try {
                const response = await Api.post('account/stores/' + id);
                this.defaultStore = response.data.data;
            } catch (error) {
                this.tryIncomingErrors(error);
                return false;
            }
        },

        hasPermissionTo(slug) {
            return (useAuthStore().isPrimary) ? true : (useAuthStore().modules.includes(slug)) ? true : false;
        },

        statesList() {
            return {
                'AC': 'Acre',
                'AL': 'Alagoas',
                'AP': 'Amapá',
                'AM': 'Amazonas',
                'BA': 'Bahia',
                'CE': 'Ceará',
                'DF': 'Distrito Federal',
                'ES': 'Espírito Santo',
                'GO': 'Goiás',
                'MA': 'Maranhão',
                'MT': 'Mato Grosso',
                'MS': 'Mato Grosso do Sul',
                'MG': 'Minas Gerais',
                'PA': 'Pará',
                'PB': 'Paraíba',
                'PR': 'Paraná',
                'PE': 'Pernambuco',
                'PI': 'Piauí',
                'RJ': 'Rio de Janeiro',
                'RN': 'Rio Grande do Norte',
                'RS': 'Rio Grande do Sul',
                'RO': 'Rondônia',
                'RR': 'Roraima',
                'SC': 'Santa Catarina',
                'SP': 'São Paulo',
                'SE': 'Sergipe',
                'TO': 'Tocantins'
            };
        },

        getOnlyParams(params, model, currentPath = []) {
            let data = {};
        
            for (let key in model) {
                let newPath = currentPath.concat(key);
        
                let formattedKey = formatPath(newPath);
                if (startsWithAnyPath(formattedKey, params)) {
                    if (typeof model[key] === 'object' && !(model[key] instanceof File)) {
                        Object.assign(data, this.getOnlyParams(params, model[key], newPath));
                    } else {
                        data[formattedKey] = model[key];
                    }
                }
            }
        
            return data;

            function formatPath(path) {
                if (path.length === 0) return '';
            
                let formatted = path[0];
                for (let i = 1; i < path.length; i++) {
                    if (isNaN(path[i])) {
                        formatted += `[${path[i]}]`;
                    } else {
                        formatted += `[${path[i]}]`;
                    }
                }
                return formatted;
            }
            
            function startsWithAnyPath(key, paths) {
                for (let path of paths) {
                    if (key.startsWith(path.replace(/\./g, '[').replace(/(?<=\w)\[/g, ']['))) {
                        return true;
                    }
                }
                return false;
            }
        },

        getOnlyTabsParams(params, model){
            let data = {};

            let new_params = [];

            /* Unmount tab routes */

            for (let param_object_key in params) {
                for (let param_key in params[param_object_key]) {
                    new_params.push(params[param_object_key][param_key]);
                }
            }

            params = new_params;

            /* Unmount tab routes */
    
            for (let key in model) {
                if(params == null || params.includes(key)){
                    data[key] = model[key];
                }
                else if(typeof model[key] === 'object'){
                    for (let key_object in model[key]){

                        if(params.includes(key + '.' + key_object)){
                            data[key + '['+ key_object + ']'] = model[key][key_object];
                        }
                    }
                }
            }

            for (let key in data) {
                if(typeof data[key] === 'object' && data[key] instanceof File == false){
                    for (let key_object in data[key]) {
                        data[key + '['+ key_object + ']'] = data[key][key_object];
                    }

                    delete data[key]
                }
            }
    
            return data;
        },

        cleanObject(obj) {
            for (let key in obj) {
              if (Object.hasOwn(obj, key)) {
                if (Array.isArray(obj[key])) {
                  obj[key] = [];
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                  this.cleanObject(obj[key]);
                } else if (typeof obj[key] === 'number'){
                  obj[key] = 0;
                } else {
                  delete obj[key];
                }
              }
            }
        },
        
        getObjectById(object, id) {
            let cloned_object = JSON.parse(JSON.stringify(object));
            for (let i = 0; i < cloned_object.length; i++) {
                if(cloned_object[i].id == id){
                    return cloned_object[i];
                }
            }

            return false;
        },

        getObjectBySlug(object, slug) {
            let cloned_object = JSON.parse(JSON.stringify(object));
            for (let i = 0; i < cloned_object.length; i++) {
                if(cloned_object[i].slug == slug){
                    return cloned_object[i];
                }
            }

            return false;
        },
    }
});