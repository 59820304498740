<template>
    <div class="video-wrapper">
      <iframe
        class="video-iframe"
        :src="videoUrl"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen>
      </iframe>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      url: String,
    },
    computed: {
      videoUrl() {
        if(this.url){
            if (this.url.includes("youtube.com") || this.url.includes("youtu.be")) {
            return this.url.replace(/(?:watch\?v=|youtu.be\/)([a-zA-Z0-9_-]+)/, "youtube.com/embed/$1") + "?autoplay=1&mute=1&controls=0";
            } else if (this.url.includes("vimeo.com")) {
            const vimeoId = this.url.split("/").pop();
            return `https://player.vimeo.com/video/${vimeoId}?autoplay=1&mute=1&controls=0`;
            }
        }

        return null;
      }
    }
  };
  </script>
  
  <style scoped>
.video-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Proporção de aspecto 16:9 */
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>