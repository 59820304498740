<template>
    <apexchart
        id="line_chart"
        width="100%"
        height="300"
        type="line"
        stacked="false"
        :options="options"
        :series="series"
    ></apexchart>
</template>
  
  <script setup>
import { ref, onMounted } from "vue";
import { useStoreStore } from "@/stores/store";

const storeStore = useStoreStore();

const options = ref({
    chart: {
        id: "line_chart",
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false,
        },
        parentHeightOffset: 0,
    },
    colors: ['#215eda', '#21dab8', '#c7530f', '#c51cd5', '#cddc39'],
    grid: {
        show: false,
    },
    xaxis: {
        categories: [],
        labels: {
            style: {
                colors: "#8f8f8f",
                fontSize: "11px",
            },
        },
        axisBorder: {
            show: false,
        },
    },
    yaxis: {
        labels: {
            formatter: function (value) {
                return Math.abs(value) > 999
                    ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) +
                          "k"
                    : Math.sign(value) * Math.abs(value);
            },
            style: {
                colors: ["#8f8f8f"],
                fontSize: "11px",
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        x: {
            formatter: function (value) {
                return options.value.xaxis.categories[value - 1];
            },
        },
        y: {
            formatter: function (value) {
                var amount = value;
                amount = value + '%';

                return amount;
            },
        },
    },
    legend: {
        show: true,
    },
    stroke: {
        width: 3,
        curve: 'straight',
    },
});

const props = defineProps({
    chartStoreLoaded: {
        type: Boolean,
        default: false,
    },
});

const series = ref([

]);

onMounted(() => {
    let dataStructToPush = {
        name: null,
        data: [null],
    }

    if (props.chartStoreLoaded === true) {
        for(let i = 0; i <storeStore.testAb.chart.days.length; i++){
        options.value.xaxis.categories.push(storeStore.testAb.chart.days[i])
        }

        for(let i = 0; i < storeStore.testAb.chart.labels.length; i++){
            dataStructToPush.name = storeStore.testAb.chart.labels[i];
            dataStructToPush.data = storeStore.testAb.chart.conversion[i];
            series.value.push(dataStructToPush);
            dataStructToPush = {
                name: null,
                data: [null],
            }
        }
    }
});


defineExpose({});
</script>

<style scoped>
#line_chart {
    padding: 0px 20px 35px 20px;
}
</style>