<template>
    <div class="box p-3 pb-2 mb-3">
        <div class="row">

            <div v-for="(reference, index) in props.references" :key="index" class="col-6">
                <p class="mb-3">
                    <strong class="d-block">{{ formatReference(index) }}</strong>
                    {{ reference }}
                </p>
            </div>

        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    references: {
        required: true,
        default: null
    }
});

function formatReference(r) {
    let size = r.length;
    let replace = r.replace('_', ' ');
    let upper = replace.substring(0, 3).toUpperCase();
    let over = replace.substring(3, size);
    return upper + over;
}
</script>